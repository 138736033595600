import { getDateObject } from "./Date";

class Group{
    constructor(group, cruise, user){
        this.group = group;
        this.createdAt = group.createdAt;
        this.cruise = cruise;
        this.user = user;
        this.isLoggedIn = user?.username ? true : false;
        this.member = (this.group && this.user && this.group.members.find(m => m.username === user.username)) || false;
        this.chat = this.group?.chat;
        this.lastChat = this.getLastChat();
        this.lastLogin = this.getLastLogin();
        this.admin = this.group && this.group.admin;
        this.isAdmin = this.group && this.group.admin === user.username;
        this.adminNickname = this.group && (this.group.adminNickname || this.group.admin);
        this.isMember = this.member ? true : false;
        this.assignedToCurrentCruise = this.group && this.cruise && this.group.cruiseId === this.cruise.id;
        this.assignedToCruise = this.group & this.group.cruiseId !== 0 ? true : false;
        this.assignedToOtherCruise = this.assignedToCruise && !this.assignedToCurrentCruise;
        this.mayOpenChat = this.isMember && !this.member.invited;
        this.mustConfirmInvite = this.isMember && this.member.invited;
        this.mayAddMembers = (group.type.includes("private") && this.isAdmin) || (this.isMember && group.type.includes("noAdmin"));
        this.noAdmin = group.type.includes("noAdmin");
        this.joinable = this.isLoggedIn && group.type.includes("public") && !this.isMember
        
    };
    getLastChat(){
    
        const lC = this.chat && this.chat.reduce((accum,next) => next.createdAt > accum ? next.createdAt : accum, "");
        if(!lC)return "";
        return getDateObject(lC, this.user.language,"db").dateTime;
    };
    getLastLogin(){
        const lL = this.member && this.member.lastLogin;
        return getDateObject(lL, this.user.language,"db").dateTime;;
    };
    static staticLastChat (grp){
        if(!(grp && grp.chat))return "";
        const lC = grp.chat.reduce((accum, next) => next.createdAt > accum ? next.createdAt : accum, "");
        if(!lC)return "";
        return getDateObject(lC, this.user.language,"db").dateTime;;
    };
};

export default Group;