import React from "react";

const FormatTextArray = ({arr = []}) => {

    return(
        <p>
            {arr.map((s,idx) => <span key = {idx}>{s}<br/></span>)}
        </p>
    )
}
export default FormatTextArray;