import React, {useState, useEffect} from 'react';
import { simpleRequest } from '../../../newForms2/simpleRequest';
import PhotoShowProvider from '../PhotoShowProvider';
import { Translate } from '../../Language/Translate';
import Album from '../../../helpers/albums/albumObj';

function HomePhotos() {
  const [allPhotos, setAllPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const language = Translate.language || "enUK";

  useEffect(() => {
    const fetch = async() => {
      const data = (await simpleRequest({url:"albumphotos"})).data;
      const alPh = data?.albumPhotos || [];
      const newArr = alPh.map(p => {
        return{...p, title:Album.analyze(p, language)}
      })
      setAllPhotos(newArr);
      setIsLoading(false);
    }
    if(isLoading)fetch();
  });
  // console.log({allPhotos});
 
  return (
    <div className="text-center">
      <PhotoShowProvider photos = {allPhotos}/>
    </div>
  );
}

export default HomePhotos;