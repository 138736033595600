// 11.11.23
import React, { useEffect, useState } from "react";
import Routes from './Routes';
import Footer from './Footer';
import {useDispatch, useSelector} from "react-redux";
import StartPage from "./FirstSteps3/StartPage";
import StartPageEmpty2 from "./FirstSteps3/StartPageEmpty2";
import {useHistory, useLocation} from 'react-router-dom';
import Requestor from "./Requestor";
import { fetchCruiseFromApi } from "../actions/currentCruise";
import Spinner from "./Spinner";
  
function SubApp() {
  // console.log('inside SubApp');
  const [isLoading, setIsLoading] = useState(true);
  const [showStartPage, setShowStartPage] = useState(true);
  const [next, setNext] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const currentUser = useSelector(st => st.currentUser)
  const username = currentUser?.username || "";
  const loggedIn = !!username;
  const dispatch = useDispatch();
       
  useEffect(() => {
    const fetch = () => {
      const search = new URLSearchParams(location.search);
      const next = search.get('next');
      setNext(next);
      const code = search.get('code');
      const username = search.get('username');
      const email = search.get('email');
      const cruiseId = search.get('cruise_id') || +localStorage.getItem("cruiseId") || null;
      let url = "/home";
        if(next === "editprofile" && loggedIn){url = `/users/${currentUser.username}/profile`}
        if(next === "cruises")url = (`/cruises/${cruiseId}`)
        if(next === "newpassword")url = (`/newpassword/${username}/${code}`)
        if(next === "datenschutz")url = ('/datenschutz');
        if(next === "login")url = ('/login')
        if(next === "confirmation")url = (`/confirmation/${username}/${code}`);
        if(next === "unsubscribe")url = (`/unsubscribe/${email}/${code}`);
        if(next === "deleteform")url = ('/deleteform')
        if(next === "delete")url = (`/delete/${username}/${email}/${code}`);
        if(next !== "cruises" && !!cruiseId){dispatch(fetchCruiseFromApi(cruiseId))};
        (() => setIsLoading(false))();
        history.push(url);
    };
    
    if(isLoading) fetch();
  }, [isLoading, currentUser, location.search, loggedIn, history, dispatch]);

  if(isLoading)return <Spinner/>
 
  return (
    <div >
    {/* <div style={{border: "1px solid green"}}> */}
      {(loggedIn || !showStartPage) && <StartPage next={next}/>}
      {!loggedIn && showStartPage && <StartPageEmpty2 showStartPage={showStartPage } setShowStartPage={setShowStartPage} next={next}/>}
      {currentUser.userStatus === "developer" && <Requestor />}
      <Routes/>
      <Footer className='mt-3 footer sticky'/>
    </div>
  );
}

export default SubApp;  