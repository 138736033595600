// 11.11.23
import {
  FETCH_CRUISES,
  UPDATE_CRUISE_IN_CRUISELIST,
  DELETE_CRUISE_FROM_CRUISELIST
} from "./types";
import {simpleRequest} from "../newForms2/simpleRequest"

export function fetchCruisesFromAPI() {
  return async function (dispatch) {
    const response = await simpleRequest({url:`cruises`, method:"get"});
    const cruises = response.data && response.data.cruises
    return dispatch(fetchCruises(cruises));
  };
};
function fetchCruises(cruises) {
  return {
    type: FETCH_CRUISES,
    cruises
  };
};

export function updateCruiseOnApi(id, data){
  return async function(dispatch){
    const response = await simpleRequest({url:`cruises/${id}`, method:"patch", data});
    const cruise = response.data && response.data.cruise;
    return dispatch(updateCruise(cruise));
  };
};
function updateCruise(cruise){
  return{
    type: UPDATE_CRUISE_IN_CRUISELIST,
    cruise
  };
};

export function deleteCruiseFromApi(id){
  return async function(dispatch){
    const response = await simpleRequest({url:`cruises/${id}`, method: "delete"});
    const deleted = response.data && response.data.deleted;
    return dispatch(deleteCruise(deleted));
  };
};

function deleteCruise(deleted){
  return{
    type: DELETE_CRUISE_FROM_CRUISELIST,
    deleted
  };
};
