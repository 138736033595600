import React, {useState } from "react";
import './CompanyDetails.css';
import CrewListDetails from "./CrewListDetails";

const CrewListItem = ({cc}) => {

    const [switcher, setSwitcher] = useState(false)

    return(
        <>
                {!switcher && <button className="btn btn-outline-dark col-12 mb-2" onClick={() => setSwitcher(true)}>{cc.firstName} {cc.lastName}</button>}
                {switcher && <CrewListDetails setSwitcher={setSwitcher} cc={cc}/>}
        </>
    )
}

export default CrewListItem;
