import {
    ADD_GROUP,
    FETCH_GROUPS,
    UPDATE_GROUP,
    DELETE_GROUP,
    UPDATE_GROUP_MEMBER,
    // FETCH_GROUP_MEMBERS,
    ADD_GROUP_CHAT
} from "./types";
import {simpleRequest} from '../newForms2/simpleRequest';
import { sortArrayByObjectField } from "../helpers/sort";

// =============================== GROUP =================================================

export function addGroupOnApi(data){
    return async function(dispatch){
      const response = await simpleRequest({url:`groups`, method:"post", data})
      const group = response.data && response.data.group;
      // console.log({group});
      return dispatch(addGroup(group));
    };
  };

  function addGroup(group){
    return{
      type: ADD_GROUP,
      group
    };  
  };
  
export function fetchGroupsFromApi(cruiseId = 0){
  // console.log('inside fetchGroupsFromApi', cruiseId);
    return async function(dispatch){
      // console.log('inside function');
        const resUser = await simpleRequest({url:"groups/user", method:"get"});
        // console.log('inside function2');
        const resCruise = await simpleRequest({url:`groups/cruise/${cruiseId}`, method:"get"});
        // console.log('inside function3');
        // console.log({resCruise, resUser});
        const userGroups = (resUser.data?.groups) || [];
        const cruiseGroups = (resCruise.data?.groups) || [];
        const userGroupList = userGroups.map(ug => ug.id);
        const allGroups = userGroups.concat(cruiseGroups.filter(cg => !userGroupList.includes(cg.id)));
        allGroups.forEach(g => g.lastChat = g.chat?.reduce((accum, next) => next > accum, ""));
        // console.log({allGroups, userGroups, cruiseGroups});
        const orderedGroups = sortArrayByObjectField(allGroups, "lastChat").reverse();
        orderedGroups.forEach((g, idx) => g.order = idx);
        return (dispatch(getGroups(orderedGroups)));
    };
};

function getGroups(groups){
  
  return{
    type: FETCH_GROUPS,
    groups
  };
};

export function updateGroupOnApi(groupId, data){
  // console.log("inside updateGroupOnApi", {groupId,data});
    return async function(dispatch){
      const res = await simpleRequest({url:`groups/${groupId}`, method:"patch", data});
      // console.log({res}, res.data);
      const group = res.data && res.data.group;
      // console.log({group});
      if(!group)return;
      return dispatch(updateGroup(group));
      
    };
};

function updateGroup(group){
  // console.log('inside updateGroup,', group)
    return{
      type: UPDATE_GROUP,
      group
    };
  };

  export function deleteGroupOnApi(groupId){
    return async function(dispatch){
      const res = await simpleRequest({url:`groups/${groupId}, method:"delete"`});
      const deleted = res.data && res.data.deleted;
      if(!deleted)return;
      return dispatch(deleteGroup(deleted));
    };
  };
  function deleteGroup(deleted){
    return{
      type: DELETE_GROUP,
      deleted
    };
  };


// ============================ GROUP_MEMBERS==========================================
  export function addDeleteMultipleGroupMembersOnApi(group, data){
    return async function(dispatch){
      const response = await simpleRequest({url:`groupmembers/${group.id}`, method:"post", data});
      const groupMembers = response.data && response.data.groupMembers;
      // console.log(response.data);
      const updatedGroup = {...group, members:groupMembers};
      return dispatch(updateGroup(updatedGroup));
    };
   };
  //  function fetchGroupMembers(members){
  //   return{
  //     type:FETCH_GROUP_MEMBERS,
  //     groupMembers
  //   }
  //  }
   
  
  export function updateGroupMemberOnApi(groupMemberId, data){
    // console.log('inside updateGroupMemberOnApi', groupMemberId)
    return async function(dispatch){
        const res = await simpleRequest({url:`groupmembers/${groupMemberId}`, method:"patch", data});
        const groupMember = res.data && res.data.groupMember;
        // console.log({groupMember});
        if(!groupMember)return 
        return dispatch(updateGroupMember(groupMember));

    };
   };
   function updateGroupMember(groupMember){
    // console.log({groupMember});
    return{
        type: UPDATE_GROUP_MEMBER,
        groupMember
    };
   };



// ============================= GROUP_CHAT============================================

  export function addChatItemOnApi(groupId, data){
    // console.log({groupId, data});
    return async function(dispatch){
      const response = await simpleRequest({url:`groupchat/${groupId}`, method: "post", data});
      const groupChat = response.data && response.data.groupChat;
      // console.log({groupChat});
      return dispatch(addChatItem(groupChat));
    };
  };
  function addChatItem(groupChat){
    return{
      type: ADD_GROUP_CHAT,
      groupChat
    }
  }


  





