import {translate} from "../../components/Language/Translate";

class FriendFormData{

    static async friendNew(data){
        
        // console.log('inside MessageFormData.messageNew', data, data.params)
        const {currentUser} = data;
        const {friendName} = data.params;
        const consts = new FriendFormSchema();
        let fields;
        Object.entries(consts.fields).forEach(e => fields = {...fields, [e[0]]: e[1].value})
        consts.formType = 'friendNew';
        fields.friendName = friendName;
        fields.username = currentUser.username;
        consts.request.url=`friends/${friendName}`
        consts.nextPage = (`/users/${currentUser.username}`);
        consts.sendData = ["message"]
        const vars={fields};
        // console.log({consts, vars})
        return {consts, vars};
    }
        
       
};

class FriendFormSchema{
    constructor(){
        
        this.formType="friendNew";
        this.request = {
            url:"friends",
            method:"post",

        };
        this.formLabels = {title: "Apply Friend", btnLabel:"Apply Friend"};
        this.actions= {
            onSuccess:{action:true}
        }

        this.fields={
            username : {
                label:translate("From:"),
                value:"",
                validation:{},
                required:true,
                disabled:true,
                type:"text",
                fieldType:"text"
            },

            friendName : {
                label:translate("To:"),
                value:"",
                validation:{
                    minLength:8,
                    mustNotBeEqualTo:"username"
                },
                required:true,
                disabled:true,
                type:"text",
                fieldType:"text"
            },

            message: {
                label:translate("Optional Message"),
                value:"",
                validation:{},
                type:"text_area",
                fieldType:"textarea"
            }
    
        }
    };
};

export default FriendFormData;