import React, { useEffect, useState } from "react";
import { BlackWhite } from "../styledComponents/colors";
import { simpleRequest } from "../../newForms2/simpleRequest";
import Spinner from "../Spinner";
import ChatThemeDetails from "./ChatThemeDetails";


const ChatThemeList = ({category}) => {
    const [themes, setThemes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [activeId, setActiveId] = useState(0);

    useEffect(() => {

        const fetch = async() => {
            const request = {url: `travelchatthemes/${category}`}
            const travelchatThemes = (await simpleRequest(request)).data?.travelchatThemes || [];
            setThemes([...travelchatThemes])
            setIsLoading(false)
        }
        fetch()
    }, [setIsLoading, category])

    if(isLoading)return <Spinner/>
    // console.log({activeId, isLoading})

    return(
        <div>
            <ul className="my-3">
                {themes.map(t => {
                    // console.log({t})
                    return(
                        <li key={t.id}>
                            {<p onClick={() => setActiveId(t.id !== activeId ? t.id : 0)}>
                                <BlackWhite>
                                    <b>{t.title}</b><span className="ml-2">{t.subTitle}</span>
                                </BlackWhite>
                            </p>}
                            {activeId === t.id && <ChatThemeDetails theme={t} backFunc={() => setActiveId(0)}/>}
                        </li>
                    )
                })}
            </ul>
        </div>
    )

};
export default ChatThemeList;