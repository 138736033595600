import { uploadToImgBB } from "./imgBB";
import { resizeFile } from "./resize";

const resizeAndUpload = (file) => {
    // console.log({file});
    
    return new Promise ((resolve, reject) => {
        
        resizeFile(file)
            .then(resized => {
                // console.log({file, resized})
                return uploadToImgBB(resized)})
            .then(data => {
                // console.log('data', data);
                // console.log('url', data.image.url);
                // console.log('success');
                const resData =  { imageUrl:data.image.url, response:JSON.stringify(data), origin:file.name};
                resolve (resData);
            })
            .catch(error => {
                // console.log('error', error);
                reject(error);
            });
    })

    ;
};

export {resizeAndUpload}