import React, {useState} from "react";
// import { useDispatch, useSelector } from "react-redux";
import { translate } from "../Language/Translate";
import PhotoItem from '../PhotoShows/Destination/PhotoItem'
import PhotoList from "../PhotoShows/Destination/PhotoList";
import AlbumPhotosNew from "../../newForms2/albums/AlbumPhotosNew";

const AlbumPhotos = ({album, refresh}) => {
    // console.log('inide AlbumPhotos');
    // const dispatch = useDispatch();
    const [selectedId, setSelectedId] = useState(0);
    const [view, setView] = useState('main'); // formAddNew
    // const currentDestination = useSelector(st => st.currentDestination);
    // const currentUser = useSelector(st => st.currentUser)
    const actualPhoto = (selectedId && album.photos.find(p => p.id === selectedId)) || null;
    // const mayDelete = album.createdBy === currentUser.username || ["admin", "developer"].includes(currentUser.userStatus);
    // const deleteText = `${translate("Remove this album from")} ${currentDestination.deDE}`;

    // console.log({selectedId});
    // const handleDelete = async () => {
        // await simpleRequest({url: `albumdestination/${album.id}`, method:"delete"});
        // dispatch(fetchDestinationFromAPI(currentDestination.id));
    // };

    const Main = () => (
        <div className="text-center">
            {/* <h6>Album: {album.titleDE} / {album.titleEN} {`(${album.photos?.length || 0})`}</h6> */}
            {/* {mayDelete && 
                <button className = "btn btn-outline-danger ml-1" onClick = {handleDelete}>{deleteText}</button>} */}
                {/* <span className="ml-2">{translate("Remove album from")} {currentDestination.deDE}</span><br/></>} */}
            {!actualPhoto && <PhotoList photos={album.photos} setSelectedId={setSelectedId}/>}
            {!actualPhoto && <button className="btn btn-outline-dark mt-2" onClick={() => setView("formAddNew")}>{translate("Add new photo")}</button>}
            {actualPhoto && <PhotoItem photo={actualPhoto} setSelectedId={setSelectedId} photos={album.photos} refresh={refresh}/>}

        </div>
    )
    return(
        <div style={{border:"1px solid grey", borderRadius:"5px"}} className='mt-2 p-2'>
            {view === "main" && <Main/>}
            {view === "formAddNew" && <AlbumPhotosNew setView={setView} album = {album} refresh={refresh}/>}
        </div>
        
    )
}
export default AlbumPhotos;