import React from "react";
import {Translate, translate} from '../../components/Language/Translate';
import { useSelector } from "react-redux";
import TextField from "../NewFields/TextField";
import TextAreaField from "../NewFields/TextAreaField";
import CheckBox from "../NewFields/CheckBox";

const AlbumFormFields = ({props}) => {
    const {variables, setVariables} = props;
    const currentDestination = useSelector(st => st.currentDestination);
    const language = Translate.language;
    const checkText = `${translate("Get Geolocation from")} ${currentDestination[language]}`;

    const handleCheck = e => {
        // console.log(e.target);
        const {name, value, checked} = e.target;
        // console.log({name, value, checked})
        const geolocation = checked ? currentDestination?.geolocation || "" : "";
        setVariables(variables => {return {...variables, geolocation, getFromGeolocation:checked}});
    }

    const newProps = {...props, handleCheck, variables}
    return(
        <>
            <TextField props = {{...newProps, name:"titleDE", maxLength: 60, label: "German", required:true, autofocus:true}} />
            <TextField props = {{...newProps, name:"titleEN", maxLength: 60, label: "English"}} />
            <CheckBox props = {{...newProps, name:"getFromGeolocation", label: checkText, handleCheck}} />
            <TextField props = {{...newProps, name:"geolocation", label: "Geolocation"}} />
            <TextAreaField props = {{...newProps, name:"description", label: "Description"}} />
        </>
    )
};
export default AlbumFormFields;