import React from "react";
import SpeciesGroupListItem from "./SpeciesGroupListItem";

const SpeciesGroupList = ({props, variables}) => {
    
    return(
        <div>
            {Object.values(variables.speciesGroups).map(gl => 
                <SpeciesGroupListItem key = {gl.id} props = {props} groupItem={gl} variables={variables}/>)}
        </div>
    );
}
export default SpeciesGroupList;