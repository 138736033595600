import React, { useState } from "react";
import {translate} from "../Language/Translate"
import {Button, Form} from 'react-bootstrap';
import { simpleRequest } from "../../newForms2/simpleRequest";

const Result = ({element}) => {

    if(element !== 0 && !element)return null;
    if(element instanceof Array){
        // console.log('is Array', {element});
        return (<>
            <span> Array ({element.length}) </span>
            <ul>
                {element.map((e, idx) => 

                            <li key={idx}>
                                {idx}: <Result element={e} />
                            </li>
                )}
            </ul>
            </>)
    }else if (element instanceof Object){
        const entries = Object.entries(element);
        return(
            <>
                <span> Object </span>
                <ul>
                    {entries.map((k, idx) => {
                        return(
                            <li key={idx}>
                                {k[0]}: <Result element={k[1]} />
                            </li>
                        )
                    })}
                </ul>
            </>
        );
    
    
    }else if(typeof element ==="boolean"){
        return (
            <>
                <span>({typeof element}): {element ? "true" : "false"}</span>
            </>
        )
    }else{
        return(
            <>
                <span> ({typeof element}): {element}</span>
            </>
        )
    }
};

const SqlField = () => {
    const [values, setValues] = useState({sql:""});
    const [result, setResult] = useState("");
    // const [order, setOrder] = useState('none');

    const handleChange = e => {
        (() => setValues({...values, sql:e.target.value}))();
    };
    const handleSubmit = async () => {
        const respArr = [];
        const dataArr = values.sql.split(';');
        for(let i = 0; i < dataArr.length; i++){
            let query = dataArr[i].replaceAll("\n", " ").trim();
            let len = query.length;
            let oldLen = len + 1;
            while (oldLen > len){
                oldLen = query.length;
                query = query.replaceAll("  ", " ");
                query = query.replaceAll("( ", "(")                
                query = query.replaceAll(" )", ")")                
                len = query.length;
            };
            if(query.length > 0){
                const data={sql:query};
                const response = await simpleRequest({url:"admin/sql", method:"post", data});
                const result = (response.data && response.data.result) || null;
                respArr.push({status:response.status, result, sql:query});
            };
            
        };
        // console.log(respArr);
        setResult(respArr);
        return respArr;
    };
    const directGet = async(url) => {
        const res = (await simpleRequest({url, method:"get", data:{}})).data;
        // console.log(res);
        (() => setResult(res))();
    }


    console.log({values, result})
        return(
        <>
        <div>
            <button className = "btn btn-outline-dark m-1" onClick={() => setValues({sql:""})}>Clear</button>
            <button className = "btn btn-outline-dark m-1" onClick={() => directGet('admin/tableschemas/get')}>TableSchemas</button>
            <button className = "btn btn-outline-dark m-1" onClick={() => directGet('admin/tableschemas/short')}>TableSchemas short</button>
            <button className = "btn btn-outline-dark m-1" onClick={() => directGet('admin/tableschemas/compare')}>Compare TableSchemas</button>
            <button className = "btn btn-outline-dark m-1" onClick={() => directGet('admin/tableschemas/statistics')}>Statistics</button>
        </div>
        <div>
            <Form>
                <Form.Group size="lg" controlId={"sql"} >
                    <Form.Label  className ="pt-1 px-1" >SQL</Form.Label>
                    <Form.Control as="textarea" rows={5}
                        name={"sql"} 
                        type="textarea" 
                        fieldtype="textarea" 
                        key={"sql"} 
                        autoFocus={true}
                        value={values.sql}
                        onChange={e => handleChange(e)} 
                    />
                </Form.Group>
                <Button onClick = {handleSubmit}>{translate("Submit")}</Button>
            </Form>
            {/* <p className="mt-3">{JSON.stringify(result)}</p> */}
        </div>
        <div className="text-left">
            <small><Result element={result}/></small>
        </div>
        </>
    );
};
export {Result};
export default SqlField;