import React from "react";
// import {translate} from '../Language/Translate';
import { impressumText } from "./impressumText";

const Impressum = () => {
   


    return(
        <div style={{backgroundColor:"rgba(255, 255, 255, 0.5)"}} className="p-3">
            <h4>Impressum</h4>
            {impressumText.map((t, idx) => {
                return (t === ""  ? <br key={idx}/> : <p key={idx}>{t}</p>)
            })}
        </div>
    )
    
    
};
export default Impressum;
    