import React, {useState} from "react";
import {translate} from "./Language/Translate"
import { sortArrayByObjectField } from "../helpers/sort";
import AlbumDetails from "./Albums/AlbumDetails";
import { useDispatch, useSelector } from "react-redux";
import { fetchCruiseFromApi } from "../actions/currentCruise";

const CruiseAlbumsList = ({albums}) => {
    const [view, setView] = useState('list'); // list, albumDetails
    const [albumId, setAlbumId] = useState(0);
    const dispatch = useDispatch();
    const sorted = sortArrayByObjectField(albums, "titleDE");
    // console.log({sorted});
    const currentCruise = useSelector(st => st.currentCruise);

    const refresh = async () => {
        dispatch(fetchCruiseFromApi(currentCruise?.id || 0));
        setView('list');
    };


    const HelpText = () => (
        <div>
            <p>{translate('At this point you cannot add albums, because a new album must be connected to a destination')}.</p>
            <p>{translate(`In order to add an new album go to Cruise Diary`)}.</p>
            <p>{translate(`There open the destination, open the photo albums, and click "Add new Album"`)}</p>
        </div>
    );
    
    const handleClick = e => {
        // console.log(e.target.id);
        (() => setAlbumId(+e.target.id))();
        (() => setView('albumDetails'))();
    };

    const AlbumButtons = () => {
        return(
            <div>
                <h4 className="mb-3">{translate("Photo Albums")}</h4>
                <div>
                    <p>
                        {sorted.map(a => 
                            <button className="btn btn-outline-dark m-1" key={a.id} id={a.albumId}
                                onClick = {e => handleClick(e)}>
                                {a.titleDE}
                            </button>)}
                    </p>
                </div>
                <HelpText />
            </div>

        )
        
    }
    return(
        <div>
            {view === "list" && <AlbumButtons />}
            {view === "albumDetails" && <AlbumDetails albumId={albumId} backFunc={() => setView('list')} refresh={refresh}/>}
        </div>
    );
};
export default CruiseAlbumsList;