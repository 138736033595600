import TextField from './Fields/TextField'
import SelectField from './Fields/SelectField'
import TextAreaField from './Fields/TextAreaField'
import CheckBox from './Fields/CheckBox';
// import InfoBox from './Fields/InfoBox';
import LinkField from './Fields/LinkField';
import TextFieldWithValueArea from './Fields/TextFieldWithValueArea';
import TextFieldWithValueAreaEvent from './Fields/TextFieldWithValueAreaEvent';
import TextAreaFieldWithPreview from './Fields/TextAreaFieldWithPreview';


const ReactFormFields = ({variables, constants}) =>{
 
  // console.log(Object.keys(variables));
  // Object.entries(constants.fields).forEach(v => // console.log(v, v[0], Object.keys(variables.fields)));
  // const fieldArray = Object.entries(constants.fields).filter(v => Object.keys(variables.fields).includes(v[0]));
  const fieldArray = Object.entries(constants.fields);
  
  // console.log({fieldArray})

  // fieldArray.forEach(f => // console.log(f[0], f[1].fieldType))
    return(
    fieldArray.map(f => {
        const name=f[0]
        const fieldType = f[1].fieldType;
        // console.log({fieldType, name})
        
          switch (fieldType) {
              case "text":
              case "date":
              case "number":
                return <TextField name={name} key={name}  constants={constants} variables = {variables}/>
              case "select":
                return <SelectField name={name} key={name} constants={constants} variables = {variables} />
              case "textarea":
                return <TextAreaField name={name} key={name} constants={constants} variables = {variables}/>
              case "textWithValuesEvent":
                return <TextFieldWithValueAreaEvent name={name} key={name} constants={constants} variables = {variables}/>
              case "textWithValues":
                return <TextFieldWithValueArea name={name} key={name} constants={constants} variables = {variables}/>
              case "checkBox":
                return <CheckBox name={name} key={name} constants={constants} variables = {variables} />
              // case "info":
              // case "info_stay":
              //   return <InfoBox name={name} key={name} constants={constants} variables = {variables} />
              case "link":
                return <LinkField name={name} key={name} constants={constants} variables = {variables} />
              case "textAreaWithPreview":
                return <TextAreaFieldWithPreview name={name} key={name} constants={constants} variables = {variables} />
                
              default:
                return null;
          }
    })
)};

export default ReactFormFields;