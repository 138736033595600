import React, { useEffect, useState } from "react";
import { StyledDivWithBorder } from "../styledComponents/divs";
import { Col, Row } from "react-bootstrap";
import { translate } from "../Language/Translate";
import TourReportSchema from '../../newForms3/tourReports/tourReportSchema';
import { getDateObjectLocal } from "../../helpers/Date";
import YesNoButtons from "../YesNoButtons";
import { simpleRequest } from "../../newForms2/simpleRequest";
import TourReportEdit from "../../newForms3/tourReports/TourReportEdit";
import TimingList from "./TourTiming/TimingMain";
import TimingMain from "./TourTiming/TimingMain";

const TourReportDetails = ({props}) => {
    const {tourReport, tour, setActiveTR, setTourReports} = props
    const [fields, setFields] = useState({});
    const [view, setView] = useState('main');


    useEffect(() => {
        const _fields = new TourReportSchema([tour]).fields;
        setFields(_fields)
    }, [tour]);
    // console.log(Object.keys(tourReport));
    // console.log(Object.keys(tour));
    const keysTR = [
        "name",
        "remarks",
        "difficulty",
        "description",
        "localGuideName",
        "localGuideLanguageAndLevel",
        "localGuideQuality",
        "headsets",
        "restaurantName",
        "restaurantQuality",
        "tourQuality",
        "contentment",
        "proposals",
        "changes",
    ];
    const handleDelete = async() => {
        const {id} = tourReport;
        const deleted = (await simpleRequest({url: `tourreports/${id}`, method:"delete"})).data?.deleted;
        console.log({deleted});
        setTourReports(tr => tr.filter(tr => tr.id !== id));
    }
    const headline = () => {
        const {short, title} = tour;
        const titleTr = tourReport.name ? `${tourReport.name}, ` : "";
        const tr = short || title ? `${short ? `${short}, `:""} ${title?`${title}, `:""}` : `(${translate("No tour selected")}.)`;
        return(
            <h6>
                {titleTr} {tr} {` ${translate("created at")}`}:
                     {` ${getDateObjectLocal(tourReport.createdAt).dateTimeLong}`}
            </h6>
        )}
    const confirmation = 
        <div className="my-2">
            {headline()}
            <h6>{translate("Do you want to delete this tour report")}?</h6>
            <YesNoButtons yesFunc={handleDelete} noFunc={() => setView("main")}/>
        </div>

    const main = 
        <div>
            <h5 onClick={() => setActiveTR(0)}>{translate("Tour Report Details")}</h5>
            {headline()}
            <TimingMain tourReport={tourReport}/>
            <table className="table">
                <tbody>

                        {keysTR.map(k => {
                            let value = tourReport[k];
                            if(typeof value === "boolean")value = value ? "ja" : "nein";
                            return(
                                <tr key={k}>
                                    <td>{fields[k]?.label}</td>
                                    <td>{value}</td>
                                </tr>)
                        })}

                </tbody>
                
            </table>
            <Row>
                <Col>
                    <button className="btn btn-outline-dark col-12" 
                            onClick = {() => setView("editForm")}>{translate("Edit")}</button>
                </Col>
                <Col>
                    <button className="btn btn-outline-danger col-12" 
                            onClick = {()=>{setView("confirmation")}}>{translate("Delete")}</button>
                </Col>
                <Col>
                    <button className="btn btn-outline-secondary col-12" 
                            onClick = {()=>{setActiveTR(0)}}>{translate("Back")}</button>
                </Col>
            </Row>
        </div>
    

    return(
        <StyledDivWithBorder>
            {view === "main" && main}
            {view === "confirmation" && confirmation}
            {view === "editForm" && <TourReportEdit props={{...props, backFunc:() => setView("main")}}/>}
        </StyledDivWithBorder>

    )
};
export default TourReportDetails;