import {tourReports} from '../../components/CsvToJson/data/tourReport'
import { getAllPropertiesFromCsvData } from '../../components/CsvToJson/helpers'
import { translate } from '../../components/Language/Translate';
import {getDateObject} from '../../helpers/Date'

class TourReportSchema{
    constructor(tours){
        console.log(getAllPropertiesFromCsvData(tourReports))
        const _fields = getAllPropertiesFromCsvData(tourReports).formFields;
        
        _fields.tourId.options = tours.map(t => {
            const dest = t.deDE ? t.deDE.split(', ')[0] : translate("No tour selected");
            return {key:t.id, value : `${getDateObject(t.date, "deDE").short} - ${dest} - ${t.short} - ${t.title}`}
        });
        // console.log({_fields});
        this.fields = _fields;
    }

};
export default TourReportSchema;