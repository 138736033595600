class DestinationFormSchema{
    constructor(){
        this.fields = {

            deDE : {
                name:"deDE",
                label : "Destination (de)",
                fieldType: "text",
                type:"text",
                disabled:false,
                value :"",
                validation :{minLength: 3},
                autoFocus: true
            },
            enUK : {
                name:"enUK",
                label : "Destination (en)",
                type:"text",
                fieldType: "text",
                disabled:false,
                value :"",
                validation :{minLength: 3}
            },
            geolocation:{
                name:"geolocation",
                label : "Geolocation",
                type:"text",
                fieldType: "text",
                disabled:false,
                value :""
            }
        };
    };
};
export default DestinationFormSchema;