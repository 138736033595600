import AnnouncementFormData from "../announcements/AnnouncementFormData";
// import {simpleRequest} from "../simpleRequest";

// returnArray = ["id", "cruiseId", "createdBy", "updatedAt", "updatedBy","by", "subject", "description", "url", "visibleCrew", "visibleStaff", "visibleGuests"]
// import


import {translate} from '../../components/Language/Translate'


class PinboardFormData {
    
    static async pinboardNew(data){
        // console.log('inside pinboardNew', data)
        const resp = await AnnouncementFormData.announcementNew(data);
        resp.vars.fields.visibleGuests=true;
        resp.consts.request.url="pinboard";
        resp.consts.formType='pinboardNew';
        resp.consts.formLabels = {title: translate("Add Pinboard Item"), btnLabel:translate("Add")};
        // console.log(resp)
        return resp;
    }
    static async pinboardEdit(data){
        // console.log('inside pinboardEdit', data)
        const resp = await AnnouncementFormData.announcementEdit(data,"pinboard");
        resp.consts.request={url:`pinboard/${data.params.id}`, method:"patch"};
        resp.consts.formType='pinboardEdit';
        resp.consts.formLabels = {title: translate("Edit Item"), btnLabel:translate("Update")};
        // console.log(resp)
        return resp;
    };
};



export default PinboardFormData;