
import React from "react";
import {useSelector} from 'react-redux';
import { Row, Col } from "react-bootstrap";
import { getDateObject } from "../../helpers/Date";
import { Translate, translate } from "../../components/Language/Translate";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { inside, isInDevelopment } from "../../config";


const Header = ({props}) => {
    inside()
        const {day,activated, handleActivate,deleteDay,addEntry, variables,  changed,
            double,validDate, atSea,handleSubmit, backFunc}=props;
        const history = useHistory();
    // console.log(props)

    

        const language = useSelector(st => st.currentUser).language || Translate.language;
        const {date} = day
    return(

        <Row>
        <Col sm={4}>
            {getDateObject(date, language, "UTC").longWeekday} 
            {!validDate && <span className="text-danger">{` - ${translate("Out of Range")}`}</span>}
        </Col>
        <Col sm={8}>
            {!variables.destinationId && activated && <button className="btn btn-outline-dark m-1" onClick={e => atSea(e)}>{translate("At Sea")}</button>}
            {!activated && <button className="btn btn-outline-dark ml-1" onClick = {e => handleActivate(e, true)}>{translate("Edit")}</button>}
            {changed && <button type="submit" className = "btn btn-outline-dark m-1" onClick={handleSubmit}>{translate("Save")}</button>}
            {activated && <button id="cancel" className = "btn btn-outline-secondary m-1" onClick={e => handleActivate(e, false)}>{translate("Cancel")}</button>}
            {(double || !validDate ) && !activated &&
                    <button className="btn btn-outline-danger m-1" onClick = {e => deleteDay(e)}>
                        {translate("Delete Entry")}
                    </button>}
            {validDate && !activated && <button className="btn btn-outline-dark m-1" onClick={addEntry}>{translate("Add Entry for this day")}</button>}
            <button className="btn btn-outline-dark ml-1" onClick={() => history.push('/destinations')}>{translate("Add Destination")}</button>
            {backFunc && <button className="btn btn-outline-secondary ml-1" onClick={backFunc}>{translate("Back")}</button>}
            

        </Col>
        </Row>
    )
};
export default Header;