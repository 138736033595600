import React, {useState, useEffect} from "react";
import AnnouncementFormSchema from "./AnnoncementFormSchema";
import { useSelector, useDispatch } from "react-redux";
import {translate} from '../../components/Language/Translate';
import Spinner from '../../components/Spinner';
import FormFields from "../FormFields";
import { addAnnouncementOnApi, addPinboardOnApi } from "../../actions/currentCruise";
import FormValidation from '../Validation/FormValidation';
const AnnouncementNewForm = ({table, setShowFormNew}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState();
    const [fieldSettings, setFieldSettings] = useState();
    const [fieldErrors, setFieldErrors] = useState({});
    // const [serverError, setServerError] = useState('');
    const currentCruise = useSelector( st => st.currentCruise);
    const currentUser = useSelector(st => st.currentUser);
    const dispatch = useDispatch();
    const type = table==="announcements" ? "Announcement" : "Pinboard Item";
    

    useEffect(() => {
      const sets = new AnnouncementFormSchema();
      // console.log(sets);
      const sendData = ["by", "subject", "description", "visibleCrew", "visibleStaff", "visibleGuests"];
      const fieldSets = {};
      const vars = {};
      sendData.forEach(d => {
        vars[d] = sets.fields[d].value;
        fieldSets[d] = sets.fields[d];
      });
      vars.by = currentUser && currentUser.nickname;
      vars.visibleGuests = table === "pinboard" ? true : false;
      (() => setVariables({...vars}))();
      (() => setFieldSettings({...fieldSets}))();
      (() => setIsLoading(false))();

    },[isLoading, setIsLoading, table, currentUser])

    if(isLoading) return <Spinner/>

    // console.log({variables, fieldSettings});

    const handleSubmit = async() => {
      // console.log({variables});
      const formValidation = new FormValidation({fieldSettings, variables});
      if (!formValidation.valid)return;
      table === "announcements" && dispatch(addAnnouncementOnApi({...variables, cruiseId: currentCruise.id}));
      table === "pinboard" && dispatch(addPinboardOnApi({...variables, cruiseId: currentCruise.id}));
      (() => setShowFormNew(false))();
    };

    const props = {fieldSettings, variables, setVariables, fieldErrors, setFieldErrors};
    // const props = {fieldSettings, variables, setVariables, fieldErrors, setFieldErrors, serverError};

    return(
        <div className = "my-5 p-3" style = {{border:"1px solid grey", borderRadius:"5px"}}>
            <h4>{translate(`Add ${type}`)}</h4>
            {/* <DestinationField props={props}/> */}
            <FormFields props={props}/>
            <div className="row col-12" >
              <div className="col-6 p-1">
                <button className="btn btn-outline-info col-12" onClick={handleSubmit}>{translate("Submit")}</button>
              </div>
              <div className="col-6 p-1">
                <button className="btn btn-outline-secondary col-12" onClick={()=>setShowFormNew(false)}>{translate("Cancel")}</button>
           
              </div>
            </div>
        </div>
    )
};
export default AnnouncementNewForm;