import React from "react";
import {useSelector} from "react-redux";
import FirstStepsLoggedIn from "./FirstStepsLoggedIn";
// import FirstStepsNotLoggedIn2 from "./FirstStepsNotLoggedIn2";


const FirstSteps2 = () => {
    
    // console.log('FirstSteps2')
    // const [showOptions, setShowOptions] = useState(false);
    const currentUser = useSelector(st => st.currentUser);
    const loggedIn = currentUser && currentUser.username;

    return(
        <>
        {/* <div style={{backgroundColor:"rgba(255,255,255,.4", color:"#201712",borderRadius:"5px"}} className="p-5"> */}

            {loggedIn && <FirstStepsLoggedIn/>}
            {/* {!loggedIn && <FirstStepsNotLoggedIn2/>} */}
        {/* </div> */}
        </>
        
    )
};
export default FirstSteps2;

