import { useState } from "react";

// Pass URL
const useFetch = (url) => {
  // console.log('inside useFetch 5', {url});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  



  const handleGoogle = async (response) => {
    // console.log('inside useFetch 10', {response}, response.credential);
    
    setLoading(true);
    const fetchObj = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    body: JSON.stringify({ credential: response.credential }),
    }
    // console.log('20', {url, fetchObj})
    fetch(url, fetchObj)
      .then((res) => {
        // console.log('inside useFetch 21', {res}, res.body)
        setLoading(false);
        // console.log({res});
        // console.log(res.body);
        return res.json();
      })
      .then((data) => {
        // console.log('inside useFetch 28', {data}, data.user)
        if (data?.user) {
          // console.log('HURRA');
          localStorage.setItem("user", JSON.stringify(data?.user));
          window.location.reload();
        }
        // throw new Error(data?.message || data);
      })
      .catch((error) => {
        // console.log("ERROR in useFetch, 37", error)
        setError(error?.message);
      });
  };
  return { loading, error, handleGoogle };
};

export default useFetch;
