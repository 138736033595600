import {BASE_URL} from '../config';
import axios from "axios";
// import Globals from '../components/globals';

// const development = Globals.nodeEnv === "development";

async function simpleRequest ({url,method,data}){
    // console.log({url, method, data, development})
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user ? user.token:null;
    const headers = {'authorization': `Bearer ${token}`}
    const axiosRequest = {
        url:`${BASE_URL}/${url}`,
            method,
            data,
            headers
     }
     // console.log(`DIESE SIMPLE REQUEST LÖSCHEN`);
    // console.log({axiosRequest});
    try{
        const response = await axios(axiosRequest);
        // console.log(response.status)
        // console.log(response.statusText)
        return response
    }catch(err){
        // console.log('inside Error', {err})
        // console.log('inside Error', err.response.data)
        // console.log('inside Error', err.response.status)
        // console.log('inside Error', err.response.statusText);
        // const errRes = err.response;
        const errStatus = err.response && err.response.status;
        const errStatusText = err.response && err.response.statusText;
        const errData = err.response && err.response.data;

        const errorMessage = `Error: Status ${errStatus}, ${errStatusText} (${errData})`;
        return {...err, errorMessage, status:errStatus};
    };
};

async function getErrors(type){
    // console.log('inside getErrors');
    const url =`${BASE_URL}/errors/${type}`
    // console.log({url})
    const response = await axios.get(url);
    // console.log({response});
    return response;
}

export {simpleRequest, getErrors};