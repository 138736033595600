export const FETCH_COMPANIES = "FETCH_COMPANIES";
export const REMOVE_COMPANY ="REMOVE_COMPANY";
export const ADD_COMPANY ="ADD_COMPANY";
export const UPDATE_COMPANY="UPDATE_COMPANY";
export const FETCH_COMPANY ="FETCH_COMPANY";

export const ADD_COMPANY_CREW = "ADD_COMPANY_CREW"
export const UPDATE_COMPANY_CREW = "UPDATE_COMPANY_CREW"
export const DELETE_COMPANY_CREW = "DELETE_COMPANY_CREW"

export const ADD_COMPANY_VESSEL = "ADD_COMPANY_VESSEL"

export const FETCH_CRUISES = "FETCH_CRUISES";
export const REMOVE_CRUISE ="REMOVE_CRUISE";
export const ADD_CRUISE ="ADD_CRUISE";
export const UPDATE_CRUISE="UPDATE_CRUISE";
export const FETCH_CRUISE ="FETCH_CRUISE";

export const ADD_CRUISE_CREW="ADD_CRUISE_CREW";
export const UPDATE_CRUISE_CREW="UPDATE_CRUISE_CREW";
export const DELETE_CRUISE_CREW="DELETE_CRUISE_CREW";

export const UPDATE_CRUISE_IN_CRUISELIST="UPDATE_CRUISE_IN_CRUISELIST";
export const DELETE_CRUISE_FROM_CRUISELIST = "DELETE_CRUISE_FROM_CRUISELIST";

export const ADD_ANNOUNCEMENT="ADD_ANNOUNCEMENT";
export const UPDATE_ANNOUNCEMENT="UPDATE_ANNOUNCEMENT";
export const DELETE_ANNOUNCEMENT="DELETE_ANNOUNCEMENT";
export const ADD_PINBOARD="ADD_PINBOARD";
export const UPDATE_PINBOARD="UPDATE_PINBOARD";
export const DELETE_PINBOARD="DELETE_PINBOARD";

export const ADD_DIARY="ADD_DIARY";
export const UPDATE_DIARY = "UPDATE_DIARY";
export const DELETE_DIARY = "DELETE_DIARY";

export const ADD_DAY = "ADD_DAY";
export const UPDATE_DAY = "UPDATE_DAY";
export const DELETE_DAY="DELETE_DAY";

export const ADD_GROUP="ADD_GROUP";
export const FETCH_GROUPS="FETCH_GROUPS";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const DELETE_GROUP = "DELETE_GROUP";

export const UPDATE_GROUP_MEMBER = "UPDATE_GROUP_MEMBER";
export const FETCH_GROUP_MEMBERS = "FETCH_GROUP_MEMBERS";

export const ADD_GROUP_CHAT = "ADD_GROUP_CHAT"

export const FETCH_USERS = "FETCH_USERS";
export const REMOVE_USER ="REMOVE_USER";
export const ADD_USER ="ADD_USER";
export const UPDATE_USER="UPDATE_USER";
export const FETCH_USER ="FETCH_USER";

export const FETCH_CURRENT_USER = "FETCH_CURRENT_USER";
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";
export const ADD_BLOCKED = "ADD_BLOCKED";
export const REMOVE_BLOCKED = "REMOVE_BLOCKED";
export const MARK_MESSAGE_AS_READ = "MARK_MESSAGE_AS_READ";
export const MARK_MESSAGE_AS_DELETED_BY_FROM = "MARK_MESSAGE_AS_DELETED_BY_FROM";
export const MARK_MESSAGE_AS_DELETED_BY_TO = "MARK_MESSAGE_AS_DELETED_BY_TO";
export const APPROVE_FRIEND_REQUEST = "APPROVE_FRIEND_REQUEST";
export const REJECT_FRIEND_REQUEST = "REJECT_FRIEND_REQUEST";
export const CANCEL_FRIEND_REQUEST = "CANCEL_FRIEND_REQUEST";
export const FETCH_USER_DAYS = "FETCH_USER_DAYS";
export const ADD_FAVOURITE = "ADD_FAVOURITE";
export const DELETE_FAVOURITE = "DELETE_FAVOURITE";


export const FETCH_DESTINATIONS = "FETCH_DESTINATIONS";
export const FETCH_DESTINATION = "FETCH_DESTINATION";
export const ADD_DESTINATION = "ADD_DESTINATION";
export const UPDATE_DESTINATION = "UPDATE_DESTINATION";
export const DELETE_DESTINATION = "DELETE_DESTINATION";

export const ADD_DESTINATION_PHOTO = "DELETE_DESTINATION_PHOTO";
export const UPDATE_DESTINATION_PHOTO = "DELETE_DESTINATION_PHOTO";
export const DELETE_DESTINATION_PHOTO = "DELETE_DESTINATION_PHOTO";

export const ADD_MEDICAL_INSTITUTE = "ADD_MEDICAL_INSTITUTE";
export const UPDATE_MEDICAL_INSTITUTE = "UPDATE_MEDICAL_INSTITUTE";

export const ADD_MEDICAL_COMMENT = "ADD_MEDICAL_COMMENT";
export const UPDATE_MEDICAL_COMMENT = "UPDATE_MEDICAL_COMMENT";
export const DELETE_MEDICAL_COMMENT = "DELETE_MEDICAL_COMMENT";

export const ADD_ALBUM = "ADD_ALBUM";
export const DELETE_ALBUM = "DELETE_ALBUM";

export const ADD_PROPOSAL = "ADD_PROPOSAL";
export const EDIT_PROPOSAL = "EDIT_PROPOSAL";
export const ADD_FOLLOW = "ADD_FOLLOW";
export const ADD_COMMENT = "ADD_COMMENT";
export const DELETE_FOLLOW = "DELETE_FOLLOW";

export const FETCH_OTHER_USER = "FETCH_OTHER_USER";
export const UPDATE_OTHER_USER = "UPDATE_OTHER_USER";


// ___________________________________________________________________________________authors

export const FETCH_AUTHORS = "FETCH_AUTHORS";

export const FETCH_AUTHOR = "FETCH_AUTHOR";
export const UPDATE_AUTHOR = "UPDATE_AUTHOR";

// __________________________________________________________________________________articles

export const FETCH_ARTICLES = "FETCH_ARTICLES";
export const FETCH_ARTICLE = "FETCH_ARTICLE";
export const ADD_ARTICLE = "ADD_ARTICLE";
export const UPDATE_ARTICLE = "UPDATE_ARTICLE";
export const DELETE_ARTICLE = "DELETE_ARTICLE";



export const FETCH_ARTICLE_LINKS = "FETCH_ARTICLE_LINKS";
export const ADD_ARTICLE_LINK = "ADD_ARTICLE_LINK";
export const UPDATE_ARTICLE_LINK = "UPDATE_ARTICLE_LINK";
export const DELETE_ARTICLE_LINK = "DELETE_ARTICLE_LINK";





// ___________________________________________________________________________________vessels
export const FETCH_VESSELS = "FETCH_VESSELS";
export const UPDATE_VESSEL = "UPDATE_VESSEL";
export const DELETE_VESSEL = "DELETE_VESSEL";

// ___________________________________________________________________________________currentVessel
export const REMOVE_VESSEL ="REMOVE_VESSEL";
export const ADD_VESSEL ="ADD_VESSEL";
export const UPDATE_CURRENT_VESSEL="UPDATE_CURRENT_VESSEL";
export const FETCH_VESSEL ="FETCH_VESSEL";

// ___________________________________________________________________________________cruiseProposals

export const FETCH_CRUISE_PROPOSALS = "FETCH_CRUISE_PROPOSALS";
export const ADD_CRUISE_PROPOSAL = "ADD_CRUISE_PROPOSAL"
export const DELETE_CRUISE_PROPOSAL = "DELETE_CRUISE_PROPOSAL";

// ___________________________________________________________________________________globals
export const ADD_SERVER_ERROR = "ADD_SERVER_ERROR";
export const DELETE_SERVER_ERROR = "DELETE_SERVER_ERROR";
export const SET_GLOBAL_VAR = "SET_GLOBAL_VAR";

export const ADD_REQUEST = "ADD_REQUEST";
export const RENEW_REQUESTS = "RENEW_REQUESTS";
export const DELETE_REQUEST = "DELETE_REQUEST"
 
export const FETCH_PHOTO_LIKES = "FETCH_PHOTO_LIKES"
export const ADD_PHOTO_LIKE = "ADD_PHOTO_LIKE"
export const REMOVE_PHOTO_LIKE = "REMOVE_PHOTO_LIKE"