import {translate} from '../../components/Language/Translate'

class VesselFormSchema{

    constructor(companies){
        this.companies = companies;
        this.formType="vesselNew";
        
        this.request = {
            url:"vessels",
            method:"post",
        };
        this.formLabels = {title: translate("Add Vessel"), btnLabel:translate("Add Vessel")};
        this.fields={
            companyHandle: {
                label:translate("Company"),
                value:"unknown",
                disabled: !(!this.companies),
                type:"text",
                fieldType:"select",
                settings:{},
                options: this.companies?.map(c => {return {key: c.handle, value: translate(c.name)}}),
                toolTip:translate("If you know the company and it is in the list, select it. Otherwise leave it at this value.")
            },
            type:{
                label:translate("Type"),
                value:"Sea Ship",
                type: "text",
                fieldType:"select",
                options:[{key:"Sea Ship", value:translate("Sea Ship")}, {key:"River Boat", value:translate("River Boat")}]
            },

            prefix: {
                label:"Prefix:",
                value:"MS",
                validation:{
                    maxLength:3,
                },
                required:true,
                type:"text",
                fieldType:"text",
            },
            name: {
                label:"Name:",
                value:"",
                validation:{
                    minLength:5,
                    maxLength:40
                },
                required:true,
                type:"text",
                fieldType:"text"
        },
            note: {
                label:translate("Note"),
                value:"",
                validation:{},
                required:false,
                type:"text",
                fieldType:"text",
                toolTip:"Optional, e.g. 1990 to 2003"
        },
            short:{
                label:translate("Shortcut"),
                value:'',
                validation:{maxLength:8},
                type:"text",
                fieldType:"text"
        },
            actual:{
                label:translate("Actual in fleet"),
                value:true,
                type:"checkBox",
                fieldType:"checkBox"
        },
            photoUrl:{
                label:translate("Photo URL"),
                value:'',
                validation:{},
                type:"text",
                fieldType:"text"
        },
            description:{
                label:translate("Description"),
                value:'',
                validation:{},
                type:"text",
                fieldType:"textarea"
        },
            isConfirmed:{
                label: translate ('Confirmed as a new vessel'),
                value:false,
                type:"checkBox",
                fieldType:"checkBox"
            }
        }
    }
};
export default VesselFormSchema;
