import React, {useState} from "react"
import { translate } from "./Language/Translate";
import { simpleRequest } from "../newForms2/simpleRequest";
import ServerError from "./ServerError";
import ServerMessageArea from "./ServerMessageArea";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const DeleteAccountTarget = () => {
    const {username, email, code} = useParams();
    // console.log({username, email, code});
    const [serverError, setServerError] = useState(null);
    const [info, setInfo] = useState(null);
    const data = {username, code, cancel:true};

    const doRequest = async() => {
        const response = await simpleRequest({url:"users/deleteAccount", method:"post", data});
        const errorMessage = response.response?.data;
        if(errorMessage){
            (() => setServerError(errorMessage))();
        }else{
            (() => setInfo("Account is successfully deleted."))();
        }
        setTimeout(() =>  window.location.href = '/', 5000);
    };

    const handleClick = async(e) => {
        e.preventDefault();
        
        data.cancel=false;
        // console.log('handleClick', data);
        // console.log({data})
        localStorage.clear();
        await doRequest();
    };

    const handleCancel = async(e) => {
        e.preventDefault();
        // console.log('handleCancel', data)
        await doRequest();
    };
    
        return(

        <div style={{backgroundColor:"rgba(255,255,255,.7)"}} className="p-4">
            <h4>{translate("Delete Account")}</h4>
            <ServerError serverError={serverError} setServerError={setServerError}  />
            <ServerMessageArea info={info} setInfo={setInfo} />
            <p>{email}</p>
            <button className="btn btn-outline-danger" onClick={e => handleClick(e)}>{translate("Delete")}</button>
            <button className="btn btn-outline-secondary ml-2" onClick={e => handleCancel(e)}>{translate("Cancel")}</button>

        </div>
        );
    };
export default DeleteAccountTarget;