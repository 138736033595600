import React, {useEffect, useState} from "react";
import { previewFileSimple } from "./functions";

const PreviewItem = ({file}) => {
    const [preview, setPreview] = useState(``);

    useEffect(() => {
        const fetch = async() => {
            const pr = await previewFileSimple(file, setPreview);
            (() => setPreview(pr))();
        }
        fetch();
    }, [file.name, file, setPreview]);

    if (!preview)return null;

    return(
                <img
                    className="m-1"
                    src={preview}
                    alt=""
                    style={{ maxWidth: '20%', minHeight:"10px", maxHeight:"100px" }}
                />
    )
}
export default PreviewItem;