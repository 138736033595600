import React from "react";
import {Form} from "react-bootstrap";
import FieldValidation from "./validation/FieldValidation";


const SelectField = ({props}) => {
    const {
        name,
        variables,
        setVariables,
        fieldSettings, 
        fieldErrors, 
        setFieldErrors,
        } = props;
        // console.log({props});

        const {label, required, autoFocus, toolTip, options} = fieldSettings[name];
        const value = variables[name] || "";

        const handleChange = e => setVariables(variables => {
            console.log(e.target, '________________________________________', e.target.value)
            return {...variables, [e.target.name]: e.target.value}
        });
        const validateField = e => {
            const validObj = new FieldValidation(name, value, fieldSettings, variables).isValid();
            if(!validObj.valid)setFieldErrors({...fieldErrors, [name]:validObj.errMsg});
        };

    return(
        <Form.Group size="lg" controlId={name}>
            <Form.Label className ="pt-1 px-1" >{label}{required? ' * ':' '}</Form.Label>
            {/* <Form.Label>{label}{required? ' *':null}</Form.Label> */}
            { fieldErrors[name]?<small > - {fieldErrors[name]}</small>:null}
            {/* { fieldErrors[name]?<small className="text-danger" > - {fieldErrors[name]}</small>:null} */}
            <Form.Control as="select"

                style = {fieldErrors[name] ? {border:"2px solid red", backgroundColor:"rgb(255,200,200)"}:{}}
                name={name} 
                required={required}
                type="select" 
                key={name} 
                autoFocus = {autoFocus}
                value={value}
                onChange={(e) => handleChange(e)} 
                onBlur={(e) => validateField(e)}
            >
            {options?.map(opt => {
                // console.log({opt});
                        return(<option value= {opt.key} id={opt.key} key={`${opt.key}_${opt.value}`}>{opt.value}</option>)
                    })}
            </Form.Control>
            {toolTip?<p><small className="text-black p-1" style={{backgroundColor:"rgba(255,255,255,.5"}}>{toolTip}</small></p>:null}
        </Form.Group>
    )
}
export default SelectField;
