import React from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import { translate } from "./Language/Translate";



const CruiseBackToCruiseButton = () => {
    const currentCruise = useSelector(st => st.currentCruise);
    if (!currentCruise || !currentCruise.id) return null;

    const btnText = `${translate("Back to Cruise")}: ${currentCruise.prefix} ${currentCruise.name} - ${currentCruise.title}`

    return(
        <div className="" style={{backgroundColor:"rgba(255,255,255,.4"}}>
        {/* <div className="p-1 mb-3" style={{backgroundColor:"rgba(255,255,255,.5"}}> */}
            {currentCruise && currentCruise.id && 
                <Link to = {`/cruises/${currentCruise.id}`} className="btn btn-block btn-outline-dark">{btnText}</Link>}
        </div>
    )
};
export default CruiseBackToCruiseButton;