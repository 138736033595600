import React, {useEffect, useState} from "react";
// import {Link} from 'react-router-dom';
import {getDateObject} from '../helpers/Date'
import { useSelector, useDispatch } from "react-redux";
import { updateAnnouncementOnApi, updatePinboardOnApi } from "../actions/currentCruise";
import { Image } from "react-bootstrap";
import {Translate, translate} from "./Language/Translate"
import UploadAreaSingle from "../newForms2/upload/UploadAreaSingle";
import ClaimsNewForm from "../newForms2/claims/ClaimsNewForm";
import AnnouncementEditForm from "../newForms2/announcements/AnnouncementEditForm";

const CruiseAreaCardAnnouncementsItem = ({a, idx, handleDelete, table}) => {
    // console.log({a, idx, handleDelete, table});
    // const [helpArea, setHelpArea] = useState(false);
    const [uploadAreaPhoto, setUploadAreaPhoto] = useState(false);
    const [uploadAreaDocument, setUploadAreaDocument] = useState(false);
    const [uploadData, setUploadData] = useState({changed:false, data:{}});
    const [claimsArea, setClaimsArea] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const currentUser = useSelector(st => st.currentUser);
    const currentCruise = useSelector(st => st.currentCruise);
    const dispatch = useDispatch();
    const title = table==="announcements" ? "Announcement" : "Pinboard Item";
    const language = currentUser?.language || Translate.language;
 
    useEffect(() => {
        // dispatch when the uploadData chance;
        const updateUrl = async () => {
            // console.log("Jetzt auf API und im State ändern:", {id:a.id, currentCruise, uploadData, table});
            if(table==="announcements") 
                return dispatch(updateAnnouncementOnApi({id: a.id, data:uploadData.data}));
            if(table==="pinboard") 
                return dispatch(updatePinboardOnApi({
                    id: a.id, data:uploadData.data}));
            
            (()=> setUploadData({changed:false}))();
        };
        if(uploadData.changed)updateUrl();
        (() => setUploadAreaDocument(false))();
        (() => setUploadAreaPhoto(false))();
    },[uploadData, setUploadData, dispatch, a, table, currentCruise]);

    const developer = currentUser.userStatus==="developer";
    const mayEditItem = currentUser.username === a.createdBy;
    const mayDeleteItem = currentUser.username === a.createdBy 
        || currentUser.userStatus.toLowerCase().includes("admin")
        || currentUser.userStatus === "developer";

    const txtBtnPhoto = a.photoUrl ? translate("Replace/Remove Photo") : translate("Add Photo");
    const txtBtnDocument = a.url? translate("Replace/Remove Document"): translate("Add Document");
    const activeBox = (box) => {
        if(box === "photo"){
            (() => setUploadAreaPhoto(!uploadAreaPhoto))();
            (() => setUploadAreaDocument(false))();
        }
        if(box === "document"){
            (() => setUploadAreaPhoto(false))();
            (() => setUploadAreaDocument(!uploadAreaDocument))();
            
        }
    }
    const subFolder = `/cruises/${currentCruise.id}/${table}/${a.id}`;
    const photoMethods = table === "pinboard" ? ["link"] : ["file", "url", "link"];
    
    return( 
        <div className="card mt-3" key={a.id} id={a.id} data-idx={idx}>
            <div className="card-header">
                {/* <HelpArea table = {table}/> */}
                {/* {helpArea && <HelpArea table = {table}/>} */}
            <p>{uploadAreaPhoto} {uploadAreaDocument}</p>
                <p>{translate("Posted on")}  {getDateObject(a.updatedAt, language).dateTime} {translate('by')} {a.by}</p>
                <p>{translate("Subject")}: {a.subject}</p>
            </div>
            <div className="card-body text-center">
                {a.photoUrl && <div><Image src={a.photoUrl} alt="" 
                    className="img-fluid img-thumbnail" style={{maxWidth:"100%", minWidth:"50%", maxHeight:"70vh", height:"auto"}} /></div>}
                {a.url && <p className="mt-3"><a href={a.url} target="_blank" rel="noreferrer" 
                    className="btn btn-outline-dark">{translate("Open Document")}</a></p>}
                <p className="mt-3">{a.description}</p>
                {developer && <p>Photo Url: {a.photoUrl}</p>}
                {developer && <p>Document Url: {a.url}</p>}
                {developer && <p>ID: {a.id}</p>}
                {developer && <p>UpdatedAt: {a.updatedAt}</p>}
                
            </div>
            <div className="p-3">
                {uploadAreaPhoto && <UploadAreaSingle title="photoUrl" subFolder = {`${subFolder}/photo`}
                    setUploadArea={setUploadAreaPhoto} methods={photoMethods} 
                    setUploadData={setUploadData}/>}
                {uploadAreaDocument && <UploadAreaSingle title="url" subFolder = {`${subFolder}/document`}
                    setUploadArea={setUploadAreaDocument} methods={["link"]} 
                    setUploadData={setUploadData}/>}
            </div>
            
            
            <div className="card-footer text-center">
                
                {mayEditItem && <button onClick={()=>activeBox("photo")} data-id={a.id} 
                    className= "btn btn-outline-dark m-1">{txtBtnPhoto}</button>}
                {mayEditItem && <button onClick={()=>activeBox("document")} data-id={a.id} 
                    className= "btn btn-outline-dark m-1">{txtBtnDocument}</button>}
                {/* {mayEditItem && <Link to={`/${table}/edit/${a.id}`} 
                    className= "btn btn-outline-dark m-1">Edit</Link>} */}
                {mayEditItem && <button onClick = {() => setShowEditForm(!showEditForm)} 
                    className= "btn btn-outline-dark m-1">{translate(`Edit ${title}`)}</button>}
                {mayDeleteItem && <button onClick={handleDelete} data-id={a.id} 
                    className= "btn btn-outline-danger m-1">{translate(`Delete ${title}`)}</button>}
                {!claimsArea && !mayEditItem && <button className="btn btn-outline-danger m-1" 
                    onClick={() => setClaimsArea(!claimsArea)}>{translate("Claim")}</button>}
                {showEditForm && <AnnouncementEditForm id={a.id} table={table} setShowEditForm={setShowEditForm}/>}
                {claimsArea && <ClaimsNewForm tablename = {table} ident={a.id} 
                        setClaimsArea={setClaimsArea} 
                        sourceData= {{textData:{subject:a.subject, description:a.description}, 
                            urls:{url:a.url, photoUrl:a.photoUrl}}}/>
                }
            </div>
                
        </div>
    );
};
export default CruiseAreaCardAnnouncementsItem;