import React, {useState} from "react";
import CrewListItem from '../CrewListItem';
import {useSelector} from 'react-redux';
import {ensureAuthorized} from '../../helpers/auth';
import {translate} from '../Language/Translate';
import CompanyCrewNew from "../../newForms2/companyCrew/CompanyCrewNew";
import {sortArrayByObjectField} from '../../helpers/sort';

const CompanyCrewList = () =>{
    const [showFormNew, setShowFormNew] = useState(false);
    const currentCompany = useSelector(st => st.currentCompany);
    const currentUser = useSelector(st => st.currentUser);
    const crew = currentCompany.companyCrew;
    const mayAddVessel = currentUser && currentUser.userStatus?ensureAuthorized(currentUser.userStatus, '#adVe', currentCompany.handle):false;
    const crewList =  crew && sortArrayByObjectField(crew, "lastName").map(cc=>
                                     <div key={cc.id}><CrewListItem cc={cc}/></div>);
    return (
        <>
            {showFormNew && <CompanyCrewNew setShowNewForm={setShowFormNew}/>}
            {(!crewList || crewList.length === 0) && <p>{translate("The company has not made any entries here yet.")}</p>}
            <p>{translate("Here the company can present its captains, officers, managers, artists, etc. These setcards are then available for the cruises.")}</p>
            {!showFormNew && mayAddVessel?
                    <button 
                	    to={`/companycrew/new/${currentCompany.handle}`} 
                        className="btn btn-outline-dark my-5 col-md-12"
                        onClick={() => setShowFormNew(true)}>{translate("Add Crew")}
                    </button>:null}
            {crewList && crewList.length > 0?<h6 className="mt-3">Crew:</h6>:null}
            {crewList}
        </>
    )
};
export default CompanyCrewList;

