import React from "react";
import { useParams } from "react-router-dom";



const UnSubscribe = () => {
    const {email, code} = useParams();

    return(
        <>
            <h4>Unsubscribe Newsletter</h4>
            <p>{email}, {code}</p>
        </>
    );
};
export default UnSubscribe;