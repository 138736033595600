import React from "react";
import {Link} from "react-router-dom";
import { sortArrayByObjectField } from "../helpers/sort";
import {translate} from "./Language/Translate";



const CompanyVesselList = ({vessels, title}) =>{
    // console.log(vessels)
    const vesselList = vessels && sortArrayByObjectField(vessels, "name").map(v=>{
        return(
            <p key={v.id}>
                <Link to = {`/vessels/${v.id}`} key={v.id} className="btn btn-outline-dark col-md-12">
                    {v.prefix} {v.name} 
                    <small><i>{v.note && ` - (${v.note})`}, {translate(v.type)}</i></small>
                </Link>
            </p>
        )
    });
    return (
        <>
            {vessels && vessels.length > 0 ? <h6 className="mt-5 mb-2">{translate(title)} ({vessels.length}):</h6>:null}
            {vesselList}
        </>
    )
        
};

export default CompanyVesselList;