import {translate}  from "../../Language/Translate"

export const proposals = [
    [translate("Restaurant"), translate("Restaurants")], 
    [translate("Bar"), translate("Bars")], 
    [translate("Shop"), translate("Shops")], 
    [translate("Sight"), translate("Sights")],
    [translate("Tour"), translate("Tours")],
    [translate("Beach"), translate("Beaches")],
    [translate("Golf Course"), translate("Golf Courses")],
    [translate("Museum"), translate("Museums")],
    [translate("Other Proposal"), translate("Other Proposals")],
    [translate("Crew only"), translate("Crew only")],
    
];




