import React, {useState, useCallback, useEffect} from "react";
import { translate } from "../../components/Language/Translate";
import UserSchema from "./UserSchema";
import FieldValidation from "../Validation/FieldValidation";
import FormValidation from "../Validation/FormValidation";
import FormatText from "../../helpers/FormatText";
import { LargeButton } from "../../components/styledComponents/colors";
import Spinning from "../../components/Spinning";
import UserLoginFields from "./UserLoginFields";
import Spinner from "../../components/Spinner";
import { simpleRequest } from "../simpleRequest";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const UserDelete = () => {
    const history = useHistory();
    const [view, setView] = useState('input'); // input, waiting, success, serverErrorLoginFailed, serverErrorSentEmail
    const [isLoading, setIsLoading] = useState(true);
    const [fieldSettings, setFieldSettings] = useState({});
    const [variables, setVariables] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    const [serverError, setServerError] = useState('');
    // const [success, setSuccess] = useState('');
    
    const validateField = (e) => {
        const val = new FieldValidation(e.target.name, e.target.value, fieldSettings, variables);
        const errMsg = val.isValid().errMsg;
        (() => setFieldErrors({...fieldErrors,[e.target.name]: errMsg}))();
    };
    
    const fetch = useCallback(async () => {
        const schema = new UserSchema();
        const vars = {};
        const fields = {};
        const fieldArr = ["usernameOrEmail", "password", "confirmationCode"];
        fieldArr.forEach(i => {
            fields[i] = schema.fields[i];
            vars[i] = "";
        });
        (() => setFieldSettings(fields))();
        (() => setVariables(vars))();
        (() => setIsLoading(false))();
    }, []);
    useEffect(() => {if(isLoading)fetch()}, [isLoading, fetch]);

    const handleChange = e => setVariables({...variables, [e.target.name]:e.target.value});
      
    const handleSubmit = async(e) => {
        e.preventDefault();
        const formValidation = new FormValidation({fieldSettings, variables});
        if(!formValidation.valid)return;
        (() => setView('waiting'))();
        const response = await simpleRequest({url:"users/delete", method:"post", data:{...variables}});
        // console.log({response});
        if(response.data?.response?.err){
            const {err} = response.data.response
            const error = typeof err === "string" ? err : JSON.stringify(err);
            setServerError(error || 'Error by sending email. Maybe no connection. Please try again later.');
            setView('')

        }else{
            setView("success");
        };
        setTimeout(() => {
            setServerError(null);
            setView(null);
            history.push('/');
        }, 5000);
        return;
    };
    
    const props = {fieldSettings, setFieldSettings, variables, setVariables, fieldErrors, setFieldErrors, 
                    serverError, handleChange, validateField};

    const ServerError = () => (<div className="my-3 p-2 text-danger text-center" ><FormatText str={serverError}/></div>);
    
    const SuccessArea = () => (<div><h5>{translate('Email sucessfully sent. Please look for your emails.')}</h5></div>);

    if (isLoading) return <Spinner/>
    return(
        <div  style={{backgroundColor:"rgba(255,255,255,.5)",borderRadius:"5px" }} className="p-3 text-center">
            {serverError && <ServerError/>}
            {view === "success" && <SuccessArea/>}
            {view === "input" && <h4>{translate("Delete Account")}</h4>}
            {view === "input" && <UserLoginFields props={props}/>}
            {view === "input" && <LargeButton type="submit" onClick={handleSubmit}>{translate("Delete Account")}</LargeButton>}
            {view === "waiting" && <Spinning/>}

        </div>
    )
};
export default UserDelete;