import React, {useState, useEffect} from "react";
import {Row, Col, Spinner} from 'react-bootstrap';
import { translate } from "../../components/Language/Translate";
import { StyledDiv, StyledDivWithBorder } from "../../components/styledComponents/divs";
import { simpleRequest } from "../simpleRequest";
import TourSchema from "./TourSchema";
import FormRowFields from "../FormRows/FormRowFields";
import { useSelector } from "react-redux";
import { getStringTemplate, getStringTour, getTemplates, templateKeys } from "./helpers";


const TemplateList = ({templates, setTemplates, handleClick}) => {
    // console.log({templates, setTemplates, handleClick});
    const currentCruise = useSelector(st => st.currentCruise);
    const {companyName} = currentCruise;

    const handleDelete = async(e) => {
        const id = +e.target.parentElement.id
        const deleted = (await simpleRequest({url:`tourtemplates/${id}`, method:"delete"})).data?.deleted;
        // console.log({id, deleted});
        setTemplates(templates => [...templates.filter(t => t.id !== id)]);
    };
    return(
        <StyledDiv>
            <h5>{translate("Templates")} - {companyName}</h5>
            <p>
                <span>{translate("Click the text to copy the data")}.{` `}</span>
                <span>{translate("Click the red button to delete the template")}.</span>
                {/* {translate("Click the button to delete the template")}. */}
            </p>
            {templates.length === 0 ? <p>{translate("No items")}</p> : null}
            <ul >
                {templates.map(t => 
                    <li className="text-left m-1" key={t.id} id={t.id} >
                        <button data-placement="top" title={translate("Delete Template")} className = "btn btn-outline-danger mr-2" onClick={e => handleDelete(e)}>X</button>
                        <span onClick={e => handleClick(e)}>{getStringTemplate(t)}</span>
                    </li>)}
            </ul>
        </StyledDiv>
    )
};
export default TemplateList

