import React from "react";
import { useSelector } from 'react-redux';
import DaysRoutingActions from "./DaysRoutingActions";
import { addEntry } from "../helpers";
import { Translate, translate } from "../../../components/Language/Translate";
import { StyledDivWithBorder } from "../../../components/styledComponents/divs";

import { getDateObject } from "../../../helpers/Date";
import { inside } from "../../../config";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const DaysRoutingDates = ({props}) => {
    inside();
    const {days, setDays, date, idx} = props;
    const currentCruise = useSelector(st => st.currentCruise);
    const history = useHistory();
    const localDays = days.filter(d => date === d.date);

    return(
        <StyledDivWithBorder>
            <h5 className="p-1">{getDateObject(date, Translate.language, "UTC").longWeekday}, {translate("Day")} {idx+1}

                <button className="btn btn-outline-dark float-right" 
                    onClick={() => addEntry(currentCruise.id, date, setDays)}>{translate("Add entry for this date")}</button>
                <button className="btn btn-outline-dark float-right mr-1" 
                    onClick={() => history.push('/destinations')}>{translate("Add Destination")}</button>                    
            </h5>
            {localDays.map(ld => <DaysRoutingActions key={days.id} props = {{...props, localDays, action:ld}}/>)}
        </StyledDivWithBorder>
    )
};
export default DaysRoutingDates;