import React, { useEffect, useState, useCallback} from "react";
import Spinner from '../../components/Spinner';
import UserSchema from "./UserSchema";
import { translate } from "../../components/Language/Translate";
import FormValidation from "../Validation/FormValidation";
import { simpleRequest } from "../simpleRequest";
import UserLoginFields from './UserLoginFields';
import FieldValidation from "../Validation/FieldValidation";
import { LargeButton } from "../../components/styledComponents/colors";
import { getErrorString } from "../serverErrors";
import { globals } from "../../helpers/globals";
import EmailWasBlocked from "./EmailWasBlocked";
import Spinning from "../../components/Spinning";
import FormatText from "../../helpers/FormatText";

const UserLogin = () => {
    const [view, setView] = useState('input'); // input, waiting, success, serverErrorLoginFailed, serverErrorSentEmail
    const [isLoading, setIsLoading] = useState(true);
    const [fieldSettings, setFieldSettings] = useState({});
    const [variables, setVariables] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    const [serverError, setServerError] = useState('');
    const [failCounter, setFailCounter] = useState(0);

    const validateField = (e) => {
        const val = new FieldValidation(e.target.name, e.target.value, fieldSettings, variables);
        const errMsg = val.isValid().errMsg;
        (() => setFieldErrors({...fieldErrors,[e.target.name]: errMsg}))();
    };
    
    const fetch = useCallback(async () => {
        const schema = new UserSchema();
        const vars = {};
        const fields = {};
        const fieldArr = ["usernameOrEmail", "password", "confirmationCode"];
        fieldArr.forEach(i => {
            fields[i] = schema.fields[i];
            vars[i] = "";
        });
        (() => setFieldSettings(fields))();
        (() => setVariables(vars))();
        (() => setIsLoading(false))();
    }, []);
    useEffect(() => {if(isLoading)fetch()}, [isLoading, fetch]);

    if (isLoading) return <Spinner/>

    const handleChange = e => setVariables({...variables, [e.target.name]:e.target.value});
      
    const handleSubmit = async(e) => {
        e.preventDefault();
        const formValidation = new FormValidation({fieldSettings, variables});
        // console.log({variables});
        if(variables.confirmationCode && variables.confirmationCode.split("#c#f#").length !== 3){
            (() => setFieldErrors({...fieldErrors, confirmationCode:translate("Invalid Code.")}))();
            return;
        };
        if(!formValidation.valid)return;
        (() => setView('waiting'))();
        const response = await simpleRequest({url:"users/authenticate/login", method:"post", data:variables});
        // console.log(response.data, response.response?.data)
        // console.log("status", response.status);
        const user = response.data?.user;
        if(user){
            // console.log({user});
            localStorage.setItem("user", JSON.stringify(user));
            // (() => setSuccess(true))();
            (() => setView('success'))();
            setTimeout(() => window.location.href="/", 2000);
        }else{
            const newView = response.status === 400 ? "serverErrorSentEmail" : "serverErrorLoginFailed";
                (() => setView(newView))();
                (() => setServerError(getErrorString(response)))();
                (() => setFailCounter(failCounter +1))();
                globals.serverError=getErrorString(response);
                return;
        };
    };
    
    const props = {fieldSettings, setFieldSettings, variables, setVariables, fieldErrors, setFieldErrors, 
                    serverError, handleChange, validateField};

    const ServerError = () => (<div className="my-3 p-2 text-danger text-center" ><FormatText str={serverError}/></div>);
    
    const SuccessArea = () => (<div><h5>{translate('Login was successfull.')}</h5></div>);

    const TryAgain = () => (<div><button className="btn btn-outline-dark" onClick={() => setView("input")}>{translate("Try again")}</button></div>)

    return(
        <div  style={{backgroundColor:"rgba(255,255,255,.5)",borderRadius:"5px" }} className="p-3 text-center">
            {view.includes("serverError") && <ServerError/>}
            {view === "serverErrorSentEmail" && <EmailWasBlocked usernameOrEmail={variables.usernameOrEmail}/>}
            {view === "serverErrorLoginFailed" && <TryAgain />}
            {view === "success" && <SuccessArea/>}
            {view === "input" && <h4>{translate("Login")}</h4>}
            {view === "input" && <UserLoginFields props={props}/>}
            {view === "input" && <LargeButton type="submit" onClick={handleSubmit}>{translate("Login")}</LargeButton>}
            {view === "waiting" && <Spinning/>}
        </div>
    );
};
export default UserLogin;