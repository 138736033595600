import React, {useEffect, useState} from "react";
import { translate } from "../../components/Language/Translate";
import { useDispatch, useSelector} from "react-redux";
import Spinner from '../../components/Spinner';
import FormFields from "../FormFields"
import FormValidation from '../Validation/FormValidation';
import { simpleRequest } from "../simpleRequest";
import NewCruiseSchema from './NewCruiseSchema'
import { fetchVesselsFromAPI } from "../../actions/vessels";
import { fetchCurrentUserFromApi} from '../../actions/currentUser';
import { fetchCruiseFromApi } from "../../actions/currentCruise";


const NewCruiseByUser = ({props}) => {
    const {mainVariables, setStage} = props;
    const {vesselId} = mainVariables;
    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState({vesselId});
    const [fieldSettings, setFieldSettings] = useState({});
    const [serverError, setServerError] = useState('');
    const [fieldErrors, setFieldErrors] = useState({});
    const vessels = useSelector(st => st.vessels);
    const dispatch = useDispatch();
    const vessel = vessels.find(v => v.id === vesselId);
    const sendData1 = ["startAt", "startPort", "endAt", "endPort", "title", "shorthand", "description"];
    const sendData2 = ["cruiseStatus", "department", "position", "optionalText"];
    const currentUser = useSelector(st => st.currentUser);
    
    // console.log(setServerError);

    useEffect(() => {
        
        const init = async() => {
            if(!vessel) await dispatch(fetchVesselsFromAPI());
            const sendData = sendData1.concat(sendData2);
            const sets = new NewCruiseSchema();
            const fields = {};
            const vars = {};
            sendData.forEach(d => {
                fields[d] = sets.fields[d];
                vars[d] = sets.fields[d].value;
            });
            (() => setFieldSettings({...sets.fields}))();
            (() => setVariables({...vars}))();
            (() => setIsLoading(false))();
        };
        if(isLoading)init();
    });
    if(isLoading)return <Spinner/>

    // console.log({variables, fieldSettings});

    const handleSubmit = async(e) => {
        // write cruise and userCruise to db.
        // new dispatch of currentUser 
        // evt. userCruise als dispatch für currentUser neu anlegen.
        // evt. auch cruises neu laden.
        e.preventDefault();
        // console.log({variables, fieldSettings, vesselId});
        const formValidation = new FormValidation({fieldSettings, variables});
        // console.log({formValidation})
        if (!formValidation.valid){
            (() => setFieldErrors({...formValidation.errObj.errObj}))();
            return;
        };
        if(!variables.title)variables.title=`${translate("From")} ${variables.startPort} ${translate("to")} ${variables.endPort}`
        let variablesCruise = {};
        let variablesUserCruise = {};
        sendData1.forEach(d => variablesCruise[d] = variables[d]);
        sendData2.forEach(d => variablesUserCruise[d] = variables[d]);
        // console.log({variablesCruise, variablesUserCruise});
        const response = await simpleRequest({url:'cruises', method:"post", data:{...variablesCruise, vesselId}});
        const newCruise = response.data && response.data.cruise;
        // console.log({newCruise});
        if(!(newCruise && newCruise.id))return;
        const response2 = await simpleRequest({url:`usercruises/${newCruise.id}`, method:"post", data:{...variablesUserCruise}});
        // console.log({response2});
        await dispatch(fetchCurrentUserFromApi(currentUser.username));
        await dispatch(fetchCruiseFromApi(newCruise.id));
        (() => setStage("selectCruise"))();
    }
    const fieldProps = {fieldSettings, variables, setVariables, fieldErrors, setFieldErrors, serverError};

    return(
        <div>
            <h4>{vessel.prefix} {vessel.name}: {translate("Add new Cruise")}</h4>
            <FormFields props={fieldProps}/>
            <div className="row">
                <div className="col-6">
                    <button className="btn btn-outline-dark col-12" onClick={handleSubmit}>{translate("Submit")}</button>
                </div>
                <div className="col-6">
                    <button className="btn btn-outline-secondary col-12" onClick={() => setStage("selectVessel")}>{translate("Back")}</button>
                </div>
            </div>
        </div>
    )
}
export default NewCruiseByUser;