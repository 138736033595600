export const impressumText= [

    "Cruisefriends.net",
    "",
    "Einzelpersongesellschaft mit Sitz in Stralsund",
    "",
    "Dietmar Schlabertz",
    "Bessiner Haken 10",
    "18435 Stralsund",
    "Deutschland",
    "",
    "Telefon: 01773036344",
    // "Telefax: 03831-278158",
    "E-Mail: mail (at) Dietmar-Schlabertz.de",
    "Steuer-ID: 69 542 583 170",
    
    "",
    "Verantwortlicher für journalistisch-redaktionelle gestaltete Inhalte:",
    "Dietmar Schlabertz",
    "",
    // "Technische Realisierung und Programmierung: Bernhard Leube, Stralsund",
    // "",
    // "Version 2022-11-27"

];