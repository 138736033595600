import React from 'react';
import {useSelector} from 'react-redux';
import {translate} from "./Language/Translate"
import OtherUserMessageItem from './OtherUserMessageItem';
import {sortArrayByObjectField} from '../helpers/sort';
 


const OtherUserMessages = ({otherUsername}) => {
    const currentUser = useSelector(st => st.currentUser);
    // console.log(currentUser);

    const corr = currentUser.messages.sentMessages
        .concat(currentUser.messages.receivedMessages)
        .filter(m => m.sentFrom === otherUsername || m.sentTo === otherUsername);
        const correspondence = sortArrayByObjectField(corr, "createdAt").reverse();
    
    return (
        <div className="text-center">
            <h3>{translate("Messages")}</h3>
            {correspondence.map(c => <OtherUserMessageItem message={c} key={c.id} otherUsername={otherUsername}/>)}
        </div>
    );
};
export default OtherUserMessages;
