const countries = [
    {country:'Afghanistan',short:'AFN',symbol:'AFN',name:'Afghanistani Afghani'},  
    {country:'Aland Islands',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'Albania',short:'ALL',symbol:'ALL',name:'Albanian Lek'},  
    {country:'Algeria',short:'DZD',symbol:'دج',name:'Algerian Dinar'},  
    {country:'Andorra',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'Angola',short:'AOA',symbol:'Kz',name:'Angolan Kwanza'},  
    {country:'Anguilla',short:'XCD',symbol:'EC$',name:'East Caribbean Dollar'},  
    {country:'Antigua & Barbuda',short:'XCD',symbol:'EC$',name:'East Caribbean Dollar'},  
    {country:'Argentina',short:'ARS',symbol:'AR$',name:'Argentine Peso'},  
    {country:'Armenia',short:'AMD',symbol:'դր',name:'Armenian Dram'},  
    {country:'Aruba',short:'AWG',symbol:'ƒ',name:'Aruban Florin'},  
    {country:'Australia',short:'AUD',symbol:'AU$',name:'Australian Dollar'},  
    {country:'Austria',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'Azerbaijan',short:'AZN',symbol:'ман',name:'Azerbaijani Manat'},  
    {country:'Bahamas',short:'BSD',symbol:'BS$',name:'Bahamian Dollar'},  
    {country:'Bahrain',short:'BHD',symbol:'ب.د',name:'Bahraini Dinar'},  
    {country:'Bangladesh',short:'BDT',symbol:'৳',name:'Bangladeshi Taka'},  
    {country:'Barbados',short:'BBD',symbol:'Bds$',name:'Barbados Dollar'},  
    {country:'Belarus',short:'BYR',symbol:'Br',name:'Belarusian Ruble'},  
    {country:'Belgium',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'Belize',short:'BZD',symbol:'BZ$',name:'Belize Dollar'},  
    {country:'Benin',short:'XOF',symbol:'franc',name:'West African CFA'},  
    {country:'Bermuda',short:'BMD',symbol:'BD$',name:'Bermuda Dollar'},  
    {country:'Bhutan',short:'BTN',symbol:'Nu',name:'Bhutanese Ngultrum'},  
    {country:'Bolivia',short:'BOB',symbol:'Bs',name:'Bolivian Boliviano'},  
    {country:'Bosnia & Herzegovina',short:'BAM',symbol:'KM',name:'Bosnia-Herzegovina Convertible Mark'},  
    {country:'Botswana',short:'BWP',symbol:'P',name:'Botswana Pula'},  
    {country:'Brazil',short:'BRL',symbol:'R$',name:'Brazilian Real'},  
    {country:'Brunei',short:'BND',symbol:'B$',name:'Brunei Dollar'},  
    {country:'Bulgaria',short:'BGN',symbol:'лв',name:'Bulgarian Lev'},  
    {country:'Burkina Faso',short:'XOF',symbol:'franc',name:'West African CFA'},  
    {country:'Burundi',short:'BIF',symbol:'FBu',name:'Burundian Franc'},  
    {country:'Cambodia',short:'KHR',symbol:'៛',name:'Cambodian Riel'},  
    {country:'Cameroon',short:'XAF',symbol:'franc',name:'Central African CFA'},  
    {country:'Canada',short:'CAD',symbol:'CA$',name:'Canadian Dollar'},  
    {country:'Cape Verde',short:'CVE',symbol:'CV$',name:'Cape Verde Escudo'},  
    {country:'Cayman Islands',short:'KYD',symbol:'CI$',name:'Cayman Islands Dollar'},  
    {country:'Central African Republic',short:'XAF',symbol:'franc',name:'Central African CFA'},  
    {country:'Chad',short:'XAF',symbol:'franc',name:'Central African CFA'},  
    {country:'Chile',short:'CLP',symbol:'CL$',name:'Chilean Peso'},  
    {country:'China',short:'CNY',symbol:'CN¥',name:'Chinese Yuan Renminbi'},  
    {country:'Christmas Island',short:'AUD',symbol:'AU$',name:'Australian Dollar'},  
    {country:'Cocos (Keeling) Islands',short:'AUD',symbol:'AU$',name:'Australian Dollar'},  
    {country:'Colombia',short:'COP',symbol:'Col$',name:'Colombian Peso'},  
    {country:'Comoros',short:'KMF',symbol:'KMF',name:'Comorian Franc'},  
    {country:'Congo (Dem. Rep.)',short:'CDF',symbol:'CDFr',name:'Congolese franc'},  
    {country:'Congo (Rep.)',short:'XAF',symbol:'franc',name:'Central African CFA'},  
    {country:'Cook Islands',short:'NZD',symbol:'NZ$',name:'New Zealand Dollar'},  
    {country:'Costa Rica',short:'CRC',symbol:'₡',name:'Costa Rican Colon'},  
    {country:"Cote d'Ivoire",short:'XOF',symbol:'franc',name:'West African CFA'},  
    {country:'Croatia (Hrvatska)',short:'HRK',symbol:'HRK',name:'Croatian Kuna'},  
    {country:'Cuba',short:'CUC',symbol:'CU$',name:'Cuban Convertible Peso'},  
    {country:'Cyprus',short:'CYP',symbol:'CY£',name:'Cypriot Pound'},  
    {country:'Czech Republic',short:'CZK',symbol:'Kč',name:'Czech Koruna'},  
    {country:'Denmark',short:'DKK',symbol:'ø',name:'Danish Krone'},  
    {country:'Djibouti',short:'DJF',symbol:'Fdj',name:'Djiboutian Franc'},  
    {country:'Dominica',short:'XCD',symbol:'EC$',name:'East Caribbean Dollar'},  
    {country:'Dominican Republic',short:'DOP',symbol:'RD$',name:'Dominican Peso'},  
    {country:'Ecuador',short:'USD',symbol:'US$',name:'United States Dollar'},  
    {country:'Egypt',short:'EGP',symbol:'ج.م',name:'Egyptian Pound'},  
    {country:'El Salvador',short:'USD',symbol:'US$',name:'United States Dollar'},  
    {country:'Equatorial Guinea',short:'XAF',symbol:'franc',name:'Central African CFA'},  
    {country:'Eritrea',short:'ERN',symbol:'Nfk',name:'Eritrean Nakfa'},  
    {country:'Estonia',short:'EEK',symbol:'KR',name:'Estonian Kroon'},  
    {country:'Ethiopia',short:'ETB',symbol:'Br',name:'Ethiopian Birr'},  
    {country:'Faeroe Islands',short:'DKK',symbol:'ø',name:'Danish Krone'},  
    {country:'Falkland Islands (Malvinas)',short:'FKP',symbol:'FK£',name:'Falkland pound'},  
    {country:'Fiji',short:'FJD',symbol:'FJ$',name:'Fiji Dollar'},  
    {country:'Finland',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'France',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'French Guiana',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'French Polynesia',short:'XPF',symbol:'CFP',name:'French Pacific Franc'},  
    {country:'TAAF',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'Gabon',short:'XAF',symbol:'franc',name:'Central African CFA'},  
    {country:'Gambia',short:'GMD',symbol:'D',name:'Gambian Dalasi'},  
    {country:'Georgia',short:'GEL',symbol:'GEL',name:'Georgian Lari'},  
    {country:'Germany',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'Ghana',short:'GHC',symbol:'₵',name:'Ghanaian Cedi'},  
    {country:'Gibraltar',short:'GIP',symbol:'GI£',name:'Gibraltar Pound'},  
    {country:'Greece',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'Greenland',short:'DKK',symbol:'ø',name:'Danish Krone'},  
    {country:'Grenada',short:'XCD',symbol:'EC$',name:'East Caribbean Dollar'},  
    {country:'Guadeloupe',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'Guam',short:'USD',symbol:'US$',name:'United States Dollar'},  
    {country:'Guatemala',short:'GTQ',symbol:'Q',name:'Guatemalan Quetzal'},  
    {country:'Guinea',short:'GNF',symbol:'FG',name:'Guinean Franc'},  
    {country:'Guinea-Bissau',short:'XOF',symbol:'franc',name:'West African CFA'},  
    {country:'Guyana',short:'GYD',symbol:'GY$',name:'Guyanese Dollar'},  
    {country:'Haiti',short:'HTG',symbol:'G',name:'Haitian Gourde'},  
    {country:'Honduras',short:'HNL',symbol:'L',name:'Honduran Lempira'},  
    {country:'Hong Kong',short:'HKD',symbol:'HK$',name:'Hong Kong Dollar'},  
    {country:'Hungary',short:'HUF',symbol:'Ft',name:'Hungarian Forint'},  
    {country:'Iceland',short:'ISK',symbol:'ISK',name:'Icelandic Krona'},  
    {country:'India',short:'INR',symbol:'IN₨',name:'Indian Rupee'},  
    {country:'Indonesia',short:'IDR',symbol:'Rp',name:'Indonesian Rupiah'},  
    {country:'Iran',short:'IRR',symbol:'ريال',name:'Iranian Rial'},  
    {country:'Iraq',short:'IQD',symbol:'ع.د',name:'Iraqi Dinar'},  
    {country:'Ireland',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'Isle of Man',short:'GBP',symbol:'UK£',name:'United Kingdom Pound Sterling'},  
    {country:'Israel',short:'ILS',symbol:'₪',name:'Israeli New Sheqel'},  
    {country:'Italy',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'Jamaica',short:'JMD',symbol:'JA$',name:'Jamaican Dollar'},  
    {country:'Japan',short:'JPY',symbol:'JP¥',name:'Japanese Yen'},  
    {country:'Jersey',short:'GBP',symbol:'UK£',name:'United Kingdom Pound Sterling'},  
    {country:'Jordan',short:'JOD',symbol:'JD',name:'Jordanian Dinar'},  
    {country:'Kazakhstan',short:'KZT',symbol:'KZT',name:'Kazakhstani Tenge'},  
    {country:'Kenya',short:'KES',symbol:'KSh',name:'Kenyan Shilling'},  
    {country:'Kiribati',short:'AUD',symbol:'AU$',name:'Australian Dollar'},  
    {country:'Korea, North',short:'KPW',symbol:'₩',name:'North Korean Won'},  
    {country:'Korea, South',short:'KRW',symbol:'₩',name:'Korean Won'},  
    {country:'Kuwait',short:'KWD',symbol:'د.ك',name:'Kuwaiti Dinar'},  
    {country:'Kyrgyzstan',short:'KGS',symbol:'KGS',name:'Kyrgyzstani Som'},  
    {country:'Laos',short:'LAK',symbol:'₭',name:'Lao Kip'},  
    {country:'Latvia',short:'LVL',symbol:'Ls',name:'Latvian Lats'},  
    {country:'Lebanon',short:'LBP',symbol:'ل.ل',name:'Lebanese Pound'},  
    {country:'Lesotho',short:'LSL',symbol:'L',name:'Lesotho Loti'},  
    {country:'Liberia',short:'LRD',symbol:'L$',name:'Liberian Dollar'},  
    {country:'Libya',short:'LYD',symbol:'ل.د',name:'Libyan Dinar'},  
    {country:'Liechtenstein',short:'CHF',symbol:'CHF',name:'Swiss Franc'},  
    {country:'Lithuania',short:'LTL',symbol:'Lt',name:'Lithuanian Litas'},  
    {country:'Luxembourg',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'Macau',short:'MOP',symbol:'MO$',name:'Macanese Pataca'},  
    {country:'Macedonia',short:'MKD',symbol:'MKD',name:'Macedonian Denar'},  
    {country:'Madagascar',short:'MGA',symbol:'MGA',name:'Malagasy Ariary'},  
    {country:'Malawi',short:'MWK',symbol:'MK',name:'Malawian Kwacha'},  
    {country:'Malaysia',short:'MYR',symbol:'RM',name:'Malaysian Ringgit'},  
    {country:'Maldives',short:'MVR',symbol:'MRf',name:'Maldives Rufiyaa'},  
    {country:'Mali',short:'XOF',symbol:'franc',name:'West African CFA'},  
    {country:'Malta',short:'MTL',symbol:'Lm',name:'Maltese Lira'},  
    {country:'Marshall Islands',short:'USD',symbol:'US$',name:'United States Dollar'},  
    {country:'Martinique',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'Mauritania',short:'MRO',symbol:'UM',name:'Mauritanian Ouguiya'},  
    {country:'Mauritius',short:'MUR',symbol:'MU₨',name:'Mauritian Rupee'},  
    {country:'Mayotte',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'Mexico',short:'MXN',symbol:'Mex$',name:'Mexican Peso'},  
    {country:'Micronesia',short:'USD',symbol:'US$',name:'United States Dollar'},  
    {country:'Moldova',short:'MDL',symbol:'MDL',name:'Moldovan Leu'},  
    {country:'Monaco',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'Mongolia',short:'MNT',symbol:'₮',name:'Mongolian Tugrik'},  
    {country:'Montenegro',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'Montserrat',short:'XCD',symbol:'EC$',name:'East Caribbean Dollar'},  
    {country:'Morocco',short:'MAD',symbol:'د.م',name:'Moroccan Dirham'},  
    {country:'Mozambique',short:'MZN',symbol:'MTn',name:'Mozambican Metical'},  
    {country:'Myanmar (Burma)',short:'MMK',symbol:'K',name:'Myanmar Kyat'},  
    {country:'Namibia',short:'NAD',symbol:'N$',name:'Namibian Dollar'},  
    {country:'Nauru',short:'AUD',symbol:'AU$',name:'Australian Dollar'},  
    {country:'Nepal',short:'NPR',symbol:'NP₨',name:'Nepalese Rupee'},  
    {country:'Netherlands',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'Netherlands Antilles',short:'ANG',symbol:'NAƒ',name:'Netherlands Antillean Gulden'},  
    {country:'New Caledonia',short:'XPF',symbol:'CFP',name:'French Pacific Franc'},  
    {country:'New Zealand',short:'NZD',symbol:'NZ$',name:'New Zealand Dollar'},  
    {country:'Nicaragua',short:'NIO',symbol:'C$',name:'Nicaraguan Córdoba'},  
    {country:'Niger',short:'XOF',symbol:'franc',name:'West African CFA'},  
    {country:'Nigeria',short:'NGN',symbol:'₦',name:'Nigerian Naira'},  
    {country:'Niue',short:'NZD',symbol:'NZ$',name:'New Zealand Dollar'},  
    {country:'Norfolk Island',short:'AUD',symbol:'AU$',name:'Australian Dollar'},  
    {country:'Northern Mariana Islands',short:'USD',symbol:'US$',name:'United States Dollar'},  
    {country:'Norway',short:'NOK',symbol:'øre',name:'Norwegian Krone'},  
    {country:'Oman',short:'OMR',symbol:'ر.ع',name:'Omani Rial'},  
    {country:'Pakistan',short:'PKR',symbol:'PKRs',name:'Pakistan Rupee'},  
    {country:'Palau',short:'USD',symbol:'US$',name:'United States Dollar'},  
    {country:'Palestine',short:'JOD',symbol:'JD',name:'Jordanian Dinar'},  
    {country:'Panama',short:'PAB',symbol:'PAB',name:'Panamanian Balboa'},  
    {country:'Papua New Guinea',short:'PGK',symbol:'K',name:'Papua New Guinea Kina'},  
    {country:'Paraguay',short:'PYG',symbol:'₲',name:'Paraguay Guarani'},  
    {country:'Peru',short:'PEN',symbol:'S./',name:'Peruvian Nuevo Sol'},  
    {country:'Philippines',short:'PHP',symbol:'₱',name:'Philippine Peso'},  
    {country:'Pitcairn Islands',short:'NZD',symbol:'NZ$',name:'New Zealand Dollar'},  
    {country:'Poland',short:'PLN',symbol:'zł',name:'Polish Zloty'},  
    {country:'Portugal',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'Puerto Rico',short:'USD',symbol:'US$',name:'United States Dollar'},  
    {country:'Qatar',short:'QAR',symbol:'ر.ق',name:'Qatari Riyal'},  
    {country:'Reunion',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'Romania',short:'RON',symbol:'ROL',name:'Romanian Leu'},  
    {country:'Russia',short:'RUB',symbol:'RUруб',name:'Russian Ruble'},  
    {country:'Rwanda',short:'RWF',symbol:'RF',name:'Rwandan Franc'},  
    {country:'St Helena',short:'SHP',symbol:'SH£',name:'St Helena Pound'},  
    {country:'St Kitts & Nevis',short:'XCD',symbol:'EC$',name:'East Caribbean Dollar'},  
    {country:'St Lucia',short:'XCD',symbol:'EC$',name:'East Caribbean Dollar'},  
    {country:'St Pierre & Miquelon',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'St Vincent & Grenadines',short:'XCD',symbol:'EC$',name:'East Caribbean Dollar'},  
    {country:'Samoa',short:'WST',symbol:'WS$',name:'Samoan Tala'},  
    {country:'Samoa (American)',short:'USD',symbol:'US$',name:'United States Dollar'},  
    {country:'San Marino',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'Sao Tome & Principe',short:'STD',symbol:'Db',name:'Sao Tome Dobra'},  
    {country:'Saudi Arabia',short:'SAR',symbol:'ر.س',name:'Saudi Arabian Riyal'},  
    {country:'Senegal',short:'XOF',symbol:'franc',name:'West African CFA'},  
    {country:'Serbia',short:'RSD',symbol:'дин',name:'Serbian Dinar'},  
    {country:'Seychelles',short:'SCR',symbol:'SRe',name:'Seychelles Rupee'},  
    {country:'Sierra Leone',short:'SLL',symbol:'Le',name:'Sierra Leonean Leone'},  
    {country:'Singapore',short:'SGD',symbol:'S$',name:'Singapore Dollar'},  
    {country:'Slovakia',short:'SKK',symbol:'Sk',name:'Slovak Koruna'},  
    {country:'Slovenia',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'Solomon Islands',short:'SBD',symbol:'SI$',name:'Solomon Islands Dollar'},  
    {country:'Somalia',short:'SOS',symbol:'Sh',name:'Somali Shilling'},  
    {country:'South Africa',short:'ZAR',symbol:'SAR',name:'South African Rand'},  
    {country:'South Georgia Islands',short:'GBP',symbol:'UK£',name:'United Kingdom Pound Sterling'},  
    {country:'Spain',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'Sri Lanka',short:'LKR',symbol:'LK₨',name:'Sri Lankan Rupee'},  
    {country:'Sudan',short:'SDD',symbol:'£Sd',name:'Sudanese Dinar'},  
    {country:'Suriname',short:'SRD',symbol:'SR$',name:'Suriname Dollar'},  
    {country:'Svalbard & Jan Mayen',short:'NOK',symbol:'øre',name:'Norwegian Krone'},  
    {country:'Swaziland',short:'SZL',symbol:'SZL',name:'Swazi Lilangeni'},  
    {country:'Sweden',short:'SEK',symbol:'kr',name:'Swedish Krona'},  
    {country:'Switzerland',short:'CHF',symbol:'CHF',name:'Swiss Franc'},  
    {country:'Syria',short:'SYP',symbol:'S£',name:'Syrian Pound'},  
    {country:'Taiwan',short:'TWD',symbol:'NT$',name:'New Taiwan Dollar'},  
    {country:'Tajikistan',short:'TJS',symbol:'TJS',name:'Tajikistan Somoni'},  
    {country:'Tanzania',short:'TZS',symbol:'TSh',name:'Tanzanian Shilling'},  
    {country:'Thailand',short:'THB',symbol:'฿',name:'Thai Baht'},  
    {country:'Timor-Leste',short:'USD',symbol:'US$',name:'United States Dollar'},  
    {country:'Togo',short:'XOF',symbol:'franc',name:'West African CFA'},  
    {country:'Tokelau',short:'NZD',symbol:'NZ$',name:'New Zealand Dollar'},  
    {country:'Tonga',short:'TOP',symbol:'PT$',name:'Tongan PaAnga'},  
    {country:'Trinidad & Tobago',short:'TTD',symbol:'TT$',name:'Trinidad and Tobago Dollar'},  
    {country:'Tunisia',short:'TND',symbol:'د.ت',name:'Tunisian Dinar'},  
    {country:'Turkey',short:'TRY',symbol:'YTL',name:'Turkish New Lira'},  
    {country:'Turkmenistan',short:'TMM',symbol:'m',name:'Turkmenistani Manat'},  
    {country:'Turks & Caicos Islands',short:'USD',symbol:'US$',name:'United States Dollar'},  
    {country:'Tuvalu',short:'AUD',symbol:'AU$',name:'Australian Dollar'},  
    {country:'Uganda',short:'UGX',symbol:'USh',name:'Ugandan Shilling'},  
    {country:'Ukraine',short:'UAH',symbol:'₴',name:'Ukrainian Hryvnia'},  
    {country:'United Arab Emirates',short:'AED',symbol:'د.إ',name:'United Arab Emirates Dirham'},  
    {country:'UK',short:'GBP',symbol:'UK£',name:'United Kingdom Pound Sterling'},  
    {country:'USA',short:'USD',symbol:'US$',name:'United States Dollar'},  
    {country:'Uruguay',short:'UYU',symbol:'UR$',name:'Uruguayan peso'},  
    {country:'Uzbekistan',short:'UZS',symbol:'UZS',name:'Uzbekistani Som'},  
    {country:'Vanuatu',short:'VUV',symbol:'Vt',name:'Vanuatu Vatu'},  
    {country:'Vatican City',short:'EUR',symbol:'€',name:'European Euro'},  
    {country:'Venezuela',short:'VEB',symbol:'Bs',name:'Venezuelan Bolivar'},  
    {country:'Vietnam',short:'VND',symbol:'₫',name:'Viet Nam Dong'},  
    {country:'Virgin Islands, British',short:'USD',symbol:'US$',name:'United States Dollar'},  
    {country:'Virgin Islands, United States',short:'USD',symbol:'US$',name:'United States Dollar'},  
    {country:'Wallis & Futuna',short:'XPF',symbol:'CFP',name:'French Pacific Franc'},  
    {country:'Yemen',short:'YER',symbol:'YER',name:'Yemeni Rial'},  
    {country:'Zambia',short:'ZMK',symbol:'ZK',name:'Zambian Kwacha'},  
    {country:'Zimbabwe',short:'ZWD',symbol:'Z$',name:'Zimbabwean Dollar'}

]



const countriesDE = [
    {"country" : "Abu Dhabi", "name" : "VAE Dirham", "short" : "AED"},
    {"country" : "Afghanistan", "name" : "Afghani", "short" : "AFN"},
    {"country" : "Ägypten", "name" : "Ägyptisches Pfund", "short" : "EGP"},
    {"country" : "Akrotiri", "name" : "Euro", "short" : "EUR"},
    {"country" : "Albanien", "name" : "Lek", "short" : "ALL"},
    {"country" : "Algerien", "name" : "Algerischer Dinar", "short" : "DZD"},
    {"country" : "Amerikanische Jungferninseln", "name" : "US-Dollar", "short" : "USD"},
    {"country" : "Amerikanisch-Samoa", "name" : "US-Dollar", "short" : "USD"},
    {"country" : "Andorra", "name" : "Euro", "short" : "EUR"},
    {"country" : "Angola", "name" : "Kwanza", "short" : "AOA"},
    {"country" : "Anguilla", "name" : "Ostkaribischer Dollar", "short" : "XCD"},
    {"country" : "Antigua und Barbuda", "name" : "Ostkaribischer Dollar", "short" : "XCD"},
    {"country" : "Äquatorialguinea", "name" : "CFA-Franc BEAC", "short" : "XAF"},
    {"country" : "Argentinien", "name" : "Argentinischer Peso", "short" : "ARS"},
    {"country" : "Armenien", "name" : "Dram", "short" : "AMD"},
    {"country" : "Aruba", "name" : "Aruba-Florin", "short" : "AWG"},
    {"country" : "Aserbaidschan", "name" : "Aserbaidschan-Manat", "short" : "AZN"},
    {"country" : "Äthiopien", "name" : "Äthiopischer Birr", "short" : "ETB"},
    {"country" : "Australien", "name" : "Australischer Dollar", "short" : "AUD"},
    {"country" : "Bahamas", "name" : "Bahama-Dollar", "short" : "BSD"},
    {"country" : "Bahrain", "name" : "Bahrain-Dinar", "short" : "BHD"},
    {"country" : "Bangladesch", "name" : "Taka", "short" : "BDT"},
    {"country" : "Barbados", "name" : "Barbados-Dollar", "short" : "BBD"},
    {"country" : "Belarus", "name" : "Weißrussischer Rubel", "short" : "BYN"},
    {"country" : "Belgien", "name" : "Euro", "short" : "EUR"},
    {"country" : "Belize", "name" : "Belize-Dollar", "short" : "BZD"},
    {"country" : "Benin", "name" : "CFA-Franc BCEAO", "short" : "XOF"},
    {"country" : "Bermuda", "name" : "Bermuda-Dollar", "short" : "BMD"},
    {"country" : "Bhutan", "name" : "Ngultrum", "short" : "BTN"},
    {"country" : "Bolivien", "name" : "Boliviano", "short" : "BOB"},
    {"country" : "Bonaire", "name" : "US-Dollar", "short" : "USD"},
    {"country" : "Bosnien und Herzegowina", "name" : "Konvertible Mark", "short" : "BAM"},
    {"country" : "Botsuana", "name" : "Botswanischer Pula", "short" : "BWP"},
    {"country" : "Brasilien", "name" : "Brasilianischer Real", "short" : "BRL"},
    {"country" : "Britische Jungferninseln", "name" : "US-Dollar", "short" : "USD"},
    {"country" : "Brunei Darussalam", "name" : "Brunei-Dollar", "short" : "BND"},
    {"country" : "Bulgarien", "name" : "Lew", "short" : "BGN"},
    {"country" : "Burkina Faso", "name" : "CFA-Franc BCEAO", "short" : "XOF"},
    {"country" : "Burundi", "name" : "Burundi-Franc", "short" : "BIF"},
    {"country" : "Kapverdische Inseln", "name" : "Kap-Verde-Escudo", "short" : "CVE"},
    {"country" : "Chile", "name" : "Chilenischer Peso", "short" : "CLP"},
    {"country" : "China", "name" : "Chinesischer Renminbi Yuan", "short" : "CNY"},
    {"country" : "Cookinseln", "name" : "Neuseeland-Dollar", "short" : "NZD"},
    {"country" : "Costa Rica", "name" : "Costa-Rica Colón", "short" : "CRC"},
    {"country" : "Côte d'Ivoire", "name" : "CFA-Franc BCEAO", "short" : "XOF"},
    {"country" : "Curaçao", "name" : "Antillen-Gulden", "short" : "ANG"},
    {"country" : "Dänemark", "name" : "Dänische Krone", "short" : "DKK"},
    {"country" : "Deutschland", "name" : "Euro", "short" : "EUR"},
    {"country" : "Dhekelia", "name" : "Euro", "short" : "EUR"},
    {"country" : "Dominica", "name" : "Ostkaribischer Dollar", "short" : "XCD"},
    {"country" : "Dominikanische Republik", "name" : "Dominikanischer Peso", "short" : "DOP"},
    {"country" : "Dschibuti", "name" : "Dschibuti-Franc", "short" : "DJF"},
    {"country" : "Dubai", "name" : "VAE Dirham", "short" : "AED"},
    {"country" : "Ecuador", "name" : "US-Dollar", "short" : "USD"},
    {"country" : "El Salvador", "name" : "US-Dollar (Colón auslaufend)", "short" : "USD"},
    {"country" : "Eritrea", "name" : "Nakfa", "short" : "ERN"},
    {"country" : "Estland", "name" : "Euro", "short" : "EUR"},
    {"country" : "Eswatini", "name" : "Lilangeni", "short" : "SZL"},
    {"country" : "Falklandinseln", "name" : "Falkland-Pfund", "short" : "FKP"},
    {"country" : "Fidschi", "name" : "Fidschi-Dollar", "short" : "FJD"},
    {"country" : "Finnland", "name" : "Euro", "short" : "EUR"},
    {"country" : "Frankreich", "name" : "Euro", "short" : "EUR"},
    {"country" : "Französisch-Guayana", "name" : "Euro", "short" : "EUR"},
    {"country" : "Französisch-Polynesien", "name" : "CFP-Franc", "short" : "XPF"},
    {"country" : "Gabun", "name" : "CFA-Franc BEAC", "short" : "XAF"},
    {"country" : "Gambia", "name" : "Dalasi", "short" : "GMD"},
    {"country" : "Gazastreifen", "name" : "Neuer Schekel", "short" : "ILS"},
    {"country" : "Georgien", "name" : "Lari", "short" : "GEL"},
    {"country" : "Ghana", "name" : "Ghanaischer Cedi", "short" : "GHS"},
    {"country" : "Gibraltar", "name" : "Gibraltar -Pfund", "short" : "GIP"},
    {"country" : "Grenada", "name" : "Ostkaribischer Dollar", "short" : "XCD"},
    {"country" : "Griechenland", "name" : "Euro", "short" : "EUR"},
    {"country" : "Grönland", "name" : "Dänische Krone", "short" : "DKK"},
    {"country" : "Großbritannien", "name" : "Pfund Sterling", "short" : "GBP"},
    {"country" : "Guadeloupe", "name" : "Euro", "short" : "EUR"},
    {"country" : "Guam", "name" : "US-Dollar", "short" : "USD"},
    {"country" : "Guatemala", "name" : "Quetzal", "short" : "GTQ"},
    {"country" : "Guinea", "name" : "Guinea-Franc", "short" : "GNF"},
    {"country" : "Guinea-Bissau", "name" : "CFA-Franc BCEAO", "short" : "XOF"},
    {"country" : "Guyana", "name" : "Guyana-Dollar", "short" : "GYD"},
    {"country" : "Haiti", "name" : "Gourde", "short" : "HTG"},
    {"country" : "Honduras", "name" : "Lempira", "short" : "HNL"},
    {"country" : "Hongkong", "name" : "Hongkong-Dollar", "short" : "HKD"},
    {"country" : "Indien", "name" : "Indische Rupie", "short" : "INR"},
    {"country" : "Indonesien", "name" : "Indonesische Rupiah", "short" : "IDR"},
    {"country" : "Irak", "name" : "Irakischer Dinar", "short" : "IQD"},
    {"country" : "Iran", "name" : "Iranischer Rial", "short" : "IRR"},
    {"country" : "Irland", "name" : "Euro", "short" : "EUR"},
    {"country" : "Island", "name" : "Isländische Krone", "short" : "ISK"},
    {"country" : "Israel", "name" : "Neuer Schekel", "short" : "ILS"},
    {"country" : "Italien", "name" : "Euro", "short" : "EUR"},
    {"country" : "Jamaika", "name" : "Jamaikanischer Dollar", "short" : "JMD"},
    {"country" : "Japan", "name" : "Yen", "short" : "JPY"},
    {"country" : "Jemen", "name" : "Jemen-Rial", "short" : "YER"},
    {"country" : "Jordanien", "name" : "Jordanischer Dinar", "short" : "JOD"},
    {"country" : "Kaimaninseln", "name" : "Kaiman-Dollar", "short" : "KYD"},
    {"country" : "Kambodscha", "name" : "Riel", "short" : "KHR"},
    {"country" : "Kamerun", "name" : "CFA-Franc BEAC", "short" : "XAF"},
    {"country" : "Kanada", "name" : "Kanadischer Dollar", "short" : "CAD"},
    {"country" : "Kasachstan", "name" : "Tenge", "short" : "KZT"},
    {"country" : "Katar", "name" : "Katar-Riyal", "short" : "QAR"},
    {"country" : "Kenia", "name" : "Kenia-Schilling (Shiling)", "short" : "KES"},
    {"country" : "Kirgisistan", "name" : "Som", "short" : "KGS"},
    {"country" : "Kiribati", "name" : "Australischer Dollar", "short" : "AUD"},
    {"country" : "Kolumbien", "name" : "Kolumbianischer Peso", "short" : "COP"},
    {"country" : "Komoren", "name" : "Komoren-Franc", "short" : "KMF"},
    {"country" : "Kongo", "name" : "CFA-Franc BEAC", "short" : "XAF"},
    {"country" : "Kongo, Demokratische Republik", "name" : "Kongo-Franc", "short" : "CDF"},
    {"country" : "Korea, Demokratische Volksrepublik", "name" : "Nordkoreanischer Won", "short" : "KPW"},
    {"country" : "Korea, Republik", "name" : "Südkoreanischer Won", "short" : "KRW"},
    {"country" : "Kosovo", "name" : "Euro", "short" : "EUR"},
    {"country" : "Kroatien", "name" : "Kroatische Kuna", "short" : "HRK"},
    {"country" : "Kuba (Kubanischer Peso)", "name" : "Kubanischer Peso", "short" : "CUP"},
    {"country" : "Kuba (Peso concertible)", "name" : "Peso concertible", "short" : "CUC"},
    {"country" : "Kuwait", "name" : "Kuwait-Dinar", "short" : "KWD"},
    {"country" : "Laos", "name" : "Laotischer Kip", "short" : "LAK"},
    {"country" : "Lesotho", "name" : "Loti", "short" : "LSL"},
    {"country" : "Lettland", "name" : "Euro", "short" : "EUR"},
    {"country" : "Libanon", "name" : "Libanesisches Pfund", "short" : "LBP"},
    {"country" : "Liberia", "name" : "Liberianischer Dollar", "short" : "LRD"},
    {"country" : "Libyen", "name" : "Libyscher Dinar", "short" : "LYD"},
    {"country" : "Liechtenstein", "name" : "Schweizer Franken", "short" : "CHF"},
    {"country" : "Litauen", "name" : "Euro", "short" : "EUR"},
    {"country" : "Luxemburg", "name" : "Euro", "short" : "EUR"},
    {"country" : "Macau", "name" : "Pataca", "short" : "MOP"},
    {"country" : "Madagaskar", "name" : "Ariary", "short" : "MGA"},
    {"country" : "Malawi", "name" : "Malawi-Kwacha", "short" : "MWK"},
    {"country" : "Malaysia", "name" : "Malaysischer Ringgit", "short" : "MYR"},
    {"country" : "Malediven", "name" : "Rufiyaa", "short" : "MVR"},
    {"country" : "Mali", "name" : "CFA-Franc BCEAO", "short" : "XOF"},
    {"country" : "Malta", "name" : "Euro", "short" : "EUR"},
    {"country" : "Marokko", "name" : "Marokkanischer Dirham", "short" : "MAD"},
    {"country" : "Marshallinseln", "name" : "US-Dollar", "short" : "USD"},
    {"country" : "Martinique", "name" : "Euro", "short" : "EUR"},
    {"country" : "Mauretanien", "name" : "Ouguiya", "short" : "MRU"},
    {"country" : "Mauritius", "name" : "Mauritius-Rupie", "short" : "MUR"},
    {"country" : "Mayotte", "name" : "Euro", "short" : "EUR"},
    {"country" : "Mexiko", "name" : "Mexikanischer Peso", "short" : "MXN"},
    {"country" : "Midway", "name" : "US-Dollar", "short" : "USD"},
    {"country" : "Mikronesien", "name" : "US-Dollar", "short" : "USD"},
    {"country" : "Moldau", "name" : "Moldauscher Leu", "short" : "MDL"},
    {"country" : "Monaco", "name" : "Euro", "short" : "EUR"},
    {"country" : "Mongolei", "name" : "Tugrik (auch Tugrig oder Tögrög)", "short" : "MNT"},
    {"country" : "Montenegro", "name" : "Euro", "short" : "EUR"},
    {"country" : "Montserrat", "name" : "Ostkaribischer Dollar", "short" : "XCD"},
    {"country" : "Mosambik", "name" : "Metical", "short" : "MZN"},
    {"country" : "Myanmar", "name" : "Kyat", "short" : "MMK"},
    {"country" : "Namibia", "name" : "Namibischer Dollar", "short" : "NAD"},
    {"country" : "Nauru", "name" : "Australischer Dollar", "short" : "AUD"},
    {"country" : "Nepal", "name" : "Nepalesische Rupie", "short" : "NPR"},
    {"country" : "Neukaledonien", "name" : "CFP-Franc", "short" : "XPF"},
    {"country" : "Neuseeland", "name" : "Neuseeland-Dollar", "short" : "NZD"},
    {"country" : "Nicaragua", "name" : "Córdoba Oro", "short" : "NIO"},
    {"country" : "Niederlande", "name" : "Euro", "short" : "EUR"},
    {"country" : "Niger", "name" : "CFA-Franc BCEAO", "short" : "XOF"},
    {"country" : "Nigeria", "name" : "Naira", "short" : "NGN"},
    {"country" : "Niue", "name" : "Neuseeland-Dollar", "short" : "NZD"},
    {"country" : "Nördliche Marianen", "name" : "US-Dollar", "short" : "USD"},
    {"country" : "Nordmazedonien", "name" : "Mazedonischer Denar", "short" : "MKD"},
    {"country" : "Nordzypern", "name" : "Neue Lira", "short" : "TRY"},
    {"country" : "Norfolkinsel", "name" : "Australischer Dollar", "short" : "AUD"},
    {"country" : "Norwegen", "name" : "Norwegische Krone", "short" : "NOK"},
    {"country" : "Oman", "name" : "Omanischer Rial", "short" : "OMR"},
    {"country" : "Österreich", "name" : "Euro", "short" : "EUR"},
    {"country" : "Pakistan", "name" : "Pakistanische Rupie", "short" : "PKR"},
    {"country" : "Palau", "name" : "US-Dollar", "short" : "USD"},
    {"country" : "Panama", "name" : "Panamaischer Balboa", "short" : "PAB"},
    {"country" : "Papua-Neuguinea", "name" : "Kina", "short" : "PGK"},
    {"country" : "Paraguay", "name" : "Paraguayischer Guaraní", "short" : "PYG"},
    {"country" : "Peru", "name" : "Peruanischer Sol", "short" : "PEN"},
    {"country" : "Philippinen", "name" : "Philippinischer Peso", "short" : "PHP"},
    {"country" : "Pitcairninseln", "name" : "Neuseeland-Dollar", "short" : "NZD"},
    {"country" : "Polen", "name" : "Zloty", "short" : "PLN"},
    {"country" : "Portugal", "name" : "Euro", "short" : "EUR"},
    {"country" : "Puerto Rico", "name" : "US-Dollar", "short" : "USD"},
    {"country" : "Réunion", "name" : "Euro", "short" : "EUR"},
    {"country" : "Ruanda", "name" : "Ruanda-Franc", "short" : "RWF"},
    {"country" : "Rumänien", "name" : "Rumänischer Leu", "short" : "RON"},
    {"country" : "Russische Föderation", "name" : "Russischer Rubel", "short" : "RUB"},
    {"country" : "Saba", "name" : "US-Dollar", "short" : "USD"},
    {"country" : "Saint-Martin", "name" : "Euro", "short" : "EUR"},
    {"country" : "Salomonen", "name" : "Salomonen-Dollar", "short" : "SBD"},
    {"country" : "Sambia", "name" : "Sambischer Kwacha", "short" : "ZMW"},
    {"country" : "Samoa", "name" : "Samoanischer Tala", "short" : "WST"},
    {"country" : "San Marino", "name" : "Euro", "short" : "EUR"},
    {"country" : "São Tomé und Príncipe", "name" : "São-toméischer Dobra", "short" : "STN"},
    {"country" : "Saudi-Arabien", "name" : "Saudi-Rial", "short" : "SAR"},
    {"country" : "Schweden", "name" : "Schwedische Krone", "short" : "SEK"},
    {"country" : "Schweiz", "name" : "Schweizer Franken", "short" : "CHF"},
    {"country" : "Senegal", "name" : "CFA-Franc BCEAO", "short" : "XOF"},
    {"country" : "Serbien", "name" : "Serbischer Dinar", "short" : "RSD"},
    {"country" : "Seychellen", "name" : "Seychellen-Rupie", "short" : "SCR"},
    {"country" : "Sierra Leone", "name" : "Sierra-leonischer Leone", "short" : "SLL"},
    {"country" : "Simbabwe", "name" : "Simbabwe-Dollar (ausgesetzt)", "short" : "ZWL"},
    {"country" : "Singapur", "name" : "Singapur-Dollar", "short" : "SGD"},
    {"country" : "Sint Eustatius", "name" : "US-Dollar", "short" : "USD"},
    {"country" : "Sint Maarten", "name" : "Antillen-Gulden", "short" : "ANG"},
    {"country" : "Slowakei", "name" : "Euro", "short" : "EUR"},
    {"country" : "Slowenien", "name" : "Euro", "short" : "EUR"},
    {"country" : "Somalia", "name" : "Somalia-Schilling (Shilling)", "short" : "SOS"},
    {"country" : "Spanien", "name" : "Euro", "short" : "EUR"},
    {"country" : "Spitzbergen", "name" : "Norwegische Krone", "short" : "NOK"},
    {"country" : "Sri Lanka", "name" : "Sri Lanka-Rupie", "short" : "LKR"},
    {"country" : "St. Barthélemy", "name" : "Euro", "short" : "EUR"},
    {"country" : "St. Kitts und Nevis", "name" : "Ostkaribischer Dollar", "short" : "XCD"},
    {"country" : "St. Lucia", "name" : "Ostkaribischer Dollar", "short" : "XCD"},
    {"country" : "St. Pierre und Miquelon", "name" : "Euro", "short" : "EUR"},
    {"country" : "St. Vincent und die Grenadinen", "name" : "Ostkaribischer Dollar", "short" : "XCD"},
    {"country" : "Südafrika", "name" : "Südafrikanischer Rand", "short" : "ZAR"},
    {"country" : "Sudan", "name" : "Sudanesisches Pfund", "short" : "SDG"},
    {"country" : "Südgeorgien und die Südlichen Sandwichinseln", "name" : "Pfund Sterling", "short" : "GBP"},
    {"country" : "Südsudan", "name" : "Südsudanesisches Pfund", "short" : "SSP"},
    {"country" : "Suriname", "name" : "Suriname-Dollar", "short" : "SRD"},
    {"country" : "Syrien", "name" : "Syrisches Pfund", "short" : "SYP"},
    {"country" : "Tadschikistan", "name" : "Somoni", "short" : "TJS"},
    {"country" : "Taiwan", "name" : "Taiwan-Dollar", "short" : "TWD"},
    {"country" : "Tansania", "name" : "Tansania-Schilling", "short" : "TZS"},
    {"country" : "Thailand", "name" : "Baht", "short" : "THB"},
    {"country" : "Timor-Leste", "name" : "US-Dollar (+ eigene Münzen)", "short" : "USD"},
    {"country" : "Togo", "name" : "CFA-Franc BCEAO", "short" : "XOF"},
    {"country" : "Tokelau", "name" : "Neuseeland-Dollar", "short" : "NZD"},
    {"country" : "Tonga", "name" : "Pa'anga", "short" : "TOP"},
    {"country" : "Trinidad und Tobago", "name" : "Trinidad und Tobago Dollar", "short" : "TTD"},
    {"country" : "Tschad", "name" : "CFA-Franc BEAC", "short" : "XAF"},
    {"country" : "Tschechische Republik", "name" : "Tschechische Krone", "short" : "CZK"},
    {"country" : "Tunesien", "name" : "Tunesischer Dinar", "short" : "TND"},
    {"country" : "Türkei", "name" : "Neue Lira", "short" : "TRY"},
    {"country" : "Turkmenistan", "name" : "Turkmenistan-Manat", "short" : "TMT"},
    {"country" : "Turks- und Caicosinseln", "name" : "US-Dollar", "short" : "USD"},
    {"country" : "Tuvalu", "name" : "Australischer Dollar", "short" : "AUD"},
    {"country" : "Uganda", "name" : "Uganda-Schilling", "short" : "UGX"},
    {"country" : "Ukraine", "name" : "Hrywna", "short" : "UAH"},
    {"country" : "Ungarn", "name" : "Forint", "short" : "HUF"},
    {"country" : "Uruguay", "name" : "Uruguayischer Peso", "short" : "UYU"},
    {"country" : "Usbekistan", "name" : "Soʻm", "short" : "UZS"},
    {"country" : "Vanuatu", "name" : "Vatu", "short" : "VUV"},
    {"country" : "Vatikanstadt", "name" : "Euro", "short" : "EUR"},
    {"country" : "Venezuela", "name" : "Venezolanischer Bolívar", "short" : "VES"},
    {"country" : "Vereinigte Arabische Emirate", "name" : "VAE-Dirham", "short" : "AED"},
    {"country" : "Vereinigte Staaten", "name" : "US-Dollar", "short" : "USD"},
    {"country" : "Vietnam", "name" : "Vietnamesischer Đồng", "short" : "VND"},
    {"country" : "Wallis und Futuna", "name" : "CFP-Franc", "short" : "XPF"},
    {"country" : "Weihnachtsinsel", "name" : "Australischer Dollar", "short" : "AUD"},
    {"country" : "Westjordanland", "name" : "Neuer Schekel", "short" : "ILS"},
    {"country" : "Zentralafrikanische Republik", "name" : "CFA-Franc BEAC", "short" : "XAF"},
    {"country" : "Zypern", "name" : "Euro", "short" : "EUR"}
    ]
    
    

export {countries, countriesDE};