import React from "react";
import "./MainBoard.css" 


const MainBoardCell = ({i, boardArray}) => {
    return(
        <div style={{width:"20%", height:"100%"}} id={i} data-img={boardArray[i].url} className={`outerBox card ${boardArray[i].className}`}>
            <div className = "innerBox">
                
                <div className = {`cardFront ${boardArray[i].url}`}></div>
                <div className = "cardBack"></div>
                
            </div>
        </div>
    );
};
export default MainBoardCell;