// import UserFields from "./Fields/UserFields"
// import UserFormPastSubmit from './UserFormPastSubmit';
// import {FormEvaluation} from './FormEvaluation'


class FormDataFieldClass{

    constructor(value, label, fieldType, type, settings, validation){
        this.value = value;
        this.label = label;
        this.fieldType = fieldType;
        this.type = type;
        this.settings = settings;
        this.validation = validation;
    }

   
}

export default FormDataFieldClass;