import React, { useEffect, useState } from "react";
import { getDateObject } from "../../../../helpers/Date";
import { Translate, translate } from "../../../Language/Translate";
import MapMainObservations from "../../../Leaflet/Observations/MapMainObservations";
import Spinner from "../../../Spinner";
import { fixGeolocation, getTextGeoFromSpeciesObservationsWithVessel } from "../../helpers";
import ObservationListList from "./ObservationsListList";
import ObservationsSelectForm from "./ObservationsSelectForm";
import { buildVariables } from "./functions";

const ObservationsList = ({mainProps}) => {
    const {speciesObservations, selectedGeolocation} = mainProps;
    const language = Translate.language;
    const [variables, setVariables] = useState({...buildVariables(speciesObservations, language)});
    const [selectArray, setSelectArray] = useState([]);
    const [selectedSpeciesObservations, setSelectedSpeciesObservations] = useState([...speciesObservations]);
    const [selectedSpeciesObservationsGeolocation, setSelectedSpeciesObservationsGeolocation] = useState([]);
    
    useEffect(() => {
            const selectedSpecies = [];
            Object.values(variables.speciesGroups).forEach(sg => {
                Object.values(sg.species).forEach(s => {if(s.checked)selectedSpecies.push({id:s.id, name:s.name})});
            });
            setSelectArray(selectedSpecies)
            const ids = selectedSpecies.map(s => s.id);
            const _selectedObservations = speciesObservations.filter(so => ids.includes(so.speciesId));
            const _selectedObservationsGeo = _selectedObservations.filter(o => o.geolocation === selectedGeolocation) || [];
            setSelectedSpeciesObservations(_selectedObservations);
            setSelectedSpeciesObservationsGeolocation(_selectedObservationsGeo);
    }, [variables, speciesObservations, selectedGeolocation])

    if(!variables)return <Spinner/>
    
    const getText = obs => {
        const date = getDateObject(obs.dateTime, Translate.language, "local").short;
        const geo = getTextGeoFromSpeciesObservationsWithVessel(obs);
        return `${date}: ${geo}`
    }

    const events = Array.from(new Set(selectedSpeciesObservations.map(o => getText(o))));
    const eventsGeolocation = Array.from(new Set(selectedSpeciesObservationsGeolocation.map(o => getText(o))));

    if(!speciesObservations.length)return <p className="mt-3">{translate("No items")}</p>

    return(
        <div>
            <div className = "mt-5">
                <p>{`${translate("Selected")}: ${selectArray.map(i => i.name).join(", ")}`}</p>
                <ObservationsSelectForm props={{...mainProps, variables, setVariables}}/>
                <MapMainObservations mainProps = {{...mainProps, selectedSpeciesObservations}}/>

                {selectedGeolocation && <h4 className="mt-5">{`${translate("Observations")}: ${fixGeolocation(selectedGeolocation)}`}</h4>}
                {selectedGeolocation && <ObservationListList 
                    props={{events: eventsGeolocation, observations: selectedSpeciesObservationsGeolocation, getText}}/>}
                <h4 className="mt-5">{translate("All Observations")}</h4>
                <ObservationListList props={{events, observations: selectedSpeciesObservations, getText}}/>

            </div>
            <div>
                
            </div>
        </div>
    );
};

export default ObservationsList;