import { simpleRequest } from "../simpleRequest";
import {translate} from "../../components/Language/Translate"

class VesselFormData{

   
    static async vesselNew(data){

        const {params} = data;
        const consts = new VesselFormSchema();
        let fields;
        Object.entries(consts.fields).forEach(e => fields = {...fields, [e[0]]: e[1].value})
        const options = await VesselFunctions._getSelectListCompanies(params.handle);
        consts.fields.companyHandle.settings.options=options;
        fields.companyHandle = params.handle;
        consts.nextPage = (`/companies/${params.handle}`);
        const vars={fields};
        return {consts, vars};
    };
    static async vesselEdit(data){
        // console.log('inside VesselFormData.vesselEdit', {data})
        const vesselId = data.params.vessel_id;
        const consts = new VesselFormSchema();
        const vessel=(await VesselFunctions._getVessel(vesselId)).vessel;
        // console.log(consts, vessel)
        consts.fields.companyHandle.settings.options = await VesselFunctions._getSelectListCompanies(vessel.companyHandle);
        consts.fields.companyHandle.fieldType="info";
        const keys= Object.keys(consts.fields);
        let fields = {};
        keys.forEach(k => fields = {...fields, [k]:vessel[k]});
        const vars = {fields};
        consts.oldValues = vessel;
        consts.request = {url:`vessels/${vesselId}`, method:"patch"};
        consts.formLabels={title: translate("Edit Vessel"), btnLabel:translate("Save")};
        consts.formType = "vesselEdit";
        consts.nextPage=`/vessels/${vesselId}`;
        // console.log({consts, vars});
        return {consts, vars};
    }
}



class VesselFormSchema{

    constructor(){
        
        this.formType="vesselNew";
        
        this.request = {
            url:"vessels",
            method:"post",
        };
        this.formLabels = {title: translate("Add Vessel"), btnLabel:translate("Add Vessel")};
        this.fields={
            companyHandle: {
                label:translate("Company"),
                value:"",
                disabled:true,
                type:"text",
                fieldType:"select",
                settings:{}
            },
            type:{
                label:translate("Type"),
                value:"Sea Ship",
                type: "text",
                fieldType:"select",
                settings:{options:[{key:"Sea Ship", value:translate("Sea Ship")}, {key:"River Boat", value:translate("River Boat")}]}
            },

            prefix: {
                label:"Prefix:",
                value:"MS",
                validation:{
                    maxLength:3,
                },
                type:"text",
                fieldType:"text"
            },
            name: {
                label:"Name:",
                value:"",
                validation:{
                    minLength:5,
                },
                required:true,
                type:"text",
                fieldType:"text"
        },
            note: {
                label:translate("Note"),
                value:"",
                validation:{},
                required:false,
                type:"text",
                fieldType:"text"
        },
            short:{
                label:translate("Shortcut"),
                value:'',
                validation:{maxLength:8},
                type:"text",
                fieldType:"text"
        },
            actual:{
                label:translate("Actual in fleet"),
                value:true,
                validation:{maxLength:8},
                type:"checkBox",
                fieldType:"checkBox"
        },
            photoUrl:{
                label:translate("Photo URL"),
                value:'',
                validation:{},
                type:"text",
                fieldType:"text"
        },
            description:{
                label:translate("Description"),
                value:'',
                validation:{},
                type:"text",
                fieldType:"textarea"
        }
        }
    }
};

class VesselFunctions{
    static async _getCompanies(){
        const reqObj= {url: `companies`, method:"get"};
        const response= await simpleRequest(reqObj);
        // console.log(response);
        // console.log(response.data);
        // console.log(response.data.companies);
        return response.data.companies;
    };
    static async _getSelectListCompanies(handle){
        const companies= await this._getCompanies();
        // console.log({companies});
        let selectList = companies.map(c => {return{"key":c.handle,"value":c.name}});
        // console.log({selectList});
        if(handle) return selectList.filter(s => s.key === handle);
        return selectList;
    };
    static async _getVessel(vesselId){
        const response = await simpleRequest({url:`vessels/${+vesselId}`, method:'get'});
        // console.log(response.data)
        return response.data;
    };
}

export default VesselFormData;





  