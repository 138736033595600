import React, {useState} from "react";
import { StyledDivWithBorder } from "../styledComponents/divs";
import { translate } from "../Language/Translate";
import TourEdit from "../../newForms2/tours/TourEdit";
import { simpleRequest } from "../../newForms2/simpleRequest";
import { useSelector } from "react-redux";

const TourDetails = ({tour, backFunc, setTours, action}) => {
    const [innerView, setInnerView] = useState('listitem');
    const [fullDescription, setFullDescription] = useState(false);
    // const cruiseId = useSelector(st => st.currentCruise).id;
    // const {username} = useSelector(st => st.currentUser);

    // const tourId = tour.id; 
    const handleDelete = async() => {
        const deleted = (await simpleRequest({url:`tours/${tour.id}`, method:"delete"})).data?.deleted;
        // console.log({deleted});
        setTours(tours => [...tours.filter(t => t.id !== tour.id)]);
        backFunc();
    };
    const handleTourReport = async() => {
        // console.log("tour report", {username, tourId, tour});
        // const data = {username, tourId}
        const result = (await simpleRequest({url: `worddocuments/tourreport/${tour.id}`})).data;
        console.log({result});
    }

    return(
        <>
        {innerView === "listitem" 
            && <StyledDivWithBorder>
                <h5>{`${tour.starttime}-${tour.endtime} - ${tour.short} - ${tour.title}, ${tour.currency} ${tour.prize/100}`}</h5>
                <p>{translate("Difficulty")}: {tour.difficulty}</p>
                {!fullDescription && <p onClick={() => setFullDescription(true)}>{tour.description.slice(0,50)}...</p>}
                {fullDescription && <p onClick={() => setFullDescription(false)}>{tour.description}</p>}

                <button className="btn btn-outline-dark" onClick={() => {setInnerView('editForm')}}>{translate("Edit")}</button>
                <button className="btn btn-outline-danger mx-1" onClick={handleDelete}>{translate("Delete")}</button>
                <button className="btn btn-outline-dark mx-1" onClick={backFunc}>{translate("Close")}</button>
                <button className="btn btn-outline-dark mx-1" onClick={handleTourReport}>{`${translate("Tour Report")} ${tour.short}`}</button>
                
            </StyledDivWithBorder>}
        {innerView === "editForm" && <TourEdit id={tour.id} backFunc={backFunc} setTours={setTours} action={action}/>}

        </>
    )
};
export default TourDetails;