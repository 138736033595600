
export const initDiaryEntry = (day) => {
    // console.log({day});
    const texts = day.actions.map(a => {
        // console.log({a})
        const {arrival, departure, deDE, description, destination,} = a;
        // let dests = `${deDE ||""} ${one ? "(":""}${arrival ||""}${departure ? " - " :""}${departure ||""}${one ? ")":""}`;
        const dest = deDE ? deDE : destination ? destination : null;
        let dests = dest && (`${dest} ${arrival || departure ?` (${arrival || ""}${(departure && " - ") || ""}${departure || ""})`:''}`);
        if(description)dests += `\n${description}`;
        return dests;
    });
    // console.log(texts);
    return {
        entry: texts.join('\n\n'),
        date:day.date,
        cruiseId: day.actions[0].cruiseId
    };

    // return{
    //     "cruiseId": 1,
    //     "dateTime": "2021-10-10, 08:00 - 16:00",
    //     "destination": "Hamburg",
    //     "title": "my Title",
    //     "description": "my Description"
    // }


};