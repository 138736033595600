import React from "react";
import { Form } from "react-bootstrap";
import { translate } from "../../../components/Language/Translate";
// import { simpleRequest } from "../../simpleRequest";
// import { FormFrame, StyledDivWithBorder } from "../../../components/styledComponents/divs";


const SpeciesFormFields = ({props}) => {
    const {variables, setVariables} = props;
    const handleChange = e => setVariables({...variables, [e.target.name]:e.target.value});
    return(
        <>
                <Form.Group>
                <Form.Label>{translate("German")}</Form.Label>
                <Form.Control
                    name = "deDE"
                    type = "text"
                    value={variables.deDE}
                    onChange = {e => handleChange(e)}
                ></Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label>{translate("English")}</Form.Label>
                <Form.Control
                    name = "enUK"
                    type = "text"
                    value={variables.enUK}
                    onChange = {e => handleChange(e)}
                ></Form.Control>
            </Form.Group>
        </>
    )
};
export default SpeciesFormFields;