import React from 'react';
import {translate} from "./Language/Translate"
import {Accordion} from 'react-bootstrap'
import OtherUserCruisesCard from './OtherUserCruisesCard';
import OtherUserMessagesCard from './OtherUserMessagesCard'

const OtherUserAccordion = ({otherUser}) => {

    return(
        <Accordion style={{backgroundColor:"rgba(255,255,255,.5"}}>
            <OtherUserMessagesCard eventKey='0' title={translate("Messages")} otherUser={otherUser} />
            <OtherUserCruisesCard eventKey='1' title={translate("Your common cruises")} otherUser={otherUser} />
        </Accordion>
    )
}
export default OtherUserAccordion;