import React, { useEffect } from "react";
import useFetch from "./hooks/useFetch";
import { StyledDivWithBorder } from "../../styledComponents/divs";
import Spinner from "../../Spinner";

const SignUp = () => {
  // console.log(process.env, "inside SignUp");
  const redirectUrl = process.env.NODE_ENV === "production"
        ? "https://cruisefriends.net/api/googleauth/signup"
        : "http://localhost:5000/api/googleauth/signup"
  const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  // console.log(client_id)
  // console.log(redirectUrl, client_id.slice(0,5), '...', client_id.slice(12,17))
  const { handleGoogle, loading, error } = useFetch(redirectUrl);
  
  useEffect(() => {
    if (window.google) {
      window.google.accounts.id.initialize({client_id, callback: handleGoogle,});
      window.google.accounts.id.renderButton(document.getElementById("signUpDiv"), {
        theme: "filled_black", text: "continue_with", shape: "pill",});
    }
  }, [handleGoogle]);

  return (
    <StyledDivWithBorder>
      <h3>Sign up with Google</h3>
      <Spinner/>
      
      <main style={{display: "flex", justifyContent: "center",flexDirection: "column",alignItems: "center",}}>
        {error && <p style={{ color: "red" }}>{error}</p>}
        {loading ? (<div>Loading....</div>) 
        : (<div id="signUpDiv" data-text="signup_with"></div>)}
      </main>
      <footer></footer>
    </StyledDivWithBorder>
  );
};

export default SignUp;
