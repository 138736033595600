import React, {useState, useEffect} from "react";
import {Row, Col, Spinner} from 'react-bootstrap';
import { translate } from "../../components/Language/Translate";
import { StyledDivWithBorder } from "../../components/styledComponents/divs";
import { simpleRequest } from "../simpleRequest";
// import TourSchema from "./TourSchema";
import FormRowFields from "../FormRows/FormRowFields";
import { useSelector } from "react-redux";
// import { getStringTemplate, getStringTour, getTemplates, templateKeys } from "./helpers";
// import TemplateList from "./TemplateList";
import TourEscortSchema from "./TourEscortSchema";

const TourEscortNew = ({action, setTourEscorts}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState({});
    const [fieldSettings, setFieldSettings] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    const [serverError, setServerError] = useState('');
    const currentCruise = useSelector(st => st.currentCruise);
    const currentUser = useSelector(st => st.currentUser);

    useEffect(() => {

        const initialData = () => {
            const _fields = new TourEscortSchema(currentUser).fields;
            const _vars = {};
            Object.keys(_fields).forEach(k => _vars[k] = _fields[k].initialValue || "");
            setVariables({..._vars});
            setFieldSettings({..._fields});
        }
        const fetch = async() => {
            initialData();
            setIsLoading(false);
        }
        if(isLoading)fetch()
    }, [isLoading, action, currentCruise, currentUser]);

    if(isLoading)return <Spinner/>

    const handleSubmit = async () => {
        const {companyHandle} = currentCruise;
        const data = {...variables, companyHandle};
        const request = {url:"tourescorts", method:"post", data};
        const tourEscort = (await simpleRequest(request)).data?.tourEscort;
        console.log({companyHandle, data, tourEscort, request});
        setTourEscorts(te => [...te, tourEscort])
        
    };

    const rowSet = {
        lg: [[3,3,6], [12], [12]], 
        sm: null,
    }

    const props = {
        fieldSettings, 
        variables, 
        setVariables,
        fieldErrors, 
        setFieldErrors, 
        serverError,
        setServerError,
        rowSet
      };

    //   // console.log({props});

    return(
        <StyledDivWithBorder>
            <h4>{translate("Add Account")} </h4>
            {/* <TemplateList templates={templates} handleClick={handleClick} setTemplates={setTemplates} /> */}
            <FormRowFields props= {props}/>
            <Row>
                <Col>
                    <button className="btn btn-info" onClick={handleSubmit}>{translate("Submit")}</button>
                </Col>
                
            </Row>
        </StyledDivWithBorder>
    );

};
export default TourEscortNew;