
import React from "react";
import {translate} from "../Language/Translate"
import {Card, Accordion, Button} from 'react-bootstrap';
import AuthorList from "./AuthorList";
import ArticleArea from "./ArticleArea";
import {BlackWhite} from '../styledComponents/colors';
import CruiseBackToCruiseButton from "../CruiseBackToCruiseButton";
import KeywordArea from "./KeywordArea";


const Editorials = () => {
 
    return(
      <div className="text-center">
        <CruiseBackToCruiseButton/>
        <Accordion className="text-center" style={{backgroundColor: "rgba(255,255,255,.5"}}>
            <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey={"1"}>
                    <BlackWhite>{translate("Authors")}</BlackWhite>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={"1"}>
                    <Card.Body>
                      <AuthorList/>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey={"2"}>
                    <BlackWhite>{translate("Articles")}</BlackWhite>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={"2"}>
                    <Card.Body>
                      <ArticleArea/>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey={"3"}>
                    <BlackWhite>{translate("Keywords")}</BlackWhite>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={"3"}>
                    <Card.Body>
                      <KeywordArea />
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
        </div>

    )
}
export default Editorials;