import React, {useEffect,useState} from 'react';
import {useParams} from "react-router-dom";
import ReactFormFields from '../ReactFormFields';
import ProposalsSchema from './ProposalsSchema';
import { ServerErrorArea } from '../FormMessageArea';
import Form from 'react-bootstrap/Form'
import Button from "react-bootstrap/Button";
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux'
import { simpleRequest } from '../simpleRequest';
import Spinner from '../../components/Spinner';

const ProposalsEditForm = () => {
    const [variables, setVariables] = useState();
    const [constants, setConstants] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState('');
    const [serverError, setServerError] = useState('');
    const [success, setSuccess] = useState(false);
    const destinationId = +useParams().destinationId;
    const history = useHistory();
    const currentDestination = useSelector(st => st.currentDestination);
    const {id} = useParams();

    useEffect(()=>{
        const fetch = async () =>{
            const consts = {fields: new ProposalsSchema(destinationId)};
            consts.events = {setVariables, setErrors, setServerError, setSuccess}
            const vars = {fields:{}};
            const currentProposal = currentDestination.proposals.find(p => p.id === +id)
            // console.log({currentDestination, currentProposal, id})
            Object.keys(consts.fields).forEach(k => {
                // console.log({k})
                vars.fields[k] = currentProposal[k]
            });
            (() => setConstants(consts))();
            (() => setVariables({fields: {...vars.fields}, errors, serverError}))();
            (() => setIsLoading(false))();
        }
        // console.log({isLoading})
        if(isLoading)fetch()
    }, [isLoading, setVariables, setErrors, setServerError, setSuccess, 
         currentDestination, destinationId, errors, serverError, id])
    
    if(!constants || !variables || !variables.fields)return <Spinner/>
    // console.log({constants, variables, isLoading});
    
    const handleSubmit = async(e) => {
        e.preventDefault();
        await simpleRequest({url:`proposals/${id}`, method:"patch", data:variables.fields})
        history.push(`/destination/details/${currentDestination.id}`)
    };

    return (
        <div>
          <h4>Edit Proposal</h4>
          <Form onSubmit = {e => handleSubmit(e,constants, variables)}>
            <ServerErrorArea constants={constants} variables = {variables}/>
            {!success && <ReactFormFields constants={constants} variables = {variables}/>}
            {!success && <Button block size="lg" type="submit" >Update</Button>}
        </Form>
        </div>
    );
};

export default ProposalsEditForm;