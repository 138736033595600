export const words = {
    'Accept Invitation' : {deDE : 'Einladung annehmen'},
    'actual' : {deDE : 'aktuell'},
    'Actual in fleet' : {deDE : 'Aktuell in der Flotte'},
    'Actual in Fleet' : {deDE : 'Aktuell in der Flotte'},
    'Add' : {deDE : 'Hinzufügen'},
    'Add / Remove Group Members' : {deDE : 'Hinzufügen / Entfernen von Gruppenmitgliedern'},
    'Add Announcement' : {deDE : 'Ankündigung hinzufügen'},
    'Add Announcements' : {deDE : 'Ankündigungen hinzufügen'},
    'Add another Destination' : {deDE : 'Weiteres Ziel hinzufügen'},
    'Add Article' : {deDE : 'Artikel hinzufügen'},
    'Add Article Link' : {deDE : 'Artikel-Link hinzufügen'},
    'Add as Friend' : {deDE : 'Als Freund hinzufügen'},
    'Add Author' : {deDE : 'Autor hinzufügen'},
    'Add Comment' : {deDE : 'Kommentar hinzufügen'},
    'Add Company' : {deDE : 'Unternehmen hinzufügen'},
    'Add Crew' : {deDE : 'Crew hinzufügen'},
    'Add Cruise' : {deDE : 'Kreuzfahrt hinzufügen'},
    'Add day' : {deDE : 'Tag hinzufügen'},
    'Add Destination' : {deDE : 'Ziel hinzufügen'},
    'Add diary entry' : {deDE : 'Tagebucheintrag hinzufügen'},
    'Add Document' : {deDE : 'Dokument hinzufügen'},
    'Add Item' : {deDE : 'Eintrag hinzufügen'},
    'Add Link' : {deDE : 'Link hinzufügen'},
    'Add Logo' : {deDE : 'Logo hinzufügen'},
    'Add Medical Institute' : {deDE : 'Medizinische Einrichtung hinzufügen'},
    'Add new Cruise' : {deDE : 'Neue Kreuzfahrt hinzufügen'},
    'Add new Photo' : {deDE : 'Neues Foto hinzufügen'},
    'Add new photos' : {deDE : 'Fotos hinzufügen'},
    'Add New Vessel' : {deDE : 'Neues Schiff hinzufügen'},
    'Add Photo' : {deDE : 'Foto hinzufügen'},
    'Add Pinboard Item' : {deDE : 'Pinnwandbeitrag hinzufügen'},
    'Add Profile Photo' : {deDE : 'Fügen Sie ein Profilbild hinzu'},
    'Add proposal' : {deDE : 'Fügen Sie einen Vorschlag hinzu'},
    'Add Proposal' : {deDE : 'Vorschlag hinzufügen'},
    'Add suggestion' : {deDE : 'Fügen Sie einen Vorschlag hinzu'},
    'Add Vessel' : {deDE : 'Schiff hinzufügen'},
    'Add/Replace' : {deDE : 'Hinzufügen / Ersetzen'},
    'Add/Replace Company-Logo' : {deDE : 'Unternehmens-Logo hinzufügen  / entfernen'},
    'Add/Update Comment' : {deDE : 'Kommentar hinzufügen / ändern'},
    'Added to favourites' : {deDE : 'Zu Favoriten hinzugefügt'},
    'Address' : {deDE : 'Adresse'},
    'Admin Area' : {deDE : 'Admin Bereich'},
    'Advertise this Cruise' : {deDE : 'Diese Kreuzfahrt bewerben'},
    'Advertisement' : {deDE : 'Werbungsannonce'},
    'Advertisements' : {deDE : 'Werbungsannoncen'},
    'After this, you will find other hints, when you klick the "Help"-Button' : {deDE : 'Danach finden Sie weitere Hinweise, wenn Sie auf den Hilfe-Button klicken'},
    'all' : {deDE : 'alle'},
    'All services on this site are free for users.' : {deDE : 'Alle Dienste dieser Seite sind für Benutzer kostenlos.'},
    'All statements without guarantee' : {deDE : 'Alle Angaben ohne Gewähr'},
    'An unique name' : {deDE : 'Ein eindeutiger Name'},
    'An unique name for login, etc.' : {deDE : 'Ein Name zum Login, etc.'},
    'An unique Symbol for the Company' : {deDE : 'Ein eindeutiges Symbol des Unternehmens'},
    'And please confirm the changes with your password' : {deDE : 'Und bestätigen Sie bitte die Änderungen mit Ihrem Passwort'},
    'And please confirm the changes with your password.' : {deDE : 'Und bestätigen Sie bitte die Änderungen mit Ihrem Passwort.'},
    'Announcement' : {deDE : 'Ankündigung'},
    'Announcements' : {deDE : 'Ankündigungen'},
    'Announcements from the ship management to passengers, staff or crew can be made here.' : {deDE : 'Hier können Ankündingngen von der Schiffleitung an Passagiere, Staff oder Crew gemacht werden.'},
    'Anyone traveling with you can join a public group and participate in the chat.' : {deDE : 'Jeder, der mit Ihnen reist, kann einer öffentlichen Gruppe beitreten und an dem Chat teilnehmen.'},
    'applied at' : {deDE : 'angefragt am'},
    'Approve Request' : {deDE : 'Anfage annehmen'},
    'approved at' : {deDE : 'bestätigt am'},
    'Are you sure' : {deDE : 'Sind Sie sicher'},
    'Are you sure to delete this day' : {deDE : 'Möchten Sie diesen Tag wirklich löschen?'},
    'Are you sure to delete this diary entry' : {deDE : 'Sind Sie sicher, dass Sie diesen Eintrag löschen möchten'},
    'Are you sure to delete this item' : {deDE : 'Sind Sie sicher, dass Sie diesen Eintrag löschen möchten'},
    'Are you sure to leave this cruise?' : {deDE : 'Sind Sie sicher, dass Sie diese Kreuzfahrt verlassen wollen?'},
    'Are you sure?' : {deDE : 'Sind Sie sicher?'},
    'Arrival' : {deDE : 'Ankunft'},
    'Article' : {deDE : 'Artikel'},
    'Articles' : {deDE : 'Artikel'},
    'As a guest, you can look at the site in peace, but you cannot enter any vessels or cruises.' : {deDE : 'Als Gast können Sie sich die Seite in Ruhe anschauen, Sie können aber keine Schiffe oder Kreuzfahrten eingeben.'},
    'As a logged in user, you can now see passengers and crew members who have also registered on this trip.' : {deDE : 'Als eingeloggter Benutzer können Sie nun auch Passagiere und Besatzungsmitglieder sehen, die sich auch auf dieser Reise eingetragen haben.'},
    'As a registered user, you can create ships and trips, and see and contact your fellow travelers.' : {deDE : 'Als registrierter Benutzer können Sie Schiffe und Kreuzfahrten anlegen, und die Mitreisenden sehen und kontaktieren.'},
    'Attention' : {deDE : 'Achtung'},
    'Author' : {deDE : 'Autor'},
    'Authors' : {deDE : 'Autoren'},
    'Back' : {deDE : 'Zurück'},
    'Back to' : {deDE : 'Zurück zu'},
    'Back to Cruise' : {deDE : 'Zurück zur Kreuzfahrt'},
    'Back to Request' : {deDE : 'Zurück zur Anfrage'},
    'Beach' : {deDE : 'Strand'},
    'Beaches' : {deDE : 'Strände'},
    'because we also want to protect the data of other users' : {deDE : 'weil wir auch die Daten der anderen Benutzer schützen.'},
    'Best Results of' : {deDE : 'Beste Ergebnisse auf'},
    'Best Results on' : {deDE : 'Beste Ergebnisse auf '},
    'Best Results on Cruise Ships (last 14 days)' : {deDE : 'Beste Ergebnisse auf Kreuzfahrtschiffen'},
    'Biography' : {deDE : 'Biographie'},
    'Block' : {deDE : 'Blockieren'},
    'Body' : {deDE : 'Nachricht'},
    'Books' : {deDE : 'Bücher'},
    'But we have new members every day.' : {deDE : 'Aber wir haben jeden Tag neue Mitglieder.'},
    'By' : {deDE : 'Von'},
    'by' : {deDE : 'von'},
    'Cancel' : {deDE : 'Abbrechen'},
    'Cancel Request' : {deDE : 'Antrag zurückziehen'},
    'Cancelled' : {deDE : 'Gestrichen'},
    'Change Profile Photo' : {deDE : 'Profilfoto ändern'},
    'Change Selection' : {deDE : 'Auswahl ändern'},
    'Change/Delete Profile Photo' : {deDE : 'Profilfoto ändern / löschen'},
    'Claim' : {deDE : 'Melden'},
    'Claims' : {deDE : 'Beschwerden'},
    'Click on a travel destination to see information and suggestions from other people or to make suggestions yourself.' : {deDE : 'Klicken Sie auf ein Reiseziel, um Informationen und Vorschläge von anderen Usern zu sehen, oder um selbst Vorschläge zu machen.'},
    'Click on Crew / Staff or Guests and see who else is on this cruise.' : {deDE : 'Klicken Sie auf Crew / Staff oder Gäste und schauen Sie, wer sonst noch auf dieser Kreuzfahrt ist.'},
    'clicks' : {deDE : 'Klicks'},
    'Close' : {deDE : 'Schließen'},
    'Close Chat' : {deDE : 'Chat schließen'},
    'Close Diary' : {deDE : 'Tagebuch schließen'},
    'Close Diary Entry' : {deDE : 'Tagebucheintrag schließen'},
    'Close Formular' : {deDE : 'Formular schließen'},
    'Comment' : {deDE : 'Kommentar'},
    'Comments' : {deDE : 'Kommentare'},
    'comments' : {deDE : 'Kommentare'},
    'Common Best Results (last 14 days)' : {deDE : 'Beste Ergebnisse'},
    'Companies' : {deDE : 'Unternehmen'},
    'Company' : {deDE : 'Unternehmen'},
    'Company ' : {deDE : 'Unternehmen'},
    'Company Logo' : {deDE : 'Unternehmens-Logo'},
    'Confirm Email' : {deDE : 'Emailadresse bestätigen'},
    'Confirm Email ' : {deDE : 'Email bestätigen'},
    'Confirm Password' : {deDE : 'Passwort bestätigen'},
    'Confirm Password ' : {deDE : 'Passwort bestätigen'},
    'Confirmation Code' : {deDE : 'Bestätigungs Code'},
    'Confirmed as a new vessel' : {deDE : 'Als neues Schiff bestätigt'},
    'Content' : {deDE : 'Inhalt'},
    'Create new group' : {deDE : 'Neue Gruppe erstellen'},
    'created' : {deDE : 'erstellt'},
    'Created at' : {deDE : 'Erstellt'},
    'created at' : {deDE : 'erstellt'},
    'crew' : {deDE : 'Crew'},
    'Crew' : {deDE : 'Besatzung'},
    'Crew members must first be entered in the company before they can be selected here. From the navigation bar, go to Cruises - Companies, select the company, then Crew - Add Crew' : {deDE : 'Crewmitglieder müssen erst im Unternehmen eingetragen werden, bevor sie hier ausgewählt werden können. Gehen Sie in der Navigationsleiste zu Kreuzfahrten – Unternehmen, wählen Sie das Unternehmen aus und dann Crew – Crew hinzufügen.'},
    'Crew only' : {deDE : 'Nur für Crew'},
    'Cruise' : {deDE : 'Kreuzfahrt'},
    'Cruise Diary' : {deDE : 'Reisetagebuch'},
    'Cruise is sent.' : {deDE : 'Die Kreuzfahrt ist gesendet.'},
    'Cruisefriends.net is an innovative network for friends of cruising.' : {deDE : 'Cruisefriends.net ist ein innovatives Netzwerk für Freunde der Kreuzfahrt.'},
    'Cruisefriends: Restore Password' : {deDE : 'Cruisefriends: Wiederherstellen eines Passworts'},
    'Cruises' : {deDE : 'Kreuzfahrten'},
    'Cruises entered so far' : {deDE : 'Bisher eingegebene Kreuzfahrten'},
    'Currency Converter' : {deDE : 'Währungsrechner'},
    'Current Advertisements' : {deDE : 'Aktuelle Anzeigen'},
    'Current Cruise' : {deDE : 'Aktuelle Kreuzfahrt'},
    'Current Cruises' : {deDE : 'Aktuelle Kreuzfahrten'},
    'Date' : {deDE : 'Datum'},
    'Date and arrival time' : {deDE : 'Datum und Ankunftszeit'},
    'Date and Time (free text)' : {deDE : 'Datum und Zeit (Freitext)'},
    'Days and Destinations' : {deDE : 'Reisetage und Ziele'},
    'Dear' : {deDE : 'Sehr geehrter / Sehr geehrte'},
    'De-Block' : {deDE : 'Blockierung aufheben'},
    'Delete' : {deDE : 'Löschen'},
    'Delete Account' : {deDE : 'Benutzer löschen'},
    'Delete Album' : {deDE : 'Album löschen'},
    'Delete Announcement' : {deDE : 'Announcement löschen'},
    'Delete Article' : {deDE : 'Artikel löschen'},
    'Delete Author' : {deDE : 'Autor löschen'},
    'Delete Comment' : {deDE : 'Kommentar löschen'},
    'Delete Company' : {deDE : 'Unternehmen entfernen'},
    'Delete Cruise' : {deDE : 'Kreuzfahrt entfernen'},
    'Delete Destination' : {deDE : 'Ziel löschen'},
    'Delete Diary Entry' : {deDE : 'Tagebucheintrag löschen'},
    'Delete Friend Request' : {deDE : 'Freundschaftsanfrage löschen'},
    'Delete Item' : {deDE : 'Eintrag löschen'},
    'Delete Pinboard Item' : {deDE : 'Pinnwandeintrag löschen'},
    'Delete Profile' : {deDE : 'Profil löschen'},
    'Delete User Account' : {deDE : 'Benutzerkonto löschen'},
    'Delete Vessel' : {deDE : 'Schiff löschen'},
    'Dentist' : {deDE : 'Zahnarzt'},
    'Departure' : {deDE : 'Abfahrt'},
    'Description' : {deDE : 'Beschreibung'},
    'Deselect all' : {deDE : 'Alle abwählen'},
    'Destinations' : {deDE : 'Ziele'},
    'Details about' : {deDE : 'Details zu'},
    'Diary Entry' : {deDE : 'Tagebucheintrag'},
    'Discover Cruisefriends.net' : {deDE : 'Erkunden Sie Cruisefriends.net'},
    'Discover Cruisefriends.net as a guest' : {deDE : 'Cruisefriends.net als Gast entdecken'},
    'Display password' : {deDE : 'Passwort anzeigen'},
    'Displayed Name' : {deDE : 'Angezeigter Name'},
    'Doctor' : {deDE : 'Arzt'},
    'Document' : {deDE : 'Dokument'},
    'Document URL' : {deDE : 'Dokument URL'},
    'e. g.' : {deDE : 'z. B.'},
    'e.g.' : {deDE : 'z. B.'},
    'e.g. "Greece, MS Albatros", divided by comma.' : {deDE : 'z. B. "Griechenland, MS Albatros", kommagetrennt.'},
    'Edit' : {deDE : 'Bearbeiten'},
    'Edit Announcement' : {deDE : 'Announcement bearbeiten'},
    'Edit Article' : {deDE : 'Artikel bearbeiten'},
    'Edit Author' : {deDE : 'Autor bearbeiten'},
    'Edit Comment' : {deDE : 'Kommentar bearbeiten'},
    'Edit Company' : {deDE : 'Unternehmen bearbeiten'},
    'Edit Crew' : {deDE : 'Crew bearbeiten'},
    'Edit Cruise' : {deDE : 'Kreuzfahrt bearbeiten'},
    'Edit Destination' : {deDE : 'Ziel bearbeiten'},
    'Edit Diary Entry' : {deDE : 'Tagebucheintrag bearbeiten'},
    'Edit Group' : {deDE : 'Gruppe bearbeiten'},
    'Edit Link' : {deDE : 'Link bearbeiten'},
    'Edit Pinboard Item' : {deDE : 'Pinnwandeintrag bearbeiten'},
    'Edit Profile' : {deDE : 'Profil bearbeiten'},
    'Edit Vessel' : {deDE : 'Schiff bearbeiten'},
    'Editorials' : {deDE : 'Editorial'},
    'Either the code is invalid or deprecated' : {deDE : 'Der Code ist entweder ungültig oder veraltet'},
    'Email Confirmation' : {deDE : 'Bestätigung der Emailadresse'},
    'Email Confirmation successful' : {deDE : 'Bestätigung der Emailadresse erfolgreich'},
    'Email sucessfully sent. Please look for your emails.' : {deDE : 'Email wurde erfolgreich versandt. Bitte schauen Sie nach Ihren Emails.'},
    'Email was sent to' : {deDE : 'Die Email wurde gesendet an'},
    'Email-Confirmation successful' : {deDE : 'Die Bestätigung der Emailadresse war erfolgreich'},
    'End at' : {deDE : 'Bis'},
    'End Port' : {deDE : 'Endhafen'},
    'Enter new album' : {deDE : 'Geben Sie den Namen für eine neues Album ein'},
    'Examples of private groups are travel groups, friends, in the crew also e.g. departments.' : {deDE : 'Beispiele für private Gruppen sind Reisegruppen, Freunde, in der Besatzung auch z. B. Departments.'},
    'Examples of public groups are single travellers, sports enthusiasts, skat players.' : {deDE : 'Beispiele für öffentliche Gruppen sind Alleinreisende, Sportbegeisterte, Skatspieler.'},
    'exchange messages with your friends' : {deDE : 'Nachrichten mit Ihren Freunden austauschen'},
    'Feel free to share interesting links or photos with your fellow travelers.' : {deDE : 'Fühlen Sie sich frei, interessante Links oder Fotos mit Ihren Mitreisenden zu teilen.'},
    'Feel free to share interesting links with your fellow travelers.' : {deDE : 'Sie können hier interessante Links mit Ihren Mitreisenden teilen.'},
    'Find friends you met on a cruise' : {deDE : 'Finden Sie Freunde, die Sie auf einer Kreuzfahrt kennengelernt haben'},
    'Find travel partners' : {deDE : 'Reisepartner finden'},
    'First Name' : {deDE : 'Vorname'},
    'First Row' : {deDE : 'Erste Zeile'},
    'For reasons of data protection, this is only possible for logged-in users' : {deDE : 'Aus Gründen des Datenschutzes nur für eingeloggte Benutzer möglich'},
    'For reasons of data protection, you cannot see the fellow travelers on a cruise.' : {deDE : 'Aus Gründen des Datenschutzes können Sie die Mitreisenden einer Kreuzfahrt nicht sehen.'},
    'For this field use only lowercased letters' : {deDE : 'Für dieses Feld sind nur Kleinbuchstaben gültig'},
    'Forex Converter' : {deDE : 'Währungsrechner'},
    'Forgot Password?' : {deDE : 'Passwort vergessen?'},
    'form chat groups with your fellow travelers' : {deDE : 'Chat-Gruppen bilden mit Ihren Mitreisenden'},
    'Friend Requests for you' : {deDE : 'Freundschaftsanfragen an Sie'},
    'Friends' : {deDE : 'Freunde'},
    'From' : {deDE : 'Von'},
    'from' : {deDE : 'vom'},
    'Further Links' : {deDE : 'Weitere Links'},
    'Further Public Groups' : {deDE : 'Weitere öffentliche Gruppen'},
    'Further public Groups' : {deDE : 'Weitere öffentliche Gruppen'},
    'Further Vessels' : {deDE : 'Weitere Schiffe'},
    'Further vessels' : {deDE : 'Weitere Schiffe'},
    'Future' : {deDE : 'Zukünftige'},
    'Future Cruise' : {deDE : 'Zukünftige Kreuzfahrt'},
    'Future Cruises' : {deDE : 'Zukünftige Kreuzfahrten'},
    'Geolocation not available' : {deDE : 'Geolocation nicht verfügbar'},
    'Get a link (only the link will be stored)' : {deDE : 'Von einer URL (nur der Link wird gespeichert)'},
    'Get actual position' : {deDE : 'Aktuelle Position ermitteln'},
    'Get Destination' : {deDE : 'Ziel finden'},
    'Get from local file' : {deDE : 'Von lokaler Datei '},
    'Get from URL' : {deDE : 'Von einer URL'},
    'Get from URL (data will be stored)' : {deDE : 'Von einer URL (die Daten werden gespeichert)'},
    'Get Geolocation from address' : {deDE : 'Geolocation von der Adresse'},
    'Go on' : {deDE : 'Weiter'},
    'Golf Course' : {deDE : 'Golfplatz'},
    'Golf Courses' : {deDE : 'Golfplätze'},
    'Group Members' : {deDE : 'Mitglieder'},
    'Groups' : {deDE : 'Gruppen'},
    'Groups is a new feature that is a work in progress and therefore not available yet.' : {deDE : 'Gruppen ist eine neue Funktion, die in Arbeit und daher noch nicht verfügbar ist.'},
    'guest' : {deDE : 'Passagier'},
    'Guest' : {deDE : 'Passagier'},
    'Guests' : {deDE : 'Passagiere'},
    'Gynecology' : {deDE : 'Gynäkologie'},
    'Have not registered yet, but want to do so now?' : {deDE : 'Sie haben sich noch nicht registriert, möchten dies aber jetzt tun?'},
    'Have you already registered and would like to log in?' : {deDE : 'Sie haben sich schon registriert, und möchten sich einloggen?'},
    'Help' : {deDE : 'Hilfe'},
    'Here are some informations about the cruise' : {deDE : 'Hier gibt es einige Informationen zur Kreuzfahrt'},
    'Here is some information about the cruise.' : {deDE : 'Hier finden Sie einige Informationen zur Kreuzfahrt.'},
    'Here the company can present its captains, officers, managers, artists, etc. These setcards are then available for the cruises.' : {deDE : 'Hier kann das Unternehmen seine Kapitäne, Offiziere, Manager, Künstler usw. vorstellen. Diese Setcards stehen dann für die Kreuzfahrten zur Verfügung.'},
    'Here the ship management can introduce themselves: captain, cruise director and the most important officers.' : {deDE : 'Hier kann sich die Schiffsleitung vorstellen: Kapitän, Kreuzfahrtdirektor und die wichtigsten Offiziere.'},
    'Here the ship management can present itself: Captain, Cruise Director, Officers' : {deDE : 'Hier kann sich die Schiffsleitung vorstellen: Kapitän, Kreuzfahrtdirektor, Manager'},
    'Here you can find your friends from your cruises, send messages in order to meet them on new cruises' : {deDE : 'Hier können Sie Ihre Freunde von Ihren Kreuzfahrten wiederfinden, ihnen Nachrichten schreiben und sich auf neuen Kreuzfahrten mit ihnen verabreden'},
    'Here you can publish texts or documents.' : {deDE : 'Hier können Sie Texte oder Dokumente veröffentlichen.'},
    'Here you can publish texts, photos or documents.' : {deDE : 'Hier können Sie Texte, Fotos oder Dokumente veröffentlichen.'},
    'Hide Comments' : {deDE : 'Kommentare verbergen'},
    'Hint' : {deDE : 'Hinweis'},
    'Home' : {deDE : 'Start'},
    'Hospital basic' : {deDE : 'Krankenhaus - Grundversorgung'},
    'Hospital maximal' : {deDE : 'Krankenhaus - Maximalversorgung'},
    'Hospital regular' : {deDE : 'Krankenhaus - Regelversorgung'},
    'How it works' : {deDE : 'So funktioniert`s'},
    'I confirm to respect these regulations.' : {deDE : 'Hiermit versichere ich, dass ich diese Regeln beachte.'},
    'I only want to have a look' : {deDE : 'Ich möchte mich nur umschauen'},
    'I want to publish posts here' : {deDE : 'Ich möchte hier Beiträge veröffentlichen'},
    'I would like to register a cruise for this ship.' : {deDE : 'Ich möchte eine Kreuzfahrt für das Schiff eintragen.'},
    'If the cruise is not yet in the list, you can create it yourself (this is also possible only for logged-in users).' : {deDE : 'Wenn die Kreuzfahrt noch nicht in der Liste steht, können Sie sie selbst anlegen (dies ist auch nur für eingeloggte Benutzer möglich).'},
    'If the ship is not yet in the list, you can create it yourself (this is only possible for logged-in users).' : {deDE : 'Wenn das Schiff noch nicht in der Liste ist, können Sie es selbst anlegen (dies ist nur für eingeloggte Benutzer möglich).'},
    'If there are articles in our travel magazine related to your ship or the destinations, you will find them here.' : {deDE : 'Wenn es in unserem Reisemagazin passende Artikel zu Ihrem Schiff oder zu den Zielen gibt, finden Sie sie hier.'},
    'If there is no list, just can enter the destination an press "Submit". Then the target will be inserted by an administrator.' : {deDE : 'Wenn Sie keine Liste sehen, können Sie einfach das Ziel eingeben und auf Senden klicken. Das Ziel wird dann von einem Administrator eingearbeitet.'},
    'If you are already registered and have confirmed the email address, please select "To login".' : {deDE : 'Wenn Sie schon registriert sind und die Emailadresse bestätigt haben, wählen Sie bitte "Zum Login".'},
    'If you change this value, you will be logged out and must confirm your email adress again.' : {deDE : 'Wenn Sie diesen Wert ändern, werden Sie ausgeloggt und die Emailadresse muss neu bestätigt werden.'},
    'If you change to "user", you cannot undo this.' : {deDE : 'Wenn Sie diese Wert auf "user" setzen, können Sie dies nicht rückgängig machen.'},
    'If you click Submit you can also find this cruise in your personal space' : {deDE : 'Wenn Sie auf Senden klicken, können Sie diese Kreuzfahrt auch in Ihrem persönlichen Bereich finden'},
    'If you did not send the request, please ignore this email.' : {deDE : 'Falls Sie diese Anfrage nicht gesendet haben, ignorieren Sie bitte diese Email.'},
    'If you do not have admin rights, please use the pinboard instead.' : {deDE : 'Wenn Sie keine Adminrechte haben, nutzen sie bitte stattdessen die Pinnwand.'},
    'If you have sent more than one request, be sure to use the latest link.' : {deDE : 'Wenn Sie mehr als eine Anfrage gestellt haben, benutzen Sie bitte die letzte Email, die wir Ihnen gesendet haben.'},
    'If you know the company and it is in the list, select it. Otherwise leave it at this value.' : {deDE : 'Wenn Sie das Unternehmen kennen, und es in der Liste ist, wählen Sie es aus. Ansonsten belassen Sie es bei diesem Wert.'},
    'If you participiate on this cruise, please fill up this form' : {deDE : 'Bitte füllen Sie das Formular aus, wenn Sie an dieser Kreuzfahrt teilnehmen'},
    'If you then click on your fellow travelers, you can write them a message directly.' : {deDE : 'Wenn Sie Ihre Mitreisenden dann anklicken, können Sie ihnen direkt ein Nachricht schreiben.'},
    'If you use firefox this game may not work.' : {deDE : 'Wenn Sie Firefox benutzen, wird dieses Spiel möglicherweise nicht funktionieren.'},
    'If you use Firefox, this game will not work.' : {deDE : 'Wenn Sie Firefox benutzen, wird dieses Spiel nicht funktionieren.'},
    'If you want to upload media, please be aware of Copyrights and Personal Rights' : {deDE : 'Wenn Sie Medien hochladen möchten, beachten Sie bitte die Urheber- und Persönlichkeitsrechte'},
    'Image rights' : {deDE : 'Bildrechte'},
    'Immigration' : {deDE : 'Einreise'},
    'In case of a future cruise, you will also be able to see who of your acquaintances will be with you again.' : {deDE : 'Bei einer zukünftigen Kreuzfahrt können Sie außerdem sehen, wer von Ihren Bekannten wieder dabei ist.'},
    'In order to correct order use the Format' : {deDE : 'Um die Reihenfolge richtig anzuzeigen, benutzen Sie bitte das Format'},
    'In order to see more details, please log in or register' : {deDE : 'Um weitere Details zu sehen, bitte loggen Sie sich ein, oder registrieren Sie sich'},
    'In order to use all options, please fill out' : {deDE : 'Bitte ergänzen Sie die Angaben, um alle Optionen nutzen zu können'},
    'In order to use the diary function or to enter destinations, you must be connected to this trip.' : {deDE : 'Um die Tagebuchfunktion zu benutzen oder um Ziele einzugeben, müssen Sie sich mit dieser Reise verbinden.'},
    'In this case, we ask you to give us the travel dates as precisely as possible.' : {deDE : 'In dem Fall bitten wir Sie, uns die Reisedaten möglichst genau mitzuteilen.'},
    'In this case, your recent password is still valid.' : {deDE : 'In diesem Fall ist Ihr Password weiterhin gültig.'},
    'Insert a link (only the link will be stored)' : {deDE : 'Link einfügen (nur dieser Link wird gespeichert)'},
    'Insert Cruise' : {deDE : 'Kreuzfahrt eingeben'},
    'Invalid code/username' : {deDE : 'Ungültiger Benutzername/Passwort'},
    'Invalid date string' : {deDE : 'Ungültiges Datumsformat'},
    'Invalid email adress' : {deDE : 'Ungültige Email-Adresse'},
    'is loading' : {deDE : 'Lade Daten'},
    'It is completely free for users.' : {deDE : 'Für Benutzer ist es völlig kostenlos.'},
    'Its purpose is to meet again friends you met on your cruise and to be able to contact them.' : {deDE : 'Es dient dazu, Freunde, die Sie auf Ihrer Kreuzfahrt kennengelernt haben, wieder zu treffen, und mit ihnen Kontakt aufnehmen zu können.'},
    'Join Cruise' : {deDE : 'Verbinden Sie sich mit dieser Kreuzfahrt'},
    'join existing chat groups' : {deDE : 'bestehenden Chat-Gruppen beitreten'},
    'Join Group' : {deDE : 'Dieser Gruppe beitreten'},
    'Join this Cruise' : {deDE : 'Mit dieser Kreuzfahrt verbinden'},
    'Join this cruise' : {deDE : 'Verbinden Sie sich mit dieser Kreuzfahrt'},
    'keywords' : {deDE : 'Stichworte'},
    'Keywords' : {deDE : 'Stichwortverzeichnis'},
    'Klick the link inside the new email' : {deDE : 'Klicken Sie den Link in der neuen Email'},
    'Language' : {deDE : 'Sprache'},
    'Last entry' : {deDE : 'Letzer Eintrag'},
    'Last login' : {deDE : 'Letzer Login'},
    'Last Name' : {deDE : 'Nachname'},
    'Last updated' : {deDE : 'Letzte Aktualisierung'},
    'Leave this Cruise' : {deDE : 'Diese Kreuzfahrt verlassen'},
    'Legal Notice' : {deDE : 'Impressum'},
    'Let us start by looking for a trip or entering a new trip.' : {deDE : 'Lassen Sie uns starten, indem wir eine Kreuzfahrt heraussuchen oder einen neue Kreuzfahrt eingeben.'},
    'Life Time (days)' : {deDE : 'Dauer (Tage)'},
    'Like private groups, but you can be invited by each group member.' : {deDE : 'Wie private Gruppen, doch Sie können von jedem Mitglied der Gruppe eingeladen werden.'},
    'Like this proposal. Then you can add a comment.' : {deDE : 'Geben Sie diesem Vorschlag einen Like. Sie können dann einen Kommentar schreiben.'},
    'Link was copied to clipboard' : {deDE : 'Link kopiert in Zwischenablage'},
    'Load Map' : {deDE : 'Karte laden'},
    'Location' : {deDE : 'Ort'},
    'Locations' : {deDE : 'Standorte'},
    'Login' : {deDE : 'Anmelden'},
    'Login failed, because your email is not confirmed' : {deDE : 'Das Login ist fehlgeschlagen, weil Ihre Emailadresse noch nicht bestätigt ist'},
    'Login successful' : {deDE : 'Das Login war erfolgreich.'},
    'Map' : {deDE : 'Karte'},
    'Matching articles from the travel magazine' : {deDE : 'Passende Artikel aus dem Reisemagazin'},
    'Maximum number of characters' : {deDE : 'Höchstanzahl Zeichen'},
    'Medical Institute' : {deDE : 'Medizinische Einrichtung'},
    'Medical Institutes' : {deDE : 'Medizinische Einrichtungen'},
    'Meet friends again' : {deDE : 'Freunde wiederfinden'},
    'Meet friends of cruises again' : {deDE : 'Freunde von Kreuzfahrten wiedertreffen'},
    'Meeting Point Cruise' : {deDE : 'Treffpunkt Kreuzfahrt'},
    'Members of' : {deDE : 'Mitglieder von'},
    'Membership is completely free and there are no obligations.' : {deDE : 'Die Mitgliedschaft ist komplett kostenlos und es entstehen keine Verpflichtungen.'},
    'Message' : {deDE : 'Nachricht'},
    'Messages' : {deDE : 'Nachrichten'},
    'Minimum number of characters' : {deDE : 'Mindestanzahl Zeichen'},
    'Movie' : {deDE : 'Film'},
    'Movies' : {deDE : 'Filme'},
    'Museum' : {deDE : 'Museum'},
    'Museums' : {deDE : 'Museen'},
    'Music' : {deDE : 'Musik'},
    'Must be equal to an existing username.' : {deDE : 'Muß ein existierender Benutzername sein.'},
    'Must not contain' : {deDE : 'Darf nicht enhalten'},
    'New best or fastest game' : {deDE : 'Neues schnellstes oder bestes Ergebnis'},
    'New Cruises' : {deDE : 'Neue Kreuzfahrten'},
    'New password was set successfully.' : {deDE : 'Das neue Passwort ist nun gültig.'},
    'New Profile Photos' : {deDE : 'Neue Profilfotos'},
    'New Vessels' : {deDE : 'Neue Schiffe'},
    'No' : {deDE : 'Nein'},
    'No albums available' : {deDE : 'Keine Alben vorhanden'},
    'No Comments available' : {deDE : 'Keine Kommentare verfügbar'},
    'No data available' : {deDE : 'Keine Daten verfügbar'},
    'No destination entered yet. Please select \'Add destination\'' : {deDE : 'Noch kein Ziel eingetragen. Bitte wählen Sie `Ziel hinzufügen`'},
    'No destination entered yet. You must be joined to this cruise to edit the destination.' : {deDE : 'Bisher ist kein Ziel eingetragen worden. Sie müssen sich mit der Kreuzfahrt verbinden, um ein Ziel eizugeben.'},
    'No destination entered yet. You must be logged in and joined to this cruise to edit the destination.' : {deDE : 'Noch kein Ziel eingegeben. Sie müssen angemeldet und an dieser Kreuzfahrt teilnehmen, um das Ziel zu bearbeiten.'},
    'No Email ? - Please click here' : {deDE : 'Keine Email bekommen ? - Bitte klicken Sie hier'},
    'No groups have been created yet.' : {deDE : 'Es sind bisher keine Gruppen angelegt worden.'},
    'No items' : {deDE : 'Keine Einträge'},
    'No user. Registration failed' : {deDE : 'Kein Benutzer. Die Registrierung ist fehlgeschlagen.'},
    'No voyages have yet been entered for this ship' : {deDE : 'Für dieses Schiff sind noch keine Kreuzfahrten eingeben worden'},
    'Not found' : {deDE : 'Nicht gefunden'},
    'Not valid. The number must be at least' : {deDE : 'Ungültige Eingabe. Mindestens:'},
    'Not visible for other users.' : {deDE : 'Nicht sichtbar für andere Benutzer.'},
    'Note' : {deDE : 'Bemerkung'},
    'Now select the cruise.' : {deDE : 'Wählen Sie nun die Kreuzfahrt aus.'},
    'Now select the ship that you have ridden or on which you will travel.' : {deDE : 'Wählen Sie nun das Schiff aus, auf dem Sie gefahren sind, oder auf dem Sie reisen werden.'},
    'Now select the year.' : {deDE : 'Wählen Sie nun das Jahr aus.'},
    'Oculist' : {deDE : 'Augenarzt'},
    'of 5 points' : {deDE : 'von 5 Punkten'},
    'on' : {deDE : 'am'},
    'On the cruise itself, through this platform you can' : {deDE : 'Auf der Kreuzfahrt selbst können Sie'},
    'On the cruise itself, through this platform you can exchange messages with the friends, form groups, join groups, share pictures and interesting texts, see recommendations for restaurants or insider tips in the ports or write recommendations yourself.' : {deDE : 'Auf der Kreuzfahrt selbst können Sie über diese Plattform mit den Freunden Nachrichten austauschen, Gruppen bilden, Gruppen beitreten, Bilder und interessante Texte mit anderen teilen, Empfehlungen für Restaurants oder Geheimtipps in den Häfen sehen oder selbst Empfehlungen schreiben.'},
    'Only for crew and staff' : {deDE : 'Nur für Crew und Staff'},
    'Only for logged in users.' : {deDE : 'Nur für eingeloggte Benutzer'},
    'Open Chat' : {deDE : 'Chat öffnen'},
    'Open Cruise' : {deDE : 'Kreuzfahrt öffnen'},
    'Open Destinations' : {deDE : 'Ziele'},
    'Open Document' : {deDE : 'Dokument öffnen'},
    'Open Map' : {deDE : 'Karte öffnen'},
    'Optional Message' : {deDE : 'Optionale Nachricht'},
    'Optional Text' : {deDE : 'Optionaler Textt'},
    'Optional, e.g. cruise number, if known' : {deDE : 'Optional, z. B. Reisenummer, falls bekannt'},
    'Optional, eg: Cruise through the Baltic See' : {deDE : 'Optional, z. B. Ostseereise'},
    'Or do you want to know who you might will meet on your next cruise?' : {deDE : 'Oder möchten Sie wissen, wen Sie womöglich auf Ihrer nächsten Kreuzfahrt treffen werden?'},
    'or write the ship\'s name in this field' : {deDE : 'oder schreiben Sie den Schiffsnamen in dieses Feld'},
    'Order' : {deDE : 'Sortierung'},
    'Other Company' : {deDE : 'Anderes Unternehmen'},
    'Other Fellow Travelers' : {deDE : 'Andere Mitreisende'},
    'Other Follow Travelers' : {deDE : 'Andere Mitreisende'},
    'Other Proposal' : {deDE : 'Anderer Vorschlag'},
    'Other Proposals' : {deDE : 'Andere Vorschläge'},
    'Otherwise ignore it' : {deDE : 'Wenn, nicht, ignorieren Sie es'},
    'Passenger' : {deDE : 'Passagier'},
    'passenger' : {deDE : 'Passagier'},
    'Passengers' : {deDE : 'Passagiere'},
    'passengers' : {deDE : 'Passagiere'},
    'Password' : {deDE : 'Passwort'},
    'Past Cruise' : {deDE : 'Frühere Kreuzfahrt'},
    'Past Cruises' : {deDE : 'Frühere Kreuzfahrten'},
    'Pediatric' : {deDE : 'Kindermedizin'},
    'Pharmacy' : {deDE : 'Apotheke'},
    'Phone' : {deDE : 'Telefon'},
    'Photo' : {deDE : 'Foto'},
    'Photo Albums' : {deDE : 'Fotoalben'},
    'Photographer' : {deDE : 'Fotograf'},
    'Photographer (copyright)' : {deDE : 'Photograph (copyright)'},
    'Photos' : {deDE : 'Fotos'},
    'Pinboard' : {deDE : 'Pinnwand'},
    'Pinboard and Photos' : {deDE : 'Pinnwand und Fotos'},
    'Planned and current cruises' : {deDE : 'Geplante und aktuelle Reisen'},
    'Planned Cruise' : {deDE : 'Geplante Kreuzfahrt'},
    'Planned Cruises' : {deDE : 'Geplante Kreuzfahrten'},
    'Please click here to add a cruise.' : {deDE : 'Bitte klicken Sie hier, um eine Kreuzfahrt einzugeben.'},
    'Please click here to enter a cruise.' : {deDE : 'Bitte klicken Sie hier, um eine Kreuzfahrt einzugeben.'},
    'Please click the button' : {deDE : 'Bitte klicken Sie die Schaltfläche'},
    'Please confirm your email address now.' : {deDE : 'Bitte bestätigen Sie nun Ihre Emailadresse.'},
    'Please insert a port or "unknown".' : {deDE : 'Bitte tragen Sie einen Hafen ein oder "unbekannt".'},
    'Please insert an URL' : {deDE : 'Bitte fügen Sie eine URL ein'},
    'Please insert the date with the format yyyy-mm-dd.' : {deDE : 'Bitte tragen Sie das Datum im folgenden Format ein: jjjj-mm-tt, zum Beispiel "2021-09-10".'},
    'Please insert username or email address' : {deDE : 'Bitte geben Sie den Benutzernamen oder die Emailadresse ein.'},
    'Please click the following link to set a new password.' : {deDE : 'Bitte klicken Sie auf den folgenden Link, um ein neues Passwort zu setzen.'},
    'Please click the link now' : {deDE : 'Klicken Sie nun auf den Link'},
    'Please open this email and click the link to set a new password' : {deDE : 'Bitte öffnen Sie diese Email und klicken Sie auf den Link, um ein neues Passwort zu vergeben.'},
    'Please open this email and klick the link inside to confirm this email address.' : {deDE : 'Bitte öffnen Sie diese Email, um die Emailadresse zu bestätigen.'},
    'Please open this email and klick the link inside to set a new password.' : {deDE : 'Bitte öffnen Sie diese Email, um ein neues Passwort zu setzen.'},
    'Please open this email click the link inside in order to set a new password.' : {deDE : 'Bitte öffnen Sie diese Email und klicken Sie auf den Link, um ein neues Passwort zu vergeben.'},
    'Please open your emails and klick the link inside the mail from Cruisefriends.de' : {deDE : 'Bitte öffnen Sie Ihr Emailkonto und klicken Sie in der Mail von Cruisefriends.net auf den Link'},
    'Please open your mail inbox and follow the instruction to confirm your new email address.' : {deDE : 'Bitte öffnen sie Ihre Emailprogramm und folgen Sie den Ablauf, Ihre neue Emailadresse zu bestätigen.'},
    'Please provide as detailed information as possible about your cruise.' : {deDE : 'Bitte gegen Sie möglichst genaue Informationen zu Ihrer Kreuzfahrt ein.'},
    'Please respect the copyright and personal rights.' : {deDE : 'Bitte wahren Sie dabei die Urheber- und Persönlichkeitsrechte.'},
    'Please select' : {deDE : 'Bitte wählen Sie aus'},
    'Please select a category.' : {deDE : 'Bitte wählen Sie eine Kategorie aus.'},
    'Please select a cruise in the list or click the button to create a new cruise.' : {deDE : 'Bitte wählen Sie eine Kreuzfahrt aus, oder klicken Sie auf den Button, um eine neue Kreuzfahrt einzugeben.'},
    'Please select a cruise or click the button at the bottom to enter a new cruise' : {deDE : 'Bitte wählen Sie eine Kreuzfahrt aus, oder klicken Sie auf den Button ganz unten, um eine neue Kreuzfahrt einzugeben'},
    'Please select a file' : {deDE : 'Bitte wählen Sie eine Datei aus'},
    'Please select a ship or click the button below to create a new vessel.' : {deDE : 'Bitte wählen Sie das Schiff aus, oder klicken Sie auf den Button unten, um ein neues Schiff anzulegen.'},
    'Please select a vessel' : {deDE : 'Bitte wählen Sie ein Schiff aus'},
    'Please select a vessel or click the button to create a new vessel.' : {deDE : 'Bitte wählen Sie ein Schiff aus oder klicken Sie auf den Button, um ein neues Schiff anzulegen.'},
    'Please select the cruise' : {deDE : 'Wählen Sie nun die Kreuzfahrt aus.'},
    'Please select the cruises that should be displayed on the map' : {deDE : 'Bitte wählen Sie die Kreuzfahrten aus, die in der Karte dargestellt werden sollen'},
    'Please select the ship for your cruise.' : {deDE : 'Bitte wählen Sie das Schiff Ihrer Kreuzfahrt aus.'},
    'Please select the ship\'s type' : {deDE : 'Wählen Sie bitte den Schiffstyp aus:'},
    'Please select the year.' : {deDE : 'Wählen Sie nun das Jahr aus.'},
    'Please select Vessel' : {deDE : 'Bitte wählen Sie ein Schiff aus'},
    'Please start here' : {deDE : 'Bitte starten Sie hier'},
    'Please start with the house number: e.g. 35, Main Street, Tampa' : {deDE : 'Bitte beginnen Sie mit der Hausnummer: z.B. 35, Main Street, Tampa'},
    'Please try again. Use the newest email' : {deDE : 'Bitte versuchen Sie es noch einmal. Verwenden Sie dafür die neueste Email'},
    'Please try to log in and follow the instructions.' : {deDE : 'Bitte versuchen Sie sich einzuloggen und folgen Sie den Instruktionen.'},
    'Please try to login and check your emails' : {deDE : 'Bitte veruchen Sie sich einzuloggen und prüfen Sie Ihre Emails'},
    'Please use Format: yyyy-mm-dd' : {deDE : 'Bitte verwenden Sie dieses Format: jjj-mm-tt'},
    'Please wait a moment…' : {deDE : 'Einen Moment bitte…'},
    'posted at' : {deDE : 'veröffentlicht'},
    'Posted at' : {deDE : 'Veröffentlicht am'},
    'posted by' : {deDE : 'veröffentlicht von'},
    'Privacy Policy' : {deDE : 'Datenschutz'},
    'Private Group' : {deDE : 'Private Gruppe'},
    'private group' : {deDE : 'private Gruppe'},
    'Private group. Fellow travellers can only become members if they are invited by a group Member.' : {deDE : 'Private Gruppe. Mitreisende können nur Mitglied werden, wenn Sie von einem Gruppenmitglied eingeladen werden.'},
    'Private group. Fellow travellers can only become members if they are invited by the group administrator.' : {deDE : 'Private Gruppe. Mitreisende können nur Mitglied werden, wenn Sie vom Gruppen-Administrator eingeladen werden.'},
    'Private Groups' : {deDE : 'Private Gruppen'},
    'Private groups (no Admin)' : {deDE : 'Private Gruppen (no Admin)'},
    'Private groups:' : {deDE : 'Private Gruppen:'},
    'Proceed' : {deDE : 'Weiter'},
    'Proposals' : {deDE : 'Vorschläge'},
    'public group' : {deDE : 'öffentliche Gruppe'},
    'Public group. Any fellow traveller can join this group.' : {deDE : 'Öffentliche Gruppe. Jeder Mitreisende kann dieser Gruppe beitreten.'},
    'Public Groups' : {deDE : 'Öffentliche Gruppen'},
    'Public groups:' : {deDE : 'Öffentliche Gruppen:'},
    'read' : {deDE : 'gelesen'},
    'read at' : {deDE : 'gelesen'},
    'Received Messages' : {deDE : 'Posteingang'},
    'Recent User Status' : {deDE : 'Bisheriger Status'},
    'Recent Vessels' : {deDE : 'Ehemalige Schiffe'},
    'Register' : {deDE : 'Registrieren'},
    'Registration is required if you want to see or contact other users' : {deDE : 'Eine Registrierung ist notwendig, wenn Sie andere Benutzer sehen oder kontaktieren wollen'},
    'Registration is required if you want to see or contact other users.' : {deDE : 'Wenn Sie die anderen Benutzer sehen oder kontaktieren wollen, ist eine Registrierung notwendig.'},
    'Reject Cruise' : {deDE : 'Löschen'},
    'Reject Invitation' : {deDE : 'Einladung ablehnen'},
    'Reject Request' : {deDE : 'Freundschaftsanfrage löschen'},
    'Remove' : {deDE : 'Entfernen'},
    'Remove album from' : {deDE : 'Album entfernen von'},
    'Remove from this Cruise' : {deDE : 'Von dieser Kreuzfahrt entfernen'},
    'Remove Profile Photo' : {deDE : 'Profilfoto löschen'},
    'Removed from favourites' : {deDE : 'Aus Favoriten entfernt'},
    'Replace Photo' : {deDE : 'Foto ersetzen'},
    'Replace/Remove Document' : {deDE : 'Dokument ersetzen / entfernen'},
    'Replace/Remove Photo' : {deDE : 'Foto ersetzen  / entfernen'},
    'River Boat' : {deDE : 'Flußschiff'},
    'Riverboat' : {deDE : 'Flußschiff'},
    'Save' : {deDE : 'Speichern'},
    'Save as Cruise' : {deDE : 'Als Kreuzfahrt speichern'},
    'Save Result' : {deDE : 'Ergebnis speichern'},
    'Sea Ship' : {deDE : 'Hochseeschiff'},
    'Search' : {deDE : 'Suche'},
    'Seaship' : {deDE : 'Hochseeschiff'},
    'Second Row' : {deDE : 'Zweite Zeile'},
    'seconds' : {deDE : 'Sekunden'},
    'see recommendations for restaurants or insider tips in the ports' : {deDE : 'Empfehlungen für Restaurants oder Geheimtipps in den Häfen sehen'},
    'Select album' : {deDE : 'Album auswählen'},
    'Select all' : {deDE : 'Alle auswählen'},
    'Select to add to group' : {deDE : 'Auswählen, um der Gruppe zuzufügen'},
    'Select to remove from group' : {deDE : 'Auswählen, um aus der Gruppe zu entfernen'},
    'Select Vessel' : {deDE : 'Schiff auswählen'},
    'Send' : {deDE : 'Senden'},
    'Send an answer' : {deDE : 'Senden Sie eine Antwort.'},
    'Send the code to confirm your email address.' : {deDE : 'Senden Sie den Code, um die Emailadresse zu bestätigen.'},
    'Send the code to restore your password.' : {deDE : 'Senden Sie den Code, um das Password zu bestätigen.'},
    'Send to Cruises' : {deDE : 'Als Cruise speichern'},
    'Sending failed.' : {deDE : 'Das Senden scheiterte.'},
    'Sent Messages' : {deDE : 'Gesendete Nachrichten'},
    'Set New Password' : {deDE : 'Neues Passwort setzen'},
    'Set new Password.' : {deDE : 'Neues Passwort setzen'},
    'share pictures and interesting articles with your fellow travelers' : {deDE : 'Bilder und interessante Artikel mit Ihren Mitreisenden teilen'},
    'Ship type' : {deDE : 'Schiffstyp'},
    'Shore leave in' : {deDE : 'Landgang in'},
    'Shortcut' : {deDE : 'Kürzel'},
    'Shorthand' : {deDE : 'Kürzel'},
    'Show all photos' : {deDE : 'Alle Fotos anzeigen'},
    'Show Comments' : {deDE : 'Kommentare anzeigen'},
    'Show Diary Entry' : {deDE : 'Tagebucheintrag öffnen'},
    'Show less' : {deDE : 'Weniger anzeigen'},
    'Show List' : {deDE : 'Liste anzeigen'},
    'Show more' : {deDE : 'Mehr anzeigen'},
    'Show only photos from this cruise' : {deDE : 'Nur Fotos von dieser Reise anzeigen'},
    'Show Profile' : {deDE : 'Zum Profil von'},
    'Show profile' : {deDE : 'Zum Profil'},
    'Show whole cruise diary' : {deDE : 'Gesamtes Tagebuch öffnen'},
    'Sight' : {deDE : 'Sehenswürdigkeit'},
    'Sights' : {deDE : 'Sehenswürdigkeiten'},
    'Some providers block emails from unknown servers' : {deDE : 'Manche Provider blockieren Emails von unbekannten Servern'},
    'Something went wrong.' : {deDE : 'Serverfehler'},
    'source' : {deDE : 'Quelle'},
    'Source' : {deDE : 'Quelle'},
    'staff' : {deDE : 'Staff'},
    'Start annonce at' : {deDE : 'Annonce startet am'},
    'Start at' : {deDE : 'Vom'},
    'Start Port' : {deDE : 'Starthafen'},
    'Stay in Contact' : {deDE : 'In Kontakt bleiben'},
    'Subject' : {deDE : 'Thema'},
    'Subjects' : {deDE : 'Themen'},
    'Submit' : {deDE : 'Senden'},
    'Subtitle' : {deDE : 'Untertitel'},
    'Thank you for registering and logging in. You are now logged in and the search for your cruise can begin.' : {deDE : 'Vielen Dank für die Registrierung und das Login. Sie sind nun eingeloggt und die Suche nach Ihrer Kreuzfahrt kann beginnen.'},
    'The claim was successfully sent to the server' : {deDE : 'Die Beschwerde wurde erfolgreich an den Server gesendet'},
    'The company has not made any entries here yet.' : {deDE : 'Das Unternehmen hat hier noch keine Einträge gemacht.'},
    'The cruise is not in the list. I want to create a new one.' : {deDE : 'Die Kreuzfahrt ist nicht in der Liste. Ich möchte eine neue Kreuzfahrt anlegen.'},
    'The cruise is not on the list. I want to create a new one.' : {deDE : 'Die Kreuzfahrt ist nicht in der Liste. Ich möchte eine neue Kreuzfahrt anlegen.'},
    'The Cruisefriends.net - Team' : {deDE : 'Das Cruisefriends.net - Team'},
    'The email adress was changed.' : {deDE : 'Die Emailadresse wurde geändert'},
    'The Email Confirmation failed' : {deDE : 'Die Bestätigung der Emailadresse ist fehlgeschlagen'},
    'The entries are only visible for you' : {deDE : 'Die Einträge sind nur für Sie sichtbar'},
    'The field does not contain this text.' : {deDE : 'Dieses Feld enthält diesen Text nicht.'},
    'The fields do not match' : {deDE : 'Die Felder sind nicht gleich'},
    'The fields must be different' : {deDE : 'Die Felder müssen unterschiedlich sein'},
    'The Innovative Network For Friends of the Cruise' : {deDE : 'Das innovative Netzwerk für Freunde der Kreuzfahrt'},
    'the innovative platform for friends of the cruise.' : {deDE : 'der innovativen Plattform für Freunde der Kreuzfahrt'},
    'The photo is currently being checked' : {deDE : 'Das Profilfoto wird geprüft'},
    'The photos are from' : {deDE : 'Die Fotos sind von '},
    'The profil data were changed.' : {deDE : 'Die Profildaten wurden geändert.'},
    'The ship is not on the list. I want to create a new one.' : {deDE : 'Das Schiff ist nicht in der Liste. Ich möchte ein neues Schiff anlegen.'},
    'The ship management has not yet made any entries here.' : {deDE : 'Die Schiffsleitung hat hier noch keine Einträge gemacht. '},
    'Then click on "Details" for a fellow traveler. You can then contact him directly.' : {deDE : 'Klicken Sie dann auf "Details" bei einem Mitreisenden. Sie können ihn dann direkt kontaktieren.'},
    'Then login.' : {deDE : 'Dann loggen Sie sich ein.'},
    'Then paste the code to the box below and proceed.' : {deDE : 'Fügen Sie dann den Code in das Feld unten ein.'},
    'Then select the cruise on the list.' : {deDE : 'Dann wählen Sie auf der Liste die Kreuzfahrt aus.'},
    'Then the photo will be checked and activated by one of our administrators.' : {deDE : 'Danach wird das Foto von einem unserer Administratoren geprüft und freigeschaltet.'},
    'Then try again to login.' : {deDE : 'Dann versuchen Sie erneut, sich einzuloggen'},
    'Then try to login again' : {deDE : 'Dann versuchen Sie erneut, sich einzuloggen'},
    'Then you will get a new confirmation email' : {deDE : 'Dann bekommen Sie eine neue Bestätigungs-Email'},
    'There are also some useful tools (such as a currency converter) at your disposal.' : {deDE : 'Außerdem stehen Ihnen einige nützliche Tools (wie zum Beispiel ein Währungsrechner) zur Verfügung.'},
    'There are now some new options available.' : {deDE : 'Es stehen nun einige neue Optionen zur Verfügung.'},
    'There must be a selected Company.' : {deDE : 'Es muss ein Unternehmen ausgewählt sein.'},
    'Third Row ' : {deDE : 'Dritte Zeile'},
    'This can take a few hours.' : {deDE : 'Dies kann ein paar Stunden dauern.'},
    'This field must not contain numbers ' : {deDE : 'Dieses Feld darf keine Zahlen enthalten'},
    'This field must not have any empty spaces ' : {deDE : 'Dieses Feld darf keine Leerzeichen enthalten'},
    'this is a message from Cruisefriends.net' : {deDE : 'dies ist eine Nachricht von Cruisefriends.net'},
    'This is the name, that will be shown in your profile. It can be your full name, or e.g. an artist name.' : {deDE : 'Dieser Name wird in Ihrem Profil gezeigt. Es kann Ihr voller Name sein oder z. B. ein Künstlername'},
    'This is where you will find your cruise and the friends and acquaintances you have met or will meet there.' : {deDE : 'Hier finden Sie Ihre Kreuzfahrt und die Freunde und Bekannte, die Sie dort getroffen haben oder treffen werden.'},
    'This may take some days.' : {deDE : 'Das kann einige Tage dauern.'},
    'This may take some hours' : {deDE : 'Das kann einige Stunden dauern'},
    'This page is new and nowhere near complete.' : {deDE : 'Diese Seite ist neu und bei weitem noch nicht vollständig. '},
    'This page is under construction. It is possible that your ship or your voyage is not here yet.' : {deDE : 'Diese Seite befindet sich im Aufbau. Es kann sein, dass Ihr Schiff oder Ihre Kreuzfahrt noch nicht dabei sind.'},
    'Title ' : {deDE : 'Titel'},
    'to' : {deDE : 'nach'},
    'To ' : {deDE : 'An'},
    'To add a comment, you must give it a like' : {deDE : 'Um einen Kommentar zu schreiben, müssen Sie ihm ein Like geben'},
    'To be sure, choice the option \'Insert a link\'' : {deDE : 'Um sicher zu sein, wählen Sie die Option "Link einfügen"'},
    'To change the destination please type some letters into the box and then select from list.' : {deDE : 'Um das Ziel einzugeben oder zu ändern, schreiben Sie einige Buchstaben des Zieles in die Box, und wählen Sie danach aus der Liste aus.'},
    'To do this, click on the link "Join this cruise"' : {deDE : 'Klicken Sie dazu auf den Link "Mit der Kreuzfahrt verbinden"'},
    'To do this, click the link' : {deDE : 'Um dies zu tun, klicken Sie den Link'},
    'To ensure that the destinations are displayed in the correct order, the fields "Arrival" and "Departure" should be completed.' : {deDE : 'Damit die Ziele in der richtigen Reihenfolge angezeigt werden, sollten die Felder "Ankunft" und "Abfahrt" ausgefüllt werden.'},
    'To join an existing private group you must be invited by the group admin.' : {deDE : 'Um einer bestehenden privaten Gruppe beizutreten, müssen Sie vom Admin der Gruppe eingeladen werden.'},
    'To join an existing private group you must be invited.' : {deDE : 'Um einer bestehenden privaten Gruppe beizutreten, müssen Sie eingeladen werden.'},
    'To join groups or create new groups, you must be logged in and connected to the cruise.' : {deDE : 'Um Gruppen beizutreten oder neue Gruppen zu erstellen, müssen Sie angemeldet und mit der Kreuzfahrt verbunden sein.'},
    'To join groups or create new groups, you must connect to the cruise.' : {deDE : 'Um Gruppen beizutreten oder neue Gruppen zu erstellen, müssen Sie sich mit der Kreuzfahrt verbinden.'},
    'To Login' : {deDE : 'Zum Login'},
    'To Registration' : {deDE : 'Zur Registrierung'},
    'To select a target in the form (after clicking "Edit"), enter part of the name and then click on an element in the list.' : {deDE : 'Um im Formular (nach dem Klick auf "Bearbeiten") ein Ziel auszuwählen, geben Sie einen Teil des Namens ein und klicken Sie dann auf ein Element der Liste.'},
    'To the article' : {deDE : 'Zum Artikel'},
    'To the Article' : {deDE : 'Zum Artikel'},
    'To the offer' : {deDE : 'Zum Angebot'},
    'To Update' : {deDE : 'Aktualisieren'},
    'To use the pinboard, you must be logged in and connected to the cruise.' : {deDE : 'Um die Pinnwand nutzen zu können, müssen Sie eingeloggt sein, und mit der Kreuzfahrt verbunden sein.'},
    'Tour' : {deDE : 'Ausflug'},
    'Touristic Proposals' : {deDE : 'Touristische Vorschläge'},
    'Tours' : {deDE : 'Ausflüge'},
    'Travel Magazine' : {deDE : 'Reisemagazin'},
    'Try again' : {deDE : 'Versuchen Sie es noch einmal'},
    'Try out Cruisefriends.net' : {deDE : 'Cruisefriends.net ausprobieren'},
    'Try to login again' : {deDE : 'Versuchen Sie sich erneut einzuloggen'},
    'Type ' : {deDE : 'Typ'},
    'Unknown Photographer' : {deDE : 'Unbekannter Fotograf'},
    'Update and confirm Cruise' : {deDE : 'Änderungen übernehmen und bestätigen'},
    'Update Geolocations' : {deDE : 'Geolocations aktualisieren'},
    'Update User' : {deDE : 'User aktualisieren'},
    'updated at' : {deDE : 'überarbeitet am'},
    'Upload data from URL (data will be stored)' : {deDE : 'Datei von URL hochladen (Daten werden gespeichert)'},
    'Upload local file' : {deDE : 'Lokale Datei hochladen'},
    'Use the "Add Destination" function if several destinations are to be visited in one day.' : {deDE : 'Verwenden Sie die Funktion "Ziel hinzufügen", wenn mehrere Ziele an einem Tag angefahren werden.'},
    'Use the diary function to write your own diary' : {deDE : 'Benutzen sie die Tagebuchfunktion um Ihr eigenes Tagebuch zu erstellen'},
    'Use the diary function to write your own diary. The entries are only visible for you.' : {deDE : 'Benutzen Sie die Tagebuchfunktion um Ihr eigenes Tagebuch zu erstellen. Die Einträge sind nur für Sie sichtbar.'},
    'Used Data' : {deDE : 'Verwendete Daten'},
    'User' : {deDE : 'Benutzer'},
    'User Registration successfull' : {deDE : 'Die Registrierung war erfogreich.'},
    'Username' : {deDE : 'Benutzername'},
    'username' : {deDE : 'Benutzername'},
    'Username already exists.' : {deDE : 'Dieser Benutzername existiert bereits.'},
    'Username or Email' : {deDE : 'Benutzername oder Email'},
    'Users' : {deDE : 'Benutzer'},
    'Userstatus' : {deDE : 'Benutzerstatus'},
    'Variables and Environment Settings' : {deDE : 'Variablen und Umgebungsvariablen'},
    'Vessel' : {deDE : 'Schiff'},
    'Vessel ID' : {deDE : 'Schiffs-ID'},
    'Vessel photo' : {deDE : 'Schiffsfoto'},
    'Vessels' : {deDE : 'Schiffe'},
    'vessels' : {deDE : 'Schiffe'},
    'Visible for Crew' : {deDE : 'Sichtbar für Crew'},
    'Visible for Guests' : {deDE : 'Sichtbar für Gäste'},
    'Visible for Staff' : {deDE : 'Sichtbar für Staff'},
    'Waiting for the answer' : {deDE : 'Warte auf Antwort'},
    'We have received your message.' : {deDE : 'Wir haben Ihre Nachricht erhalten.'},
    'We sent an email to your email adress if username or email exists.' : {deDE : 'Wir haben eine Email an Ihre Emailadresse gesendet, wenn Benutzername oder Emailadresse existiert'},
    'We sent an email to your email adress.' : {deDE : 'Wir haben eine neue Email an Ihre Emailadresse gesendet.'},
    'We sent you a new email to your email address' : {deDE : 'Wir senden Ihnen eine neue Email an Ihre Emailadresse'},
    'We sent you an email with a link inside' : {deDE : 'Wir haben Ihnen eine Email geschickt mit einem Link.'},
    'We then check the entries and record the journey in the database.' : {deDE : 'Wir prüfen dann die Eingaben und nehmen die Kreuzfahrt in die Datenbank auf.'},
    'We will check the cruise and add it to the database.' : {deDE : 'Die Kreuzfahrt wird von uns geprüft und in die Datenbank aufgenommen.'},
    'We will check the entries and then put the cruise ino the database.' : {deDE : 'Wir werden die Eingaben prüfen, und die Kreuzfahrt dann in die Datenbank stellen.'},
    'Welcome back' : {deDE : 'Willkommen zurück'},
    'Welcome to' : {deDE : 'Willkommen zu'},
    'Welcome to Cruisefriends.net, the innovative network for friends of cruises' : {deDE : 'Willkommen zu Cruisefriends.net, dem innovativen Netzwerk für Freunde der Kreuzfahrt'},
    'What is Cruisefriends.net' : {deDE : 'Was ist Cruisefriends.net'},
    'Would you like to meet up again with friends and acquaintances whom you have made on cruises?' : {deDE : 'Sie möchten Freunde und Bekannte wiedertreffen, die Sie auf Kreuzfahrten kennengelernt haben?'},
    'Write Message' : {deDE : 'Eine Nachricht schreiben'},
    'write recommendations yourself' : {deDE : 'selbst Empfehlungen schreiben'},
    'write your personal travel diary' : {deDE : 'Ihr persönliches Reisetagebuch schreiben'},
    'Year' : {deDE : 'Jahr'},
    'Yes' : {deDE : 'Ja'},
    'You are admin of this group.' : {deDE : 'Sie sind Gruppenadmin.'},
    'You are invited to this group' : {deDE : 'Sie wurden in diese Gruppe eingeladen'},
    'You are joined to this cruise as' : {deDE : 'Sie sind mit dieser Kreuzfahrt verbunden als'},
    'You are member of this group.' : {deDE : 'Sie sind Gruppenmitglied.'},
    'You are not logged in. Therefore, only limited information is available to you here and you cannot make any entries' : {deDE : 'Sie sind nicht eingeloggt. Deshalb stehen Ihnen hier nur begrenzte Informationen zur Verfügung und Sie können keine Eingaben machen'},
    'You can create private groups.' : {deDE : 'Sie können private Gruppen gründen.'},
    'You can create public and private groups here.' : {deDE : 'Sie können hier öffentliche und private Gruppen erstellen.'},
    'You can find the personal area by clicking on your first name in the navigation bar at the top' : {deDE : 'Den persönlichen Bereich finden Sie, wenn Sie oben in der Navigationsleiste auf Ihren Vornamen klicken'},
    'You can login now' : {deDE : 'Sie können sich jetzt einloggen'},
    'You can look around. When you connect to the cruise, you can provide input.' : {deDE : 'Sie können sich umschauen. Wenn Sie sich mit der Kreuzfahrt verbinden, können Sie Eingaben machen. '},
    'You can now also find the cruise directly in your personal area (click on your first name in the navigation bar).' : {deDE : 'Sie finden diese Kreuzfahrt nun auch in Ihrem persönlichen Bereich. Klicken Sie dafür auf Ihren Vornamen in der Navigationsleiste.'},
    'You can only see private groups if you are a member.' : {deDE : 'Sie können private Gruppen nur sehen, wenn Sie deren Mitglied sind.'},
    'You can then see details of the trip if they have already been entered.' : {deDE : 'Sie können dann Einzelheiten der Reise sehen, wenn diese schon eingegeben worden sind.'},
    'You can upload your profile photo here.' : {deDE : 'Hier können Sie Ihr Profilfoto hochladen.'},
    'You can use this site free of charge and the data will be treated confidentially' : {deDE : 'Die Nutzung dieser Seite ist für Sie kostenlos und die Daten werden vertraulich behandelt'},
    'You changed your email address.' : {deDE : 'Sie haben Ihre Emailadresse geändert'},
    'You did not found your cruise.' : {deDE : 'Sie haben Ihr Schiff oder Ihre Kreuzfahrt nicht gefunden?'},
    'You did not receive the email?' : {deDE : 'Sie haben keine Email erhalten?'},
    'You do not want to register yet, but just want to have a look around?' : {deDE : 'Sie möchten sich noch nicht registrieren, sondern möchten sich nur umschauen?'},
    'You found your cruise.' : {deDE : 'Sie haben die Kreuzfahrt gefunden'},
    'You haven\'t found your ship or your cruise?' : {deDE : 'Sie haben Ihr Schiff oder Ihre Kreuzfahrt nicht gefunden?'},
    'You must be logged in and connected to the cruise to post here.' : {deDE : 'Sie müssen eingeloggt und mit der Kreuzfahrt verbunden sein, um hier Einträge zu posten.'},
    'You must be member of this group to open the chat.' : {deDE : 'Sie müssen Mitglied sein, um den Chat zu öffnen'},
    'You need admin rights to post an announcement here.' : {deDE : 'Sie benötigen Adminrechte, um hier eine Ankündigung zu schreiben.'},
    'You sent a request to set a new password for your account with this email adress.' : {deDE : 'Sie haben eine Anfrage zum Setzen eines neuen Passworts für Ihren Account mit dieser Email.'},
    'You start by choosing the ship on which you will take the cruise.' : {deDE : 'Sie starten damit, das Schiff auszuwählen, mit dem Sie die Kreuzfahrt unternehmen.'},
    'You will be logged out now.' : {deDE : 'Sie werden nun ausgeloggt.'},
    'You will be logged out now. You get a new confirmation code to your new email adress.' : {deDE : 'Sie werden nun ausgeloggt. Sie bekommen eine neue Email mit einem Bestätiungscode für Ihre Emailadresse.'},
    'You will find more when you connect with the cruise.' : {deDE : 'Weitere finden Sie, wenn Sie sich mit der Kreuzfahrt verbinden.'},
    'You will get the email manually' : {deDE : 'Sie werden dann die Email manuell bekommen'},
    'You will then find further information by clicking the "Help" button again.' : {deDE : 'Danach finden Sie weitere Hinweise, wenn Sie erneut auf den Button "Hilfe" klicken.'},
    'You will then find further information if you click on the Help button again.' : {deDE : 'Danach finden Sie weitere Hinweise, wenn Sie erneut auf den Hilfe-Button klicken.'},
    'Your actual cruise' : {deDE : 'Ihre aktuelle Kreuzfahrt'},
    'Your actual cruises' : {deDE : 'Ihre aktuellen Kreuzfahrten'},
    'Your best results (last 14 days)' : {deDE : 'Ihre besten Ergebnisse (letzte 14 Tage)'},
    'Your common cruises' : {deDE : 'Ihre gemeinsamen Kreuzfahrten'},
    'Your Cruises' : {deDE : 'Ihre Kreuzfahrten'},
    'Your email address is not confirmed.' : {deDE : 'Ihre Emailadresse ist noch nicht bestätigt.'},
    'Your Favourites' : {deDE : 'Ihre Favoriten'},
    'Your Friend Requests' : {deDE : 'Ihre Freundschaftsanfragen'},
    'Your Friends' : {deDE : 'Ihre Freunde'},
    'Your Groups' : {deDE : 'Ihre Gruppen'},
    'Your Groups on Cruise' : {deDE : 'Ihre Gruppen auf der Kreuzfahrt'},
    'Your Map' : {deDE : 'Ihre Karte'},
    'Your Messages' : {deDE : 'Ihre Nachrichten'},
    'Your options' : {deDE : 'Ihre Möglichkeiten'},
    'Your photo is being reviewed' : {deDE : 'Ihr Foto wird geprüft'},
    'Your Profile' : {deDE : 'Ihr Profil'},
    'Your profile is not complete without a profile photo.' : {deDE : 'Ihr Profil ist nicht vollständig ohne ein Profilfoto.'},
    'Your profile photo is being checked.' : {deDE : 'Ihr Profilfoto wird geprüft.'},
    'Your vessel is not in the list? Then add a new vessel.' : {deDE : 'Ihr Schiff steht nicht in der Liste? Dann fügen Sie bitte das Schiff hinzu.'},
    'Show Album' : {deDE : 'Zum Album'},
    'Favourites are only available for logged in users' : {deDE : 'Favoriten sind nur möglich für angemeldete Benutzer'},
    'Login with' : {deDE : 'Login mit'},
    'Click it to get more informations' : {deDE : 'Klicken Sie es an um mehr Informationen zu erhalten'},
    'From the following ports you can go to' : {deDE : 'Von folgenden Häfen können Sie das Ziel erreichen:'},
    'Click the photo to get more details' : {deDE : 'Klicken Sie ein Foto an, um mehr zu erfahren'},
    'Remove this album from' : {deDE : 'Diese Album entfernen von'},
    'Albums' : {deDE : 'Alben'},
    'From the following ports you can go to this destination' : {deDE : 'Von folgenden Häfen können Sie das Ziel erreichen'},
    'Back to the list' : {deDE : 'Zurück zur Liste'},
    'Delete Photo' : {deDE : 'Foto löschen'},
    'Select existing album' : {deDE : 'Bereits existierendes Album hinzufügen'},
    'Create new album' : {deDE : 'Neues Album erstellen'},
    'Add Album for' : {deDE : 'Album hinzufügen für'},
    'Add new photo' : {deDE : 'Neues Foto hinzufügen'},
    'You cannot create a new album at this point because an album always has to be assigned to a destination' : {deDE : 'Sie können an dieser Stelle kein neues Album anlegen, '},
    'At this point you cannot add albums, because a new album must be connected to a destination' : {deDE : 'An dieser Stelle können Sie kein neues Album anlegen, weil ein Album immer einem Hafen zugeordnet sein muss'},
    'In order to add an new album go to Cruise Diary' : {deDE : 'Um ein neues Album anzulegen gehen Sie zum Reisetagebuch'},
    'There open the destination, open the photo albums, and click "Add new Album"' : {deDE : 'Klicken Sie dort auf den Hafen, öffnen Sie die Fotoalben und klicken Sie auf "Album hinzufügen"'},
    'Ports near' : {deDE : 'Häfen in der Nähe von'},
    'Nature Observations' : {deDE : 'Naturbeobachtungen'},
    'Add new Observation' : {deDE : 'Neue Beobachtung hinzufügen'},
    'Date and Time' : {deDE : 'Datum und Zeit'},
    'Write some chars from the species and select it from list' : {deDE : 'Schreiben Sie ein paar Buchstaben, und wählen Sie dann aus der Liste aus'},
    'No results' : {deDE : 'Keine Ergebnisse'},
    'Please select from list' : {deDE : 'Bitte wählen Sie einen Eintrag aus der Liste'},
    'Please use Format' : {deDE : 'Bitte wählen Sie folgendes Format'},
    'Insert Geolocation' : {deDE : 'Geolocation eintragen'},
    'Get from destination' : {deDE : 'Von einem Ziel'},
    'German' : {deDE : 'Deutsch'},
    'English' : {deDE : 'Englisch'},
    'Existing Groups' : {deDE : 'Existierende Gruppen'},
    'Add New Group' : {deDE : 'Neue Gruppe erstellen'},
    'Update Group' : {deDE : 'Gruppe bearbeiten'},
    'Add Items' : {deDE : 'Einträge hinzufügen'},
    'Close Form' : {deDE : 'Formular schließen'},
    'Nature Observations on this cruise' : {deDE : 'Naturbeobachtungen auf dieser Reise'},
    'New Observation' : {deDE : 'Neue Beobachtung'},
    'Insert geolocation' : {deDE : 'Geolocation einfügen'},
    'Observations' : {deDE : 'Beobachtungen'},
    'Select/Deselect all' : {deDE : 'Alle auswählen / alle abwählen'},
    'Add Observations here' : {deDE : 'Hier Beobachtung hinzufügen'},
    'Observation' : {deDE : 'Beobachtung'},
    'Nature Observations on this Cruise' : {deDE : 'Naturbeobachtungen auf dieser Reise'},
    'Delete Group' : {deDE : 'Gruppe löschen'},
    'Add Species' : {deDE : 'Eintrag hinzufügen'},
    'Missing values' : {deDE : 'Fehlende Werte'},
    'Port / Destination' : {deDE : 'Hafen / Ziel'},
    'Required' : {deDE : 'Feld darf nicht leer sein'},
    'Details to' : {deDE : 'Details zu'},
    'Edit Routing' : {deDE : 'Route bearbeiten'},
    'Personal Diary' : {deDE : 'Persönliches Tagebuch'},
    'Open link for further informations' : {deDE : 'Öffnen Sie den Link für weitere Informationen'},
    'Photo address' : {deDE : 'Bildadresse'},
    'All Observations' : {deDE : 'Alle Beobachtungen'},
    'Like this photo and vote for the photo of the month' : {deDE : 'Linken Sie dieses Foto und wählen Sie mit beim Foto des Monats.'},
    'Photo of the Month' : {deDE : 'Foto des Monats'},
    'Add to Favourites' : {deDE : 'Zu Favoriten hinzufügen'},
    'Remove from Favourites' : {deDE : 'Von Favoriten entfernen'},
    'zzzz' : {deDE : 'Favoriten sind nur möglich für angemeldete Benutzer'},
    'These destinations have no geolocations yet. These will be added in the next few days.' : {deDE : 'Diese Ziele haben noch keine Geolocation. Diese werden in den nächsten Tagen hinzugefügt.'},
    'At Sea' : {deDE : 'Auf See'},
    'Edit Proposal' : {deDE : 'Vorschlag bearbeiten'},
    'Total days' : {deDE : 'Tage gesamt'},
    'days' : {deDE : 'Tage'},
    'No Destination' : {deDE : 'Keine Destination'},
    'Difficulty' : {deDE : 'Schwierigkeit'},
    'Add Tour' : {deDE : 'Ausflug hinzufügen'},
    'Short' : {deDE : 'Kurz'},
    'Templates' : {deDE : 'Vorlagen'},
    'or' : {deDE : 'oder'},
    'Click the text to copy the data' : {deDE : 'Auf den Text klicken, um die Daten zu übernehmen'},
    'e. g. 1, 2, 3 or 1 - 4' : {deDE : 'z.B. 1, 2, 3 oder 1 - 4'},
    'Edit Tour' : {deDE : 'Ausflug bearbeiten'},
    'Other_Proposal' : {deDE : 'Anderer Vorschlag'},
    'Delete Template' : {deDE : 'Vorlage löschen'},
    'Click the red button to delete the template' : {deDE : 'Auf den roten Button klicken, um die Vorlage zu löschen'},
    'Wish List' : {deDE : 'Wunschliste'},
    'Your next cruise' : {deDE : 'Ihre nächste Kreuzfahrt'},
    'Harbour Time' : {deDE : 'Liegezeit'},
    'Please put in the whole text from the tour' : {deDE : 'Bitte fügen Sie hier den kompletten Text vom Ausflug ein.'},
    'Read tour automatically' : {deDE : 'Ausflug automatisch einfügen'},
    'Please insert the complete text of the excursion (including price) from the brochure here' : {deDE : 'Bitte fügen Sie hier den kompletten Text  des Ausflugs (einschließlich Preis) aus der Broschüre ein'},
    'Fields for Wish List' : {deDE : 'Felder für Wunschliste'},
    'This destination does not exist. Please click "Edit Routing"' : {deDE : 'Diese Destination existiert noch nicht. Bitte klicken Sie auf "Route bearbeiten"'},
    'Add Entry for this day' : {deDE : 'Eintrag für diesen Tag hinzufügen'},
    'Edit Album' : {deDE : 'Album bearbeiten'},
    'Delete Entry' : {deDE : 'Eintrag löschen'},
    'Please insert the whole routing text' : {deDE : 'Bitte kopieren Sie den ganzen Routen-Text hierher'},
    'Finish edit routing' : {deDE : 'Routing beenden'},
    'Day' : {deDE : 'Tag'},
    'Add entry for this date' : {deDE : 'Eintrag für diesen Tag hinzufügen'},
    'Please click to insert the data into the form' : {deDE : 'Bitte klicken Sie auf den Eintrag, um die Daten ins Formular zu übernehmen'},
    'No destination selected from list' : {deDE : 'Keine Destination von der Liste ausgewählt'},
    'Delete entry' : {deDE : 'Eintrag löschen'},
    'Chat Groups' : {deDE : 'Chat Gruppen'},
    'Staff (including Commissioners, Lectures, Experts, Guest Artist etc. with the status as passenger)' : {deDE : 'Staff (einschließlich Kommissionäre, Lektoren, Experten, Gastkünstler, etc. mit Passagierstatus)'},
    'Routing and Tours' : {deDE : 'Routing und Ausflüge'},
    'Copy Link to this Cruise' : {deDE : 'Link zu dieser Kreuzfahrt kopieren'},
    'Travel Chats' : {deDE : 'Reise-Chats'},
    'Add Theme' : {deDE : 'Thema hinzufügen'},
    'Fields Wishlist' : {deDE : 'Felder für Wunschliste'},
    'Fields Dayly Duty' : {deDE : 'Felder für Daily Duty'},
    'Timing - New Entry' : {deDE : 'Timing - Neuer Eintrag'},
    'Now' : {deDE : 'Jetzt'},
    'Do you want to delete this tour report' : {deDE : 'Möchten Sie diesen Tour Repot löschen?'},
    'No tour selected' : {deDE : 'Kein Ausflug ausgewählt'},
    'Until' : {deDE : 'Bis'},
    'Activity' : {deDE : 'Aktivität'},
    'Timing - Edit Entry' : {deDE : 'Timing - Eintrag bearbeiten'},
    };
    