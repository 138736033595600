// wird verlagert nach newForms2 als eingebettetes Formular


import { translate } from "../../components/Language/Translate";
import { simpleRequest } from "../simpleRequest";

class DestinationFormData {

    static async destinationNew() {
        
        // console.log('inside DestinationFormData.destinationNew', data, data.params)
        const consts = new DestinationFormSchema();
        let fields;
        Object.entries(consts.fields).forEach(e => fields = {...fields, [e[0]]: e[1].value})
        consts.formType = 'destinationNew';
        consts.request = {
            url:`destinations/`,
            method:"post"
        };
        consts.nextPage = (`/destinations`);
        consts.sendData = ["deDE", "enUK"]
        const vars={fields};
        // console.log({consts, vars})
        return {consts, vars};
    }
    
    static async destinationEdit(data){
        
        // console.log('inside CruiseFormData. cruiseEdit', {data})
        const destinationId=+data.params.id
        const destination = await DestinationFunctions._getById(destinationId)

        // console.log({destination})
        const consts = new DestinationFormSchema()
                
        consts.sendData = ["deDE", "enUK"]
        let fields;
        consts.sendData.forEach(k => fields = {...fields, k: destination[k]})
        consts.formType = 'destinationEdit';
        consts.formLabels = {
            title:translate("Edit Destination"),
            btnLabel:translate("Update Destination")
        }
        consts.request = {
            url:`destinations/${destinationId}`,
            method:"patch"
        };        
        consts.nextPage = (`/destinations`);
        consts.oldValues=destination;
        const vars={fields:destination}
        
        return {consts, vars};
    };
        
};
       




class DestinationFormSchema{
    constructor(){
        this.formType="destinationNew";
        this.nextPage=""
        this.request = {
            url:"destinations",
            method:"post",
        };
        this.formLabels = {title: translate("Add Destination"), btnLabel:translate("Add")};


        this.fields = {

            deDE : {
                name:"deDE",
                label : "Destination (de)",
                fieldType: "text",
                type:"text",
                disabled:false,
                value :"",
                validation :{minLength:2}
            },
            enUK : {
                name:"enUK",
                label : "Destination (en)",
                type:"text",
                fieldType: "textWithValues",
                disabled:false,
                value :"",
                validation :{minLength:2}
            }
        }
    }
};

class DestinationFunctions{
    static async _getById(id){
        
        const response = await simpleRequest({url:`destinations/${id}`, method:"get"});
        const destination = response.data && response.data.destination;
        // console.log({destination});
        return destination;
    };
};


export default DestinationFormData;