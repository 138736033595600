import React, { useEffect, useState } from "react";
import { simpleRequest } from "../../newForms2/simpleRequest";
import { BlackWhite } from "../styledComponents/colors";
import KeywordDetails from "./KeywordDetails";

const KeywordArea = () => {
    const [articles, setArticles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showKeyword, setShowKeyword] = useState(null);

    useEffect(() => {
        const fetch = async() => {
            const res = await simpleRequest({url:"articles", method:"get"});
            const artic = res.data && res.data.articles
            if(artic)(() => setArticles(artic))();
            setIsLoading(false);
        }
        if(isLoading)fetch();
    }, [isLoading, articles]);

    const list = articles?.map(a => a.subjects)
        .reduce((accum,subj) => [...accum, ...subj.split(', ')], [])
        .filter(kw => kw !== "");
    
    const keywords = Array.from(new Set(list)).sort((a,b) => a < b ? -1 : 1);

    return(
        <div>
            {!showKeyword && 
                <ul className="text-left columns" style={{columns: 2}} >
                    {keywords.map(kw => <li key={kw} onClick = {e => setShowKeyword(e.target.innerText)}><BlackWhite>{kw}</BlackWhite></li>)}
                </ul>
            }
            {showKeyword && <KeywordDetails keyword={showKeyword} setShowKeyword={setShowKeyword} />}

        </div>
        
    )
}
export default KeywordArea;