// import React, { Component } from 'react';
// import { render } from 'react-dom';

import { AES, enc } from 'crypto-js';

// import './style.css';

// let name = 'fsdf';

// const randomPw =

export const encrypt = (text, secret) => {
    const cipherText = AES.encrypt(text, secret);
    return cipherText.toString();
};

export const decrypt = (cipher, secret) => {
    try{
        const bytes = AES.decrypt(cipher, secret);
        const decrypted = bytes.toString(enc.Utf8);
        // console.log({secret, bytes, decrypted});
        // if(!decrypted) throw new Error(message="NEIN");
        return decrypted;
    }catch(err){
        // console.log({err});
        return "Not available"
    }
};










// class Field extends Component {}

// class App extends Component {
//   constructor() {
//     super();
//     this.state = {
//       name: 'React',
//       message: 'test',
//       secret: 'test key',
//       cipher: '',
//       decrypted: '',
//     };
//   }

//   tick() {
//     // console.log('name is ', name);
//   }

//   componentDidMount() {
//     //setInterval(this.tick,5000)
//   }

//   onSubmit = (event) => {
//     event.preventDefault();
//     // console.log(this.state);

//     const cipherText = AES.encrypt(this.state.message, this.state.secret);
//     this.setState({ cipher: cipherText.toString(), message: '' });
//   };

//   decryptMessage = (event) => {
//     let bytes;

//     try {
//       bytes = AES.decrypt(this.state.cipher, this.state.secret);
//       // console.log(bytes);
//       const decrypted = bytes.toString(enc.Utf8);
//       this.setState({ decrypted });
//     } catch (err) {
//       // console.log('UNABLE TO DECIPHER', err);
//     }
//   };

//   render() {
//     return (
//       <div>
//         <form onSubmit={this.onSubmit}>
//           <label htmlFor="secret">Secret:</label>
//           <input
//             id="secret"
//             onChange={(e) => this.setState({ secret: e.target.value })}
//             value={this.state.secret}
//           />

//           <br />
//           <label htmlFor="message">Message:</label>
//           <textarea
//             rows="4"
//             cols="50"
//             type="textarea"
//             id="message"
//             onChange={(e) => this.setState({ message: e.target.value })}
//             value={this.state.message}
//           />

//           <br />
//           <input type="submit" value="Submit" />
//         </form>
//         <label htmlFor="cipher">Secret Message:</label>
//         <textarea
//           rows="4"
//           cols="50"
//           id="cipher"
//           onChange={(e) => {
//             this.setState({ cipher: e.target.value });
//           }}
//           value={this.state.cipher}
//         ></textarea>
//         <button onClick={this.decryptMessage}>Decrypt</button>
//         <p>{this.state.decrypted}</p>
//       </div>
//     );
//   }
// }

// render(<App />, document.getElementById('root'));
