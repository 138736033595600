import React, {useState, useEffect} from "react";
import {translate} from '../../components/Language/Translate';
import { Form } from "react-bootstrap";
import { simpleRequest } from "../simpleRequest";
import Spinner from "../../components/Spinner";
import AlbumFormSchema from "./AlbumFormSchema";
import {useRequest} from '../../hooks/useRequest'
import AlbumFormFields from "./AlbumFormFields";

const AlbumEdit = ({albumId, backFunc, refresh}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    const sendData = ["titleDE", "titleEN", "geolocation", "description"];
    const setRequestObj = useRequest();

    useEffect(() => {
        const fetch = async() => {
            const sendData = ["titleDE", "titleEN", "geolocation", "description"];
            const fs = new AlbumFormSchema();
            const album = (await simpleRequest({url: `albums/${albumId}`})).data?.album;
            const vars = {getFromGeolocation:false};
            sendData.forEach(sd => {
                vars[sd] = fs.fields[sd].initialValue;
                vars[sd] = album[sd] ? album[sd] : ""});
            setVariables({...vars});
            setIsLoading(false)
        };
        if(isLoading)fetch()
    }, [isLoading, albumId])

    const errorFunction = (err) => {console.log("inside errorFunction", err)}
    
    if(isLoading)return <Spinner/>

    const handleSubmit = async(e) => {
        e.preventDefault();
        const data = {};
        sendData.forEach(sd => data[sd] = variables[sd]);
        const request = {url:`albums/${albumId}`, method:"patch", data};
        setRequestObj({request, errorFunction, successFunction: refresh});
    };
    const props = {variables, setVariables, fieldErrors, setFieldErrors}
 
    return(
        <div>
            <h4>{`${translate("Edit album")}`}</h4>
            <Form>
                <AlbumFormFields props={props}/>
                <button type="submit" className="btn btn-primary" onClick={e => handleSubmit(e)}>{translate("Submit")}</button>
                <button className="btn btn-secondary ml-2" onClick={backFunc}>{translate("Cancel")}</button>
            </Form>
        </div>
    )
};
export default AlbumEdit;