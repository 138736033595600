import {
    FETCH_COMPANY,
    UPDATE_COMPANY,
    ADD_COMPANY_CREW,
    UPDATE_COMPANY_CREW,
    DELETE_COMPANY_CREW,
    ADD_COMPANY_VESSEL
    } from "../actions/types";

export default function rootReducer(state = {}, action) {

    switch (action.type) {

        case FETCH_COMPANY:
            if(!action.company)return state;
           return ({...action.company});
        case UPDATE_COMPANY:
            if(!action.company)return state;
            return ({...state, ...action.company});
        case ADD_COMPANY_CREW:
            if(!action.companyCrew)return state;
            return ({...state, companyCrew:[...state.companyCrew, action.companyCrew]});
        case UPDATE_COMPANY_CREW:
            if(!action.companyCrew)return state;
            const newCompanyCrew = [...state.companyCrew.filter(cc => cc.id !== action.companyCrew.id), action.companyCrew];
            return ({...state, companyCrew:newCompanyCrew});
        case DELETE_COMPANY_CREW:
            if(!action.deleted)return state;
            return ({...state, companyCrew: [...state.companyCrew.filter(cc => cc.id !== action.deleted.id)]});
        case ADD_COMPANY_VESSEL:
            if(!action.vessel) return state;
            return ({...state, vessels: [...state.vessels, action.vessel]});
        default:
          return state;
    };
};