import React from "react";
// import {translate} from '../Language/Translate';
import { groupTextPrivate, groupTextPublic, textNotJoined, textNotLoggedIn } from "./text";
import { useSelector } from "react-redux";

const HelpArea = () => {
    const currentUser=useSelector(st => st.currentUser);
    const loggedIn = !!currentUser.username;
    const joined = false;

    // console.log({groupTextPrivate, groupTextPublic});
//     const text = [
//         "You can create public and private groups here.",
//         "",
//         "Public groups:",
//         "Examples of public groups are single travellers, sports enthusiasts, skat players.",
//         "Anyone traveling with you can join a public group and participate in the chat.",
//         "", 
//         "Private groups:",
//         "Examples of private groups are travel groups, friends, in the crew also e.g. departments.",
//         "You can create private groups.",
//         "To join an existing private group you must be invited by the group admin.",
//         "You can only see private groups if you are a member.",
//         '',
//         "Private groups (no Admin)",
//         "Like private groups, but you can be invited by each group member."
//     ]







    return(
        <div className="p-2" style={{backgroundColor:"rgba(255,255,255,.6)"}}>
            <h4>{groupTextPublic.headLine}</h4>
            <p>{groupTextPublic.textArr.map((t,idx) => <span key={idx}>{t}<br/></span>)}</p>
            <h4>{groupTextPrivate.headLine}</h4>
            <p>{groupTextPrivate.textArr.map((t,idx) => <span key={idx}>{t}<br/></span>)}</p>
            {!loggedIn && <p><b>{textNotLoggedIn.textArr.map((t,idx) => <span key={idx}>{t}<br/></span>)}.</b></p>}
            {loggedIn && !joined && <p><b>{textNotJoined.textArr.map((t,idx) => <span key={idx}>{t}<br/></span>)}</b></p>}




        </div>
    );

};
export default HelpArea;

