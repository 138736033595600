import { translate } from "../../components/Language/Translate";


class TourEscortSchema{
    constructor(user){
        this.fields = {
            "displayedName": {
                "fieldName": "displayedName",
                "validation": {
                    "minLength": 1,
                    "maxLength": 40
                },
                "type": "string",
                "fieldType": "text",
                "maxLength": 40,
                "required": true,
                "label": "Displayed Name",
                "initialValue": user.firstName,
                "toolTip": ""
            },
            "position": {
                "fieldName": "position",
                "validation": {
                    "minLength": 1,
                    "maxLength": 20
                },
                "type": "string",
                "fieldType": "text",
                "maxLength": 20,
                "required": true,
                "label": "Position",
                "initialValue": "",
                "toolTip": ""
            },
            "responseEmail": {
                "fieldName": "responseEmail",
                "validation": {
                    "maxLength": 80
                },
                "type": "string",
                "fieldType": "text",
                "maxLength": 80,
                "required": true,
                "label": "Response Email",
                "initialValue": user.email,
                "toolTip": ""
            },
            "fields": {
                "fieldName": "fields",
                "type": "string",
                "fieldType": "textarea",
                "label": translate("Fields for Wish List"),
                "initialValue": "TM, CSM, GRM, Foto/Video, DJ, Nighty, REZI, Pianist, TRAINEE, TRAINEE",
                "toolTip": ""
            },
            "fieldsDd": {
                "fieldName": "fieldsDd",
                "type": "string",
                "fieldType": "textarea",
                "label": translate("Fields for Daily Duty"),
                "initialValue": "Bus, Capacity, Guide name, Ship-Escort, Remarks, Pax",
                "toolTip": ""
            }
        }



    }
    
};
export default TourEscortSchema;