import React, {useState, useEffect, useRef} from "react";
import { simpleRequest } from "../../newForms2/simpleRequest";
import { Duration, dateTimeStringToDate, timeStampToString } from "../../helpers/Date";
import Spinner from '../Spinner'
import {translate} from "../Language/Translate"

const calculateLifeTime = (arr) =>{
    return arr.map(v => {
        const start = dateTimeStringToDate(v.startAt)
        const end = new Date ((start.getTime() + (v.lifeTime * Duration.day)));
        return {...v, start, end};
    });
};

const AdvertisementArea = () => {
    const annoncesMin = 3;
    const duration = 10000;
    const now = () => new Date();

    const [isLoading, setIsLoading] = useState(true);
    const [reload, setReload] = useState(true);

    const advertsRef = useRef()
    const timerCodeRef = useRef();
    const idxRef = useRef();
    const nextChangeRef = useRef()

    useEffect(()=>{
        const randomOrder = (array) => {
            const newArr = array.map(v => {return{...v, rn:Math.random()}})
            newArr.sort((v1, v2) => (v1.rn > v2.rn) ? 1 : (v1.rn < v2.rn) ? -1 : 0)
            return newArr;
        };

        const reduceToActuelAnnonces = (arr) => {
            return arr.filter(v => {
                return(v.start <= now() && v.end >= now())})
        };
        const calculateNextChange = (arr) => {
            const changes = []
            arr.forEach(v => {
                if(v.start > now())changes.push(timeStampToString(v.start))
                if(v.end > now())changes.push(timeStampToString(v.end))
            });
            changes.sort();
            return changes.sort()[0]
        }
        const reCalculate = async (arr) => {
            const arr1 = arr && calculateLifeTime(arr);
            const arr2 = reduceToActuelAnnonces(arr1);
            while (arr2.length < annoncesMin){
                const start = await new Date();
                const s1 = start.getTime();
                const s2 = s1 + (14 * Duration.day);
                const end = await new Date(s2)
                arr2.push({startAt: timeStampToString(start), start:start, end:end, firstRow:"placeholder", id:-1})
            };
            const arr3 = randomOrder(arr2);
            // console.log({arr, arr1, arr2, arr3});
            nextChangeRef.current=calculateNextChange(arr1);
            idxRef.current=0;
            return arr3;

        }

        const showAnnonces = () => {
            const interval = setInterval(() => {
                // console.log("INTERVAL", nextChangeRef, {reload}, idxRef.current)
                if(timeStampToString(now()) >= nextChangeRef.current){
                    setIsLoading(true)
                }else{
                    if (idxRef.current >= advertsRef.current.length-1){
                        idxRef.current = 0
                    }else{
                        idxRef.current = idxRef.current + 1;
                    }
                }
                setReload(true)
            }, duration);
            timerCodeRef.current = interval;
        }

        
            const fetch = async() =>{
                // console.log("NEUBERECHNUNG!!!!!!!")
                const a = await simpleRequest({url:"advertisements", method:"get"});
                const a2 = a.data && a.data.advertisements;

                if(a2){
                    const a3 = await reCalculate(a2)
                    advertsRef.current=a3;
                    showAnnonces();
                    setIsLoading(false);
                }
            }
        if(isLoading)fetch();

    }, [isLoading, reload]);
   

    useEffect(() => {
        if(reload && advertsRef.current){
            (() => setReload(false))();
            // console.log({reload})
        }
    },)

    if(isLoading || !advertsRef.current) return <Spinner/>

    const advertisements = advertsRef.current;
    const annonce = advertisements[idxRef.current];

    return(
        // <div style={{border:"1px solid black", borderRadius:"5px", backgroundColor:"rgba(255,255,255,.4)"}}>
        <div className="p-md-3" style={{border:"1px solid black", borderRadius:"5px", backgroundColor:"rgba(255,255,255,.4)"}}> 
            <p className = "text-center"><b>{annonce.firstRow}</b><br/>{annonce.secondRow}<br/>{annonce.thirdRow}
                <a href={annonce.url} target="_blank" rel="noopener noreferrer" 
                    className = "btn btn-outline-dark ml-md-3">{translate("To the offer")}</a>
            </p>
        </div>
    )
};
export {calculateLifeTime};
export default AdvertisementArea;

