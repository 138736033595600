// import {translate} from "../../components/Language/Translate";
import {simpleRequest} from "../../newForms2/simpleRequest";
import {translate} from "../../components/Language/Translate"

class ArticleFormData {
    static async articleNew() {
        // console.log('inside ArticleFormData.articleNew')
        const consts = new ArticleFormSchema();
        let fields;
        Object.entries(consts.fields).forEach(e => fields = {...fields,[e[0]]: e[1].value})
        consts.nextPage = (`/editorials`);
        consts.sendData = [	"type", "source", "title", "subtitle", "description", "subjects", "documentUrl", "photoUrl", "copyright"];
        const vars = {fields};
        // console.log({consts,vars})
        return {consts, vars};
    }

    static async articleEdit(data) {

        // console.log('inside CruiseFormData. cruiseEdit', {data})
        const id = +data.params.id
        const article = await ArticleFunctions._getById(id)

        // console.log({article})
        const consts = new ArticleFormSchema()
        delete consts.fields.articleHandle;

        consts.sendData = ["type", "source", "title", "subtitle", "photoUrl", "copyright", "documentUrl", "description", "subjects"]
        let fields;
        consts.sendData.forEach(k => fields = {...fields,k: article[k]})
        consts.formType = 'articleEdit';
        consts.formLabels = {
            title: "Edit Article",
            btnLabel: "Update "
        }
        consts.request = {
            url: `articles/${id}`,
            method: "patch"
        };
        consts.nextPage = (`/editorials`);
        consts.oldValues = article;
        const vars = {fields: article}

        return {consts,vars};
    };

};

class ArticleFormSchema {
    constructor() {
        this.formType = "articleNew";
        this.nextPage = "articles"
        this.request = {url: "articles", method: "post"};
        this.formLabels = {title: translate("Add Article"), btnLabel: translate("Add")};
        this.fields = {

            type: {
                label: translate("Type"),
                value: "article",
                validation: {minLength:3, maxLength: 15},
                fieldType: "select",
                type: "text",
                autoFocus:true,
                settings:{
                        options: [
                            {key: "article", value:translate("Article")},
                            {key: "book", value:translate("Buch")},
                            {key: "movie", value:translate("Movie")},
                            {key: "music", value:translate("Music")},
                    ]
                },
                required:true
            },
            source: {
                label: translate("Source"),
                value: "",
                validation: {maxLength: 25},
                fieldType: "text",
                type: "text",
                toolTip: translate(`Please fill out this field, if you are not the author of the article`),
                placeholder: translate(`Please fill out this field, if you are not the author of the article`),
            },
            title: {
                label: translate("Title"),
                value: "",
                validation: {minLength: 3, maxLength: 60},
                fieldType: "text",
                type: "text",
                required:true
            },
            subtitle: {
                label: translate("Subtitle"),
                value: "",
                validation: {minLength: 3, maxLength: 100},
                fieldType: "text",
                type: "text",
            },
            photoUrl: {
                label: "Photo URL",
                value: "",
                validation: {minLength: 8},
                fieldType: "text",
                type: 'url'
            },
            copyright: {
                label: "Copyright",
                value: "",
                fieldType: "text",
                type: 'text'
            },
            documentUrl: {
                label: translate("Document URL"),
                value: "",
                validation: {minLength: 8},
                fieldType: "text",
                type: 'url'
            },
            description: {
                label: translate("Description"),
                value: "",
                validation: {},
                fieldType: 'textarea',
                type: "text"
            },
            subjects: {
                label: translate("Subjects"),
                value: "",
                fieldType: 'textarea',
                type: "text",
                required:true,
                validation:{minLength:3},
                placeholder: "e.g MS Albatros, MS Deutschland, Greece... Divided by comma",
                toolTip: 'Divide subjects by comma.'
            },
        }
    }
};

class ArticleFunctions {
    static async _getById(username) {
        const response = await simpleRequest({
            url: `articles/${username}`,
            method: "get"
        });
        const cc = response && response.data && response.data.article
        return cc;
    }
}


export default ArticleFormData;