import React, {useState} from "react"
import { translate } from "./Language/Translate";
import {DeleteHints, Confirmation} from "./AGB/html/deleteData";
import UserDelete from "../newForms2/users/UserDelete";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const DeleteAccount = () => {
    // console.log('DeleteAccount');
    const history = useHistory();
    const [view, setView] = useState('start');
    const [confirmed, setConfirmed]=useState(false);
    
    return(
        <div style={{backgroundColor:"rgba(255,255,255,.7)"}} className="p-4">
            <DeleteHints />
            {view === "start" && <Confirmation confirmed={confirmed} setConfirmed={setConfirmed}/>}
            {view === "start" && confirmed && <>
                    <button className="btn btn-outline-danger" onClick={() => setView("form")}>{translate("Proceed")}</button>
                    <button className="btn btn-outline-secondary ml-2" onClick={() => history.push("/")}>{translate("Cancel")}</button>
                </>}
            {view === "form" && <UserDelete/>}
        </div>
        );
    };
export default DeleteAccount;