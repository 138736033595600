import React from "react";
import SelectField from "./Fields/SelectField";
import TextField from "./Fields/TextField";
import TextArea from "./Fields/TextArea";
import CheckBox from './Fields/CheckBox';
import FieldValidation from './Validation/FieldValidation'
import UploadAreaSingle from "./upload/UploadAreaSingle";
import RadioField from "./Fields/RadioField";
import { translate } from "../components/Language/Translate";
import MultiCheck from "./destinations/MultiCheck";
import StarSet from "./Fields/StarSet";
import GeolocationField from "./Fields/GeolocationField";
import DateTime from "./Fields/DateTime";


const FormFields = ({props}) => {
  // console.log(props);
  const {
    fieldSettings, 
    variables, 
    setVariables,
    fieldErrors, 
    setFieldErrors, 
    serverError,
    setServerError
  } = props;

  const handleChange = e => {
    // console.log(e.target);
    setFieldErrors({...fieldErrors, [e.target.name]:""});
    setVariables({...variables, [e.target.name]:e.target.value});
    setServerError && serverError && (() => setServerError(""))();
  };
  
  const validateField = (e) => {
    const {name, value} = e.target;
    const validation = new FieldValidation(name, value, fieldSettings, variables);
    const objValid = validation.isValid()
    if(objValid.valid){
      const newFieldErrors = {...fieldErrors};
      delete newFieldErrors[name];
      setFieldErrors({...newFieldErrors});
    }else{
      setFieldErrors({...fieldErrors, [name]: objValid.errMsg});
    };
};
  
const newProps = {
    ...props,
    handleChange,
    validateField,
  };
  const keys = Object.keys(variables);
  // console.log({keys});

  return(
    <>  
    {serverError && <div className="p-2" ><p className = "text-danger" >{translate(serverError)}</p></div>}
    {keys.map(k => {
            // console.log({k});
            if(!fieldSettings[k])return null;
            switch (fieldSettings[k].fieldType) {
                case "text":
                case "number":
                  return <TextField key={k} name={k} props={newProps} />
                case "select":
                  return <SelectField key={k} name={k} props={newProps} />
                case "textarea":
                  return <TextArea key={k} name={k} props={newProps}/>
                case "checkBox":
                  return <CheckBox name={k} key={k} props={newProps} />
                case "radio":
                  return <RadioField name={k} key={k} props={newProps} />
                case "upload":
                  return <UploadAreaSingle key={k} name= {k} props={newProps}/>
                case "multicheck":
                  return <MultiCheck key ={k} name={k} props={newProps}/>
                case "starSet":
                  return <StarSet key = {k} name={k} props={newProps}/>
                case "geolocation":
                  return <GeolocationField key = {k} name={k} props={newProps} />
                case "dateTime":
                  return <DateTime key = {k} name={k} props={newProps} />
                default:
                  return null;
            }
        })
      }
      {serverError && <div className="p-2" ><p className = "text-danger" >{translate(serverError)}</p></div>}
      </>
  )
};

export default FormFields;