import React, { useState } from "react";
import {translate} from "../Language/Translate"
import {Button, Form} from 'react-bootstrap';
import { simpleRequest } from "../../newForms2/simpleRequest";




const Result = ({element}) => {

return null;
};

const ObjectField = () => {
    const [values, setValues] = useState({sql:""});
    const [result, setResult] = useState("");

    const analyse = value => {
        const outerArr = value.split('\n');
        console.log({outerArr});
        const result = outerArr.map(i => {
            return i.split('\t');
        });
        let resArr = [];

        for (let i = 1; i < outerArr.length; i++){
            const obj = {}
            const fieldnames = outerArr[0].split('\t');
            const values = outerArr[i].split('\t');
            for (let j = 0; j < fieldnames.length; j++){
                obj[fieldnames[j]] = values[j]
            };
            resArr = [...resArr, obj]

        }

        console.log({result, resArr})
        return result
    }





    // const [order, setOrder] = useState('none');

    const handleChange = e => {
        (() => setValues({...values, sql:e.target.value}))();
        (() => setResult(analyse(e.target.value)))();
    };
    const handleSubmit = async () => {

    };


    console.log({values, result})
        return(
        <>
        <div>
            <button className = "btn btn-outline-dark m-1" onClick={() => setValues({sql:""})}>Clear</button>
        </div>
        <div>
            <Form>
                <Form.Group size="lg" controlId={"obj"} >
                    <Form.Label  className ="pt-1 px-1" >Object</Form.Label>
                    <Form.Control as="textarea" rows={5}
                        name={"obj"} 
                        type="textarea" 
                        fieldtype="textarea" 
                        key={"obj"} 
                        autoFocus={true}
                        value={values.sql}
                        onChange={e => handleChange(e)} 
                    />
                </Form.Group>
                <Button onClick = {handleSubmit}>{translate("Submit")}</Button>
            </Form>
            {/* <p className="mt-3">{JSON.stringify(result)}</p> */}
        </div>
        <div className="text-left">
            <small><Result element={result}/></small>
        </div>
        </>
    );
};
// export {Result};
export default ObjectField;