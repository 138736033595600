import React, { useState } from "react";
import {translate} from '../Language/Translate';
import { getDateObject } from "../../helpers/Date";
import Stars from "../Stars";
import { useDispatch, useSelector } from "react-redux";
import MedicalCommentEdit from "../../newForms2/destinations/MedicalCommentEdit";
import { deleteMedicalCommentOnApi } from "../../actions/currentDestination";

const MedicalCommentItem = ({comment}) => {
    const [showForm, setShowForm] = useState('none');
    const currentUser = useSelector(st => st.currentUser);
    const isCurrentUser = comment.createdBy === currentUser?.username;
    const isAdmin = ["admin", "developer"].includes(currentUser.userStatus);
    const dispatch = useDispatch();
    const handleDelete = () => {
        dispatch(deleteMedicalCommentOnApi(comment.id))
    };
    const postedText = `${translate("Posted at")} ${getDateObject(comment.createdAt).dateTime} ${translate("by")} ${comment.nickname || comment.createdBy}`
    const updatedText = (comment.updatedAt && `, ${translate("updated at")} ${getDateObject(comment.updatedAt).dateTime}
            ${translate("by")} ${comment.nickname || comment.createdBy}`) || "";
    const wholeText = `${postedText}${updatedText}`

    const DeleteArea = () => {
        return(
            <div className= "p-2 m-1" style = {{border:"1px solid red", borderRadius:"5px"}}>
                <p>{translate("Are you sure to delete this item")}?</p>
                <button className = "btn btn-outline-danger mx-2" onClick={handleDelete}>{translate("Yes")}</button>
                <button className = "btn btn-outline-secondary" onClick={() => setShowForm("none")}>{translate("Cancel")}</button>
            </div>
        );
    };

    return(
        <div key={comment.id} className="mt-2 p-3" style={{border:"1px solid grey", borderRadius:"5px"}}>
            <p>{wholeText}</p>
            <h6>{comment.subject} <Stars value={comment.points}/></h6>
            <p>{comment.comment}</p>
            {isCurrentUser && <button className="btn btn-outline-dark" onClick={() => setShowForm(showForm === "edit" ? "none": "edit")}>
                {showForm === "edit" ? translate("Close Formular") : translate("Edit Comment")}</button>}
            {((isCurrentUser || isAdmin) && showForm !== "delete") && <button className="btn btn-outline-danger mx-2" onClick={() => setShowForm(showForm === "delete" ? "none" : "delete")}>
                {translate("Delete Comment")}</button>}
            {showForm === "delete" && <DeleteArea/>}
            {showForm === "edit" && <MedicalCommentEdit comment={comment} />}

        </div>
    )

};
export default MedicalCommentItem