import React, {useState} from "react";
import { translate } from "../Language/Translate";
import CruiseAreaCardDestinationDiary from "../CruiseAreaCardDestinationDiary";
import { Card } from "react-bootstrap";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import { StyledDivWithBorder } from "../styledComponents/divs";
// import TourArea from "../Tours/TourArea";
import ActionSection from "./ActionSection";

const CruisAreaCardDayBody = ({day, showBody, setShowBody, setEditSingleDay}) => {
    const [showLocal, setShowLocal] = useState('main');
    // console.log({day});
    // const history = useHistory();

    return(
        <>
        <Card.Body>

            <div className="my-1">
                {day.actions.map(a => (<ActionSection a={a} day={day} key={a.id}/>))}
            </div>
            <div>
                <button className="btn btn-outline-dark ml-2" onClick={() => setEditSingleDay(true)}>
                        {translate("Edit Routing")}
                </button>
            </div>
        </Card.Body>    
        <Card.Footer className="mb-3">
            <div className="my-1">
                {/* <button className = "btn btn-outline-dark ml-1">{translate("Nature Observations")}</button> */}
                <button className = "btn btn-outline-dark ml-1" onClick={() => setShowLocal("diary")}>{translate("Personal Diary")}</button>
                {/* <button className="btn btn-outline-dark ml-1">{translate("Edit Routing")}</button> */}
                {/* <button className="btn btn-outline-dark ml-1" onClick={() => setShowBody(false)}>{translate("Close")}</button> */}
            </div>
            <div>
                {showLocal === "diary" && <CruiseAreaCardDestinationDiary day={day}/>}
            </div>
        </Card.Footer>
        </>
    )
};
export default CruisAreaCardDayBody;