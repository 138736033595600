import React from 'react'
// import { propTypes } from 'react-bootstrap/esm/Image';
import {Link} from "react-router-dom"

const SuccessArea = ({constants, variables}) => {
  const txt=constants.successText
  const successButton = constants.successButton;
  const serverError = variables.serverError;
  const successTitle = txt && txt.title;
  const successText = txt && txt.text && txt.text.split('\n').map ((t,i) =><p key={i}>{t}</p>)
  if(!successTitle && !successText)return null;
    return (
    <div className="success-area mt-6">
        <h5>{successTitle}</h5>
        <h6 className="my-5">{successText}</h6>
        {successButton && !serverError && <Link to={successButton.link} className="btn btn-outline-dark" >{successButton.label}</Link>}
    </div>
  )
};

const ServerErrorArea = (props) =>{
  // const proceedButton = props.constants.proceedButton;
  //  // console.log({props})
    const {serverError} = props.variables;
    // console.log({serverError})
    const formatServerError =serverError? serverError.split('\n').map((t,i) =><p key={i}>{t}</p>):'';
    
      return(

        <div className="servererror-area text-danger my-3">
          {formatServerError}
          {/* {proceedButton && serverError && <Link to={proceedButton.link} className="btn btn-outline-dark" >{proceedButton.label}</Link>} */}
        </div>

    )
};

export {SuccessArea, ServerErrorArea};

