import {translate} from "../../components/Language/Translate";
// import { simpleRequest } from "../simpleRequest";

class CodeFormData {

    static async codeInput(data) {
        const {type} = data.params;
        // console.log('inside CodeyFormData.dayNew', data)
        const consts = new CodeFormSchema(type);
        let fields;
        Object.entries(consts.fields).forEach(e => fields = {...fields, [e[0]]: e[1].value})
        consts.formType = 'codeInput';
        consts.nextPage = '/login'
        consts.request = {method:"post", url: `${type}`};
        // consts.nextPage = (`/message/code/${type}`);
        consts.sendData = ["code"]
        const vars={fields};
        // console.log({consts, vars})
        return {consts, vars};
    }
}
export default CodeFormData;

class CodeFormSchema {

    constructor(type) {
        // console.log({type})
        const titleText =type==="confirmation"?translate('Send the code to confirm your email address.'):translate('Send the code to restore your password.')
        this.formLabels = {
            title: titleText,
            btnLabel: "Send"
        };
        // this.proceedButton={
        //     link :`/${type}code`, label:translate("Proceed with confirmationcode")
        // }


        this.fields = {

            code: {
                name: "code",
                label: translate("Input text from Email"),
                toolTip:translate("You can put in the whole text from the email. Be sure that it contains the code."),
                fieldType:"textarea",
                type: "string",
                value: "",
                required: true
            },
        }
    }
}