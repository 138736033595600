import React, { useState, useEffect } from "react";
import Randomfn from "../../helpers/Randomfn";

const PhotoShow2 = ({photos=[], setActualPhoto = () => {}, actualPhoto={}, setView=()=>{}}) => {
    const [imgLabel, setImgLabel] = useState("");
    const [actualIndex, setActualIndex] = useState(0);
    const [randomArray, setRandomArray] = useState([]);
    const {prefix, name, vesselId, title, titleDE, titleEN} = actualPhoto;
    const location = title || titleDE || titleEN || ""
    const titleString = vesselId ? `${prefix} ${name} / ${location}` : location;

    useEffect(() => {
        
        setRandomArray(Randomfn.createRandomIndexArray(photos.length));
        const int = setInterval(() => setActualIndex(i => i < photos.length - 1 ? i + 1 : 0), 5000);
        return () => clearInterval(int);
    },[photos.length]);

    useEffect(() => {
        if(photos.length && randomArray.length)setActualPhoto(photos[randomArray[actualIndex]] || {});
    }, [setActualPhoto, photos, randomArray, actualIndex]);

    if(photos.length === 0)return null;

    return (
        <div className="center p-1 mb-5" style = {{border:"1px solid grey", borderRadius:"5px", backgroundColor:"rgba(255, 255, 255,.05)"}}>
            <div className = "center" >
                <div className = "center" style={{height: "55vh"}}>
                    <img src={actualPhoto.imageUrl} 
                        style = {{height:"50vh", width : "auto", objectFit: "contain"}} 
                        alt='Photo_' className="img-thumbnail" onClick = {()=>setView('details')} 
                        onLoad={() => setImgLabel(titleString)} >
                    </img>
                </div>
            </div>
            <p><b>{imgLabel}</b></p>
        </div>
    );
};
export default PhotoShow2;