import React from "react";
import { translate } from "../../../Language/Translate";
import { Form } from "react-bootstrap";
import SpeciesGroupList from "./SpeciesGroupList";

const ObservationsSelectForm = ({props}) => {
    const {variables, setVariables} = props;

    const handleChange = () => {
        const newAll = !variables.all;
        // const {speciesGroups} = variables;
        let newSpeciesGroups = {...variables.speciesGroups};
        Object.keys(newSpeciesGroups).forEach(k => {
            // const group = newSpeciesGroups[k]
            newSpeciesGroups[k].checked = newAll;
            Object.keys(newSpeciesGroups[k].species).forEach(k2 => {
                newSpeciesGroups[k].species[k2].checked = newAll;
            });
        });
        setVariables({all: newAll, speciesGroups:{...newSpeciesGroups}});
    }

    // console.log({variables}, variables);
    return(
        <div>
            <Form>
                <Form.Group>
                    <Form.Check type = "checkbox"
                        label = {translate("Select/Deselect all")}
                        checked={props.variables.all}
                        onChange={handleChange}
                        />
                </Form.Group>
            </Form>
            <SpeciesGroupList props = {{...props}} variables={variables}/> 
        </div>
    )
};
export default ObservationsSelectForm;