import { translate } from "../../components/Language/Translate";

class ChatCommentSchema{
    constructor(){
        this.fields = {
            comment:{
                type:"text",
                fieldType: "textarea",
                initialValue:"",
                label: translate("Comment")
            },

        }
    }
};
export default ChatCommentSchema;