import React, { useEffect, useState} from "react";
import { useDispatch } from 'react-redux';
import Spinner from '../../components/Spinner';
import FormFields from "../FormFields";
import { translate } from "../../components/Language/Translate";
import FormValidation from '../Validation/FormValidation';
import ArticleLinksSchema from "./ArticleLinksSchema";
import { simpleRequest } from "../simpleRequest";

  const ArticleLinkFormEdit = ({id, article, setArticle, setShowEditForm}) => {
    // console.log({id, article, setArticle, setShowEditForm});
    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState();
    const [fieldSettings, setFieldSettings] = useState();
    const [fieldErrors, setFieldErrors] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
      // console.log('inside useEffect')
      const fetch = async() => {
        const sendData = ["subject", "description", "url", "photoUrl"];
        (() => setFieldSettings((new ArticleLinksSchema()).fields))();
        const resData = (await simpleRequest({url:`articlelinks/${id}`, method:"get"})).data;
        const vars = {};
        sendData.forEach(sd => vars[sd] = resData.articleLink[sd]);
        // console.log({vars});
        (() => setVariables({...vars}))();
        (() => setIsLoading(false))();
      }
      if(isLoading)fetch();
    },[isLoading, setIsLoading, dispatch, id]);

    if(isLoading) return <Spinner/>

    const handleSubmit = async() => {
      const formValidation = new FormValidation({fieldSettings, variables});
      if(!formValidation.valid)return;
      const data = {...variables};
      const resData = (await simpleRequest({url:`articlelinks/${id}`, method:"patch", data})).data;
      const articleLink = resData?.articleLink;
      if(!(articleLink))return;
      await (() => setArticle({...article, links:[...article.links.filter(l => l.id !== id), articleLink]}))();
      (() => setShowEditForm(false))();
    };

    const props = {fieldSettings, variables, setVariables, fieldErrors, setFieldErrors};

    return(
        <div className = "my-5 p-3" style = {{border:"1px solid grey", borderRadius:"5px"}}>
            <h4>{translate("Edit Article Link")}</h4>
            <FormFields props={props}/>
            <div className="row col-12" >
              <div className="col-6 p-1">
                <button className="btn btn-outline-info col-12" onClick={handleSubmit}>{translate("Submit")}</button>
              </div>
              <div className="col-6 p-1">
                <button className="btn btn-outline-secondary col-12" onClick={()=>setShowEditForm(false)}>{translate("Cancel")}</button>
              </div>
            </div>
        </div>
    );

  };
  export default ArticleLinkFormEdit;