import React from "react";
// import { translate } from "./Language/Translate";
import Symbols from "./Symbols";

const Stars = ({value, amount=5}) => {
    const valArr = []
    for (let i=0; i < amount; i++){
        valArr.push (i < value ? "StarFas": "StarFar") 
    };
    return <Symbols typeArray={valArr}/>

};
export default Stars;