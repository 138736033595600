import React, { useEffect} from 'react';

const ServerMessageArea = ({info, setInfo, delay=3000, stay=false}) => {
    // console.log('inside ServerMessageArea', info);
      
    useEffect(() => {
        if(!stay && info)setTimeout(() => setInfo(null), delay)
    }, [stay, info, setInfo, delay])


    if(!info)return null;

    return (
        <div className = "my-3">
            <h5>{JSON.stringify(info)}</h5>
            {stay && <button className = "btn btn-outline-dark mt-2" onClick={() => setInfo(null)}>Ok</button>}
        </div>
    )
};
export default ServerMessageArea;