import React, { useEffect, useState } from "react";
import { simpleRequest } from "../../newForms2/simpleRequest";
import { translate } from "../Language/Translate";
import Spinner from "../Spinner";
// import { BlackWhite } from "../styledComponents/colors";
import ArticleList from "./ArticleList";
// import ArticleListItem from "./ArticleListItem";

const KeywordDetails = ({keyword, setShowKeyword}) => {
    const [articles, setArticles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const fetch = async () => {
            const url = `articles/keyword/${keyword}`

            const data = (await simpleRequest({url, method:"get"})).data;
            // console.log({url, data});
            // console.log(data.articles);
            await (() => setArticles(data?.articles || []))();
            (() => setIsLoading(false))();
        };
    if(isLoading)fetch()
    }, [isLoading, keyword]);
    
    if(isLoading)return <Spinner/>
    // console.log({articles});
    return(
        <div>
            <h5>{keyword}</h5>
            {articles.length > 5 && <button className="btn btn-outline-dark" onClick={() => setShowKeyword(null)}>{translate("Back")}</button>}
            <ArticleList list={articles} />
            <button className="btn btn-outline-dark" onClick={() => setShowKeyword(null)}>{translate("Back")}</button>

        </div>
        
    );

};
export default KeywordDetails;