import React, {useState, useEffect} from "react";
import {Translate, translate} from '../../components/Language/Translate';
import { Form } from "react-bootstrap";
import { simpleRequest } from "../simpleRequest";
import { useDispatch, useSelector } from "react-redux";
import { fetchDestinationFromAPI } from "../../actions/currentDestination";

const AlbumNew = () => {
    const initialValues = {
        view: "existing",
        selected: 0,
        newName: "",
        newGeolocation:"",
        getFromGeolocation: false
    }
    const language = Translate.language || "enUK";
    const [isLoading, setIsLoading] = useState(true);
    const [albums, setAlbums] = useState([]);
    const [variables, setVariables] = useState(initialValues);
    const [fieldErrors, setFieldErrors] = useState({});
    const currentDestination = useSelector(st => st.currentDestination);
    const dispatch = useDispatch();
    const destTitle = language === "deDE" ? currentDestination.deDE : currentDestination.enUK;

    
    const radioOptions = [
        {value:"existing", key:translate("Select existing album")},
        {value: "new", key:translate("Create new album")},
        ];
    
    const selectOptions = albums.map(a => {
        return {key: language === "deDE" ? a.titleDE : a.titleEN, value: a.id}
    });
    // console.log({selectOptions});
    // console.log({albums});
    // console.log(variables);
    
    useEffect(() => {
        const fetch = async() => {
            const albs = (await simpleRequest({url:"albums"})).data?.albums || [];
            // console.log({albs});
            (() => setAlbums([...albs]))();
            (() => setIsLoading(false))();
        }
        if(isLoading)fetch();
    }, [isLoading])
    



    const handleChangeRadio = e => {
        (() => setVariables({...variables, view:e.target.value}))()
    };
    const handleChangeSelect = e => {
        setVariables({...variables, selected:+e.target.value})
    };
    const handleChangeNew = e => {
        if(e.target.value.length > 60)return;
        setVariables({...variables, newName:e.target.value})
    };
    const handleCheck = () => {
        // console.log(currentDestination, currentDestination.geolocation, variables);
        const getFromGeolocation = !variables.getFromGeolocation;
        const newGeolocation = getFromGeolocation ? currentDestination.geolocation : "";
        // conso
        // console.log(getFromGeolocation, newGeolocation);
        (() => setVariables({...variables, getFromGeolocation, newGeolocation}))();
    }




    const handleSubmit = async(e) => {
        e.preventDefault();
        // console.log(variables);
        let albumId;
        if (variables.view === "existing"){albumId = variables.selected;
        }else if (variables.view === "new"){
            // console.log({variables})
            const {newName, newGeolocation} = variables;
            // console.log({newName});
            if(albums.findIndex(a => (a.titleDE === newName || a.titleEN === newName)) >-1){
                setFieldErrors({...fieldErrors, newName:translate("Album already exists")});
                return;
            }
            const data = {titleDE:newName, titleEN:newName, geolocation:newGeolocation};
            albumId = (await simpleRequest({url:'albums', method:"post", data})).data?.album?.id;
        }
        // console.log({albumId});
        if(!albumId)// console.log("ERROR")
        await simpleRequest({url:"albumdestination", method:"post", data:{albumId, destinationId:currentDestination.id}})
        dispatch(fetchDestinationFromAPI(currentDestination.id));
    }
    
    // console.log(variables.getFromGeolocation, variables.newGeolocation, '_______________________________________');
    
    return(
        <div>
            <h6>{`${translate("Add new album")}`}</h6>
            <Form>
                <Form.Group>
                    <Form.Label>{translate("Please select")}</Form.Label>
                    {radioOptions.map(o => {
                    return(
                        <Form.Check
                            key = {o.key}
                            name= "radio"
                            value = {o.value}
                            checked = {o.value === variables.view}
                            onChange = {e => handleChangeRadio(e)}
                            label = {o.key}
                            >
                            </Form.Check>
                    )
                    })}
                </Form.Group>
                
                {variables.view === "existing" && <Form.Group>
                        <Form.Label>{translate("Select album")}</Form.Label>
                        <Form.Control as="select"
                            name="selectList"
                            type="select"
                            value={variables.selected}
                            options = {selectOptions}
                            onChange = {e => handleChangeSelect(e)}
                        >
                            {selectOptions.map(o => {
                                // console.log(o);
                                return(<option key = {o.key} value={o.value}>{o.key}</option>)
                            })}
                        </Form.Control>

                    </Form.Group>}
                {variables.view === "new" && <Form.Group>
                    <Form.Label>{translate("Enter new album")}</Form.Label>
                    <Form.Control
                        name="new"
                        type="text"
                        onChange={e => handleChangeNew(e)}
                    />
                    </Form.Group>}
                {variables.view === "new" && <Form.Group>
                    <Form.Label>{`${translate(`Get Geolocation from`)} ${destTitle}`}</Form.Label>
                    <Form.Check
                        name="getFromGeolocation"
                        type="checkbox"
                        value={variables.getFromGeolocation}
                        onChange={handleCheck}
                    />
                    </Form.Group>}                
                {variables.view === "new" && <Form.Group>
                    <Form.Label>{`Optional: ${translate("Enter geolocation")}`}</Form.Label>
                    <Form.Control
                        name="new"
                        type="text"
                        value={variables.newGeolocation}
                        onChange={e => handleChangeNew(e)}
                    />
                    </Form.Group>}

                <button type="submit" className="btn btn-primary" onClick={e => handleSubmit(e)}>{translate("Submit")}</button>
            </Form>
        </div>
    )
};
export default AlbumNew;