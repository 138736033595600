import React from 'react';
import {Card, Accordion, Button} from 'react-bootstrap';
import UserListCard from './UserListCard'
import {translate} from "./Language/Translate"
import { useSelector } from 'react-redux';
import {BlackWhite} from './styledComponents/colors'


const CruiseAreaCardUser = ({members, eventKey, title, defaultKey}) => {
// console.log({members, eventKey, title, defaultKey});

  const currentUser = useSelector(st => st.currentUser);
  const loggedIn = currentUser && currentUser.username ? true : false;
  // const newKey = isLoggedIn ? eventKey : "invalid";


  const handleClick = () => {
    defaultKey.current = eventKey
  };

    const list = members && members.map(m => {
      return(<UserListCard user={m} key={m.username}  />)
    });

    const btnTxt = !loggedIn ? " (" + translate("For reasons of data protection, this is only possible for logged-in users") + ")" : "";

    return(
      <>
        <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey={eventKey} onClick={handleClick}>
                <BlackWhite>{`${title}${btnTxt}`}</BlackWhite>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={loggedIn ? eventKey : "invalid"}>
              <Card.Body>
                  <div className="row row-eq-height">
                      {(list && list.length) > 0 ? list:translate("No items")}
                  </div>
              </Card.Body>
            </Accordion.Collapse>
        </Card>
        </>
    )
};

export default CruiseAreaCardUser;