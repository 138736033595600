import {
    FETCH_DESTINATION,
    ADD_PROPOSAL,
    EDIT_PROPOSAL,
    ADD_FOLLOW,
    ADD_COMMENT,
    DELETE_FOLLOW,
    ADD_DESTINATION_PHOTO,
    ADD_MEDICAL_INSTITUTE,
    UPDATE_MEDICAL_INSTITUTE,
    ADD_MEDICAL_COMMENT,
    UPDATE_MEDICAL_COMMENT,
    DELETE_MEDICAL_COMMENT,
    // ADD_ALBUM
    } from "../actions/types";

export default function rootReducer(state = {}, action) {
    // console.log({action});
    switch (action.type) {

    case FETCH_DESTINATION:
        if(!action.destination)return state;
        return ({...action.destination});
    case ADD_PROPOSAL:
        
        if(!action.proposal)return state;
        return({...state, proposals:[...state.proposals, action.proposal]});
    case EDIT_PROPOSAL:
        if(!action.proposal)return state;
        return({...state, proposals:[...state.proposals.filter(p => p.id !== action.proposal.id), action.proposal]});
    case ADD_FOLLOW:
        if(!action.proposalId || !action.follow)return state;
        let proposal = state.proposals.find(p => action.proposalId === p.id);
        proposal.follows = [...proposal.follows, action.follow];
        const proposals = [...state.proposals.filter(p => p.id !== action.proposalId), proposal]
        return({...state, proposals});
    case ADD_COMMENT:
        if(!action.follow)return state;
        const {follow} = action;
        const {proposalId, id}  = follow;
        const proposal2 = state.proposals.find(p => proposalId === p.id);
        const newProposal = {...proposal2, follows:[...proposal2.follows.filter(f => f.id !== id), follow]};
        const newProposals = [...state.proposals.filter(p => p.id !== proposalId), newProposal];
        return ({...state, proposals:newProposals})
    case DELETE_FOLLOW:
        if(!action.deleted)return state;
        const proposal3 = state.proposals.find(p => action.deleted.proposalId === p.id);
        const newProposal3 = {...proposal3, follows:[...proposal3.follows.filter(f => f.id !== action.deleted.id)]};
        return {...state, proposals:[...state.proposals.filter(p => p.id !== action.deleted.proposalId), newProposal3]};   
    case ADD_DESTINATION_PHOTO:
        if(!action.destinationPhoto)return state;
        return {...state, destinationPhotos:[...state.destinationPhotos, action.destinationPhoto]};
    case ADD_MEDICAL_INSTITUTE:
        if(!action.medicalInstitute)return state;
        return{...state, medicalInstitutes:[...state.medicalInstitutes, action.medicalInstitute]};
    case UPDATE_MEDICAL_INSTITUTE:
        if(!action.medicalInstitute)return state;
        return{...state, medicalInstitutes:[
            ...state.medicalInstitutes.filter(mi => mi.id !== action.medicalInstitute.id), action.medicalInstitute]};
    case ADD_MEDICAL_COMMENT:
        const medInst = state.medicalInstitutes.find(mi => mi.id === action.medicalComment.medicalInstituteId);
        medInst.comments = [...(medInst.comments || []), action.medicalComment];
        return{...state, medicalInstitutes:[
            ...state.medicalInstitutes.filter(mi => mi.id !== action.medicalComment.medicalInstituteId), medInst
        ]};
    case UPDATE_MEDICAL_COMMENT:
        const medInst2 = state.medicalInstitutes.find(mi => mi.id === action.medicalComment.medicalInstituteId);
        medInst2.comments = [...medInst2.comments.filter(mc => mc.id !== action.medicalComment.id), action.medicalComment]
        return{...state, medicalInstitutes:[
            ...state.medicalInstitutes.filter(mi => mi.id !== action.medicalComment.medicalInstituteId), medInst2
        ]};
    case DELETE_MEDICAL_COMMENT:
        const medInst3 = state.medicalInstitutes.find(mi => mi.id === action.deleted.medicalInstituteId);
        medInst3.comments = [...medInst3.comments.filter(mc => mc.id !== action.deleted.id)];
        return{...state, medicalInstitutes:[
            ...state.medicalInstitutes.filter(mi => mi.id !== action.deleted.medicalInstituteId), medInst3
        ]};
    // case ADD_ALBUM: // hat Bilder nicht dabei
    //     // console.log({action});
    //     return{...state, albums:[...state.albums, action.albumDestination]};
    default:
      return state;
  }
};