const chatCategories = {
    garden:{
        category: "garden",
        label: {deDE: "Gartenreisen", enUK:"open"},
        keywords: ["Gartenreise"]
    } ,
    gulf:{
        category: "gulf",
        label: {deDE: "Golfreisen", enUK:"open"},
        keywords: "Golfreise"
    } ,
    wellness:{
        category: "wellness",
        label: {deDE: "Wellnessreisen", enUK:"open"},
        keywords: "Wellnessreise"
    }, 
    active:{
        category: "active",
        label: {deDE: "Aktivreisen", enUK:"open"},
        keywords: "Aktivreise, Sportreise"
    }, 
    classicalMusic:{
        category: "classicalMusic",
        label: {deDE: "Musikreisen Klassik", enUK:"open"},
        keywords: "Musikreise Klassik"
    },
    expedition:{
        category: "expedition",
        label: {deDE: "Expeditionsreisen", enUK:"open"},
        keywords: "Expeditionsreise"
    },
};
export default chatCategories;