import React, {useState, useEffect} from "react";
import {Link, useParams, useHistory} from "react-router-dom"
import {useSelector, useDispatch} from "react-redux";
import {actualDateString} from '../helpers/Date'
import { simpleRequest } from "../newForms2/simpleRequest";
import {ensureAuthorized} from '../helpers/auth'
import { fetchVesselFromAPI, updateCurrentVesselOnAPI } from '../actions/currentVessel';
import { fetchCruisesFromAPI } from '../actions/cruises';
import CruiseListItems from './CruiseListItems';
import CruiseFunctions from "../functions/CruiseFunctions";
import { fetchVesselsFromAPI } from "../actions/vessels";
import { translate } from "./Language/Translate";
import Spinner from './Spinner';
import UploadAreaSingle from '../newForms2/upload/UploadAreaSingle'
import VesselPhotos from "./PhotoShows/Vessel/VesselPhotos";

const VesselDetails = () => {
    // console.log("RENDER VON VesselDetails")
    const vesselId = +useParams().vessel_id;
    const actualDate = actualDateString();
    const currentUser = useSelector(st => st.currentUser);
    const currentVessel = useSelector(st => st.currentVessel)
    const history = useHistory();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [confirmation, setConfirmation] = useState(false);
    const [uploadArea, setUploadArea] = useState(false);
    const [uploadData, setUploadData] = useState(null);
    const [assignedCruises, setAssignedCruises] = useState([])

    // console.log({currentVessel})

    useEffect (()=>{
        const fetchData = async()=>{
            dispatch(fetchCruisesFromAPI());
            dispatch(fetchVesselsFromAPI());
            dispatch(fetchVesselFromAPI(+vesselId));
            setIsLoading(false);
        }
        if(isLoading)fetchData();
    },[isLoading, setIsLoading, dispatch, vesselId]);

    useEffect(() => { 
        const fetch = () => {
                const crs = currentVessel.cruises;
                const crsJoined = crs && CruiseFunctions.setIsJoined(currentUser, crs);
                setAssignedCruises(crsJoined)
        } 
        if(currentVessel.cruises)fetch()
    }, [currentVessel, currentUser]);

    useEffect(() => {
        // dispatch when the uploadData change
        const updateUrl = async () => {
            // console.log('inside useEffect', {uploadData})
            if(uploadData.data && uploadData.data.photoUrl){
                // console.log(uploadData.data.photoUrl)
                await dispatch(updateCurrentVesselOnAPI({vesselId: currentVessel.id, data:{photoUrl:uploadData.data.photoUrl}}));
                (() => setUploadData(null))();
            };
        };
        if(uploadData)updateUrl();
        (()=> setUploadArea(false))();
    },[uploadData, dispatch, currentVessel]);

    if(!(currentVessel && assignedCruises))return <Spinner/>
            
    const handleDelete = async() =>{
        const response = await simpleRequest({method:"delete", url:`vessels/${vesselId}`, data:{}});
        // console.log({response});
        history.push('/vessels')
    };

    const handleConfirm = () => setConfirmation(true)
    const handleCancel = () => setConfirmation(false)
    
    const Confirm = () => (
            <div>
                <h4>Are you sure to delete this Vessel?</h4>
                <button className="btn btn-danger mr-1" onClick={handleDelete}>Delete</button>
                <button className="btn btn-secondary mx-1 my-1" onClick={handleCancel}>Cancel</button>
            </div>
    )
    const mayEditVessel = currentUser && currentUser.userStatus?ensureAuthorized(currentUser.userStatus, '#adVe', currentVessel.companyHandle):false;
    const mayAddCruise  = currentUser && currentUser.userStatus?ensureAuthorized(currentUser.userStatus, '#adCr', currentVessel.companyHandle):false;
    
    assignedCruises.forEach(c=>{
        c.name=currentVessel.name;
        c.prefix=currentVessel.prefix;
        c.showVessel=false;
    });
    const currentCruises = assignedCruises.filter(c =>{return (c.startAt <= actualDate && c.endAt >= actualDate)});
    const plannedCruises = assignedCruises.filter(c =>{return (c.startAt > actualDate)});
    const passedCruises = assignedCruises.filter(c =>{return (c.endAt < actualDate)});
    const labelPhotoButton = currentVessel.photoUrl?translate("Replace Photo"):translate("Add Photo");

    console.log({currentCruises, plannedCruises})

    return (
        <div className="text-center p-1" style={{backgroundColor:"rgba(255,255,255,.4)"}}>

             <h4 className="text-center mb-4">{currentVessel.prefix} {currentVessel.name} - <small>{translate(currentVessel.type)}</small></h4>
             <VesselPhotos />
             {/* {currentVessel.photoUrl && <Image src={currentVessel.photoUrl} alt="" className="img-fluid img-thumbnail" width="90%" height="auto"/>} */}
             {/* {currentVessel.photoUrl && <Image src={currentVessel.photoUrl} alt="" className="img-fluid img-thumbnail" style={{maxWidth:"60%", height:"auto"}} />} */}
             {!confirmation && uploadArea && <UploadAreaSingle title="photoUrl" setUploadArea={setUploadArea} 
                    methods={["file", "url", "link"]} uploadData={uploadData} setUploadData={setUploadData} subFolder = {`vessels/${currentVessel.id}`}/>}
             {/* <h6 className="mt-3">{currentVessel.short?currentVessel.short + " - ":""}{currentVessel.companyName}</h6> */}
             <h6>{currentVessel.note?currentVessel.note:""}</h6>
             <p>{currentVessel.description}</p>
             <div  className="p-1">
             {/* <div style={{backgroundColor:"rgba(255,255,255,0.5)", borderRadius:"5px"}} className="p-1"> */}
                {!confirmation && mayEditVessel?<Link to= {`/vessels/edit/${currentVessel.id}`} className='btn btn-outline-dark mx-1 my-1'>{translate('Edit Vessel')}</Link>:null}
                {!confirmation && mayEditVessel && <button className="btn btn-outline-dark m-1" onClick = {()=>setUploadArea(!uploadArea)}>{labelPhotoButton}</button>}
                {!confirmation && mayAddCruise?<Link to={`/vessels/${currentVessel.id}/new_cruise`} id={currentVessel.id} className="btn btn-outline-dark mx-1 my-1">{translate('Add Cruise')}</Link>:null}
                
                <Link to= {`/companies/${currentVessel.companyHandle}`} className='btn btn-outline-dark mx-1 my-1'>{currentVessel.companyName}</Link>
                {!confirmation && mayEditVessel?<button className='btn btn-outline-danger mx-1 my-1' onClick={handleConfirm}>{translate('Delete Vessel')}</button>:null}
             </div>
             {confirmation?<Confirm/>:null}
             
             {currentCruises.length > 0 && <CruiseListItems headLine="Current" cruises={currentCruises} currentUser={currentUser} period="none"/>}
             {plannedCruises.length > 0 && <CruiseListItems headLine="Future" cruises={plannedCruises} currentUser={currentUser} period="year"/>}
             {passedCruises.length > 0 && <CruiseListItems headLine="Past" cruises={passedCruises} currentUser={currentUser} period="year"/>}
        </div>       
    )
};

export default VesselDetails;