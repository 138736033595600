export const getErrorString = (response) => {
    if(!response)return "NO RESPONSE";
    // console.log(response.status)
    // console.log(response);
    // console.log(response.response);
    // console.log(response.response && response.response.data);
    // console.log(response.errorMessage);
    const possibility1 = response.errorMessage || response.response || response.response.data || "SERVER ERROR (not defined)"
    // const possibility2 = response.errorMessage;
   

    return possibility1;
};

export const getErrorObject = (response) => {
    const errorMessage = getErrorString(response);
    const status = response.status;
    return({status, errorMessage});
};

export const displayServerError = (errArr, setServerError) => {
    setServerError(JSON.stringify(errArr));
    setTimeout(setServerError(''), 3000);
};