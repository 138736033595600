import React from "react";
import { translate } from "../components/Language/Translate";
import { StyledDiv } from "../components/styledComponents/divs";

const Confirmation = ({setConfirmation, func, text="Are you sure?"}) => {
    return (
        <StyledDiv>
            <h4>{translate(text)}</h4>
            <button className="btn btn-outline-danger" onClick={func}>{translate("Yes")}</button>
            <button className="btn btn-outline-secondary ml-2" onClick={() => setConfirmation(false)}>{translate("No")}</button>
        </StyledDiv>
    )
};
export default Confirmation