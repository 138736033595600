import React from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { BlackWhite } from '../../../styledComponents/colors';
import FormatTextArray from '../../../../helpers/FormatTextArray';
import Icons from '../../Icons';


const AllObservationMap = ({props}) => {
    const {center, zoom, locationObj, setSelectedGeolocation} = props;

    return(
            <div id="map" style = {{height:'80vh', width:"99%", border:"1px solid black"}}>
                <MapContainer style = {{minHeight:'100%', minWidth :"100%" }} center={center} zoom={zoom} scrollWheelZoom={false}>
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {locationObj.map((d, idx) => {
                    return(
                        <Marker icon = {Icons.boatIcon }key = {idx} position={d.geolocation.split(', ')} riseOnHover={true}
                                onMouseOver={e => e.target.openPopup()} onMouseOut={e => e.target.closePopup()}>
                            <Popup >
                                <div>
                                    <h6><BlackWhite onClick={() => setSelectedGeolocation(d.geolocation)}>
                                        {d.headline}
                                    </BlackWhite></h6>
                                    <FormatTextArray arr = {d.animals}/>
                                </div>
                            </Popup>
                        </Marker>
                    )
                  }
)}
                </MapContainer>
            </div>
    );
};
export default AllObservationMap;