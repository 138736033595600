// 11.11.23

import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import { fetchCurrentUserFromApi } from "../actions/currentUser";
import Spinner from './Spinner';
import {Translate} from './Language/Translate'
import {browserLanguages} from './Language/browserLanguages'
import SubApp from "./SubApp";
import { fetchGroupsFromApi } from "../actions/groups";
import {globals} from '../helpers/globals';

function App() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const currentUser = useSelector(st => st.currentUser);

  useEffect(function() {
    const fetchUser = () => {
      const userLS = JSON.parse(localStorage.getItem('user'));
      globals.token = (userLS && userLS.token) || null;
      globals.userStatus = (userLS && userLS.userStatus) || "user";
      if (userLS)dispatch(fetchCurrentUserFromApi(userLS.username, userLS.token));
      dispatch(fetchGroupsFromApi());
      (() => setIsLoading(false))();
    };
    if (isLoading) fetchUser();
  }, [isLoading, dispatch, setIsLoading, currentUser]);

  if(isLoading) return <Spinner/>;
  
  const browserLanguageShort = navigator.language || navigator.userLanguage || "en";
  const browserLanguage = browserLanguages[browserLanguageShort] || "enUK";
  const userLanguage = currentUser?.language;
  const language = userLanguage || browserLanguage;
  Translate.language = language
  globals.language = language;

  return (
    <div >
      <SubApp />
    </div>
    );
};
export default App;
