import {translate} from "../../components/Language/Translate";
import { simpleRequest } from "../simpleRequest";

class DayFormData {

    static async dayNew(data) {
        
        // console.log('inside DayFormData.dayNew)))))))))))))))))))))))))))))))))))))))))))))))))))))))))', data, data.params, +data.params.cruise_id)
        // console.log(data.params, +data.params.cruise_id, data.params.date)
        
        const consts = new DayFormSchema();
        
        consts.fields.cruiseId.value = +data.params.cruise_id;
        let fields;
        Object.entries(consts.fields).forEach(e => fields = {...fields, [e[0]]: e[1].value});
        fields.date = data.params.date;
        consts.formType = 'dayNew';
        consts.request = {
            url:`days/`,
            method:"post"
        };
        consts.nextPage = (`/cruises/${data.params.cruise_id}`);
        consts.sendData = ["date", "cruiseId", "destinationId", "departure", "arrival", "description"]
        const vars={fields};
        // console.log({consts, vars})
        return {consts, vars};
    }
    
    static async dayEdit(data){
        
        // console.log('inside CruiseFormData. cruiseEdit', {data})
        const dayId=+data.params.id
        const day = await DayFunctions._getById(dayId)
        // console.log({day,dayId})
        const consts = new DayFormSchema();
        // consts.fields.destinationId.settings.options=[{key:day.destinationId, value:day.deDE}]
        consts.fields.destinationId.value=day.destinationId
        consts.fields.cruiseId.value=day.cruiseId;
        consts.sendData = ["date", "destinationId", "departure", "arrival", "description"]
        let fields;
        consts.sendData.forEach(k => fields = {...fields, k: day[k]})
        consts.formType = 'dayEdit';
        consts.formLabels = {
            title:"Edit Day",
            btnLabel:"Update Day"
        }
        consts.request = {
            url:`days/${dayId}`,
            method:"patch"
        };        
        consts.nextPage = (`/cruises/${day.cruiseId}`);
        consts.oldValues=day;
        const vars={fields:day}
        
        return {consts, vars};
    };
        
};
       




class DayFormSchema{
    constructor(){
        this.formType="dayNew";
        this.nextPage=""
        this.request = {
            url:"days",
            method:"post",
        };
        this.formLabels = {title: "Add Day", btnLabel:"Add"};


        this.fields = {

            cruiseId : {
                name:"cruiseId",
                label : "Cruise ID",
                fieldType: "info_stay",
                type:"number",
                value :0,
                required:true
            },
            destinationId:{
                name:"destinationId",
                disabled:true,
                type:"text",
                fieldType:"text",
                label:"Destination ID",
                toNumber:true,
                value:""
            },            
            destination : {
                name:"destination",
                label : "Destination",
                type:"text",
                fieldType: "textWithValuesEvent",
                disabled:false,
                value :"",
                toolTip:"Please write and then select an item from the list."
            },
            date: {
                name:"date",
                label : translate("Date"),
                type:"text",
                fieldType: "text",
                disabled:false,
                value :"",
                validation:{validDateString:true}
            },

            arrival: {
                name:"arrival",
                label : translate("Arrival"),
                type:"text",
                fieldType: "text",
                disabled:false,
                value :"",
            },
            departure: {
                name:"departure",
                label : translate("Departure"),
                type:"text",
                fieldType: "text",
                disabled:false,
                value :"",
            },
            description: {
                name:"description",
                label : translate("Description"),
                type:"textarea",
                fieldType: "textarea",
                disabled:false,
                value :"",
            }
        }
    }
};

class DayFunctions{
    static async _getById(id){
        const response = await simpleRequest({url:`days/${id}`, method:"get"});
        const cc = response && response.data
        return cc;
    }

}


export default DayFormData;