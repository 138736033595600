import React, { useState} from 'react';
import {getDateObject} from '../helpers/Date';
import {Accordion, Card, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
// import {simpleRequest} from '../newForms2/simpleRequest'
import {translate, Translate} from "./Language/Translate"
import { approveFriendRequestOnApi, deleteFriendRequestOnApi } from '../actions/currentUser';
import { useDispatch, useSelector } from 'react-redux';
import {BlackWhite} from './styledComponents/colors';
import NewsBadge from './NewsBadge';


const Row = (props) =>{

    const{friend, dir}=props
    // console.log({friend, props})

    const [confirmation, setConfirmation] = useState(false);
    const [btnText, setBtnText] = useState();
    const [target, setTarget] = useState();
    const dispatch=useDispatch();
    
    
    const handleApprove = async (e) => {
      await dispatch(approveFriendRequestOnApi(+e.target.dataset.id))
    };
    
    const handleReject = (e) => {
      setTarget(e.target)
      setBtnText("Reject Request")
      setConfirmation(true);
    }
    const handleCancelRequest = (e) => {
      setTarget(e.target)
      setBtnText("Cancel and delete Request")
      setConfirmation(true);
    }

    const handleCancel = (e) => {
      
      setConfirmation(false)

    }
    // const handleMessage = () => {}
    const handleDelete = async() => {
      // console.log(target)
      setConfirmation(false)
      await dispatch(deleteFriendRequestOnApi(+target.dataset.id))
      
    }



    const Line=() =>{

      const language = useSelector(st => st.currentUser)?.language || Translate.language;
        const appr = friend.approvedAt ? `, ${translate('approved at')} ${getDateObject(friend.approvedAt, language).dateTime}.`:'';
        
        return (
            <p>
              <BlackWhite>
                {friend.nickname}<small>, {translate('applied at')} {getDateObject(friend.appliedAt, language).dateTime}{appr}</small>
              </BlackWhite>
            </p>
        )
        }

    const Body=() =>{
        return(
        <div style={{backgroundColor:"rgba(255,255,255,.5"}} className="p-2">
          <div>
            {friend.message && <h5>{translate('Message')}:</h5>}
            <p >{friend.message}</p>

            {dir==="requestFrom"?<button onClick={handleApprove} data-id={friend.id} className="btn btn-outline-dark mx-1 my-1">{translate('Approve Request')}</button>:null}
            {dir==="requestFrom"? <button onClick={handleReject} data-id={friend.id} className="btn btn-outline-danger mx-1 my-1">{translate('Reject Request')}</button>:null}
            {dir==="requestTo"? <button onClick={handleCancelRequest} data-id={friend.id} className="btn btn-outline-danger mx-1 my-1">{translate('Cancel Request')}</button>:null}
            <Link to= {`/messages/${friend.friendName}`} className="btn btn-outline-dark mx-1 my-1">{translate("Write Message")}</Link>
            <Link to={`/users/show/${friend.friendName}`} className="btn btn-outline-dark mx-1 my-1">{friend.friendName}</Link>
          </div>
          <div className="card-user mt-3">
              {friend.photoUrl && <img src={friend.photoUrl} className="mb-4" alt={friend.nickame} style={{height:"40vh", width:"auto"}}/>}
          </div>
        </div>
      )
    }

    const DeleteArea = () =>{
      // console.log('inside deleteArea')
      return (
        <div style={{backgroundColor:"rgba(255,255,255,.5"}} className="py-3">
          <h3>{translate('Delete Friend Request')}</h3>
          <p>{translate('Are you sure?')}</p>
          <button onClick={handleDelete} className="btn btn-outline-danger mx-1">{btnText}</button>
          <button className="btn btn-outline-info mx-1" onClick={handleCancel}>{translate('Back to Request')}</button>
        </div>
      )
    }
    // console.log({serverError})
    return(
        <Card>
          <Card.Header style={{backgroundColor:"rgba(255,255,255,.5"}}>
            <Accordion.Toggle as={Button} variant="link" eventKey={props.eventKey} >
              <div className="row row-eq-height text-center my-1">
                <div className="col-md-6 text-center">
                  {friend.photoUrl && <img src={friend.photoUrl}  alt={friend.nickame} style={{height:"70px", width:"auto"}}/>}
                </div>
                <div className="col-md-6">
                  <Line />
                </div>
              </div>
            </Accordion.Toggle>
          </Card.Header >
          <Accordion.Collapse eventKey={props.eventKey}>
            <Card.Body>
              {(!confirmation) &&  <Body/>}
              {confirmation && <DeleteArea/>}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
    );
};

const Rows = (props) =>{

    const {friends, dir,title} = props;
    // console.log({friends,dir,title})
    if (friends.length === 0) return (<p>{translate("No items")}.</p>)

    return(
        <>
               
                <Accordion>
                    {friends.map(friend =>{
                        return(<Row key={friend.id} eventKey={friend.id} friend={friend} dir={dir} title={title}/>)})}
                </Accordion>
         
        </>
    )
    }
    




const UserAreaCardFriendsCard = (props) =>{

    // console.log({props})
    const {friends, setIsLoading} = props;
    

    return(
        <Card>
            <Card.Header style={{backgroundColor:"rgba(255,255,255,.5)"}}>
              <Accordion.Toggle as={Button} variant="link" eventKey={props.eventKey} >
                <BlackWhite>{props.title}</BlackWhite> 
                {props.type==="requestFrom" && <NewsBadge count={props.newsCount}/>}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={props.eventKey}>
              <Card.Body>
                    {props.type==="requestFrom" && <Rows friends={friends} dir={props.type} setIsLoading={setIsLoading}/>}
                    {props.type==="requestTo" && <Rows friends={friends} dir={props.type} setIsLoading={setIsLoading}/>}
                    {props.type==="friends" && <Rows friends={friends} dir={props.type} setIsLoading={setIsLoading}/>}
              </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
};

export default UserAreaCardFriendsCard;