// 4.12.23
import React, {useState, useEffect} from "react";
import { Translate } from "../Language/Translate";
import AlbumListItem from "./AlbumListItem";
import { BlackWhite } from "../styledComponents/colors";
import AlbumObj from "../../helpers/albums/albumObj";
import Spinner from "../Spinner";

const AlbumInitialList = ({albums, handleClick}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [newArr, setNewArr] = useState([]);

    useEffect(() => {
        const fetch = async() => {
            setNewArr(albums.map(a => {return {...a, newTitle: AlbumObj.analyze(a, Translate.language)}}))
            setIsLoading(false);
        }
        if(isLoading)fetch()
    }, [setIsLoading, setNewArr, isLoading, albums]);

    // console.log({newArr});
    // console.log(newArr.map(i => i.newTitle));

    if(isLoading)return <Spinner/>

    const initials = Array.from(new Set(newArr.map(newItem => newItem.newTitle.slice(0,1)))).sort();
    return(
        <div >
            <div className = "text-center">
                {initials.map((i, idx) => 
                    <span key={idx}>{idx !== 0 && i !== "-"? " - ":""}
                        <a href={`#${i}`}><BlackWhite>{i}</BlackWhite></a>
                    </span>
                )}
            </div>
            {initials.map(i => {
                const subAlbums = newArr.filter(newItem => newItem.newTitle.slice(0,1) === i)
                return(
                    <div id={i} className = "my-3" key={i}>
                        <span className = "mx-3"><big><b>{i}</b></big></span><br/>
                        {subAlbums.map(a => <AlbumListItem key={a.id} album={a} handleClick={handleClick}/>)}
                    </div>
                )
            })}
        </div>
    )
};
export default AlbumInitialList;