import React from "react";
import Test from "../../helpers/test";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalVarToState } from "../../actions/globals";

// const dispatchError = () => {

// }

const TestArea = () => {
    Test.testFunctions();
    const globals = useSelector(st => st.globals);
    const {serverError} = globals;
    const dispatch = useDispatch(); 
    const exampleText = "This is an error";
    // console.log(serverError, typeof serverError)
    const error = typeof serverError === "string" ? serverError : JSON.stringify(serverError);
    
    const testServerError = (errMessage) => {
        // console.log({errMessage});
        dispatch(setGlobalVarToState({serverError:errMessage}));
        setTimeout(() => dispatch(setGlobalVarToState({serverError:''})), 3000);
    };

    return(

        
        <div>
            <h4>Testarea</h4>
            <button className="btn btn-outline-dark" onClick={() => testServerError(exampleText)}>ServerError</button>
            <p>{error}</p>

        </div>
    )
};
export default TestArea;