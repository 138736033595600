import { translate } from "../../components/Language/Translate";
// import MedicalDepartments from "../../components/Destinations/MedicalDepartments";

class MedicalInstituteFormSchema{
    constructor(){
        this.fields={
            name:{
                name:"name",
                label : translate("Name"),
                fieldType: "text",
                type:"text",
                value :"",
                validation :{minLength: 5, maxLength: 50},
                autoFocus: true,
                required: true
            },
            website:{
                name:"website",
                label : translate("Website"),
                fieldType: "text",
                type:"text",
                value :"",
            },
            departments:{
                name: "departments",
                label: translate("Departments"),
                fieldType:"multicheck",
                options:[
                    {key: "Hospital basic", label:translate("Hospital basic"), value:false},
                    {key: "Hospital regular", label:translate("Hospital regular"), value:false},
                    {key: "Hospital maximal", label:translate("Hospital maximal"), value:false},
                    {key: "Gynecology", label:translate("Gynecology"), value:false},
                    {key: "Pediatric", label:translate("Pediatric"), value:false},
                    {key: "Doctor", label:translate("Doctor"), value:false},
                    {key: "Dentist", label:translate("Dentist"), value:false},
                    {key: "Oculist", label:translate("Oculist"), value:false},
                    {key: "Pharmacy", label:translate("Pharmacy"), value:false},
                ]
            }, 
            address:{
                name:"address",
                label : translate("Address"),
                fieldType: "textarea",
                type:"text",
                value :"",
                required:false,
            },
            phone:{
                name:"phone",
                label : translate("Phone"),
                fieldType: "text",
                type:"text",
                validation :{maxLength: 30},
                value :"",
                required:false
            },
            email:{
                name:"email",
                label : translate("Email"),
                fieldType: "text",
                type:"email",
                validation :{maxLength: 40},
                value :"",
                required:false
            },
            geolocation:{
                name:"geolocation",
                label : translate("Geolocation"),
                fieldType: "text",
                type:"text",
                value :"",
                placeholder: translate("Dezimales Format: 54.22222222, 7.3333333333"),
                toolTip:translate("Dezimales Format: 54.22222222, 7.3333333333")
            },
            description:{
                name:"description",
                label : translate("Description"),
                fieldType: "textarea",
                type:"text",
                value :"",
            },
        };
    };
};
export default MedicalInstituteFormSchema;