
import {
    FETCH_CURRENT_USER,
    UPDATE_CURRENT_USER,
    ADD_BLOCKED,
    REMOVE_BLOCKED,
    MARK_MESSAGE_AS_READ,
    MARK_MESSAGE_AS_DELETED_BY_FROM,
    MARK_MESSAGE_AS_DELETED_BY_TO,
    APPROVE_FRIEND_REQUEST,
    REJECT_FRIEND_REQUEST,
    CANCEL_FRIEND_REQUEST,
    FETCH_USER_DAYS,
    ADD_FAVOURITE,
    DELETE_FAVOURITE
    } from "../actions/types";





export default function rootReducer(state = {}, action) {
  // console.log({state,action})
  switch (action.type) {
    case FETCH_CURRENT_USER:
      return ({...action.currentUser});
    case UPDATE_CURRENT_USER:
      // console.log({action})
      return ({...state, ...action.updated});
    case ADD_BLOCKED:
      return({...state, blockedUsers:[...state.blockedUsers, action.blocked]})
    case REMOVE_BLOCKED:
      return({...state, blockedUsers:state.blockedUsers.filter(b => b !== action.blocked)});
    case MARK_MESSAGE_AS_READ:
      const idx = state.messages.receivedMessages.findIndex(m => m.id === +action.read.id)
      const newMsg = {...state.messages.receivedMessages[idx], readAt:action.read.readAt}
      return({
        ...state, messages:{
          ...state.messages, receivedMessages:[
            ...state.messages.receivedMessages.filter(m => m.id !== +action.read.id), newMsg]}})
    case MARK_MESSAGE_AS_DELETED_BY_FROM:
      // console.log('inside MARK_MESSAGE_AS_DELETED_BY_FROM')
      return({
        ...state, messages:{
          ...state.messages, sentMessages:[
            ...state.messages.sentMessages.filter(m => m.id !== +action.deletedByFrom.id)]}});
    case MARK_MESSAGE_AS_DELETED_BY_TO:
      // console.log('inside MARK_MESSAGE_AS_DELETED_BY_TO', state.messages, {action})
      return({
        ...state, messages:{
          ...state.messages, receivedMessages:[
            ...state.messages.receivedMessages.filter(m => m.id !== +action.deletedByTo.id)]}});
    case APPROVE_FRIEND_REQUEST:
      // console.log('inside APPROVE_FRIEND_REQUEST', {action}, state.friends.friendRequestsFrom)
      const idx4 = state.friends.friendRequestsFrom.findIndex(m => m.id === +action.approved.id);
      const newFriend = {...state.friends.friendRequestsFrom[idx4], approvedAt:action.approved.approvedAt}
      // console.log({idx4, newFriend})
      return({
        ...state, friends:{
          ...state.friends
          , friends:[...state.friends.friends, newFriend]
          , friendRequestsFrom:[...state.friends.friendRequestsFrom.filter(m => m.id !== +action.approved.id)]}});
    case REJECT_FRIEND_REQUEST:
      // console.log('inside REJECT_FRIEND_REQUEST', action);
      return({
        ...state, friends:{
          ...state.friends, friendRequestsFrom:[
            ...state.friends.friendRequestsFrom.filter(f => f.id !== +action.rejected.id)]}});              
    case CANCEL_FRIEND_REQUEST:
      // console.log('inside CANCEL_FRIEND_REQUEST', action);
      return({
        ...state, friends:{
          ...state.friends, friendRequestsTo:[
            ...state.friends.friendRequestsTo.filter(f => f.id !== +action.cancelled.id)]}}); 
    case FETCH_USER_DAYS:
      // console.log(action);
      return({...state, days: action.days});
    case ADD_FAVOURITE:
          // console.log({action});
          return {...state, favourites:[...state.favourites, action.favourite]};
    case DELETE_FAVOURITE:
      // console.log({action});
      return {...state, favourites:state.favourites.filter(f => f.id !== action.deleted.id)};
    default:
      return state;
};
};
