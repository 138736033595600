import React from "react";
import { Col, Row } from "react-bootstrap";
import { translate } from "./Language/Translate";

const YesNoButtons = ({yesFunc, noFunc}) => (
    <Row className="my-3">
        <Col>
            <button className="btn btn-outline-danger col-12" 
                onClick = {yesFunc}>{translate("Yes")}</button>
        </Col>
        <Col>
            <button className="btn btn-outline-secondary col-12" 
                onClick = {noFunc}>{translate("No")}</button>
        </Col>
    </Row>
);
export default YesNoButtons;