
import {
FETCH_CRUISES,
// ADD_CRUISE, 
UPDATE_CRUISE_IN_CRUISELIST,
DELETE_CRUISE_FROM_CRUISELIST
} from "../actions/types";

function sortByStartAt(cruises) {

  return cruises.sort(startAt => startAt);
}


export default function rootReducer(state = [], action) {

  switch (action.type) {
   
    case FETCH_CRUISES:
      if(!action.cruises)return state;
      return sortByStartAt([...action.cruises]);
    case UPDATE_CRUISE_IN_CRUISELIST:
      // console.log({action})
      if(!action.cruise)return state;
      return sortByStartAt([...state.filter(c => c.id !== action.cruise.id), action.cruise]);
    case DELETE_CRUISE_FROM_CRUISELIST:
      // console.log(action);
      if(!action.deleted)return state;
      return sortByStartAt([...state.filter(c => c.id !== action.deleted.id)]);
    default:
      return state;
  };
};
