import React from "react";
import {translate} from "../Language/Translate"
import ArticleListItem from "./ArticleListItem";


const ArticleListTable = ({list}) => {
    const showAuthor=true;
    return(
        
     
            <table className="table">
                <thead>
                    <tr>
                        <th>{translate("Created at")}</th>
                        <th>{translate("Title")}</th>
                        <th>{translate("Subtitle")}</th>
                        {showAuthor && <th>{translate("Author")}</th>}
                    </tr>
                </thead>

                <tbody>
                        {list.map(ar => <ArticleListItem article={ar} key={ar.id}/>)}
                </tbody>

            </table>
        
    )
};
export default ArticleListTable;