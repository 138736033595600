import React, {useState, useEffect, useCallback} from "react";
import {useDispatch, useSelector} from 'react-redux';
import { translate } from "../../components/Language/Translate";
import {fetchDestinationsFromAPI} from '../../actions/destinations';
import {sortArrayByObjectField} from '../../helpers/sort';
import {useHistory} from "react-router-dom";
import { simpleRequest } from "../../newForms2/simpleRequest";

const DestinationEditByAdmin = ({day, setEditForm, setNewDays, newDays}) => {
    // console.log({day, setEditForm});
    const [proposals, setProposals] = useState([]);
    const history = useHistory();
    const dispatch = useDispatch();
    
    const getProposalsFromChars = (word, destinations, numOfChars) => {
        const newWord = word.toLowerCase().replaceAll(' ', '').replaceAll('sch', '#');
        const newSet = new Set();
        const newArray = [];
        for (let i = 0; i < newWord.length - numOfChars + 1; i++){
            const chars = newWord.slice(i, i+numOfChars);
            destinations.forEach(d =>  {
                // console.log(d.enUK.toLowerCase());
                if (d.enUK.toLowerCase().replaceAll(' ', '').replaceAll('sch', '#').includes(chars) 
                    || d.deDE.toLowerCase().replaceAll(' ', '').replaceAll('sch', '#').includes(chars)){
                    // console.log(d.enUK.toLowerCase(), d.deDE.toLowerCase(), chars);
                    if(!newSet.has(d.deDE)) newArray.push(d);
                    newSet.add(d.deDE);
                }
                
            });
        };
        return newArray;
    };



    // const currentCruise = useSelector(st => st.currentCruise);
    const destinations = useSelector(st => st.destinations);
    // console.log({day, destinations}, day.destination);




    useEffect(() => {
        const fetch = async() => {
            dispatch(fetchDestinationsFromAPI());
        };
        if(destinations.length === 0)fetch();
    },[destinations.length, dispatch]);

    useEffect(() => {
        (() => setProposals(sortArrayByObjectField(getProposalsFromChars(day.destination, destinations, 4),"deDE")))();
    }, [day, destinations]);

    const handleClick = useCallback(async(e) => {
        const destId = e.target && +e.target.id;
        // console.log({destId})
        if(!destId)return;
        await simpleRequest({url:`days/${day.id}`, method:"patch", data:{destinationId:+e.target.id, destination:""}});
        (() => setNewDays([...newDays.filter(d => d.id !== day.id)]))();
    },[day.id, setNewDays, newDays]);
    
    useEffect(() => {
        const list = document.getElementById(`selectList_${day.id}`);
        list.addEventListener('click', e => handleClick(e));
        // console.log("Installing the EVENTLISTENER")
        return () => {
          // console.log('REMOVING Eventlistener')
          window.removeEventListener('click', e => handleClick(e));
        };
    }, [day.id, handleClick]);

        // (() => setEditForm(false))();
    
    const handleDelete = async(e) => {
        await simpleRequest({url:`days/${day.id}`, method:"patch", data:{destination:null}});
        (() => setNewDays([...newDays.filter(d => d.id !== day.id)]))();
    }

    return(
        <div style={{border:"1px solid grey", borderRadius:"5px"}} className="p-2">

            <h6>{day.destination}</h6>
            {proposals.length === 0 && <p>{translate("No items")}</p>}
            <ul id={`selectList_${day.id}`}>
                {proposals.map(p => <li key={p.id} id={p.id}>{p.deDE} / {p.enUK}</li>)}
            </ul>
            <button className="btn btn-outline-success" onClick={() => history.push(`cruises/${day.cruiseId}`)}>{translate("Open Cruise")}</button>
            <button className="btn btn-outline-info ml-1" onClick={() => history.push(`destinations`)}>{translate("Open Destinations")}</button>
            <button className="btn btn-outline-danger ml-1" onClick={handleDelete}>{translate("Delete Item")}</button>

        </div>
    )
    
    
};
export default DestinationEditByAdmin;