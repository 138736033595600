import React, { useEffect, useState } from "react";
import Spinner from '../../components/Spinner';
import { translate } from "../../components/Language/Translate";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Button, Form} from "react-bootstrap";
import FormField from "../FormField";
// import TourReportSchema from "./tourReportSchema";
// import { simpleRequest } from "../../newForms2/simpleRequest";
import { StyledDivWithBorder } from "../../components/styledComponents/divs";
// import TourTimingSchema from "../tourReports/tourTimingSchema";
import TourTimingSchema from "./tourTimingSchema";
import FormValidation from "../Fields/validation/FormValidation";
import { simpleRequest } from "../../newForms2/simpleRequest";
import { getDateObjectLocal } from "../../helpers/Date";


const TourTimingNew = ({timing, setTiming, tourReport, backFunc}) => {
    const [variables, setVariables] = useState({});
    const [fieldSettings, setFieldSettings] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    const [serverError, setServerError] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const currentCruise = useSelector(st => st.currentCruise);

    useEffect(() => {
        const getStartTime = () => {
            if(timing.length){
                return timing[timing.length - 1].endTime;
            }else{
                return getDateObjectLocal(new Date()).time;
            }
        }
        const fetch = async() => {
            const fields = new TourTimingSchema().fields;
            setFieldSettings({...fields});
            const vars = {};
            Object.keys(fields).forEach(k => vars[k] = fields[k].initialValue);
            setVariables({...vars, startTime: getStartTime()});
            (() => setIsLoading(false))();
        }
        if(isLoading)fetch();
    }, [isLoading, currentCruise, timing]);
    if(isLoading)return <Spinner/>;

    const handleSubmit = async(e) => {
        e.preventDefault();
        console.log(variables)
        const formValidation = new FormValidation({fieldSettings, variables, setFieldErrors});
        if(!formValidation.valid)return;
        const request = {url:'tourtiming', method:"post", data:{...variables, tourReportId: tourReport.id}}
        const tourTiming = (await simpleRequest(request)).data?.tourTiming;
        setTiming(timing => [...timing, tourTiming]);
        backFunc();
    }

    const props = {    
        fieldSettings, 
        variables, 
        setVariables,
        fieldErrors, 
        setFieldErrors, 
        serverError,
        setServerError
    };
    const mainOrders = Array.from(new Set(Object.values(fieldSettings).map(fs => fs.mainOrder)));
    console.log({mainOrders, fieldSettings})

    // console.log({props});
    return(

        <StyledDivWithBorder className="p-2">
            <h5 >{translate("Timing - New Entry")}</h5>
            
            <Form.Row className="row row-eq-height">
                {Object.values(fieldSettings).map(lf =>
                
                <Col key = {lf.name} id={lf.id}className={`col-${lf.col}`}>
                     <FormField props = {{...props, name:lf.name}}/>
                </Col>
            )}
            </Form.Row>
            <Form.Row>
                <Col >
                    <Button block className="btn btn-success" onClick={handleSubmit}>{translate("Submit")}</Button>
                </Col>
                <Col>
                    <Button block className="btn btn-secondary" onClick={backFunc}>{translate("Cancel")}</Button>
                </Col>
            </Form.Row>
        </StyledDivWithBorder>
    )
};
export default TourTimingNew;