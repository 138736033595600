import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { translate } from "../Language/Translate";
import { StyledDivWithBorder } from "../styledComponents/divs";
import ChatPostNew from "../../newForms3/travelchats/ChatPostNew";
import ChatPostList from "./ChatPostList";

const ChatThemeDetails = ({theme, backFunc}) => {
    const [showForm, setShowForm] = useState('none'); //addPost, //edit
    console.log({theme});
    return(
        <StyledDivWithBorder className="m-1 p-2">
            <h5>{theme.title}</h5>
            <h6>{theme.subTitle}</h6>
            {showForm === "addPost" && <ChatPostNew theme={theme} backFunc={() => setShowForm("none")}/>}
            {showForm === "none" && 
                <Row>
                    <Col><button className="btn btn-outline-dark col-12" onClick={() => setShowForm('addPost')}>{translate("Add Post")}</button></Col>
                    <Col><button className="btn btn-outline-dark col-12">{translate("Edit")}</button></Col>
                    <Col><button className="btn btn-outline-secondary col-12" onClick={backFunc}>{translate("Back")}</button></Col>
                </Row>}
            {showForm === "none" && <ChatPostList theme={theme}/>}
        </StyledDivWithBorder>
    )
};
export default ChatThemeDetails;