import {
  ADD_DESTINATION, 
  DELETE_DESTINATION, 
  FETCH_DESTINATIONS, 
  UPDATE_DESTINATION
} from "./types";
import {simpleRequest} from "../newForms2/simpleRequest";

export function fetchDestinationsFromAPI() {
  // console.log('inside fetchdestinationsFromAPI')
  return async function (dispatch) {
    const response = await simpleRequest({url:`destinations`, method:"get"});
    // console.log(response.data)
    const destinations = response.data && response.data.destinations;
    return dispatch(getDestinations(destinations));
  };
};
function getDestinations(destinations) {
  return {
    type: FETCH_DESTINATIONS,
    destinations,
  };
};
export function addDestinationOnAPI(data){
  return async function(dispatch){
    // console.log('inside addDestinationOnAPI', data)
    const response = await simpleRequest({url:"destinations", method:"post", data});
    const destination = response.data?.destination;
    // console.log({destination});
    return dispatch(addDestination(destination));
  };
};
function addDestination(destination){
  return {
    type: ADD_DESTINATION,
    destination
  };
}; 
export function updateDestinationOnAPI(id, data){
  return async function(dispatch){
    const response = await simpleRequest({url:`destinations/${id}`, method:"patch", data});
    const destination = response.data?.destination;
    // console.log({destination});
    return dispatch(updateDestination(destination));
  };
};
function updateDestination(destination){
  return {
    type: UPDATE_DESTINATION,
    destination
  };
};
export function deleteDestinationFromApi(id){
  return async function(dispatch){
    const response = await simpleRequest({url:`destinations/${id}`, method: "delete"});
    const destination = response.data?.deleted;
    return dispatch(deleteDestination(destination));
  };
};
function deleteDestination(destination){
  return {
    type: DELETE_DESTINATION,
    destination
  };
};