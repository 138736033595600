import React from "react";
import Spinner from "./Spinner";
import { translate } from "./Language/Translate";


const Spinning = ({text = "Please wait a moment..."}) => (
    <div className = "text-center">
        <h6>{translate(text)}</h6>
        <Spinner/>
    </div>
);
export default Spinning;