import React, {useEffect, useState} from "react";
import GroupMain from "./GroupMain";
// import Globals from "../../helpers/globals";
import {useDispatch, useSelector} from 'react-redux';
import { setGlobalVarToState } from "../../actions/globals";
// import { Button } from "react-bootstrap";

const GroupProvider = ({location="cruise"}) => {
    const [isLoading, setIsLoading] = useState(true);
    // console.log({location});
    const dispatch = useDispatch();
    const globals = useSelector(st => st.globals);

    useEffect(()=> {
        const fetch = () => {
            // console.count('useEffect in GroupProvider fetch()____________________________________________________________________')
            // console.log(location, globals.groupLocation);
            if(location !== globals.groupLocation)dispatch(setGlobalVarToState({groupLocation:location}));
            setIsLoading(false)
        }
        if(isLoading)fetch();
        
    },[dispatch, location, globals.groupLocation, isLoading]);

    // console.log({globals, isLoading});
    // return <p>Dietmar</p>
    return <GroupMain key = {`groupMain_${location}`}/>;
};
export default GroupProvider;