import {translate} from '../../components/Language/Translate'


class CompanySchema{

    // static async _getForm(){

        // const consts = {
        //     formType:"companyNew",
        //     request : {
        //         url:"companies",
        //         method:"post",
        //     },
        //     nextPage:"/companies",
        //     formLabels : {
        //         title: translate("Add Company"), 
        //         btnLabel:translate("Add Company")
        //     },
        constructor(){
            this.fields = {
                handle: {
                    label:translate("Handle"),
                    initialValue:"",
                    validation:{maxLength:25, minLength:3, lowerCased:true},
                    required:true,
                    type:"text",
                    fieldType:"text",
                    toolTip:translate("An unique Symbol for the Company.")

                },  

                name: {
                    label:"Name",
                    initialValue:"",
                    validation:{
                        minLength:5,
                    },
                    required:true,
                    type:"text",
                    fieldType:"text"
                },  
                suffix: {
                    label:"Suffix",
                    initialValue:"",
                    validation:{},
                    required:false,
                    type:"text",
                    fieldType:"text"
                },  
                note: {
                    label:"Beschreibung",
                    initialValue:"",
                    validation:{},
                    required:false,
                    type:"text",
                    fieldType:"textarea"
                },

                // logoUrl: {
                //     label:"Logo URL",
                //     initialValue:"",
                //     validation:{},
                //     type:"url",
                //     fieldType:"upload"

                // }
             
        }
    }
        // return consts;
};

export default CompanySchema;

