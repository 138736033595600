import React from "react";
import { useSelector } from "react-redux";
import { translate } from "../../../components/Language/Translate";
import { simpleRequest } from "../../simpleRequest";
import DaysDates from "./DaysToursDates";
import { StyledDivWithBorder } from "../../../components/styledComponents/divs";

const DaysTours = ({props}) => {
    const {days, setDays} = props;
    const currentCruise = useSelector(st => st.currentCruise);
    const dates = Array.from(new Set(days.map(d => d.date))).sort();
    const handleTR = async() => {
        const response = (await simpleRequest({url:`worddocuments/tourreport/cruise/${currentCruise.id}/all`})).data;
        console.log({response})
    };
    const handleDD = async() => {
        // /dailyduty/cruise/:cruiseId/all
        const response = (await simpleRequest({url:`worddocuments/dailyduty/cruise/${currentCruise.id}/all`})).data;
        console.log({response})
    }

    return(
        <div className="my-3">
            <h4 className="mb-3">{translate("Tours")}</h4>
            <button className="btn btn-outline-dark" onClick={handleTR}>{translate("Send all Tour Reports")}</button>
            <button className="btn btn-outline-dark" onClick={handleDD}>{translate("Send all Daily Duties")}</button>
            <StyledDivWithBorder >
                {dates.map ((date,idx)=> <DaysDates key={idx} props={{days, setDays, date, idx}}/>)}
            </StyledDivWithBorder>
        </div>
    )
};
export default DaysTours;