import React, { useState } from "react";
import SignUp from "./google/SignUp";
import { translate } from "../Language/Translate";
import Facebook from "./facebook/Facebook";

const ButtonAuth = ({service}) => {
    const [view, setView] = useState(null);
    const Button = () => (
        <div>
            <button className="btn btn-outline-dark my-2" 
                    onClick = {() => {
                        setView(service)}}>
                        {`${translate("Login with")} ${service}`} 
            </button>
        </div> 
    )

    return(
        <div>
            {!view && <Button/>}
            {view === "Google" && <SignUp />}
            {view === "Facebook" && <Facebook />}

        </div>
    )
};
export default ButtonAuth;