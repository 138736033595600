import { translateDE } from "../Language/Translate";

const _groupTextPublic = {
    headline:"Öffentliche Gruppen",
    textArr:[
        "Sie sind begeisterter Skatspieler und suchen Leute, die Ihre Leidenschaft teilen?",
        "Oder Sie wandern gern, oder Sie möchten mit Mitreisenden über alles Mögliche philosophieren?",
        "Dann gründen Sie doch einfach eine öffentliche Gruppe.",
        `Klicken Sie auf den Link "Gruppe hinzufügen" und nennen Sie die neue Gruppe z. B. "Skatfreunde".`,
        `Alle Mitreisenden können diese Gruppe nun sehen, und ihr beitreten.`,
        `In einem einfachen Chat können Sie sich dann zum Beispiel zum Spielen verabreden.`,
    ]
};
const _groupTextPrivate = {
    headline : "Private Gruppen",
    textArr: [
        `Oder Sie haben eine Gruppe gefunden, mit der Sie öfter etwas unternehmen wollen.`,
        `Geben Sie der Gruppe einen Namen.`,
        `Wenn Sie die Option "Private Gruppe" wählen, können Sie aus den Mitreisenden die Leute auswählen, die Sie gern aufnehmen möchten.`,
        `Nur für diese Auswahl an Reisenden erscheint dann die Option, der Gruppe beizutreten.`, 
        `Für alle anderen sind private Gruppen nicht sichtbar.`,
        `Sie können dann in dieser privaten Gruppe miteinander kommunizieren, ohne persönliche Daten (wie zum Beispiel Telefonnummern oder
            Emailadressen) austauschen zu müssen.`
    ]
};
const _notLoggedIn = {
    textArr : [
        "Sie müssen angemeldet und mit der Reise verbunden sein, um Gruppen zu gründen oder ihnen beizutreten,",
        `Bitte gehen Sie zu "Login" oder "Register".`,
        "Bitte füllen Sie danach die Felder weiter oben aus."
    ]
};
const _notJoined = {
    textArr:[
        "Sie mit der Reise verbunden sein, um Gruppen zu gründen oder ihnen beizutreten.",
        "Bitte füllen Sie dazu die Felder weiter oben aus."
    ]
};
const buildObj = (obj) => {
    return{
        headLine: obj.headline,
        textArr: obj.textArr.map(t => translateDE(t))
    };
};

export const groupTextPublic = buildObj(_groupTextPublic);
export const groupTextPrivate = buildObj(_groupTextPrivate);
export const textNotJoined = buildObj(_notJoined);
export const textNotLoggedIn = buildObj(_notLoggedIn);

