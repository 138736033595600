
import {
  FETCH_COMPANIES,
  ADD_COMPANY
} from "../actions/types";
import {sortArrayByObjectField} from '../helpers/sort';

function sortByCompanyName(companies) {
  // return companies.sort(name => name);
  return sortArrayByObjectField(companies,"name");
}

export default function rootReducer(state = [], action) {

  switch (action.type) {

    case FETCH_COMPANIES:
      if(!action.companies)return state;
      return sortByCompanyName([...action.companies]);
    case ADD_COMPANY:
      if(!action.company)return state;
      return sortByCompanyName([...state, action.company]);
    default:
      return state;
  }
}
