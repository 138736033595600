export const replacementsFromBackend = {
  "albumDestination": "album_destination",
  "albumId": "album_id",
  "albumPhotoId": "album_photo_id",
  "albumPhotos": "album_photos",
  "appliedAt": "applied_at",
  "approvedAt": "approved_at",
  "articleId": "article_id",
  "articleLinks": "article_links",
  "artistName": "artist_name",
  "blockedBy": "blocked_by",
  "blockedUsername": "blocked_username",
  "checkedAt": "checked_at",
  "checkedBy": "checked_by",
  "commentId": "comment_id",
  "companyCrew": "company_crew",
  "companyCrewId": "company_crew_id",
  "companyHandle": "company_handle",
  "confirmationCode": "confirmation_code",
  "confirmedBy": "confirmed_by",
  "createdAt": "created_at",
  "createdBy": "created_by",
  "cruiseCrew": "cruise_crew",
  "cruiseId": "cruise_id",
  "cruiseProposals": "cruise_proposals",
  "cruiseStatus": "cruise_status",
  "dateText": "date_text",
  "dateTime": "date_time",
  "dayId": "day_id",
  "deDE": "de_de",
  "decidedAt": "decided_at",
  "decidedBy": "decided_by",
  "deletedAt": "deleted_at",
  "deletedBy": "deleted_by",
  "deletedFrom": "deleted_from",
  "deletedTo": "deleted_to",
  "destinationId": "destination_id",
  "destinationPhotos": "destination_photos",
  "displayedName": "displayed_name",
  "documentUrl": "document_url",
  "enUK": "en_uk",
  "endAt": "end_at",
  "endPort": "end_port",
  "fieldName": "field_name",
  "fieldsDd": "fields_dd",
  "firstName": "first_name",
  "firstRow": "first_row",
  "foreignKeyA": "foreign_key_a",
  "foreignKeyB": "foreign_key_b",
  "genusId": "genus_id",
  "groupChat": "group_chat",
  "groupId": "group_id",
  "groupMembers": "group_members",
  "imagePath": "image_path",
  "imageUrl": "image_url",
  "isBlocked": "is_blocked",
  "isConfirmed": "is_confirmed",
  "lastLogin": "last_login",
  "lastName": "last_name",
  "lastUpdated": "last_updated",
  "lifeTime": "life_time",
  "localGuideLanguageAndLevel": "local_guide_language_and_level",
  "localGuideName": "local_guide_name",
  "localGuideQuality": "local_guide_quality",
  "logoUrl": "logo_url",
  "medicalComments": "medical_comments",
  "medicalInstituteId": "medical_institute_id",
  "medicalInstitutes": "medical_institutes",
  "memoryScore": "memory_score",
  "optionalText": "optional_text",
  "payedAt": "payed_at",
  "payedBy": "payed_by",
  "photoConfirmed": "photo_confirmed",
  "photoUrl": "photo_url",
  "postId": "post_id",
  "proposalId": "proposal_id",
  "publicId": "public_id",
  "readAt": "read_at",
  "responseEmail": "response_email",
  "restaurantName": "restaurant_name",
  "restaurantQuality": "restaurant_quality",
  "restoreCode": "restore_code",
  "secondRow": "second_row",
  "sentFrom": "sent_from",
  "sentTo": "sent_to",
  "setByAdmin": "set_by_admin",
  "speciesGroupId": "species_group_id",
  "speciesId": "species_id",
  "startAt": "start_at",
  "startPort": "start_port",
  "startTime": "start_time",
  "statusSetBy": "status_set_by",
  "subTitle": "sub_title",
  "tableId": "table_id",
  "themeId": "theme_id",
  "thirdRow": "third_row",
  "titleDE": "title_de",
  "titleEN": "title_en",
  "tourId": "tour_id",
  "tourQuality": "tour_quality",
  "updatedAt": "updated_at",
  "updatedBy": "updated_by",
  "usedAt": "used_at",
  "userCruise": "user_cruise",
  "userStatus": "user_status",
  "vesselId": "vessel_id",
  "vesselPhotos": "vessel_photos",
  "visibleCrew": "visible_crew",
  "visibleGuests": "visible_guests",
  "visibleStaff": "visible_staff"
};