import React, { useState } from "react";
import Fields from "./Fields";
import { simpleRequest } from "../../simpleRequest";
import { Form } from "react-bootstrap";
import { translate } from "../../../components/Language/Translate";

const SpeciesGroupNew = ({props}) => {
    const {setSpeciesGroups, speciesGroups, setOpenForm} = props;
    const initialValues = {
        category:"Fauna",
        enUK:"",
        deDE:"",
        latin:"",
    };
    const [variables, setVariables] = useState({...initialValues});

    const handleSubmit = async(e) => {
        e.preventDefault()
        const speciesGroup = (await simpleRequest({url:"speciesGroups", method:"post", data:{...variables}})).data?.speciesGroup;
        // console.log({speciesGroup});
        setSpeciesGroups([...speciesGroups, speciesGroup]);
        setVariables({...initialValues});
        setOpenForm('speciesGroupList');
    };

    return(
        <div className="mt-5">
            <h5>{translate("Add New Group")}</h5>
            <Form >
                <Fields variables={variables} setVariables={setVariables}/>
                <button className="btn btn-outline-dark" onClick={e => handleSubmit(e)}>{translate("Save")}</button>
                <button className="btn btn-outline-secondary ml-1" onClick={() => setOpenForm("speciesGroupList")}>{translate("Cancel")}</button>
            </Form>
        </div>

    )
};
export default SpeciesGroupNew;