// 11.11.23
import {
  FETCH_COMPANIES,
  ADD_COMPANY
} from "./types";
import {simpleRequest} from "../newForms2/simpleRequest"

export function fetchCompaniesFromAPI() {
  return async function (dispatch) {
    const response = await simpleRequest({url:`companies`, method:"get"});
    const companies = response.data && response.data.companies;
    return dispatch(getCompanies(companies));
  };
};

function getCompanies(companies) {
  return {
    type: FETCH_COMPANIES,
    companies
  };
};

export function addCompanyOnAPI(data){
  return async function (dispatch) {
    const response = await simpleRequest({url:`companies`, method:"post", data});
    const company = response.data && response.data.company;
    return dispatch(addCompany(company));
  }
};
function addCompany(company){
  return{
    type: ADD_COMPANY,
    company
  };
};



