import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./FormBuilder.css";
import ReactFormFields from './ReactFormFields';
import {handleSubmit} from './ReactFormFunctions';
import {SuccessArea, ServerErrorArea} from './FormMessageArea';

import {useHistory} from 'react-router-dom'

const FormBuilder = ({variables, constants}) => {
  // console.log('RENDER Formbuilder', {constants, variables})
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState();
  const [success, setSuccess] = useState(false);
  // const [success, setSuccess] = useState(false);
  const history = useHistory();

  // console.log({constants}, {variables}) 
  constants.events = {...constants.events, setServerError, setErrors, setSuccess, history}
  variables.serverError=serverError;
  variables.errors=errors;
  return(
    <div>

        <h4>{constants.formLabels.title}</h4>
        {constants.formLabels.subTitle && <h5>{constants.formLabels.subTitle}</h5>}

        <Form onSubmit = {e => handleSubmit(e,constants, variables)}>
          {success &&<SuccessArea constants={constants} variables = {variables}  />}
          <ServerErrorArea constants={constants} variables = {variables}/>
          {!success && <ReactFormFields constants={constants} variables = {variables}/>}
          {!success && <Button block size="lg" type="submit" >{constants.formLabels.btnLabel}</Button>}
        </Form>


    </div>
  )
}

export default FormBuilder;