import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { translate } from "../../../components/Language/Translate";
import { simpleRequest } from "../../simpleRequest";
import { StyledDivWithBorder } from "../../../components/styledComponents/divs";
import SpeciesFormFields from "./SpeciesFormFields";

const SpeciesEdit = ({props}) => {
    // console.log({props});
    const {setSpecies,  actualSpecies, setFormEdit, localForm} = props;
    const initialValues = {
        "deDE": actualSpecies.deDE,
        "enUK": actualSpecies.enUK,
    };
    const [variables, setVariables] = useState({...initialValues});
    
    const handleSubmit = async(e) => {
        e.preventDefault();
        // console.log(variables);
        const request = {url:`species/${actualSpecies.id}`, method:"patch", data:{...variables}};
        // console.log({request});
        const newSpecies = (await simpleRequest(request)).data?.species || {};
        // console.log({newSpecies});
        setSpecies(species => {return[...species.filter(s => s.id !== actualSpecies.id), newSpecies]});
        setFormEdit(false);
    };
    const handleCancel = async(e) => {
        // console.log(e.target);
        e.preventDefault();
        setFormEdit(false);
    };

    return(
        <StyledDivWithBorder>
         {/* <div className="my-3, p-3" style={{border:"1px solid grey", borderRadius:"5px"}}> */}
            <h4>{translate("Edit")}{`: ${actualSpecies.deDE} / ${actualSpecies.enUK}`}</h4>
            <h5>{localForm}</h5>
            <Form>
                <SpeciesFormFields props={{...props, variables, setVariables}}/>
                <button className="btn btn-outline-dark" onClick={e => handleSubmit(e)}>{translate("Save")}</button>
                <button className="btn btn-outline-secondary ml-1" onClick={e => handleCancel(e)}>{translate("Close")}</button>                
            </Form>
        {/* </div> */}
        </StyledDivWithBorder>
    )
};
export default SpeciesEdit;