// 11.11.23

import React from "react";
import {Accordion} from 'react-bootstrap';
import { useSelector } from "react-redux";
import CardVariablesArea from "./CardVariablesArea";
import CardProfilePhotoList from "./CardProfilePhotoList";
import UserListAccordion from "./UserListAccordion";
import {translate} from "../Language/Translate";
import TestAreaAccordion from './TestAreaAccordion';
import CardNewDestinationDays from "./CardNewDestinationDays";
import CardSqlArea from "./CardSqlArea";
import CardNewDestinationInUse from "./CardNewDestinationsInUse";
import { versionDate } from "../../config";
import ListOfAlbumsCard from "./ListOfAlbums/ListOfAlbumsCard";
import PrivateCard from "./PrivateCard";
import { encrypt, decrypt } from "../Crypto/crypto";
// import { StyledDivWithBorder } from "../styledComponents/divs";
// import { insertCruisesHlc } from "../insertData/functions/cruisesHlc";
// import InsertCruises from "./InsertCruises";
// import Resizertest from "./Tests/Resizer/Resizertest";
import CardObjectArea from "./CardObjectArea";
import HeicInput from "../../newForms2/uploadNewHeic/HeicInput";
// import WordDocument from "./Word/WordDocument";
// import Thumb from "../image/thumbnail";
// import InsertData from "./InsertData/InsertData";
// import InsertDataCard from "./InsertDataCard";
// import TestUseRequest from "./TestUseRequest";
// import { createThumbnailFromUri } from "../image/thumbnail";


const AdminArea = () => {
    // const [showPhotos, setShowPhotos] = useState(false);
    const currentUser = useSelector(st => st.currentUser);
    const developer = currentUser?.userStatus === "developer";
    // console.log({developer});
    // const img = createThumbnailFromUri('https://media.istockphoto.com/id/1068352350/de/foto/angedockte-segelboote-und-h%C3%A4user-im-kanal-mit-backsteint%C3%BCrme-stralsund-deutschland.jpg?s=612x612&w=0&k=20&c=wD1PkcJAaOD-VME9INwJk4k0JQV4Pd3ro01GMYzyukI=')
    const text = "Dies ist ein Text";
    const secret = "mum";
    const encrypted = encrypt(text, secret);
    const decrypted = decrypt(encrypted, secret);
    const wrong = decrypt(encrypted, "wrongKey");
    console.log({text, secret, encrypted, decrypted, wrong});


    return(
        <div className="p-1 text-center" style = {{border:"1px solid black", borderRadius:"5px"}}>
            <h4 >{translate('Admin Area')}</h4>
            <p>Version {versionDate}</p>

            {/* <button className="btn btn-outline-dark" onClick={() => setShowPhotos(!showPhotos)}>Show Photos</button> */}
            {/* <div> */}
                {/* <Thumb/> */}
            {/* </div> */}
            {/* <InsertCruises /> */}
            {/* <WordDocument/> */}
            {/* <Resizertest/> */}
            {/* <HeicInput /> */}
            <Accordion style={{backgroundColor:'#e5e9df', borderRadius:"5px"}}>
                {/* <InsertData/> */}
                {/* <TestUseRequest/> */}
                {/* {developer && <InsertDataCard eventKey = "15"/>} */}
                <PrivateCard eventKey = "18"/>
                <ListOfAlbumsCard eventKey="15"/>
                {/* <DeleteAccount /> */}
                {/* <PhotoShowDestination/> */}
                {/* <PromiseTester2 /> */}
                {/* <AuthenticationMain/> */}
                {/* <GooglePhotos /> */}
                {/* <Albums/> */}
                {/* <AlbumsMain /> */}
                {/* {showPhotos && <AllPhotos/>} */}
                {/* <PhotosMain/> */}
                {/* <DropboxMain/> */}
                {/* <ActualGeolocation /> */}
                {/* <Claims eventKey = "4" />
                <CardNewVesselList eventKey = "7"/>
                <CardNewCruiseList eventKey = "8"/> */}
                <CardProfilePhotoList eventKey = "3"/>
                {/* <CardAdvertisementsArea eventKey = "1"/> */}
                {/* <Facebook/> */}
                {/* <ButtonFacebook/> */}
                <UserListAccordion eventKey = "2"/>
                <CardNewDestinationDays eventKey = "9"/>
                <CardNewDestinationInUse eventKey = "14"/>
                {developer && <CardVariablesArea eventKey = "5"/>}
                {developer && <TestAreaAccordion eventKey = "6"/>}
                {developer && <CardSqlArea eventKey = "12"/>}
                {developer && <CardObjectArea eventKey = "15"/>}
                
                {/* {developer && <CardRequestArea eventKey = "13"/>} */}
            </Accordion>
        </div>
    )
}
export default AdminArea;



