import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import CruiseBackToCruiseButton from "../../../components/CruiseBackToCruiseButton";
import { StyledDivWithBorder } from "../../../components/styledComponents/divs";
// import DaysDates from "../DaysDates";
import RoutingFormNew from "./RoutingFormNew";
// import { translate } from "../../../components/Language/Translate";
import { simpleRequest } from "../../simpleRequest";
import RoutingFormNew2 from "./RoutingFormNew2";
import DaysRoutingDates from "./DaysRoutingDates";
import { Translate, translate } from "../../../components/Language/Translate";


const DaysRouting = ({props}) => {
    const {days, setDays} = props;
    // const currentCruise = useSelector(st => st.currentCruise);
    // const [days, setDays] = useState([]);
    const [proposals, setProposals] = useState([]);
    const dates = Array.from(new Set(days.map(d => d.date))).sort();
    
    return(
        <div className="my-3">
            <h3>Routing</h3>
            <RoutingFormNew2 setProposals={setProposals}/>
            <RoutingFormNew setProposals={setProposals}/>
            <StyledDivWithBorder >
                {dates.map ((date,idx)=> <DaysRoutingDates key={idx} props={{days, setDays, date, idx, proposals}}/>)}
            </StyledDivWithBorder>
        </div>
    )
};
export default DaysRouting