import React, {useState} from "react";
import { useSelector } from "react-redux";
import { Translate, translate } from "../../Language/Translate";
import DestinationPhotos from "./DestinationPhotos";
import {Row, Col} from "react-bootstrap";
import AlbumNew from "../../../newForms2/albums/AlbumNew";

const DestinationAlbums = () => {
    const currentDestination = useSelector(st => st.currentDestination);
    const albums = currentDestination.albums || [];
    const [showAlbumNew, setShowAlbumNew] = useState(false);
    const [selectedAlbum, setSelectedAlbum] = useState(albums.length === 1 ? albums[0].id : 0); // 0 === all
    const destTitle = Translate.language === "deDE" ? currentDestination.deDE : currentDestination.enUK;
    
    // console.log({currentDestination});
    return(
        <div>
            <h5>{translate('Photos')}</h5>
            <Row className = "mb-3">
                <Col><button className="btn btn-outline-dark" onClick={() => setShowAlbumNew(!showAlbumNew)}>
                    {`${translate("Add Album for")} ${destTitle}`}
                    </button>
                </Col>
            </Row>
            {albums.length > 0 && <p>
                {albums.length > 1 && <span onClick={() => setSelectedAlbum(0)}>{selectedAlbum === 0 
                        ? <b>{translate('all')}</b> 
                        : <i>{translate("all")}</i>}
                    </span>}
                {albums.map(a => {
                    return(
                        <span key={a.id} id={a.id} onClick={() => setSelectedAlbum(a.id)}>{` - `} 
                        {selectedAlbum === a.id ? <b>{a.titleDE}</b> : <i>{a.titleDE}</i>}
                    </span>
                )})}
            </p>}
            {albums.length === 0 && <p>{translate("No albums available")}</p>}
            {showAlbumNew && <AlbumNew />}
            {selectedAlbum === 0 && albums.map(a => <DestinationPhotos key = {a.id} album={a}/>)}
            {selectedAlbum !== 0 && <DestinationPhotos album={albums.find(a => a.id === selectedAlbum)} />}
        </div>
    );
};
export default DestinationAlbums;