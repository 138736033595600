import { translate } from "../../components/Language/Translate";

class FirstStepsEvaluation{
    static async notFoundNew(constants, variables, data){

        constants.successText={
            title: translate("Cruise was sent successfully"), 
            text:translate('We will check the data and we will put it into the database as soon as possible.')
        }
        constants.events.setSuccess(true);
        // console.log(constants)
            setTimeout( ()=>{
                constants.events.history.push('/');
            }, 6000);
        };
};
export default FirstStepsEvaluation;

