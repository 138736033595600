import React from "react";
import { Link } from "react-router-dom";
import {translate} from '../Language/Translate'
// import Signup from '../Auth/google/SignUp'
// import SignUp from "../Auth/google/SignUp";
// import ButtonFacebook from "../Auth/facebook/ButtonFacebook";
import ButtonAuth from "../Auth/ButtonAuth";
// import Facebook from "../Auth/facebook/Facebook";

const FirstStepsNotLoggedIn2 = ({setShowFirstSteps}) => {
    // console.log("FirstStepsNotLoggedIn2")

    const handleClick = e => {
        if(e.target.className.split(' ').includes('proceed'))(()=>setShowFirstSteps(false))();
    };
    return(

        <div className="mt-3 p-3" onClick = {e => handleClick(e)}style={{backgroundColor:"rgba(255,255,255,.5)"}}>
            <h4>{translate("Your options")}:</h4>
            {/* <Facebook/> */}
            <ButtonAuth service = "Google"/>
            <ButtonAuth service = "Facebook"/>

            {/* <SignUp />
            <ButtonFacebook /> */}
            <h5>{translate("Have you already registered and would like to log in?")}</h5>
            <Link to={"/login"} className="proceed btn btn-outline-dark">{translate("To Login")}</Link>
            <h5 className="mt-4">{translate("Have not registered yet, but want to do so now?")}</h5>
            <p>{translate('As a registered user, you can create ships and trips, and see and contact your fellow travelers.')}<br/>
            {translate('Membership is completely free and there are no obligations.')}</p>
            <Link to={"/register"} className="proceed btn btn-outline-dark">{translate("To Registration")}</Link>
            <h5 className="mt-4">{translate("You do not want to register yet, but just want to have a look around?")}</h5>
            <p>{translate("As a guest, you can look at the site in peace, but you cannot enter any vessels or cruises.")}<br/>
            {translate("For reasons of data protection, you cannot see the fellow travelers on a cruise.")}</p>
            <Link to={"/start"} className="proceed btn btn-outline-dark mt-3">{translate("Discover CruiseFriends.net as a guest")}</Link>
        </div>
    )
};
export default FirstStepsNotLoggedIn2;