import React from "react";
import {useSelector} from 'react-redux';
import {translate} from './Language/Translate';
import CruiseAreaCardDestinationWholeDiaryDay from './CruiseAreaCardDestinationWholeDiaryDay';
import {sortArrayByObjectField} from '../helpers/sort';

const CruiseAreaCardDestinationWholeDiary = ({setWholeDiary}) => {

    const currentCruise = useSelector(st => st.currentCruise);
    const diaries = sortArrayByObjectField(currentCruise.diaries, "dateTime");
    // console.log(currentCruise.diaries, diaries);

    return(
        <div className="p-4">
            <h4>{translate("Cruise Diary")}</h4>
            {diaries.map(d => <CruiseAreaCardDestinationWholeDiaryDay day={d} key={d.id}/>)}
            <button className="btn btn-outline-secondary" onClick={() => setWholeDiary(false)}>{translate("Close")}</button>
        </div>
    )
};
export default CruiseAreaCardDestinationWholeDiary;