import React, { useState, useEffect } from "react";
import { useDispatch  } from "react-redux";
import { translate } from "../../components/Language/Translate";
import Spinner from "../../components/Spinner";
import FormFields from "../FormFields";
import { Form, Button } from "react-bootstrap";
import FormValidation from "../Validation/FormValidation";
import { updateMedicalCommentOnApi } from "../../actions/currentDestination";
import MedicalCommentFormSchema from "./MedicalCommentFormSchema";

const MedicalCommentEdit = ({comment}) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [fieldSettings, setFieldSettings] = useState({});
    const [variables, setVariables] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    // const [serverError, setServerError] = useState('');
    // const currentDestination = useSelector (st => st.currentDestination);
    // const destinationId = currentDestination.id;
    // console.log({currentDestination});
    
    useEffect (() => {
        const fetch = () => {
            const sets = new MedicalCommentFormSchema();
            (() => setFieldSettings(sets.fields))();
            const vars = {};
            Object.keys(sets.fields).forEach(k => vars[k] = comment[k]);
            (() => setVariables(vars))();
        };
        if(isLoading)fetch();
        (() => setIsLoading(false))();
    }, [comment, isLoading]);

    if(isLoading)return <Spinner/>
  
    const handleSubmit = e => {
        e.preventDefault();
        // console.log({variables});
        const validation = new FormValidation({fieldSettings, variables, setFieldErrors});
        validation.formValid();
        if(Object.keys(fieldErrors).length !== 0)return;
        dispatch(updateMedicalCommentOnApi(comment.id, {...variables}));
    };
    
    const props = {
        fieldSettings, 
        variables, 
        setVariables,
        fieldErrors, 
        setFieldErrors, 
        // serverError
    };
        
    return(
        <div className = "m-2 p-2" style={{border:"1px solid grey", borderRadius:"5px"}}>
        <h5 className = "mt-3">{translate("Edit Comment")}</h5>
         <Form onSubmit = {e => handleSubmit(e)}>
            <FormFields props = {props}/>
            <Button block size="lg" type="submit" className="btn btn-info">{translate("Update")}</Button>
        </Form>
        </div>
    );
};
export default MedicalCommentEdit;