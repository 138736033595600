
import {
  
  FETCH_USERS,
} from "../actions/types";


function sortByUsername(users) {

  return users.sort(username => username);
}


export default function rootReducer(state = [], action) {

  switch (action.type) {

    case FETCH_USERS:
      // console.log({action})
      return sortByUsername([...action.users.users]);



    default:
      return state;
  }
}
