import {
    FETCH_PHOTO_LIKES,
    ADD_PHOTO_LIKE,
    REMOVE_PHOTO_LIKE,
  } from "../actions/types";

  export default function rootReducer(state = [], action) {
    // console.log({action})
    switch (action.type) {
        case FETCH_PHOTO_LIKES:
            // console.log('FETCH_PHOTO_LIKES', action);
            if(!action.photoLikes)return state;
            return ([...action.photoLikes]);
        case ADD_PHOTO_LIKE:
            // console.log(ADD_PHOTO_LIKE, action);
            if(!action.photoLike)return state;
            return ([...state, action.photoLike]);
        case REMOVE_PHOTO_LIKE:
            // console.log(REMOVE_PHOTO_LIKE, action);
            return [...state.filter(pl => pl.id !== action.deleted.id)]
        default:
            return state;
        };
};