import {FETCH_AUTHORS} from "../actions/types";
import {sortArrayByObjectField} from '../helpers/sort';

  function sortByUserName(authors) {
    //  return authors.sort(firstName => firstName);
    return sortArrayByObjectField(authors, "firstName");
  };
  
  export default function rootReducer(state = [], action) {

    switch (action.type) {
      
      case FETCH_AUTHORS:
        if(!action.authors) return state;
        return sortByUserName([...action.authors]);
      default:
        return state;
    }
};
  