
import React, {useEffect, useState} from "react";
import { Form, Col, Row } from "react-bootstrap";
import { StyledDivWithBorder } from "../../styledComponents/divs";
import Spinner from "../../Spinner";

const PrivateFormNew = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState({});

    useEffect(() => {
        const fetch = () => {
            const vars = {name: "", url:""}
            const pairs = Array.from("0123456789").map(n => {
            // return  {[`pair${n}`]: {key:"", value:""}}
            return  {key:"", value:""}
       
        
        
            });

        
            // console.log({vars, pairs});
            setVariables({...vars, pairs});
            setIsLoading(false);
        }
        if(isLoading)fetch();
        
    }, [isLoading]);

    const handleChange = e => {
        // console.log(e.target);
    }
    const handleSubmit = e => {

    }

    const handleChangePair = e => {
        // console.log(e.target);
        const {name, value} = e.target;
        const type = name.includes('key') ? "key" : "value";
        const number = +name.slice(-1);
        const {pairs} = variables;
        // console.log({type, number, value});
        pairs[number][type] = value;
        setVariables({...variables, pairs})
    }
    // console.log({variables});
    if(isLoading)return <Spinner/>

    return(
        <StyledDivWithBorder>
        <Form>
            <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                    type="text"
                    name="name"
                    onChange={e => handleChange(e)}
                    >

                </Form.Control>
            </Form.Group>

            <Form.Group>
                <Form.Label>Url</Form.Label>
                <Form.Control
                    type="text"
                    name="url"
                    
                    onChange={e => handleChange(e)}
                    >

                </Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label>Note</Form.Label>
                <Form.Control as="textarea" rows={5}
                    type="textarea"
                    name="note"
                    
                    onChange={e => handleChange(e)}
                    >

                </Form.Control>
            </Form.Group>
        </Form>
       <button className="btn btn-outline-dark" onClick={e => handleSubmit(e)}>Submit</button>

        </StyledDivWithBorder>
    );
};
export default PrivateFormNew;