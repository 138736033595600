import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import { addDayOnApi, deleteDayOnApi, updateDayOnApi } from "../../actions/currentCruise";
import Display from "./Display";
import CruiseAreaEditRoutingForm from "./CruiseAreaEditRoutingForm";
import Header from "./Header";
import {StyledDivWithBorder} from '../../components/styledComponents/divs'
import { Row, Col } from "react-bootstrap";


const Proposal = ({proposal, props}) => {
    const {setVariables, setChanged} = props;

    useEffect(() => {
        console.log(proposal.fulltext);
        if(setVariables && proposal.fulltext?.includes("Entspannung auf See")){
            setVariables(variables => {return {...variables, destinationId:1}});
            setChanged(true);
        }
            
    }, [proposal, setVariables])
       
    const destinations = useSelector(st => st.destinations);
    const {destination, fulltext} = proposal;
    const localDests = destinations.filter(d => d.enUK.includes(destination) || d.deDE.includes(destination));
    const filtered = proposal.rest.filter(r => r.includes('.')) || [];
    const [arrival] = filtered;
    const [departure] = filtered.reverse();
    // console.log({destinations, localDests})
    const handleChangeDestination = e => {
        setChanged(true);
        setVariables(variables => {return{...variables, destinationId: +e.target.dataset.value}});
    };

    return(
        <StyledDivWithBorder className="text-center">
            
            <Row>
                <Col>
                    <p><b>Destination</b></p>
                    <p><b>{destination}</b></p>
                    <ul>
                        {localDests.map(ld => 
                            <li key={ld.id} data-name="destinationId" 
                                data-value={ld.id} onClick={e => handleChangeDestination(e)}>
                                {`${ld.deDE} / ${ld.enUK}`}

                            </li>)}
                    </ul>
                    
                </Col>
                <Col>
                    <p>Arrival</p>
                    <p id="arrival" value={arrival}>{arrival}</p>
                </Col>
                <Col>
                    <p>Departure</p>
                    <p>{departure}</p>
                </Col>

            </Row>
        </StyledDivWithBorder>
    )
}





const ProposalsArea = ({props}) => {
    const {localProposals} = props;
    return(
        <StyledDivWithBorder>
            {localProposals.map(lp => <Proposal proposal={lp} props={props}/>)}
        </StyledDivWithBorder>
    )
};
export default ProposalsArea;
