import React, { useState, useEffect} from "react";
import "./MainBoard.css" 
import { sortArrayByObjectField } from "../../../helpers/sort";
import {readTimeStamp} from '../../../helpers/Date';
import { simpleRequest } from "../../../newForms2/simpleRequest";
import Spinner from '../../Spinner';


const CruiseScores = ({cruise}) => {
    // console.log({cruise});
    const [isLoading, setIsLoading] = useState(true);
    const [scores, setScores] = useState();
    const {cruiseId, prefix, name, title} = cruise;
    useEffect(() => {
        const fetch = async() => {
            const sc = (await simpleRequest({url:`memoryscores/${cruiseId}`, method:"get"})).data;
            (() => setScores(sc.memoryScores))();
            (() => setIsLoading(false))();
        }
        if(isLoading)fetch();
    });
    if(isLoading)return <Spinner />
    // console.log({scores});
    // console.log({id, prefix, name, title, suffix, scores});
    const best = sortArrayByObjectField(scores, "counter")[0];
    const fastest = sortArrayByObjectField(scores, "seconds", "float")[0];
    // console.log({best, fastest});
    return(
        <div className="my-3 p-3" style = {{border:"1px solid grey", borderRadius:"5px",backgroundColor: "rgba(255,255,255,.3)"}}>
            {(best || fastest) && <h5>Best Results on {prefix} {name}: {title}</h5>}
            {best && <i>Best: <b>Counter: {best.counter},</b> Seconds: {best.seconds} ({readTimeStamp(best.createdAt)}, {best.nickname})<br/></i>}
            {fastest && <i>Fastest: Counter: {fastest.counter}, <b>Seconds: {fastest.seconds}</b> ({readTimeStamp(fastest.createdAt)}, {fastest.nickname})<br/></i>}
        </div>
    );
};
export default CruiseScores;