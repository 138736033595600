import React, {useState} from "react";
// import { Translate,translate } from "../../../Language/Translate";
// import { getDateObject } from "../../../../helpers/Date";
// import { getTextGeoFromSpeciesObservations } from "../../helpers";
// import { sortArrayByObjectField } from "../../../../helpers/sort";
// import { useSelector } from "react-redux";
import { BlackWhite } from "../../../styledComponents/colors";
import ObservationDetails from "./ObservationDetails";


const ListItem = ({props}) => {
    const {o, handleClick} = props;
    return(
        <div key = {o.id} id={o.id} onClick={e => handleClick(e)}>
                    <p><BlackWhite><b>{`${o.deDE} / ${o.enUK}`}</b></BlackWhite></p>
                                    {o.description && <p>{o.description}</p>}
        </div>
    )
}



const ObservationEventItem = ({o, props}) => {
    const [view, setView] = useState('list') // details, form
    

    const handleClick = e =>{
        // const id = +e.target.parentElement.parentElement.parentElement.id;
        // console.log(o.id);
        setView("details");
    }

    // const props = {o, handleClick}
    return(
        <>
            {view === "list" && <ListItem props={{...props, o, handleClick}}/>}
            {view === "details" && <ObservationDetails o = {o} setView={setView} props={{...props, o, handleClick}}/>}
        </>
    );

    
}
export default ObservationEventItem;