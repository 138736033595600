import { simpleRequest } from "../simpleRequest";
import {translate} from "../../components/Language/Translate"

class AdvertisementFormData {

    static returnArray = ["id", "cruiseId", "startAt", "lifeTime", "firstRow", "secondRow", "thirdRow"];

    static async advertisementNew(data) {
        
        const consts = new AdvertisementFormSchema(data);
        // const cruiseId = data.params.cruise_id;
        // console.log({cruiseId})
        consts.fields.cruiseId.value = +data.params.cruise_id;
        let fields;
        Object.entries(consts.fields).forEach(e => fields = {...fields, [e[0]]: e[1].value})
        consts.formType = 'advertisementNew';
        consts.request = {
            url:`advertisements/`,
            method:"post"
        };
        consts.nextPage = (`/`);
        consts.sendData = ["cruiseId", "startAt", "lifeTime", "firstRow", "secondRow", "thirdRow", "url"];
        const vars={fields};
        // console.log({consts, vars})
        return {consts, vars};
    }
    
    static async advertisementEdit(data){
        
        // console.log('inside AdvertisementFormData. advertisementEdit', {data})
        const advertisementId=+data.params.id
        // console.log({advertisementId})
        const advertisement = await AdvertisementFunctions._getById(advertisementId)
        // console.log({advertisement})
        const consts = new AdvertisementFormSchema()
        // consts.fields.cruiseId.value=advertisement.cruiseId;

        consts.fields.lifeTime.validation = {minNumber:advertisement.lifeTime}
        // console.log(consts.fields)
        consts.sendData = ["startAt", "lifeTime", "firstRow", "secondRow", "thirdRow", "url"]
        let fields;
        consts.sendData.forEach(k => fields = {...fields, k: advertisement[k]})
        consts.formType = 'advertisementEdit';
        consts.formLabels = {
            title:"Edit Advertisement",
            btnLabel:"Update"
        }
        consts.request = {
            url:`advertisements/${advertisementId}`,
            method:"patch"
        };        
        consts.nextPage = (`/admin`);
        consts.oldValues=advertisement;
        const vars={fields:advertisement}
        // console.log({consts, vars});
        return {consts, vars};
    };
        
};
       




class AdvertisementFormSchema{
    constructor(){
        this.formType="advertisementNew";
        this.nextPage=""
        this.request = {
            url:"advertisements",
            method:"post",
        };
        this.formLabels = {title: "Add Advertisement", btnLabel:"Add"};

        // "cruiseId", "startAt", "lifeTime", "firstRow", "secondRow", "thirdRow"
        this.fields = {

            cruiseId : {
                name:"cruiseId",
                label : "Cruise ID",
                fieldType: "info_stay",
                type:"number",
                required:true
            },
      
            startAt : {
                name:"startAt",
                label : translate("Start annonce at"),
                type:"text",
                fieldType: "text",
                disabled:false,
                value :"",
                required:true,
                placeholder:"Format: 2021-04-03_08-45",
                toolTip:"Format: 2021-04-03_08-45",
                validation:{minLength:16, maxLength:16}
            },
            lifeTime : {
                name:"lifeTime",
                label : translate("Life Time (days)"),
                type:"number",
                fieldType: "number",
                disabled:false,
                value :7,
                required:true,
                placeholder:"Number of days, this advertisement will be schown.",
                toolTip:"Number of days, this advertisement will be schown.",
                validation:{minNumber:0},
                toNumber:true,
                
            },
            firstRow : {
                name:"firstRow",
                label : translate("First Row"),
                type:"text",
                fieldType: "text",
                disabled:false,
                value :"",
                required:true,
                validation:{maxLength:100, minLength:5}
            },
            secondRow : {
                name:"secondRow",
                label : translate("Second Row"),
                type:"text",
                fieldType: "text",
                disabled:false,
                value :"",
                required:true,
                validation:{maxLength:100}
            },
            thirdRow : {
                name:"thirdRow",
                label : translate("Third Row"),
                type:"text",
                fieldType: "text",
                disabled:false,
                value :"",
                required:true,
                validation:{maxLength:100}
            },
            url : {
                name:"url",
                label : "URL",
                type:"url",
                toolTip:"Extern link to the site, where the cruise can be booked",
                fieldType: "text",
                disabled:false,
                value :"",
                required:true
            }
            

        }
    }
};

class AdvertisementFunctions{
    static async _getById(id){
        const response = await simpleRequest({url:`advertisements/${id}`, method:"get"});
        const cc = response && response.data && response.data.advertisement;
        return cc;
    }
}


export default AdvertisementFormData;