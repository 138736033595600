import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import { addDayOnApi, deleteDayOnApi, updateDayOnApi } from "../../actions/currentCruise";
import Display from "./Display";
import CruiseAreaEditRoutingForm from "./CruiseAreaEditRoutingForm";
import Header from "./Header";
import ProposalsArea from "./ProposalsArea";

const CruiseAreaEditRoutingMain = ({days, id, backFunc=null, proposals, value, setValue}) => {
    const dispatch = useDispatch();
    const [variables, setVariables] = useState({});
    const [initialVars, setInitialVars] = useState({});
    const [changed, setChanged] = useState(false);
    const [activated, setActivated] = useState(false);
    const [localProposals, setLocalProposals] = useState([]);
    const currentCruise = useSelector(st => st.currentCruise);
    const day = days.find(d => d.id === id);
    const {date} = day;
    // const localProposals = proposals?.filter(p => date.includes(p.dateMonthDay)) || [];
    // console.log({localProposals});

    useEffect(() => {
        console.log('useEffect');
        const {destinationId, destination, arrival, departure, description} = day;
        const vars = {destinationId, destination, arrival, departure, description};
        if(proposals){
            const locProps = proposals.filter(p => day.date.includes(p.dateMonthDay));
            if(locProps.length)setActivated(true)
            setLocalProposals([...locProps]);
        }
        (() => setVariables({...vars}))();
        (() => setInitialVars({...vars}))();
    }, [day, setVariables, proposals]);
    
    const double = days.findIndex(d => d.date === date) !== days.findLastIndex(d => d.date === date);
    const validDate = date <= currentCruise.endAt && date >= currentCruise.startAt;

    const invalidColor = validDate ?   "lightgrey" : "darkgrey";
   
    const deleteDay = e => {
        e.preventDefault();
        // console.log("delete: ", day, id)
        dispatch(deleteDayOnApi(id));
    }

    const handleSubmit = e => {
        e.preventDefault();
        console.log('SUBMIT', {id, variables},' _________________________________________________________________________');
        (() => setChanged(false))();
        dispatch(updateDayOnApi({dayId: id, data:{...variables}}))
    }

    const atSea = e => {
        e.preventDefault();
        setVariables({...variables, destinationId: 1});
        setChanged(true);
    };
    const deleteDestination = e => {
        e.preventDefault();
        // console.log('delete');
        (() => setVariables({...variables, destinationId:null}))();

    };
    const addEntry = () => {
        // console.log("add entry");
        const data = {cruiseId:currentCruise.id, date:day.date}
        dispatch(addDayOnApi(data));
    }

    const handleChange = e => {
        console.log(e.target, e.target.name, e.target.value, e.target.dataset);
        !changed && (() => setChanged(true))();
        (() => setVariables({...variables, [e.target.name]: e.target.value}))();
    };
    const handleActivate = (e,bol) => {
        // console.log(variables);
        e.preventDefault();
        if(bol === false){
            (() => setChanged(false))();
            (() => setVariables({...initialVars}))();
        };
        (() => setActivated(bol))();
    };

    const props = {
        day,
        setActivated,
        deleteDay,
        addEntry,
        variables, 
        setVariables, 
        localProposals,
        setLocalProposals,
        changed,
        setChanged,
        double,
        validDate, 
        invalidColor,
        handleActivate,
        activated,
        atSea,
        handleSubmit,
        deleteDestination,
        handleChange,
        backFunc
    };
    console.log({props, variables, day});
    return(
        <div className = "text-left p-3 mt-1" style = {{border:`1px solid grey`, backgroundColor:invalidColor}}>
            <Header props = {props}/>
          {!activated  && <Display day={day}/>}
          {activated && <CruiseAreaEditRoutingForm props={props}/>}
          {localProposals.length > 0 && <ProposalsArea props={props}/>}
        </div>
    );

};
export default CruiseAreaEditRoutingMain;