import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import { translate } from "../../components/Language/Translate";
import {Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
// import { useDispatch} from "react-redux";
import Spinner from '../../components/Spinner';
// import FormCruiseNotFoundSchema from "./CruiseProposalSchema";
import FormFields from "../FormFields"
// import CruiseProposalSchema from "./CruiseProposalSchema";
import CompanySchema from "./CompanySchema";
// import {addCruiseProposalOnAPI} from '../../actions/cruiseProposals'
import FormValidation from '../Validation/FormValidation';
import { simpleRequest } from "../../newForms2/simpleRequest";
// import { addCompanyOnAPI } from "../../actions/companies";

const CompanyNew = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState({});
    const [fieldSettings, setFieldSettings] = useState({});
    const [success, setSuccess] = useState(false);
    const [serverError, setServerError] = useState('');
    const [fieldErrors, setFieldErrors] = useState({});
    // const vessels = useSelector(st => st.vessels)
    const history = useHistory();
    // const dispatch = useDispatch();
    // const dispatch= useDispatch();

    // console.log('inside CompanyNew in newforms2')

    useEffect(() => {
        // console.log('inside useEffect');
        const fetch = () => {
            const sets=new CompanySchema();
            (() => setFieldSettings({...sets.fields}))();
            const vars = {}
            Object.entries(sets.fields).forEach(v => vars[v[0]] = v[1].initialValue);
            (()=> setVariables(vars))();
            (()=> setIsLoading(false))();
        }
        if(isLoading)fetch();
    }, [isLoading, setFieldSettings, setVariables, setIsLoading]);
 
    if(isLoading) return <Spinner />
    // console.log({variables})

    const handleSubmit = async(e) => {
        e.preventDefault();
        // console.log('inside handleSubmit', e.target, variables, fieldSettings);
        const formValidation = new FormValidation({fieldSettings, variables});
        if(!formValidation.valid)return;
        const data = {...variables};
        const response = await simpleRequest({url:'companies', method:"post", data});
        // await dispatch(addCompanyOnAPI(data));
        if(response.data){
            (() => setSuccess(true))();
            (() => setTimeout(() => {
                setSuccess(false)
                history.push('/companies')  
            },2000))();
        }else{
            const errorMessage = response.response && response.response.data.error && response.response.data.error.message;
            if (errorMessage){
                (() => setServerError(errorMessage))();
            };
        };
        
    };

    const props = {fieldSettings, variables, setVariables, fieldErrors, setFieldErrors, serverError};

    return(
        <div>
            <hr/>
            <h4>{translate("Add new Company")}</h4>
            {!success && 
                <Form onSubmit = {e => handleSubmit(e)}>
                    <FormFields props={props}/>
                    <Button block size="lg" type="submit" >{translate("Add Company")}</Button>
                </Form>}
                {success && <h5>Company successfully added.</h5>}

            
        </div>
    )
};
export default CompanyNew;


