import React, { useEffect} from 'react';

const ServerError = ({serverError, setServerError, delay=3000, stay=false}) => {
    // console.log('inside ServerError', serverError);
      
    useEffect(() => {
        if(!stay && serverError)setTimeout(() => setServerError(null), delay)
    }, [stay, serverError, setServerError, delay])


    if(!serverError)return null;

    return (
        <div className = "my-3">
            <h5 className="text-danger">{serverError}</h5>
            {stay && serverError && <button className = "btn btn-outline-dark mt-2" onClick={() => setServerError(null)}>Ok</button>}
        </div>
    )
};
export default ServerError;