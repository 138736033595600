// 4.12.23
import React, {useEffect, useState} from "react";
import {useSelector} from 'react-redux'
import PhotoShow2 from "../PhotoShow2";

const VesselPhotos = () => {
    const [photos, setPhotos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [actualPhoto, setActualPhoto] = useState({})
    const currentVessel = useSelector(st => st.currentVessel);

    useEffect(() => {
        
        const fetch = () =>{
            const mainPhoto = (currentVessel?.photoUrl && {imageUrl:currentVessel.photoUrl}) || null;
            if(!mainPhoto){
                setPhotos([...currentVessel.photos]);
            }else{
                setPhotos([mainPhoto]);
                setTimeout(() => setPhotos([...currentVessel.photos]), 5000);
            };
            (() => setIsLoading(false))();
        }
        if(currentVessel?.photos)fetch()
    },[currentVessel, setPhotos, isLoading, setIsLoading])

    return(
        <div>
            <PhotoShow2 photos={photos} actualPhoto={actualPhoto} setActualPhoto={setActualPhoto}/>
        </div>
    )
};
export default VesselPhotos;