import React, {useEffect, useState} from 'react';
import Form from "react-bootstrap/Form";
import {validateField} from '../ReactFormFunctions';
import {useSelector, useDispatch} from 'react-redux';
import { fetchDestinationsFromAPI } from '../../actions/destinations';
import {Link} from 'react-router-dom';
import Spinner from '../../components/Spinner';

const TextFieldWithValueArea = (props) => {

    //  // console.log('inside textfield, ', {props});
    const {name, variables, constants} = props;
    const {errors} = variables;
    const {fields} = constants;
    const field = fields[name]
    const value = variables.fields[name] || "";
    const [destinationList, setDestinationList] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const destinations = useSelector(st => st.destinations);
    const dispatch = useDispatch();
    
    const handleChangeWithList=async(e, constants, variables)=>{
        const {name, value}=e.target;
        
        const fields = {
            ...variables.fields,
            [name]: value
        }
        const vars = {
            ...variables,
            fields: fields
        }
        constants.events.setVariables(vars)
        // console.log({variables})
        if(value.length < 3)return;
        // console.log("VOR FILTER")      
        const destList = destinations.filter(d=>{
           return d.enUK.toLowerCase().includes(value.toLowerCase());
        });
        // console.log('NACH FILTER')
        // console.log(destList && destList.length)
        destList && destList.length > 0 && setDestinationList(destList);

    }
    useEffect(function() {
        async function fetchDestinations() {
        //   // console.log('inside useEffect, fetchDestinations', {isLoading})
          await dispatch(fetchDestinationsFromAPI());
          // console.log('nach dispatch+++++.', destinations, destinations.length)
          setIsLoading(false);
        }
          if (isLoading && destinations.length === 0) {
          fetchDestinations();
        }
    }, [dispatch, isLoading, destinations]);

    // console.log({destinations, isLoading})
    if (!destinations) return <Spinner/>
    // console.log({destinations})
    // const handleClick = (e) => {
    //     if(e)e.preventDefault();
    //     // console.log(e && e.target)
    // }
  

    const ValueArea = (props) => {
        // console.log("VOR RENDER destinationList")
        const destinationOutput = destinationList && destinationList.map(function(d){
            return(
                <li key={d.id} id={d.id}>
                    {d.enUK}
                    <Link to={`/destination/edit/${d.id}`} className="ml-3">Edit</Link>
                </li>)
        });
        // console.log("NACH RENDER destinationList")
        // console.log(destinationList && destinationList.length)
        const ul=(<ul id="destinationList">{destinationOutput}</ul>)
        return ul;
    }
    
    return (
    <>
         <Form.Group size="lg" controlId={name}>
           <Form.Label>{field.label}{field.required? ' * ':' '}</Form.Label>
              { errors[name]?<small className="text-danger" > - {errors[name]}</small>:null}
              <Form.Control 
                 type={field.type || "text"}
                 key={name}
                 required={field.required}
                 autoFocus={field.autofocus}
                 disabled={field.disabled}
                 value={value || ""}
                 placeholder={field.placeholder?`${field.placeholder}`:null}
                 name={name}
                 onChange={(e) => handleChangeWithList(e, constants, variables)}
                 onBlur={(e) => validateField(e, constants, variables)}
                    //              onTouchEnd={(e) => // console.log('onTouchEnd')}
           />
           
           {field.toolTip?<p><small className="text-black" >{field.toolTip}</small></p>:null}
        </Form.Group>
        <ValueArea/>
    </>
    )
}

export default TextFieldWithValueArea;