import React, {useState} from "react";
import { translate } from "../../Language/Translate";
import SpeciesNew from "../../../newForms2/observations/species/SpeciesNew";
import { BlackWhite } from "../../styledComponents/colors";
import { StyledDiv } from "../../styledComponents/divs";
import SpeciesDetails from "../species/SpeciesDetails";
import Confirmation from "../../../newForms2/confirmation";
import { simpleRequest } from "../../../newForms2/simpleRequest";

const SpeciesGroupDetails = ({props}) => {
    const [localForm, setLocalForm] = useState('main');
    const [confirmation, setConfirmation] = useState(false);
    const {speciesGroups, setSpeciesGroups, species, setOpenForm, speciesGroupId, speciesId, setSpeciesId} = props
    const actualGroup = speciesGroups.find(sg => sg.id === speciesGroupId) || {};
    const specs = species.filter(s => s.speciesGroupId === speciesGroupId);
    const {enUK, deDE, latin } = actualGroup;
    
    // console.log(speciesId, {actualGroup, specs, species, localForm});
    const handleClick = e => {
        setSpeciesId(+e.target.parentElement.parentElement.id);
        setLocalForm('speciesDetails');

    };
    const handleDelete = async() => {
        const request = {url:`speciesgroups/${speciesGroupId}`, method:"delete"};
        const deleted = (await simpleRequest(request)).data?.deleted;
        setSpeciesGroups(speciesGroups => [...speciesGroups.filter(sg => sg.id !== speciesGroupId)]);
        setOpenForm('speciesGroupList');
        // console.log("delete", {request, speciesGroups, deleted, speciesGroupId});
    };

    const Buttons = () => {
        return(
            <>
                <button className="btn btn-outline-dark" onClick={() => setOpenForm("updateSpeciesGroup")}>{translate("Edit Group")}</button>
                <button className="btn btn-outline-danger ml-1" onClick={() => setConfirmation(true)}>{translate("Delete Group")}</button>
                <button className="btn btn-outline-dark ml-1" onClick={() => setOpenForm("speciesGroupList")}>{translate("Back")}</button>
                <button className="btn btn-outline-dark ml-1" onClick={() => setLocalForm("addSpecies")}>{translate("Add Items")}</button>
                {confirmation && <Confirmation setConfirmation={setConfirmation} func={handleDelete}/>}
            </>
        )
    };
    return(
        <StyledDiv>
            <h3>{`${deDE || ""} / ${enUK || ""} / ${latin || ""}`}</h3>
            {/* <h4>localForm: {localForm}</h4> */}
            {localForm === "main" && <ul>{specs.map(s => 
                <li key={s.id} id={s.id} onClick={e => handleClick(e)}>
                    <BlackWhite><b>{`${s.deDE} / ${s.enUK}`}</b></BlackWhite>
                </li>)}
            </ul>}
            {localForm === "addSpecies" && <SpeciesNew props={{...props, setLocalForm}}/>}
            {localForm === "speciesDetails" && <SpeciesDetails props={{...props, setLocalForm}}/>}
            {!["speciesDetails", "addSpecies"].includes(localForm) && <Buttons />}
        </StyledDiv>
    );
};
export default SpeciesGroupDetails;