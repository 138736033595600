import {translate} from "../../components/Language/Translate";




class UserCruiseSchema{

    constructor(){
        this.fields = {
            username:{
                 disabled:true,
                 fieldType:"text",
                 value:"",
                 label:translate("Username")
            },
            cruiseId:{
                 disabled:true,
              //    hidden :true,
                 fieldType:"number",
                 value:1,
                 label:"Cruise ID"
            },
            cruise:{
                 disabled:true,
                 fieldType:"text",
                 value:"",
                 label:translate("Cruise")
            },
            cruiseStatus: {
                label:"Status",
                required : true,
                fieldType : "select",
                type: "text",
                options:[{key:"guest", value:translate("Passenger")}, {key:"staff", value:"Staff"}, {key:"crew", value:"Crew"}],
                value : "guest",
            },
            department : {
              label : "Department",
              fieldType: "text",
              type: "text",
              value: ""
            },
            position : {
                 label:"Position",
                 fieldType: "text",
                 type:"text",
                 value:""
            },
            optionalText: {
                  label:translate("Optional text"),
                  fieldType:"textarea",
                  type:"text",
                  value:''
            }
        }
    }
};
export default UserCruiseSchema;
