import FormDataFieldClass from '../FormDataFieldClass'
import {translate} from "../../components/Language/Translate"

class UserSchema{
    constructor(){
        this.fields = {
            username : new FormDataFieldClass(
                "", translate("Username"), "text", "text",
                {required:true, noUpdate: true},
                {minLength:8, maxLength:15, noSpace:true, mustNotContain:"@"}
            ),
            nickname : new FormDataFieldClass(
                "", translate("Displayed Name"), "text", "text",
                {required:true},
                {minLength:3, maxLength:40,}
            ),        
            firstName : new FormDataFieldClass(
                "", translate("First Name"), "text", "text",
                {required:true},
                {minLength:2, maxLength:25, noNumbers: true}
            ),
            lastName : new FormDataFieldClass(
                "", translate("Last Name"), "text", "text",
                {required:true},
                {minLength:2, maxLength:25, noNumbers:true}
            ),
            language : new FormDataFieldClass(
                "enUK", translate("Language"), "select", "text",
                {required:true, options:[{key:"deDE", value:"Deutsch"}, {key:"enUK", value:"English"}]},
                {minLength:5, maxLength:5, noSpace:true}
            ),
            email : new FormDataFieldClass(
                "", "Email", "text", "email",
                {required:true},
                { minLength:8, maxLength:40, isEmail:true}
            ),
            confirmEmail : new FormDataFieldClass(
                "", translate("Confirm Email"), "text", "email",
                {required:true},
                {mustBeEqualTo:"email"}
            ),
            photoUrl : new FormDataFieldClass(
                "", "Photo URL", "text", "url",
                {required:false},
                {isUrl:true}
            ),
            password : new FormDataFieldClass(
                "", translate("Password"), "text", "password",
                {required:true},
                {minLength:8, maxLength:15, noSpace:true}
            ),
            confirmPassword : new FormDataFieldClass(
                "", translate("Confirm Password"), "text", "password",
                {required:true},
                {mustBeEqualTo:"password", minLength:8, maxLength:15, noSpace:true}
            ),
            
            userStatus : new FormDataFieldClass(
                "user", "Status", "select", "text",
                {options:[{key:"user", value:"user"}, {key:"admin", value:"admin"}]}

            ),
            usernameOrEmail : new FormDataFieldClass(
                "", translate("Please insert username or email address"), "text", "text",
                {required:true}

            ),
            forgotPassword : new FormDataFieldClass(
                `/users/password/request`, translate("Forgot Password?"), "link", "link",{}
            )
        }
        
    }
        
        }

export default UserSchema;