import React, {useState} from "react";
import {Accordion, Card, Button} from 'react-bootstrap';
import {getDateObject} from '../helpers/Date';
import CruiseAreaCruiseCrewList from "./CruiseAreaCruiseCrewList";
import { useSelector} from "react-redux";
import {translate} from "./Language/Translate"
import CruiseCrewNew from "../newForms2/cruiseCrew/CruiseCrewNew";
import {BlackWhite} from './styledComponents/colors';
import { Translate } from "./Language/Translate";

const HelpArea = () => (
  <div className="mt-3">
    <p>{translate("Here the ship management can present itself: Captain, Cruise Director, Officers")}.</p>
  </div>
);
const CruiseDetailsCard = ({title, eventKey, defaultKey}) =>{
    const [showNewCrewForm, setShowNewCrewForm] = useState(false);
    const currentCruise=useSelector(st => st.currentCruise);
    const currentUser=useSelector(st => st.currentUser);
    const language = currentUser?.language || Translate.language;
    const userStatus = currentUser && currentUser.userStatus;
    const mayEdit = userStatus && (["developer", "admin"].includes(userStatus) || userStatus.includes(currentCruise.companyHandle));
    const startObj = getDateObject(currentCruise.startAt, language, 'UTC');
    const endObj = getDateObject(currentCruise.endAt, language, 'UTC');
    
    
    // console.log({currentCruise});
    return(
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey={eventKey} onClick={() => defaultKey.current=eventKey}>
            <BlackWhite>{title}</BlackWhite>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={eventKey} id={eventKey} >
          <Card.Body>
              
              <div className="p-3">
                <h4 className="text-center">{currentCruise.prefix} {currentCruise.name} - {currentCruise.title}</h4>
                <h5 className="text-center">
                  {startObj.longWeekday} ({currentCruise.startPort}) - {endObj.longWeekday} ({currentCruise.endPort})
                </h5>
                <p className="text-center">{currentCruise.description}</p>
                <h5>{translate("Crew")}</h5>
                <HelpArea />
                {currentCruise.cruiseCrew && currentCruise.cruiseCrew.length === 0 && <p>{translate("The ship management has not yet made any entries here.")}</p>}
              </div>
               <div className="row pl-3">
                    <CruiseAreaCruiseCrewList defaultKey={defaultKey}/>
              </div>
              {mayEdit && <button  className="btn btn-outline-dark mt-3" onClick={() => setShowNewCrewForm(!showNewCrewForm)}>
                {translate('Add Crew')}</button>}
              <div>
                {showNewCrewForm && <CruiseCrewNew setShowNewCrewForm={setShowNewCrewForm}/>}
              </div>
          </Card.Body>
        </Accordion.Collapse>
    </Card>
    )
}

export default CruiseDetailsCard;