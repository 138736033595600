import React, { useState, useEffect } from "react";
import { simpleRequest } from "../../../../newForms2/simpleRequest";
import { translate } from "../../../Language/Translate";
import Spinner from "../../../Spinner";
// import ObservationListAll from "./ObservationsListList";
import { StyledDivWithBorder } from "../../../styledComponents/divs";
import ObservationsList from "./ObservationsList";
import { sortArrayByObjectField } from "../../../../helpers/sort";


const ObservationsAllMain = () =>{
    const [mainLoading, setMainLoading] = useState(true);
    const [speciesGroups, setSpeciesGroups] = useState([]);
    const [species, setSpecies] = useState([]);
    const [speciesObservations, setSpeciesObservations] = useState([]);
    const [openForm, setOpenForm] = useState('list'); // list, addObservation, addSpeciesGroup, updateSpeciesGroup
    const [selectedGeolocation, setSelectedGeolocation] = useState(null);

    useEffect(() => {
        const fetch = async() => {
            const sg = (await simpleRequest({url:"speciesgroups"})).data?.speciesGroups || [];
            const s = (await simpleRequest({url:"species"})).data?.species || [];
            const so = (await simpleRequest({url:"speciesobservations"})).data?.speciesObservations || [];
            (() =>setSpeciesGroups(sg))();
            (() =>setSpecies(s))();
            (() =>setSpeciesObservations(sortArrayByObjectField(so, "dateTime").reverse()))();
            (() =>setMainLoading(false))();
        }
        if(mainLoading)fetch();
    }, [mainLoading]);

    if(mainLoading)return <Spinner/>
   
    const mainProps = {
        mainLoading, setMainLoading, 
        openForm, setOpenForm,
        speciesGroups, setSpeciesGroups,
        species, setSpecies,
        speciesObservations, setSpeciesObservations,
        selectedGeolocation, setSelectedGeolocation,
    };
    console.log({mainProps});
    return(
        <StyledDivWithBorder>
            <h3>{translate("Nature Observations")}</h3>
            {openForm === "list" && <ObservationsList mainProps = {mainProps}/>}
            
        </StyledDivWithBorder>
    )
}

export default ObservationsAllMain;