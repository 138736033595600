import React from 'react';
import {getDateObject} from '../helpers/Date';
import {Accordion, Card, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux'
import {Translate, translate} from "./Language/Translate"
import { markMessageAsDeletedOnApi, markMessageAsReadOnApi } from '../actions/currentUser';
import { BlackWhite } from './styledComponents/colors';
import User from '../helpers/user';
import NewsBadge from './NewsBadge';

const Row = (props) =>{
    const currentUser=useSelector(st => st.currentUser);
    const language = currentUser?.language || Translate.language;
    const{msg, dir}=props
    const other = dir==="from"?msg.sentFrom:msg.sentTo;
    let readValue = msg.readAt;
    const created = getDateObject(msg.createdAt, language).dateTime;
    const read = getDateObject(readValue, language).dateTime;
    const msgName = dir==="from" ? msg.sentFrom:msg.sentTo
    const dispatch = useDispatch()

    const handleClick =async(e)=>{
      // console.log('inside handleClick', e.target, e.target.dataset)
      if(msg.sentTo === currentUser.username){
        // console.log({msg, currentUser})
        dispatch(markMessageAsReadOnApi(e.target.dataset.id))
      };
    };
    const handleDelete=async(e)=>{
      // console.log("inside delete", e.target, e.target.dataset)
      dispatch(markMessageAsDeletedOnApi(+e.target.dataset.id))
    }
    const Line=() =>{
        return (
            <p onClick={handleClick} data-id={msg.id}>
              <BlackWhite data-id={msg.id}>
                {other} - {msg.subject} ({translate("created")}: {created}{msg.readAt? `, ${translate("read")}: ${read}` :""});
              </BlackWhite>
                 
            </p>
        )
    }
    return(
        
            <Card style={{backgroundColor:'rgba(255,255,255,.5)'}}>
            <Card.Header >
              <Accordion.Toggle as={Button} variant="link" eventKey={props.eventKey}>
                {/* {msg.subject} */}
                {readValue?<i><Line /></i>: <b><Line /></b>}
              </Accordion.Toggle>
            </Card.Header >
            <Accordion.Collapse eventKey={props.eventKey}>
              <>
              <Card.Body>
                  <p >{msg.body}</p>
              </Card.Body>
              <Card.Footer>
                <>
                {dir==="from" && <Link to={`/users/show/${msgName}`} className="btn btn-outline-dark mr-3">{translate('Show Profile')} {msg.sentFrom}</Link>}
                {dir==="to" && <Link to={`/users/show/${msgName}`} className="btn btn-outline-dark mr-3">{translate('Show Profile')} {msg.sentTo}</Link>}
               
                {dir==="from" && <Link  to={`/messages/${msg.sentFrom}`}className="btn btn-outline-dark mr-3">{translate('Send an answer')}</Link >}
                <button data-id={msg.id} className="btn btn-outline-danger" onClick={handleDelete}>{translate('Delete')}</button>
                </>
              </Card.Footer>
              </>
            </Accordion.Collapse>
            </Card>
    );
}

const Rows = (props) =>{
    const {msgs, dir,title, setIsLoading} = props;
    if (!msgs || msgs.length === 0) return (<p>{translate("No items")}.</p>)
    // console.log({props})
    return(
        <>
           <Accordion>
               {msgs.map(msg =>{
                   return(<Row key={msg.id} eventKey={msg.id} msg={msg} dir={dir} 
                                 title={title} setIsLoading={setIsLoading} msgs={msgs}/>)})}
           </Accordion>
        </>
    );
};
  
const UserAreaCardMessagesCard = ({type, eventKey}) =>{
  const title = type === "from" ? translate('Received Messages') : translate('Sent Messages');
  const currentUser = useSelector(st => st.currentUser);
  const news = User.news(currentUser);
  const {sentMessages, receivedMessages, countMessages} = news;

    return(
        <Card >
            <Card.Header style={{backgroundColor:'rgba(255,255,255,.5)'}}>
              <Accordion.Toggle as={Button} variant="link" eventKey={eventKey}>
                <BlackWhite>{title} {type==="from"&& <NewsBadge count={countMessages}/>} </BlackWhite>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey}>
              <Card.Body>
                    {type==="from" && <Rows msgs={receivedMessages} dir="from"/>}
                    {type==="to" && <Rows msgs={sentMessages} dir="to"/>}
              </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
};

export default UserAreaCardMessagesCard;