
import {FETCH_USERS} from "./types";
import {simpleRequest} from '../newForms2/simpleRequest'

export function fetchUsersFromAPI() {
  
  return async function (dispatch) {
    const response = await simpleRequest({url:`users`, method:"get"});
    // console.log('inside fetchUsersFromAPI', response.data)
    return dispatch(getUsers(response.data));
  };
};

function getUsers(users) {
  return {
    type: FETCH_USERS,
    users,
  };
};

