import React, {useState} from "react";
import { Translate,translate } from "../../../Language/Translate";
import { getDateObject } from "../../../../helpers/Date";
import { getTextGeoFromSpeciesObservations } from "../../helpers";
// import { sortArrayByObjectField } from "../../../../helpers/sort";
// import { useSelector } from "react-redux";
// import { BlackWhite } from "../../../styledComponents/colors";
import { StyledDivWithBorder } from "../../../styledComponents/divs";
// import { Table } from "react-bootstrap";
import Confirmation from "../../../../newForms2/confirmation";
import { useRequest } from "../../../../hooks/useRequest";


const ObservationDetails = ({o, setView, props}) => {
    const [confirmation, setConfirmation] = useState(false);
    const setRequestObj = useRequest();
    // console.log({props});
    const {setSpeciesObservations} = props;
    // const [localView, setLocalView] = useState('');
    const newObj = {
        "Date and Time": `${getDateObject(o.dateTime, Translate.language, "UTC").dateTimeLong} (UTC)`,
        "Observation": `${o.deDE} / ${o.enUK}`,
        "Location": getTextGeoFromSpeciesObservations(o),
        "Vessel": `${o.prefix ? `${o.prefix} ` : ''}${o.vesselName}`,
    };

    const successFunction = (data) => {
        // console.log('inside successFunction__________________________________________________')
        setSpeciesObservations(spOb => [...spOb.filter(so => so.id !== o.id)]);
        setView('list');
    };
    const errorFunction = (err) => {
        // console.log("inside errorFunction", err);
        setView('list');
    };

    const handleDelete = async() => {
        // console.log("Delete___________________________________________________");
        const request = {url: `speciesobservations/${o.id}`, method:"delete"};
        // console.log(request);
        setRequestObj({request, errorFunction, successFunction});

        // setView('list');
    };

    return(
        <StyledDivWithBorder>
            
        {/* <div className="my-5"> */}
            <h5> ObservationDetails</h5>
            <table className="table">
            <tbody>
                            {Object.keys(newObj).map(f => (
                            
                            <tr key={f}>
                                <td >
                                    <b>{f}</b>
                                </td>
                                <td>
                                    {newObj[f]}
                                </td>
                            </tr>

                            ))}
                        </tbody>
            </table>


            
            {!confirmation && <button className = "btn btn-outline-dark ml-2">{translate("Edit")}</button>}
            {!confirmation && <button className = "btn btn-outline-danger ml-2" onClick={() => setConfirmation(true)}>{translate("Delete")}</button>}
            {!confirmation && <button className = "btn btn-outline-secondary ml-2" onClick={() => setView("list")}>{translate("Close")}</button>}
            {confirmation && <Confirmation setConfirmation={setConfirmation} func={handleDelete}/>}
        {/* </div> */}
        </StyledDivWithBorder>
    )
};
export default ObservationDetails;