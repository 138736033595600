import React from "react";
// import AdvertisementLists from "./Advertisements/AdvertisementLists";
import {Card, Accordion, Button} from 'react-bootstrap'
import Variables from "../Debug/Variables";
import {translate} from '../Language/Translate'
import { BlackWhite } from "../styledComponents/colors";


const CardVariablesArea = ({eventKey}) => {


    return (
        <Card style={{backgroundColor:'#e5e9df', color:"#1a1620"}}>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey={eventKey} >
                <h6><BlackWhite>{translate("Variables and Environment Settings")}</BlackWhite></h6>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey} id={eventKey} >
              <Card.Body>
                <Variables />
            </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}
export default CardVariablesArea;