import React from "react";
import {translate} from '../Language/Translate'
import CurrencyConverter2 from "./ForexConverter/CurrencyConverter2";
import {Accordion, Card, Button} from 'react-bootstrap'
import { BlackWhite } from "../styledComponents/colors";

const CardForexConverter = ({eventKey}) => {

    return (
        <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey={eventKey} >
                <h6><BlackWhite>{translate("Currency Converter")}</BlackWhite></h6>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey} id={eventKey} >
                <Card.Body>
                    <CurrencyConverter2/>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}
export default CardForexConverter;