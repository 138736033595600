import {
  FETCH_CRUISE, 
  ADD_CRUISE_CREW,
  UPDATE_CRUISE_CREW, 
  DELETE_CRUISE_CREW,
  DELETE_DAY,
  ADD_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  ADD_PINBOARD,
  UPDATE_PINBOARD,
  DELETE_PINBOARD,
  ADD_DIARY, 
  UPDATE_DIARY,
  DELETE_DIARY,
  ADD_DAY,
  UPDATE_DAY

} from "./types";

import {simpleRequest} from "../newForms2/simpleRequest"

export function fetchCruiseFromApi(cruiseId) {
  return async function (dispatch) {
    const response = await simpleRequest({url:`cruises/${cruiseId}`, method:"get"});
    const cruise=response.data && response.data.cruise;
    // console.log({cCruise})
    return dispatch(getCruise(cruise));
  };
}

function getCruise(cruise) {
  return {
    type: FETCH_CRUISE,
    cruise,
  };
}


// ====================================== CruiseCrew ===========================================================
export function addCruiseCrewOnApi(cruiseId, data){
  return async function(dispatch){
    const response = await simpleRequest({url:`cruisecrew/${cruiseId}`, method:"post", data});
    // console.log(response)
    const cruiseCrew = response.data && response.data.cruiseCrew;
    return dispatch(addCruiseCrew(cruiseCrew));
  };
};
function addCruiseCrew(cruiseCrew){
  return{
    type: ADD_CRUISE_CREW,
    cruiseCrew
  }
}

export function updateCruiseCrewOnApi(id, data){
  // console.log({id, data})
  return async function (dispatch){
    const response = await simpleRequest({url:`cruisecrew/${id}`, method:"patch", data:data});
    const cruiseCrew = response.data && response.data.cruiseCrew;
    return dispatch(updateCruiseCrew(cruiseCrew));
  }
};
function updateCruiseCrew(cruiseCrew){
  return{
    type: UPDATE_CRUISE_CREW,
    cruiseCrew
  }
};
export function deleteCruiseCrewOnApi(id){
  return async function(dispatch){
    const response = await simpleRequest({url:`cruisecrew/${id}`, method:"delete"});
    const deleted=response.data && response.data.deleted;
    return dispatch(deleteCruiseCrew(deleted));
  }
};
function deleteCruiseCrew(deleted){
  return {
    type:DELETE_CRUISE_CREW,
    deleted
  }
}

// ========================================= Day =======================================
export function addDayOnApi(data){
  return async function(dispatch){
    const response = await simpleRequest({url:`days`, method:"post", data});
    const day = response.data && response.data.day;
    return dispatch(addDay(day));
  };
};
function addDay(day){
  return{
    type: ADD_DAY,
    day
  };
};


export function deleteDayOnApi(id){
  return async function(dispatch){
    const response = await simpleRequest({url:`days/${id}`, method:"delete"});
    const deleted = response.data && response.data.deleted;
    return dispatch(deleteDay(deleted))

  }
};
function deleteDay(deleted){
  return {
    type:DELETE_DAY,
    deleted
  }
}


// =============================== Announcement, Pinboard ==============================================================

export function addAnnouncementOnApi(data){
  return async function(dispatch){
    const response = await simpleRequest({url:"announcements", method:"post", data});
    const announcement = response.data && response.data.announcement;
    return dispatch(addAnnouncement(announcement));
  };
};
function addAnnouncement(announcement){
  return{
      type: ADD_ANNOUNCEMENT,
      announcement
  };
};


export function addPinboardOnApi(data){
  return async function(dispatch){
    const response = await simpleRequest({url:"pinboard", method:"post", data});
    // console.log(response, response.data);
    const pinboard = response.data && response.data.pinboard; //!!! Rückgabe ist announcement!
    return dispatch(addPinboard(pinboard));
  };
};
function addPinboard(pinboard){
  return{
      type: ADD_PINBOARD,
      pinboard
  };
};







export function updateAnnouncementOnApi({id, data}){
  return async function(dispatch){
    const response = await simpleRequest({url:`announcements/${id}`, method:"patch", data});
    const  announcement = response.data && response.data.announcement;
    return dispatch(updateAnnouncement(announcement))
  };
};
function updateAnnouncement(announcement){
  return{
    type: UPDATE_ANNOUNCEMENT,
    announcement
  };
};
export function updatePinboardOnApi({id, data}){
  
  return async function(dispatch){
    const response = await simpleRequest({url:`pinboard/${id}`, method:"patch", data});
    const  pinboard = response.data && response.data.pinboard;
    return dispatch(updatePinboard(pinboard))
  };
};
function updatePinboard(pinboard){
  return{
    type: UPDATE_PINBOARD,
    pinboard
  };
};






export function deleteAnnouncementPinboardOnApi({table, id}){
  return async function(dispatch){
    const response = await simpleRequest({url:`${table}/${id}`, method:"delete"});
    const deleted = response.data && response.data.deleted;
    if (table==="announcements"){
      return dispatch(deleteAnnouncement(deleted))
    }else if(table==="pinboard"){
      return dispatch(deletePinboard(deleted))
    };
  };
};
function deleteAnnouncement(deleted){
  // console.log("inside deleteAnnouncements", {deleted})
  return {
    type: DELETE_ANNOUNCEMENT,
    deleted
  };
};
function deletePinboard(deleted){
  // console.log("inside deletePinboard", {deleted})
  return {
    type: DELETE_PINBOARD,
    deleted
  };
};

//============================================== DIARY===================================================================
export function addDiaryOnApi(data){
  return async function(dispatch){
    const response = await simpleRequest({url:`diaries`, method:"post", data})
    const diary = response.data && response.data.diary;
    return dispatch(addDiary(diary));
  };
};
function addDiary(diary){
  return{
    type: ADD_DIARY,
    diary
  };  
};

export function updateDiaryOnApi(id, data){
  return async function(dispatch){
    const response = await simpleRequest({url:`diaries/${id}`, method:"patch", data});
    const diary = response.data && response.data.diary;
    return dispatch(updateDiary(diary));
  };
};
function updateDiary(diary){
  return{
    type: UPDATE_DIARY,
    diary
  };
};

export function deleteDiaryOnApi(id){
  return async function(dispatch){
    const response = await simpleRequest({url:`diaries/${id}`, method:"delete"});
    const deleted = response.data && response.data.deleted;
    return dispatch(deleteDiary(deleted));
  };
};
function deleteDiary(deleted){
  return {
    type: DELETE_DIARY,
    deleted
  };
};

//=============================================================DAY========================================
export function updateDayOnApi({dayId, data}){
  // console.log('inside editDayOnApi', {dayId, data})
  return async function(dispatch){
    const response = await simpleRequest({url:`days/${dayId}`, method:"patch", data})
    const day = response.data && response.data.day;
    return dispatch(updateDay(day));
  }
};
function updateDay(day){
  return{
    type: UPDATE_DAY,
    day
  };  
};
