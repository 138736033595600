import React from "react";

export const FAQDeutsch = () => {
    return(
        <div className="text-left p-4" style={{backgroundColor: 'rgba(255, 255, 255,.8)', color:"black"}}>
            <h2>FAQ - Tipps für den Start</h2>
            <h4>Erste Schritte und Registrierung</h4>
            <h6>Als Gast möchte ich mir die Website anschauen, ohne mich anmelden zu müssen, um dann zu entscheiden.</h6>
            <p>Rufen Sie dazu die Seite cruisefriends.net auf, scrollen Sie nach unten, und wählen Sie „cruisefriends.net als Gast entdecken“ auf. Sie können dann ein Schiff und danach Ihre Reise auswählen. Aus Gründen des Datenschutzes können Sie jedoch keine Personen auf der Reise sehen. Als Gast können Sie keine Eingaben machen.</p>
            <p>Cruisefriends.net ausprobieren {`=>`} Cruisefriends.net als Gast entdecken {`=>`} Schiff auswählen {`=>`} Reise auswählen</p>
            <h6>Ich habe cruisefriends.net als Gast getestet, und möchte nun als User teilnehmen.</h6>
            <p>Rufen Sie die Seite cruisefriends.net auf und wählen Sie aus „Zur Registrierung“.</p>
            <p>Der Benutzername und die Emailadresse müssen einzigartig sein.</p>
            <h6>Was kostet die Benutzung von cruisefriends.net?</h6>
            <p>Die Benutzung ist für User komplett kostenlos. Es gibt keine kostenpflichtigen Varianten für User. </p>
            <h6>Warum muss ich meine Emailadresse bestätigen?</h6>
            <p>Diese Art der Anmeldung macht es automatischen Bots schwer. 
                Bots können zwar hunderte von Profilen anlegen, müssten jedoch alle Emails aufrufen und einzeln bestätigen. 
                Außerdem ist die Emailadresse die einzige verlässliche Methode um sich auszuweisen. Cruisefriends.net erhält Daten seiner Mitglieder, die zum Teil für andere User sichtbar sind (siehe nächste Frage). 
                Wer andere sehen will, kann aus Gründen den Datenschutzes selbst nicht komplett anonym bleiben.
                
            </p>
            <h6>Welche meiner Daten sind für andere User sichtbar?</h6>
            <p>Sie sind für andere User nur sichtbar, wenn Sie beide auf einer Kreuzfahrt angemeldet sind.</p>
            <p>Sichtbar für andere sind</p>
            <ul>
                <li>Benutzername (dieser ist frei wählbar, darf aber noch nicht vergeben sein. Er ist wichtig für die Funktionen der Seite, und ist manchmal sichtbar.) 
                        Eventuell wird der Benutzername bald durch eine automatisch erstellte Chiffre ersetzt. </li>
                <li>Angezeigter Name (Sie brauchen nicht Ihren Vor- und Nachnamen zu verwenden. Genauso gut kann hier ein Künstlername oder ein Spitzname stehen.) </li>
                <li>Profilbild, falls vorhanden.</li>          
            </ul>

            <p>Alle anderen Daten (auch Vor- und Nachname) können von anderen Benutzer nicht gesehen werden.</p>
            
            <h6>Als angemeldeter User möchte ich mich nur umschauen, möchte aber noch nicht gesehen werden.</h6>
            <p>Solange Sie sich nicht auf einer Kreuzfahrt als Gast oder als Crew anmelden, sind Sie für andere User nicht sichtbar. </p>
            
            
            <h4>Finden Sie Ihre Kreuzfahrt</h4>
            <h6>Als User möchte ich eine Kreuzfahrt auswählen</h6>
            <p>Start {`=>`} nach unten scrollen {`=>`} "Erkunden Sie cruisefriends.net" {`=>`} Schiff auswählen {`=>`} Reise auswählen</p>
            <h6>Als User möchte ich ein Schiff auswählen, kann es jedoch nicht finden.</h6>
            <p>Folgen Sie den Schritten:</p>
            <p>Start {`=>`} Erkunden Sie cruisefriends.net {`=>`} Das Schiff ist nicht in der Liste. Ich möchte ein neues Schiff anlegen</p>
            <h6>Als User möchte ich eine Reise auswählen, kann sie jedoch nicht finden.</h6>
            <p>Sie können zum Beispiel auch Reisen aus der Vergangenheit eingeben.</p>
            <p>Folgen Sie den Schritten:</p>
            <p>Start {`=>`} Erkunden Sie cruisefriends.net {`=>`} Schiff auswählen {`=>`} Die Kreuzfahrt ist nicht in der Liste. Ich möchte eine neue Kreuzfahrt anlegen.</p>
            <h6>Als Benutzer möchte ich auf der Reise sichtbar sein, und mit anderen Mitreisenden Kontakt aufnehmen.</h6>
            <p>Start {`=>`} Erkunden Sie cruisefriends.net {`=>`} Schiff auswählen {`=>`} Kreuzfahrt auswählen {`=>`} Ihre Daten angeben und absenden. Die Kreuzfahrt erscheint dann auch in Ihrem persönlichen Bereich (in der Navigationsleiste auf Ihren Vornamen klicken).</p>
            <h4>Kontakt zu Mitreisenden</h4>
            <h6>Als Benutzer möchte ich einem Mitreisenden eine Nachricht schreiben.</h6>
            <p>Voraussetzung für eine Kontaktaufnahme mit einem Mitreisenden ist, dass er </p>
            <ul>
                <li>auf der gleichen Kreuzfahrt angemeldet ist, dann finden Sie ihn unter Crew/Staff bzw. Passagiere</li>
             	<li>- oder - eine Freundschaftsanfrage besteht</li>
             	<li>- oder - bereits Nachrichten ausgetauscht wurden.</li>
            </ul>

            <p>Im ersten Fall öffnen Sie die Kreuzfahrt wie oben beschrieben, dann auf Crew/Staff bzw. Besatzung. Wählen Sie aus, und klicken Sie dann auf „Details zu…“. Sie gelangen zur Detailseite des anderen Users, wo Sie auch eine Nachricht senden können.
                In den anderen Fällen finden Sie die Freunde und Nachrichten im persönlichen Bereich
            </p>
            <h6>Als Benutzer möchte ich einem Mitreisenden eine Freundschaftsanfrage senden.</h6>
            <p>Folgen Sie den Anweisungen der vorigen Frage. Auf der gleichen Seite können Sie die Anfrage senden.</p>

        </div>
   
    );
};
    