import React from "react";
// import React,{useState, useEffect} from "react";
import {useSelector} from 'react-redux';
import {actualDateString} from '../helpers/Date'
import CruiseListItems from './CruiseListItems';

const UserAreaCardCruises = () =>{

    
    const actualDate = actualDateString()
    const currentUser = useSelector(st => st.currentUser)


    const cruises = currentUser?.cruises || [];

    if (!cruises) return null;
    cruises.forEach(c => c.showVessel=true);
    const currentCruises = cruises.filter(c =>{return (c.startAt <= actualDate && c.endAt >= actualDate)});
    const passedCruises = (cruises.filter(c =>{return (c.endAt <= actualDate)}));
    const plannedCruises = cruises.filter(c =>{return (c.startAt >= actualDate)});
    
    return(
        <div>
          {currentCruises.length>0?<div><CruiseListItems cruises = {currentCruises} headLine="Current" period="none" /></div>:null}
          {plannedCruises.length>0?<div><CruiseListItems cruises = {plannedCruises} headLine="Future" period="none"/></div>:null}
          {passedCruises.length>0?<div><CruiseListItems cruises = {passedCruises} headLine="Past" period="year"/></div>:null}
        </div>
    )
};

export default UserAreaCardCruises;