import chatCategories from "../../components/TravelChat/chatCategories";
import { translate, Translate } from "../../components/Language/Translate";

class ChatThemeSchema{
    constructor(chatCategory){
        this.fields = {
            category: {
                options: Object.values(chatCategories).map(cG => {
                        return {
                        key: cG.category,
                        value: cG.label[Translate.language || "enUK"]
                    }
                    }).sort((a, b) => a.value > b.value ? 1 : -1), 
                fieldType:"select",
                type:"text",
                initialValue:chatCategory.category,
                validation: {
                    maxLength: 20,
                    minLength: 3,
                },
                label: translate("Category"),
                required:true,
            },
            title:{
                type:"text",
                fieldType: "text",
                initialValue:"",
                validation: {
                    maxLength: 60,
                    minLength: 3,
                },
                label: translate("Title"),
                required:true,
            },
            subTitle:{
                type:"text",
                fieldType: "text",
                initialValue:"",
                validation: {
                    maxLength: 60,
                },
                label: translate("Subtitle")
            },

        }
    }
};
export default ChatThemeSchema;