import React from "react";
import {Accordion, Card, Button} from 'react-bootstrap';
// import { useSelector} from "react-redux";
import {translate} from "./Language/Translate";
import OtherUserMessages from './OtherUserMessages';
import { BlackWhite } from "./styledComponents/colors";

const OtherUserMessagesCard = ({title, eventKey, otherUser}) => {
  
  // const currentUser = useSelector(st => st.currentUser);  
 
  // console.log(currentUser, otherUser, title, eventKey);
  const handleClick = () => {};
  
    return(
      <Card >
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link"  eventKey={eventKey} onClick={handleClick} className="ml-3">
            <BlackWhite>{translate(title)}</BlackWhite> 
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={eventKey} id={eventKey} >
          <Card.Body>
              <OtherUserMessages otherUsername={otherUser.username}/>
          </Card.Body>
        </Accordion.Collapse>
    </Card>
    )
}

export default OtherUserMessagesCard;