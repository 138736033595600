import React, {useState} from "react";
import {Accordion, Card, Button} from 'react-bootstrap';
import { BlackWhite } from "../styledComponents/colors";
import { translate } from "../Language/Translate";
import { useSelector } from "react-redux";
import MedicalInstituteNew from "../../newForms2/destinations/MedicalInstituteNew";
import MedicalInstituteList from "./MedicalInstituteList";
import NewsBadge from "../NewsBadge";
import Symbols from "../Symbols";
import DestinationClass from "../../helpers/destinationClass";

const MedicalInstitutes = ({eventKey}) => {
    const [showFormNew, setShowFormNew] = useState(false);
    const currentDestination = useSelector(st => st.currentDestination);
    const currentUser = useSelector(st => st.currentUser);
    const loggedIn = currentUser?.userStatus ? true : false
    const len = currentDestination?.medicalInstitutes?.length;

    return(
        <Card>
            <Card.Header className="text-center">
              <Accordion.Toggle as={Button} variant="link" eventKey={eventKey}>
                <BlackWhite>{translate("Medical Institutes") + ' '} 
                <NewsBadge count={len}/></BlackWhite>
                <Symbols typeArray={DestinationClass.getMedInstDepartmentArray(currentDestination)}/>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey} id={eventKey}>
              <Card.Body>
                  <div className="p-3 text-center">
                    <h4 >{translate("Medical Institutes")}</h4>
                    {loggedIn && <button className="btn btn-outline-dark" onClick={() => setShowFormNew(!showFormNew)}>
                      {showFormNew ?  translate("Close Formular") : translate("Add Medical Institute")}
                      </button>}
                    </div>
                    <div>
                    {showFormNew && <MedicalInstituteNew/>}
                    {!showFormNew && <MedicalInstituteList/>}
                  </div>
              </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
};
export default MedicalInstitutes;