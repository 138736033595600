import {getDateObject} from './Date';
import { translate } from '../components/Language/Translate';
import { developerModus } from '../config';


class Cruise{
    constructor({currentCruise, currentUser}){
        this.loggedIn = !!currentUser.username;
        this.favourites = currentUser.favourites || [];
        this.favouriteId = (this.favourites.find(f => f.cruiseId === currentCruise.id))?.id || 0;
        this.favStar = this.favouriteId ? "StarFas" : "StarFar";
        this.toolTipText = !this.loggedIn ? translate("Favourites are only available for logged in users")
                            : !this.favouriteId ? translate("Add to Favourites") 
                            : translate("Remove from Favourites");
        this.currentUserIsJoined = currentUser && currentUser.cruises && currentUser.cruises.some(v => +v.cruiseId === currentCruise.id);
        this.currentUserCruise = currentUser && currentUser.cruises && currentUser.cruises.find(v => +v.cruiseId === currentCruise.id);
        this.startObj = getDateObject(currentCruise.startAt, currentUser?.language, 'UTC');
        this.endObj = getDateObject(currentCruise.endAt, currentUser?.language, 'UTC');
        this.currentUserText=null;
        this.positionText=null;
        this.writeText();
        this.isDeveloper = currentUser?.userStatus === "developer";
        this.isInDeveloperModus = this.isDeveloper && developerModus;
    }

    writeText(){
        if (this.currentUserCruise){
            const currentUserUcStatus = this.currentUserCruise.cruiseStatus || "guest";
            const ucStatus = ["crew", "staff"].includes(currentUserUcStatus) ? translate(`${currentUserUcStatus}`) : translate("guest");
            this.positionText = `Department: ${this.currentUserCruise.department}, Position: ${this.currentUserCruise.position}`
            const details = (currentUserUcStatus==="crew" || currentUserUcStatus==="staff")? ` (${this.positionText}).`:`.`
            const joinText = translate("You are joined to this cruise as");
            this.currentUserText = `${joinText} ${ucStatus}${details}`;
          };
    } ;
};
export default Cruise;