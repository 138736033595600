import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { StyledDiv, StyledDivWithBorder } from "../styledComponents/divs";

const ActionSection = ({a, day}) => {
    const history = useHistory();

                    return(
                        <StyledDiv key = {a.id}>
                            <div>
                            {!!a.destinationId && <button className="btn btn-outline-dark m-1" key = {a.id}
                                onClick={() => history.push(`/destination/details/${a.destinationId}`)}>
                                    {`${a.deDE} / ${a.enUK}`}
                                    </button>}
                            </div>
                        </StyledDiv >
                    )
                }

export default ActionSection;
// const ActionSection = ({a, day}) => {
//     const history = useHistory();

//                     return(
//                         <StyledDivWithBorder key = {a.id}>
//                             <div>
//                             {!!a.destinationId && <button className="btn btn-outline-dark m-1" key = {a.id}
//                                 onClick={() => history.push(`/destination/details/${a.destinationId}`)}>
//                                     {`${a.deDE} / ${a.enUK}`}
//                                     </button>}
//                             </div>
//                         </StyledDivWithBorder >
//                     )
//                 }

// export default ActionSection;