import React, {useState, useEffect} from 'react';
import {getLocationObj, getObjWithLatLongCenterZoom} from '../helpers';
import { Translate } from '../../Language/Translate';
import AllObservationMap from './AllObservations.js/AllObservationMap';
import Spinner from '../../Spinner';

const MapMainObservations = ({mainProps}) => {
    const {selectedSpeciesObservations, setSelectedGeolocation} = mainProps
    const [isLoading, setIsLoading] = useState(true)
    const [mainObject, setMainObject] = useState({zoom:2, newArr:[], arr:[...selectedSpeciesObservations], center:[0.0, 0.0]});

    useEffect(() => {
            const locationObj = getLocationObj(selectedSpeciesObservations, Translate.language);
            setMainObject({...getObjWithLatLongCenterZoom(selectedSpeciesObservations), locationObj, setSelectedGeolocation});
            setIsLoading(false)       
    }, [selectedSpeciesObservations, setSelectedGeolocation]);

    if(isLoading)return <Spinner/>
    return(<AllObservationMap props={{...mainObject}} />)
};
export default MapMainObservations;