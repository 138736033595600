import React from 'react';
import NewDestinationListItem from './NewDestinationListItem'

const NewDestinationList = ({newDays, setNewDays}) => {

    return(
        <div>
            <h5>New Destinations</h5>
            {newDays && newDays.map(d => <NewDestinationListItem setNewDays = {setNewDays} newDays = {newDays} day={d} key={d.id}/>)}
        </div>
    );
};
export default NewDestinationList;