import { useState, useEffect } from "react";
import { simpleRequestNew} from '../newForms2/simpleRequest';
import { useDispatch } from "react-redux";
import { addOpenRequest } from "../actions/requests";

function useRequest(){
    const [requestObj, setRequestObj] = useState(null);
    const dispatch = useDispatch();
    // console.log({requestObj});
    
    useEffect(() => {
        // console.log('inside useRequest', requestObj);
        if(!requestObj) return;
        const {request, errorFunction, successFunction} = requestObj;
        // console.log({requestObj, request , errorFunction, successFunction});
        
        const fetch = async() => {
            try{
                const resData = (await simpleRequestNew(request)).data;
                console.log("Request in useRequest ausgeführt: ", {resData});
                if(successFunction)successFunction();
            }catch(err){
                console.error('ERROR in useRequest',{request, err}, err.response.data);
                if(errorFunction)errorFunction();
                dispatch(addOpenRequest({...requestObj}));
            }finally{
                // console.log('inside finally____________________________________________________')
                // if(refreshFunction)refreshFunction();
            }
        }
        fetch()
    }, [requestObj, dispatch])
    return setRequestObj;
};

export {useRequest};

