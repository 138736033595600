
class VesselFormEvaluation{

    static vesselNew(constants, variables){
        // console.log('inside VesselFormEvaluation.vesselNew', {constants, variables})
        constants.history.push(`/companies/${variables.fields.companyHandle}`);
    }

    static async vesselEdit(constants, variables){
        // console.log('inside VesselFormEvaluation.vesselEdit', {constants, variables})
        constants.history.push(`/vessels/${constants.oldValues.id}`)
    };


};

export default VesselFormEvaluation;