import { simpleRequest } from "../simpleRequest";

export const getStringTour = (tour) => 
    `${tour.starttime} - ${tour.endtime}, ${tour.short} - ${tour.title}, ${tour.currency} ${(tour.prize/100).toFixed(2)}, ${tour.difficulty}, ${tour.numbers}`;
    
export const getStringTemplate = (tour) => 
    
    `${tour.short} - ${tour.title}, ${tour.currency} ${(tour.prize/100).toFixed(2)}, ${tour.difficulty}`;
    
export const getTemplates = async(cruise, action) => {
    const url = `tourtemplates/company/${cruise.companyHandle}/destination/${action.destinationId}`;
    const _templates = (await simpleRequest({url})).data?.tourTemplates || [];
    return _templates.sort((a, b) => a.short >= b.short ? 1: -1);
};
export const templateKeys = ["short", "title", "prize", "currency", "description", "difficulty"];

export const initialEscort = (user) => {
    const obj = {};
    obj.username = user.username;
    obj.displayedName = user.nickname;
    obj.responseEmail = user.email;
    return obj
}
