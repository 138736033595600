
class Randomfn{
    static createRandomString(len=30){
        const randomString = [...Array(len)].map(i=>(~~(Math.random()*36)).toString(36)).join('')
        // console.log({randomString})
        return randomString;
    };
    static createRandomStringOnlyLetters(len=30){
        const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcedfghijklmnopqrstuvwxyz".split("");
        return [...Array(len)].map(i=>letters[(Math.floor(Math.random()*letters.length))]).join('');
    }
    static createRandomStringOnlyLowerLetters(len=10){
        const letters="abcdefghijklmnopqrstuvwxyz".split('');
        const randomString = [...Array(len)].map(i=>letters[(Math.floor(Math.random()*letters.length))]).join('');
        // console.log({randomString});
        return randomString;
    }
    static sortArrayWithRandomBegin(array, key){
        const randomString = this.createRandomStringOnlyLowerLetters(10);
        return this.sortArrayWithFixBegin(array, key, randomString);
    }
    static sortArrayWithFixBegin(array, key, value){
        const before = array.filter(i => i[key] <= value);
        const after = array.filter(i => i[key] > value);
        const newArray = after.concat(before);
        // console.log({array, key, value, before, after, newArray})
        return newArray;
    };
    static createArrayOfNumbers(length){
        const arr = [];
        for(let i=0; i<length;i++){
            arr.push(i);
        };
        return arr;
    };
    static createRandomIndexArray(length){
        if (!length) return [];
        return this.createArrayOfNumbers(length)
            .map(v => {return {v, r: Math.random()}})
            .sort((a,b)=> a.r > b.r ? 1 : -1)
            .map(obj => obj.v);
    };
    static randomEmail = this.createRandomStringOnlyLetters(10) + "@dietmar-schlabertz.de"
    static randomUserData = {
        username: "User" + this.createRandomString(10),
        nickname: "Nick" + this.createRandomStringOnlyLetters(10),
        firstName: "First" + this.createRandomStringOnlyLetters(5),
        lastName: "Last"+ this.createRandomStringOnlyLetters(5),
        email: this.randomEmail,
        confirmEmail: this.randomEmail,
        language : "deDE",
        photoUrl: "",
        password:"password",
        confirmPassword:"password",
    };
};

export default Randomfn;