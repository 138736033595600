import { replacementsFromBackend } from "./data/replacementsFromBackend";

const props = "requiredSchemaNew noSchema noForm"

const getSqlFromJs = word => {
  // console.log({word}, "??????", word)
  return word?.split('').map(c => 
    "ABCDEFGHIJKLMNOPQRSTUVWXYZ".includes(c) ? `_${c.toLowerCase()}` : c).join('')};

export const getValidValue = (value, type) => {
      return  value || value === 0 || value === "" ? value
            : type === "string" ? ""
            : type === "number" ? ""
            : type === "boolean" ? false
            : "UNKNOWN TYPE";
};

function filterFalsy(obj) {
        return Object.keys(obj).reduce((acc, key) => {
          if (obj[key] !== undefined) {acc[key] = obj[key]}
          return acc
        }, {})
      };
const getJsonArr = values => {
  const {text, tablename} = values;
  const resArr = text.split('\n');
  const keys = resArr[0].split('\t');
  const resultArr = [];
  for(let i = 1; i < resArr.length - 1; i++){
      const line = resArr[i];
      const values = line.split('\t');
      const innerObj = {tablename};
      for(let j = 0 ; j < keys.length; j++){
          const value = values[j].trim() ? values[j].trim() : null;
          const key = keys[j].trim();
          // if(value === "false")innerObj[`${key}`] = false;
          if(!!value)innerObj[`${key}`] = isNaN(+value) ? value : +value;
      };
      resultArr.push(filterFalsy(innerObj));
  }
  return resultArr;
}
const getNewReplacements = jsonArr => {
  console.log({jsonArr});
  const filtered = jsonArr.filter(i => {
      // console.log(i.name, getSqlFromJs(i.name), '111111111111111111111111111111');
      return i.name && i.name !== getSqlFromJs(i.name)});
  // console.log({filtered});
  const newObj = {}
  filtered.forEach(i => {
      // console.log(i.name, getSqlFromJs(i.name), '2222222222222222');
      newObj[i.name] = getSqlFromJs(i.name);
  });
 
  return newObj;
};

const getReplacements = jsonArr => {
  const newReplacements = getNewReplacements(jsonArr);
  const newKeys = Object.keys(newReplacements);
  const oldKeys = Object.keys(replacementsFromBackend);
  const validNewKeys = newKeys.filter(k => !oldKeys.includes(k));
  const invalidNewKeys = newKeys.filter(k => oldKeys.includes(k));
  const allSortedKeys = Array.from(new Set([...newKeys, ...oldKeys])).sort();
  const newObj = {...replacementsFromBackend}
  validNewKeys.forEach(k => {
    newObj[k] = newReplacements[k];
  });
  const replacements = {}
  allSortedKeys.forEach(k => {
    replacements[k] = newObj[k];
  });
  const keys = {newKeys, oldKeys, validNewKeys, invalidNewKeys, allSortedKeys, 
            oldLen: oldKeys.length, allLen: allSortedKeys.length, newLen: newKeys.length}
  const replacementObj = {replacements, newReplacements, replacementsFromBackend,}
  const info = {keys, replacementObj }
  return {info, result:replacements }
};
const getSql = jsonArr => {
  const getSingleBody = number => {
    const sql = jsonArr.map(pl => {
      const {name, sqlType, maxLength, type} = pl;
      const localType = sqlType?.split(', ')[number] || sqlType?.split[0];
      const sqlName = getSqlFromJs(name);
      // console.log({localType, type});
      const newSqlType = localType ? localType 
                          : maxLength ? `VARCHAR(${maxLength})`
                          : type === "number" ? "INTEGER"
                          : type === "string" ? "TEXT"
                          : type.toUpperCase()
      return `${sqlName} ${newSqlType}`;
      }).join(`,\n  `)
      return sql
    };
    const end = `\n  created_by VARCHAR(25),`
                  +`\n  updated_at TIMESTAMP,`
                  +`\n  updated_by VARCHAR(25)`


  const tablename = getSqlFromJs(jsonArr[0].tablename);
  return `DROP TABLE IF EXISTS ${tablename};`
                  + `\nDROP TABLE IF EXISTS ${tablename}_actions;`
                  + `\n\nCREATE TABLE ${tablename}(`
                  + `\n  ${getSingleBody(0)},`
                  + `\n  created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP,`
                  + end
                  + `\n);`
                  + `\n\nCREATE TABLE ${tablename}_actions(`
                  + `\n  actions_id SERIAL PRIMARY KEY,`
                  + `\n  ${getSingleBody(1)},`
                  + `\n  created_at TIMESTAMP,`
                  + end
                  + `,\n  changed TIMESTAMP DEFAULT CURRENT_TIMESTAMP,`
                  + `\n  changed_by VARCHAR(25),`
                  + `\n  method VARCHAR(10)`
                  + `\n);`
  }

const getSchemaNew = jsonArr => {

    const properties = {};
    const required = jsonArr.filter(pl => pl.props?.includes('requiredSchemaNew')).map(pl => pl.name);
    jsonArr.filter(pl => !pl.props?.includes('noSchema'))
      .forEach(pl => {
        const {name, type, maxLength} = pl;
        const obj = {type};
        if(maxLength)obj.maxLength=maxLength;
      properties[name] = {...obj};
      });
      return {required, properties, additionalProperties: false};
};
const getSchemaUpdate = jsonArr => {
  const newObj = {};
  jsonArr.filter(pl => !pl.props?.includes('noSchema'))
    .forEach(pl => {
      const {name, type, maxLength} = pl;
      const obj = {type};
      if(maxLength)obj.maxLength=maxLength;
      newObj[name] = filterFalsy({...obj});
    });
  return {required: [], properties: newObj, additionalProperties: false};
};
const getFormFields = jsonArr => {
  const newObj = {};
  jsonArr.filter(pl => !pl.props?.includes('noForm'))
    .forEach((pl, idx) => {
      const {name, type, maxLength, fieldType, label, mainOrder, order, col, options, initialValue} = pl;
      const optionArr = options?.split(', ') || [];
      const newOptions = []
      optionArr.forEach(o => {
        const [key, value] = o.split(':');
        newOptions.push({"key": key?.trim(), "value": value?.trim() || key?.trim() || "- Please select -"});
    });
    const newInitialValue = getValidValue(initialValue, type);
    const validation = maxLength ? {maxLength} : null;
    // const initialValue = type === "string" ? "" : type === "boolean" ? false : 0;
    newObj[name] = filterFalsy({idx: idx+1, name, type, initialValue:newInitialValue, fieldType, label, mainOrder, order, col});
    if(validation)newObj[name].validation = validation;
    if(options)newObj[name].options = newOptions;
  });
  return newObj;
};

export const getAllPropertiesFromCsvData = values => {
    const jsonArr = getJsonArr(values);
    const sql = getSql(jsonArr);
    const returnArray = jsonArr.map(pl => pl.name);
    const schemaNew = getSchemaNew(jsonArr);
    const schemaUpdate = getSchemaUpdate(jsonArr);
    const formFields = getFormFields(jsonArr);
    const replacements = getReplacements(jsonArr);
    return {length: values.text.length, jsonArr, returnArray, schemaNew, schemaUpdate, 
            formFields, sql, props, replacements};
};

