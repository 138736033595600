import React from 'react';
import {translate} from '../../components/Language/Translate';

const MultiCheck = ({name, props}) => {
    const {fieldSettings, variables, setVariables} = props;
    let varArr = variables[name]?.split('#') || [];
    const options = fieldSettings[name].options;
    options.forEach(o => o.value = varArr.includes(o.key));
    
    const handleChangeLocal = (e) => {
        varArr = e.target.checked 
            ? [...varArr, e.target.name]
            : varArr.filter(v => v !== e.target.name);
        (() => setVariables({...variables, [name]:varArr.join("#")}))();
    }

    return (
        <>
        <h5>{translate("Departments")}</h5>
        <ul>
            {options.map(o => {
                const checked = o.value;
                return(
                    <li key={o.key} >
                    <input type="checkbox"
                            className="mr-1" 
                            checked={checked} 
                            name={o.key} 
                            onChange={e => handleChangeLocal(e)}>
                    </input>
                    {o.key}
                </li>
                )
            }
            )}
        </ul>

        </>
    )
}
export default MultiCheck;