const _accounts = {
    cruisefriends:{
        email: "mail@dietmar-schlabertz.de",
        imgBB: "7801176156020a3847121a8c1afc4b09",
        password: "..."
    },
    diddi1967:{
        email:"mail@Dietmar-Schlabertz.de",
        imgBB: 'bf859edaf558ebb96ec47c4608897f9f',
        password:"..."
    },
    friends_001:{
        email: "friends_001@gmx.de",
        password: "C...#2...",
        imgBB: "5311d759958b4ea6ed9aa17927057b12"
    },
    friends_002:{
        email: "friends_002@tutanota.com",
        password: "C...#2...",
        imgBB: "e3ef5a66f1c1601be139fae01e86eb2b"
    },
    friends_004:{
        email: "friends.a00444@gmail.com",
        password: "C...#2...",
        nameImgBB: "Friends-a00444",
        imgBB: "525ed18d5e5aa8da1d6299f544e8b11b"
    },
    cordulaOboe:{
        email: "CordulaOboe@web.de",
        password: "C...#2...",
        nameImgBB: "CordulaOboe",
        imgBB: "7ef915498b95a0b987f61812ab3abcb1"
    },
    gabiGeige:{
        email: "GabidieGeige@gmx.de",
        password: "C...#2...",
        nameImgBB: "GabiGeige",
        imgBB: "0ec914662e54523b3a1d3276fbdc533d"
    },
    developCr:{
        email: "developcr@gmx.de",
        password: "C...#2...",
        nameImgBB: "DevelopCr",
        imgBB: "dd11a8b96fdf5c29068890688c0a2589"
    },
};




const actualAccountName = {
    development: "developCr",
    // production: "cruisefriends",
    production: "gabiGeige",
}

const getActualAccount = (environment) => {
    // console.log({environment});
    const actualAccount = _accounts[actualAccountName[environment]]
    // console.log({actualAccount})
    return actualAccount;
}



module.exports = {getActualAccount, actualAccountName};