import React, { useState, useEffect, useRef } from "react";
import "./MainBoard.css" 
import MainBoard from './MainBoard';

const Memory = ({setResults}) => {
    const [counter, setCounter] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [play, setPlay] = useState('initial');
    const timeTicker = useRef();
    const startRef = useRef();

    
   
    useEffect(() => {
        const clear = () => {
            // console.log(`inside clear, das ist play: ${play}`)
            clearInterval(timeTicker.current);
            timeTicker.current = null;
            const end = new Date();
            const duration = (end && startRef.current) ? (end.getTime() - startRef.current.getTime())/1000 : 0;
            (() => setResults({counter, seconds:duration}))();
            (() => setPlay("newGame"))();
        };
        if(play === "won")clear()
    }, [play, setResults]);

    const initialize = () => {
        if(!timeTicker.current){
            setResults({counter:null, seconds:null, cruiseId:0})
            startRef.current = new Date();
            (() => setSeconds(0))();
            timeTicker.current = setInterval(() => (() => setSeconds(s => s + 1))(), 1000);
            (() => setCounter(c => 0))();
        };
    };
    useEffect(() => {
        return () => {
            // console.log('CLEAN UP IN MEMORY', timeTicker);
            clearInterval(timeTicker.current);
        };
    },[])
    
    return (
        <div>
            {play === "initial" && <button className="btn btn-outline-dark" onClick={() => setPlay("play")}>Start Game</button>}
            {play === "newGame" && <button className="btn btn-outline-dark" onClick={() => setPlay("play")}>Play again</button>}
            {play === "play" && <MainBoard counter={counter} setCounter={setCounter} seconds={seconds} setPlay={setPlay} initialize={initialize}/>}
        </div>
    );
};
export default Memory;