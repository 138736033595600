import React from "react";

const CruiseAreaCardDestinationWholeDiaryDay = ({day}) => {
    // console.log({day});

    const {dateText, description} = day;
    const texts = description.split("\n");

    return(
        <div className="py-2">
            <h6 >{dateText}</h6>
            {texts.map((t, idx) => {
                return (t === ""  ? <br key={idx}/> : <p key={idx}>{t}</p>)
            })}
        </div>
    );
};
export default CruiseAreaCardDestinationWholeDiaryDay;