import React, { useState } from "react";
import {useSelector} from 'react-redux';
import {getDateObject} from '../helpers/Date';
import { Card, Col, Row } from "react-bootstrap";
import { BlackWhite } from "./styledComponents/colors";
import Symbols from "./Symbols";
import { translate, Translate } from "./Language/Translate";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CruisAreaCardDayBody from "./CruiseAreaCardDay/CruiseAreaCardDayBody";
import CruiseAreaEditRoutingMain from "../newForms2/dayDestinations/CruiseAreaEditRoutingMain";
import { sortArrayByObjectField } from "../helpers/sort";

const CruiseAreaCardDestinationAccCard = ({day}) => {
        const [editSingleDay, setEditSingleDay] = useState(false)
        const [showBody, setShowBody] = useState(false);
        // const history = useHistory();
        const currentUser = useSelector(st => st.currentUser);
        const currentCruise = useSelector(st => st.currentCruise);
        const days = (sortArrayByObjectField(currentCruise.days, "arrival"))
        const {date, actions} = day;
        // actions.sort((a, b)  => a.arrival > b.arrival ? 1 : -1);
        const language = currentUser?.language || Translate.language;

    const SingleAction = ({action}) => {
        const [showText, setShowText] = useState(false);
        // console.log(action);
        const {destination, arrival, departure, description, items, destinationId} = action;
        const destinationText = action[language] || action.enUK || destination || "";
        // const showTextInt = () => {
        //     console.log('inside showTextInt')
        //     setShowText(true);
        //     setTimeout(() => setShowText(false), 3000)
        // };

        const handleClickDestination = () => {
            // console.log({destination, destinationId})
            // destinationId > 1 ? history.push(`/destination/details/${destinationId}`) 
            //     : !destinationId && destination ? showTextInt() : console.log('nothing');
        };
        const hintText = `This destination does not exist. Please click "Edit Routing"`

        return(
            <div>
                <span>
                    {destinationId  ? <BlackWhite onClick={handleClickDestination}><b>{destinationText}</b></BlackWhite> 
                        : <p style = {{color: "grey"}} onClick={handleClickDestination}>{destinationText}</p>}
                    {showText && <p>{translate(hintText)}</p>}
                    {arrival || departure ? ` (${arrival || ''} - ${departure || ''})`:""}
                    <Symbols typeArray = {items}/>
                </span>
                
                <p><small>{description}</small></p>
            </div>
        );
    };

    const TitleString = () => {
        const dateString = getDateObject(date, language,'UTC').longWeekday;
            return(
                    <div style={{color:'black'}}>

                                <h5 onClick = {() => setShowBody(!showBody)}><BlackWhite>{dateString}</BlackWhite>
                                    
                                </h5>
                        {!showBody && actions.map(a => <SingleAction key={a.id} action = {a}/>)}

                    </div>
            );
        };

        const Main = () => {
            return(
                <>
                
                <Card onClick={() => setShowBody(!showBody)}>
                    <Card.Header>
                            <TitleString/>
                    </Card.Header>
                    {showBody && <CruisAreaCardDayBody day={day} showBody={showBody} setShowBody={setShowBody} setEditSingleDay={setEditSingleDay}/>}
                </Card>
                
                </>
            )

        }

        return(
            <>
            
                {!editSingleDay && <Main/>}
                {editSingleDay &&  actions.map(a => 
                    <CruiseAreaEditRoutingMain key={a.id} days={days} id={a.id} backFunc={() => setEditSingleDay(false)}/>)}
            </>
        );
    
};
export default CruiseAreaCardDestinationAccCard;