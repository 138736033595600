import React, { useState, useEffect, useRef } from "react";
import { Accordion } from 'react-bootstrap';
import CruiseAreaCard from '../CruiseAreaCard';
import CruiseAreaCardUser from '../CruiseAreaCardUser';
import CruiseDetailsCard from '../CruiseDetailsCard';
import { useParams, Link, useHistory } from "react-router-dom";
import { fetchCruiseFromApi } from "../../actions/currentCruise";
import { useSelector, useDispatch } from 'react-redux';
import { ensureAuthorized } from '../../helpers/auth';
import { simpleRequest } from "../../newForms2/simpleRequest";
import ConfirmationArea from '../ConfirmationArea';
import { translate } from "../Language/Translate";
import { addFavouriteOnApi, deleteFavouriteFromApi, fetchCurrentUserFromApi } from "../../actions/currentUser";
import CruiseAreaCardGroups from "../CruiseAreaCardGroups";
import { BlackWhite } from '../styledComponents/colors';
import CruiseDetailJoinArea from "../CruiseDetailJoinArea";
import { fetchGroupsFromApi } from "../../actions/groups";
import Spinner from "../Spinner";
import CruiseAreaCardMap from "../CruiseAreaCardMap";
import Cruise from "../../helpers/cruise";
import Symbols from "../Symbols";
import PhotoShowProvider from "../PhotoShows/PhotoShowProvider";
import CruiseAlbumsCard from "../CruiseAlbumsCard";
import CruiseObservationCard from "../CruiseObservationCard";
import {getDatesOfCruise} from '../../helpers/usercruise'

const CruiseDetails = ({ id }) => {
  let { cruise_id } = useParams();
  const cruiseId = id ? id : +cruise_id;
  const currentCruise = useSelector(st => st.currentCruise);
  const currentUser = useSelector(st => st.currentUser);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationLeaveCruise, setConfirmationLeaveCruise] = useState(false);
  const [copied, setCopied] = useState(null);
  const [albums, setAlbums] = useState([]);
  const [photos, setPhotos] = useState([]);

  const defaultKey = useRef("none");
  const history = useHistory();
  const frontendURL = process.env.NODE_ENV === "production" ? "https://cruisefriends.net" : "http://127.0.0.1:3000";
  const directLink = `${frontendURL}?next=cruises&cruise_id=${cruiseId}`;

  const cruiseLength = getDatesOfCruise(currentCruise).length;

  useEffect(function () {
    async function fetch() {
      dispatch(fetchCruiseFromApi(cruiseId));
      dispatch(fetchGroupsFromApi(cruiseId));
      localStorage.setItem('cruiseId', cruiseId);
      (() => setIsLoading(false))();
    };
    if (isLoading) fetch();
  }, [dispatch, isLoading, cruiseId]);

  useEffect(() => {
    const fetchPhotos = async () => {
      const albs = (await simpleRequest({ url: "albumdestination/days", method: "post", data: currentCruise.days })).data?.albums || [];
      (() => setAlbums([...albs]))();
      let ph = [];
      albs.forEach(a => ph = [...ph, ...a.photos]);
      (() => setPhotos(ph))();
    };
    if (currentCruise.days) fetchPhotos();
  }, [currentCruise]);

  if (isLoading || !currentCruise.id) return <Spinner />;

  const { favouriteId, loggedIn, currentUserIsJoined, currentUserCruise, startObj, endObj, 
          currentUserText, favStar, toolTipText, isInDeveloperModus } = new Cruise({ currentCruise, currentUser });
  const mayAddCruise = currentUser && currentUser.userStatus ? ensureAuthorized(currentUser.userStatus, '#adVe', currentCruise.companyHandle) : false;
  const handlePressLeaveCruise = () => setConfirmationLeaveCruise(true);
  const handleCancelLeaveCruise = () => setConfirmationLeaveCruise(false);
  const handlePressDelete = () => { setConfirmation(true); };
  const handleDelete = async () => {
    await simpleRequest({ url: `cruises/${currentCruise.id}`, method: "delete" });
    history.push(`/vessels/${currentCruise.vesselId}`);
  };
  const toggleFavourites = () => {
    if (!loggedIn) return;
    if (favouriteId) {
      dispatch(deleteFavouriteFromApi(favouriteId));
    } else { dispatch(addFavouriteOnApi(cruiseId)); }
  };

  const handleDisconnectFromCruise = async () => {
    await simpleRequest({ url: `usercruises/${currentUserCruise.id}`, method: "delete" });
    dispatch(fetchCruiseFromApi(currentCruise.id));
    currentUser.username && dispatch(fetchCurrentUserFromApi(currentUser.username));
    setConfirmationLeaveCruise(false);
    setIsLoading(true);
    history.push(`/cruises/${currentCruise.id}`);
  };
  const handleCancel = () => { setConfirmation(false); };
  const crewStaff = currentCruise && currentCruise.users && currentCruise.users.filter(u => ["crew", "staff"].includes(u.cruiseStatus));
  const guests = currentCruise && currentCruise.users && currentCruise.users.filter(u => u.cruiseStatus === "guest");

  const shorthand = currentCruise.shorthand ? ` - ${currentCruise.shorthand}` : "";
  const handleCopy = () => {
    navigator.clipboard.writeText(directLink);
    setCopied(translate('Link was copied to clipboard'));
    setTimeout(() => setCopied(null), 2000);
  };
  // console.log({currentCruise});

  const MainArea = () => {

    return (
      <>
        <div className="mb-2 text-center">
          {currentUserIsJoined && <p>{currentUserText}</p>}
          {currentUserIsJoined && <button onClick={handlePressLeaveCruise} className="btn btn-outline-dark mx-1 my-1">{translate("Leave this Cruise")}</button>}
          {mayAddCruise && <Link to={`/cruises/${currentCruise.id}/edit`} className="btn btn-outline-dark mx-1 my-1">{translate("Edit Cruise")}</Link>}
          {mayAddCruise && <button onClick={handlePressDelete} className="btn btn-outline-danger mx-1 my-2">{translate("Delete Cruise")}</button>}
          {<button onClick={handleCopy} className="btn btn-outline-dark mx-1 my-2">{translate("Copy Link to this Cruise")}</button>}
          {copied && <span>{copied}</span>}
        </div>

        <Accordion defaultActiveKey={defaultKey.current}>
          <CruiseDetailsCard eventKey='0' type="showCruise" id="cruiseDetails" title={translate("Cruise")} defaultKey={defaultKey} />
          <CruiseAreaCard currentCruise={currentCruise} isLoading={isLoading} eventKey='12' defaultKey={defaultKey}
            type="showDays2" title={`${translate("Cruise Diary")}`} />
          <CruiseAlbumsCard eventKey='16' defaultKey={defaultKey} albums={albums} />
          <CruiseObservationCard eventKey="17" defaultKey={defaultKey} />

          {!isInDeveloperModus &&<CruiseAreaCardMap />}
          <CruiseAreaCardUser currentCruise={currentCruise} isLoading={isLoading} eventKey='4' type="showCrew" title="Crew/Staff" members={crewStaff} defaultKey={defaultKey} />
          <CruiseAreaCardUser currentCruise={currentCruise} isLoading={isLoading} eventKey='6' type="showGuests" title={translate("Passengers")} members={guests} defaultKey={defaultKey} />
          <CruiseAreaCardGroups eventKey='11' key={"cruiseCard"} title={translate("Groups")} defaultKey={defaultKey} />
          <CruiseAreaCard currentCruise={currentCruise} isLoading={isLoading} eventKey='10' defaultKey={defaultKey}
            type="showEditorials" title={translate("Matching articles from the travel magazine")} />
        </Accordion>
      </>
    );
  };

  return (
    <div className="text-center" style={{ backgroundColor: "rgba(255,255,255,.4)" }}>

      {!isInDeveloperModus && <PhotoShowProvider photos={photos} />}
      {currentCruise.announcement && <h4 className="text-danger">{currentCruise.announcement}</h4>}
      <h4><Link to={`/vessels/${currentCruise.vesselId}`}><BlackWhite>{currentCruise.prefix} {currentCruise.name}</BlackWhite></Link></h4>
      <div></div>
      <p>
        <span id="tooltip"
          onClick={toggleFavourites} className="mr-2">
          <i data-placement="top" title={toolTipText}> <Symbols typeArray={[favStar]} margin="" /></i>
        </span>

        <i>{startObj.longWeekday} ({currentCruise.startPort}) - {endObj.longWeekday}, ({currentCruise.endPort})</i>
      </p>
      <p><i>{currentCruise.title && `${currentCruise.title}`} {shorthand}, {cruiseLength} {translate("days")}</i></p>

      {!currentUserIsJoined && <CruiseDetailJoinArea />}
      {currentUser && confirmationLeaveCruise &&
        <ConfirmationArea question={translate("Are you sure to leave this cruise?")}
          handleDelete={handleDisconnectFromCruise} handleCancel={handleCancelLeaveCruise} />}
      {mayAddCruise && confirmation &&
        <ConfirmationArea question="Are you sure to delete this cruise"
          handleDelete={handleDelete} handleCancel={handleCancel} />}
      {!confirmation && !confirmationLeaveCruise && <MainArea />}
    </div>
  );
};
export default CruiseDetails;
