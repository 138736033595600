// import { simpleRequest } from "../simpleRequest";

import {translate} from "../../components/Language/Translate";
import { simpleRequest } from "../simpleRequest";

class CompanyCrewFormData {

    static async compCrewNew(data) {
        // console.log('inside CompanyCrewFormData.companyCrewNew', data, data.params, data.params.companyHandle)
        const companyHandle = data.params.handle;
        const consts = new CompanyCrewFormSchema();
        consts.fields.companyHandle.value=companyHandle
        let fields;
        Object.entries(consts.fields).forEach(e => fields = {...fields, [e[0]]: e[1].value})
        consts.formType = 'compCrewNew';
        consts.request = {
            url:`companycrew/${companyHandle}`,
            method:"post"
        };
        consts.nextPage = (`/companies/${companyHandle}`);
        consts.sendData = ["companyHandle", "firstName", "lastName", "bio", "photoUrl"]
        const vars={fields};
        // console.log({consts, vars})
        return {consts, vars};
    }
    
    static async compCrewEdit(data){
        
        // console.log('inside CruiseFormData. cruiseEdit', {data})
        const compCrewId=+data.params.id
        const compCrew = await CompanyCrewFunctions._getById(compCrewId)

        // console.log({compCrew})
        const consts = new CompanyCrewFormSchema()
        delete consts.fields.companyHandle;
        
        consts.sendData = ["firstName", "lastName", "photoUrl", "bio"]
        let fields;
        consts.sendData.forEach(k => fields = {...fields, k: compCrew[k]})
        consts.formType = 'compCrewEdit';
        consts.formLabels = {
            title:"Edit Crew",
            btnLabel:"Update Crew"
        }
        consts.request = {
            url:`companycrew/${compCrewId}`,
            method:"patch"
        };        
        consts.nextPage = (`/companies/${compCrew.companyHandle}`);
        consts.oldValues=compCrew;
        const vars={fields:compCrew}
        
        return {consts, vars};
    };
        
};
       




class CompanyCrewFormSchema{
    constructor(){
        this.formType="compCrewNew";
        this.nextPage=""
        this.request = {
            url:"cruises",
            method:"post",
        };
        this.formLabels = {title: translate("Add Crew"), btnLabel:translate("Add")};


        this.fields = {

            companyHandle : {
                label : "Company Handle",
                fieldType: "info",
                disabled:true,
                value :""

            },
            
            firstName : {
                label:translate("First Name"),
                value:"",
                validation : {minLength: 2, maxLength:40},
                fieldType : "text",
                type : "text",
                required:true
            },
            
            lastName : {
                label:translate("Last Name"),
                value:"",
                validation : {minLength: 2, maxLength:40},
                fieldType : "text",
                type : "text",
                required:true
            },
            
            photoUrl : {
                label:"Photo URL",
                value : "",
                validation : {minLength: 10},
                fieldType : "text",
                type:'url'
            },
        
            bio : {
                label:translate("Biography"),
                value : "",
                validation : {},
                fieldType : 'textarea',
                type:"text"
            },
        }
    }
};

class CompanyCrewFunctions{
    static async _getById(id){
        const response=await simpleRequest({url:`companycrew/${id}`, method:"get"});
        const cc = response && response.data && response.data.companyCrew
        return cc;
    }
}


export default CompanyCrewFormData;