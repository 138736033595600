import React, { useEffect, useState, useCallback} from "react";
import Spinner from '../../components/Spinner';
import { Form } from "react-bootstrap";
import UserSchema from "./UserSchema";
import FormFields from "../FormFields";
import { translate } from "../../components/Language/Translate";
import FormValidation from "../Validation/FormValidation";
import { simpleRequest } from "../simpleRequest";
import { LargeButton } from "../../components/styledComponents/colors";
import { trimVariables } from "../../helpers/string";
import PasswordToggle from  '../Fields/PasswordToggle';
import UserLogin from '../users/UserLogin';
import { useParams } from "react-router-dom";

const NewPassword = (obj) => {
    const p = useParams();
    // console.log({p, obj});
    // const {username, code} = obj;
    const username = p.username || obj?.username;
    const code = p.code || obj?.code;
    // console.log({username, code});


    // console.log('RENDER NewPassword');
    const [isLoading, setIsLoading] = useState(true);
    const [fieldSettings, setFieldSettings] = useState({});
    const [variables, setVariables] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    const [serverError, setServerError] = useState('');
    const [view, setView] = useState('input'); // success, login, waiting
    const [submitted, setSubmitted] = useState(false);
    
    const fetch = useCallback(async () => {
        
        const schema = new UserSchema();
        const vars = {};
        const fields = {}
        const fieldArr = ["password", "confirmPassword"]
        
        fieldArr.forEach(i => {
            vars[i] = schema.fields[i].initialValue;
            fields[i] = schema.fields[i]
        });
        (() => setVariables(vars))();
        // if(isInDevelopment)setVariables(Randomfn.randomUserData);
        (() => setFieldSettings(fields))();
        (() => setIsLoading(false))();
    }, []);
    useEffect(() => {if(isLoading)fetch()},[isLoading, fetch]);


    if (isLoading) return <Spinner/>

    const handleSubmit = async(e) => {
        e.preventDefault();
        (() => setVariables(trimVariables(variables)))();
        const formValidation = new FormValidation({fieldSettings, variables});
        if(!formValidation.valid){
            (() => setFieldErrors({...formValidation.errObj.errObj}))();
            return;
        };        
        (() => setSubmitted(true))();
        const timeOut = setTimeout(() => setSubmitted(false), 5000);
        const data = {...variables, username, code}
        const response = await simpleRequest({url:"users/password/setnew", method:"patch", data});
        clearTimeout(timeOut);
        if(response.errorMessage || !response.data){
            setServerError(`${response.errorMessage || ""} - ${translate('Please try again. Use the newest email')}.`);
            setTimeout(() => setView('login'), 3000);
        };
        (() => setView("success"))();
        setTimeout(() => setView('login'), 3000);
        return;
    };

    // const InputArea = () => {
    //     return(

    //         <div style={{backgroundColor:"rgba(255,255,255,.5)",borderRadius:"5px" }} className="p-3">
    //             <h4>{translate("Set New Password")}</h4>
    //             <FormFields props={props} />
    //             <PasswordToggle props={props}/>
    //             {submitted ?  <Spinner/> : <LargeButton type="submit" onClick={handleSubmit}>{translate("Submit")}</LargeButton>}  

    //         </div>
            
    //     )
    // };
    const SuccessArea = () => {
        return(
            <div>
                <h4>{translate('New Password was set successfully')}.</h4>
                <p>{translate('You can login now with the new password')}.</p>
            </div>
        );
    };

    const props = {fieldSettings, setFieldSettings, variables, setVariables, fieldErrors, setFieldErrors, serverError};

    return(           
        <div>
            <h1 className="my-5"><i>Cruisefriends</i>.net</h1>
            {view === "input" && 
                <div style={{backgroundColor:"rgba(255,255,255,.5)",borderRadius:"5px" }} className="p-3">
                    <h4>{translate("Set New Password")}</h4>
                    <Form>
                    <FormFields props={props} />
                    <PasswordToggle props={props}/>
                    {!submitted && <LargeButton type="submit" onClick={handleSubmit}>{translate("Submit")}</LargeButton>} 
                     </Form>
                </div>
            };
            {view === "success" && <SuccessArea/>}
            {view === "login" && <UserLogin/>}
        </div>
    );
};
export default NewPassword;