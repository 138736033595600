import React from 'react';
import CruiseListItem from './CruiseListItem';
import CruiseListPagination from './CruiseListPagination';
import './CruiseListItem.css'
import {translate} from "./Language/Translate"
import { getDaysOnSea } from '../helpers/usercruise';

const CruiseListItems = ({cruises, headLine="", period="none"}) => {
    // console.log({cruises, headLine, period})
    let newHeadLine = headLine && `${headLine}${cruises.length && cruises.length === 1?" Cruise":" Cruises"}`
    const totalDays = getDaysOnSea(cruises);
    newHeadLine = `${translate(newHeadLine)}  (${cruises.length}, ${totalDays} ${translate("days")})`;
    return (
      <div className = "text-center">
        {headLine && cruises.length > 0 && <h5 className = "mt-3">{newHeadLine}</h5>}
        {period === "none" && cruises.map(c => {return(<CruiseListItem key={c.id} cruise={c}/>)})}
        {period !== "none" && <CruiseListPagination cruises={cruises} period={period} headLine={headLine}/>}
      </div>
    )
  };

  export default CruiseListItems;