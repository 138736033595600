import { Translate } from "../components/Language/Translate";

const monthsDE = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
const monthsEN = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const monthsENShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const monthsDEShort = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"];
const weekdaysDE = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
const weekdaysDEShort = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
const weekdaysEN = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const weekdaysENShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const production = process.env.NODE_ENV === "production";

function dateObjectFromDb(postgresTimeStamp){
    // Funktion erstellt, weil es mit den Zeitzonen sonst nicht klappte.
    // console.log({globals});
    const pgTime = new Date(postgresTimeStamp);
    if(production)return dateObjectLocal(pgTime);
    // console.log("WEITER");
    const offset = new Date().getTimezoneOffset();
    const offsetMS = offset * 60 * 1000;
    const timeVar = pgTime.getTime();
    const newDate = new Date(timeVar - offsetMS);
    const obj = dateObjectLocal(newDate);
    return obj;
};
function actualDateString(timeZone="local" | "UTC"){
    const dateObj = (timeZone === "local" && dateObjectLocal(new Date())) || dateObjectUTC(new Date());
    return writeDateString(dateObj);
};
function actualTimeString(timeZone="local" | "UTC"){
    const dateObj = (timeZone === "local" && dateObjectLocal(new Date())) || dateObjectUTC(new Date());
    return writeTimeString(dateObj);
};
function actualDateTimeString(timeZone="local" | "UTC"){      
    const dateObj = (timeZone === "local" && dateObjectLocal(new Date())) || dateObjectUTC(new Date());
    return writeDateTimeString(dateObj);                                 
};
function getNewDateObject(date){
    const newDateObj = dateObjectLocal(date);
    const {year, month, day, hours, minutes } = newDateObj;
    // console.log({newDateObj});
    const str = `${year}-${`0${month+1}`.slice(-2)}-${`0${day}`.slice(-2)}T${`0${hours}`.slice(-2)}:${`0${minutes}`.slice(-2)}`;
    // console.log({str});
    return str;
}


function dateLanguage({year, month, day, weekday, hours, minutes, seconds}, language="enUK"){     
    const _yearShort = `${year}`.slice(-2);
    const _hours = `0${hours}`.slice(-2);
    const _minutes = `0${minutes}`.slice(-2);
    const _common = {
        dateTime: `${day}.${month + 1}.${_yearShort}, ${`0${hours}`.slice(-2)}:${`0${minutes}`.slice(-2)}`,
        time:`${_hours}:${_minutes}`,
    }

    switch (language){
        case('deDE'): 
            return {
                ..._common,
                longWeekday: `${weekdaysDE[weekday]}, ${day}. ${monthsDE[month]} ${year}`,
                shortWeekday: `${weekdaysDEShort[weekday]} ${day}.${month + 1}.${_yearShort}`,
                long: `${day}. ${monthsDE[month]} ${year}`,
                short: `${day}.${month + 1}.${_yearShort}`,
                weekday: weekdaysDE[weekday],
                weekdayShort: weekdaysDEShort[weekday],
                dateTimeLong: `${day}. ${monthsDE[month]} ${year}, ${_hours}:${_minutes}`,
                monthYear: `${monthsDE[month]} ${year}`,
            }
        case('enUK'):
        default:
            return {
                ..._common,
                longWeekday: `${weekdaysEN[weekday]} ${monthsEN[month]} ${day}, ${year}`,
                shortWeekday: `${weekdaysENShort[weekday]} ${monthsENShort[month]} ${day}, ${year}`,
                long: `${monthsEN[month]} ${day}, ${year}`,
                short: `${monthsENShort[month]} ${day}, ${year}`,
                weekday: weekdaysEN[weekday],
                weekdayShort: weekdaysENShort[weekday],
                dateTimeLong: `${monthsEN[month]} ${day}, ${year}, ${_hours}:${_minutes}`,
                monthYear: `${monthsEN[month]} ${year}`,
        };
    };
};

function dateObjectLocal(dDate) {                                                
    const year = dDate.getFullYear();
    const month = dDate.getMonth();
    const day = dDate.getDate();
    const weekday = dDate.getDay();
    const hours = dDate.getHours();
    const minutes = dDate.getMinutes();
    const seconds = dDate.getSeconds();
    return {year, month, day, weekday, hours, minutes, seconds};
};
function dateObjectUTC(dDate) {
    const year = dDate.getUTCFullYear();
    const month = dDate.getUTCMonth();
    const day = dDate.getUTCDate();
    const weekday = dDate.getUTCDay();
    const hours = dDate.getUTCHours();
    const minutes = dDate.getUTCMinutes();
    const seconds = dDate.getUTCSeconds();
    // console.log({month});
    return {year, month, day, weekday, hours, minutes, seconds};
};
function getDateObject(timeStamp, language=Translate.language || "enUK", method= "UTC" | "local" | "db"){
    // console.log({method});
    const func = method === "local" ? dateObjectLocal 
            : method === "UTC" ? dateObjectUTC : dateObjectFromDb;
            const dateVar = new Date(timeStamp);
            const obj = dateLanguage(func(dateVar), language);
            return obj;
};
const getDateObjectLocal = (timeStamp) => getDateObject(timeStamp, Translate.language, "local");


function dateTimeStringToDate(dateTimeString){
    
    // new Date(year, month, day, hours, minutes, seconds, milliseconds)
    const year = parseInt(dateTimeString.slice(0,4));
    const month = parseInt(dateTimeString.slice(5,7)) - 1;
    const day = parseInt(dateTimeString.slice(8,10));
    const hours = parseInt(dateTimeString.slice(11,13));
    const minutes = parseInt(dateTimeString.slice(14,16));
    const seconds = 0;
    const milliseconds = 0;
    const newDate = new Date(year, month, day, hours, minutes, seconds, milliseconds);
    // console.log('dateTimeStringToDate:', {dateTimeString, newDate});
    return newDate;
};

class Duration{
    static second = 1000;
    static minute = this.second * 60;
    static hour = this.minute * 60;
    static day = this.hour * 24;
    static week = this.day * 7;
    static month = this.day * 30;
    static year = this.day * 365;
};

function formatDate(dateString, language = "deDE") {
    if (!dateString) return;
    const year = dateString.slice(0, 4);
    const month = dateString.slice(5, 7);
    const day = dateString.slice(8, 10);
    switch (language) {
        case ('enUK'): {
            const dateShort = `${month}/${day}/${year}`;
            return dateShort;

        };
        case ('deDE'):
        default:
                 {
            const dateShort = `${day}.${month}.${year}`;
            return dateShort;
        };
    }
};
function readTimeStamp(timeStamp, showTime=true) {
    
    if(!timeStamp)return "";
    const obj = dateLanguage(dateObjectFromDb(timeStamp));
    // console.log({timeStamp, showTime, obj});
    return showTime ? obj.dateTime : obj.short;
};

function reduceTime (num, timeConst){
    let count = 1;
    let result = 0;
    while (num / (timeConst*count) >= 1){
        result = count;
        count++;
    }
    return {result, rest: num - (result*timeConst)}


};

function ensureDateTimeFormat(dateTime){
    const newString = `${dateTime.slice(0,4)}-${dateTime.slice(5,7)}-${dateTime.slice(8,10)}`;
    // console.log({newString});
    return newString;
};
function smartTimeDifference(diff){
    
    // const positive = diff >= 0;
    const absolute = Math.abs(diff);
    const years = reduceTime(absolute, Duration.year);
    const months = reduceTime(years.rest, Duration.month);
    const weeks = reduceTime(months.rest, Duration.week);
    const days = reduceTime(weeks.rest, Duration.day);
    const hours = reduceTime(days.rest, Duration.hour);
    const minutes = reduceTime(hours.rest, Duration.minute);
    const seconds = reduceTime(minutes.rest, Duration.second);

    return {diff, years, months, weeks, days, hours, minutes, seconds}

};
function smartTimeDiffText(diff, lastEntry="minutes"){
    const smtd = smartTimeDifference(diff);
    const arr = ["years", "months", "weeks", "days", "hours", "minutes", "seconds"];
    let stop = false
    const arr2 = arr.map(v => {
        // console.log(smtd[v].result)
        const ret = !stop && smtd[v].result ? `${smtd[v].result} ${v}`: null;
        stop = v === lastEntry;
        return ret;
    });
    return arr2.filter(v => v !== null).join(', ')
};
function timeStampToString (timeStamp, showTime=true) {
    const year = timeStamp.getFullYear();
    const month = timeStamp.getMonth()+1;
    const day = timeStamp.getDate();
    const hour = timeStamp.getHours();
    const minutes = timeStamp.getMinutes();
    const time = `${("00"+hour).slice(-2)}-${("00"+minutes).slice(-2)}`;
    const date = `${year}-${("00"+month).slice(-2)}-${("00"+day).slice(-2)}`;
    return showTime ? `${date}_${time}` : date;
};
function writeDateString(dateObj){
    const {year, month, day} = dateObj;
    return `${year}-${`0${month+1}`.slice(-2)}-${`0${day}`.slice(-2)}`
};
function writeDateTimeString(dateObj, separator = " "){

    return `${writeDateString(dateObj)}${separator}${writeTimeString(dateObj)}`
};
function writeTimeString(dateObj){
    const {hours, minutes, seconds} = dateObj;
    return `${`0${hours}`.slice(-2)}:${`0${minutes}`.slice(-2)}:${`0${seconds}`.slice(-2)}`
};


export {
    actualDateString,
    actualDateTimeString,
    actualTimeString,
    dateLanguage,
    dateObjectLocal,
    dateObjectUTC,
    dateObjectFromDb,
    dateTimeStringToDate,
    Duration,
    ensureDateTimeFormat,
    formatDate,
    getDateObject,
    getDateObjectLocal,
    getNewDateObject,
    monthsDE,
    monthsDEShort,
    monthsEN,
    monthsENShort,
    readTimeStamp,
    smartTimeDifference,
    smartTimeDiffText,
    timeStampToString,
    weekdaysDE,
    weekdaysDEShort,
    weekdaysEN,
    weekdaysENShort,
    writeTimeString,
    writeDateString
};


