import React from "react";
// import AdvertisementLists from "./Advertisements/AdvertisementLists";
import {Card, Accordion, Button} from 'react-bootstrap'
import { translate } from "../Language/Translate";
import { BlackWhite } from "../styledComponents/colors";
import SqlField from "./SqlField";
import CsvToJson from '../CsvToJson/CsvToJsonField'


const CardSqlArea = ({eventKey}) => {
    // console.log("CardAdvertisementsArea")

    return (
        <Card style={{backgroundColor:'#e5e9df', color:"#1a1620"}}>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey={eventKey} >
                <h6><BlackWhite>{translate("SQL")}</BlackWhite></h6>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey} id={eventKey} >
              <Card.Body>
                <SqlField/>
                <CsvToJson/>
            </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}
export default CardSqlArea;