import React, { useState } from "react";
import { translate } from "../Language/Translate";
import TourEscortEdit from "../../newForms2/tours/TourEscortEdit";

const TourEscort = ({account, setTourEscorts}) => {
    const [details, setDetails] = useState(false);
    const [showFormEdit, setShowFormEdit] = useState(false);
    const {displayedName, position, responseEmail} = account;

    const backFunc = () => setShowFormEdit(false);
    // console.log({account});
    return(
        <>
        {!showFormEdit && <div>
            <h6 onClick={() => setDetails(!details)}>{displayedName}, {position}, {responseEmail}</h6>
            {details && 
            <button className="btn btn-outline-dark" onClick={() => setShowFormEdit(true)}>
                {translate("Edit")}
            </button>
           }
        </div>}
        {showFormEdit && <TourEscortEdit account={account} setTourEscorts={setTourEscorts} backFunc={backFunc}/>}
        </>
    )
};
export default TourEscort;