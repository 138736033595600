import { sortArrayByObjectField } from "./sort";

class User{
    static newAndOldUsers(users, currentUser){
        if(!users || !currentUser) return {oldUsers:[], newUsers:[]};
        const lastLogin = currentUser.lastLogin || "1999-01-01";
        const newUsers = users.filter(u => u.createdAt > lastLogin);
        const oldUsers= users.filter(u => u.createdAt < lastLogin);
        return {newUsers, oldUsers};
    }

    static news(user, allGroups = []){
        // console.log({user, allGroups});
        if(!user || !user.username || !allGroups)return({countFriendRequests:0, countMessages:0, groups:[], countGroupChats:0, countAll:0});
               
        const friendRequestsFrom = user.friends && user.friends.friendRequestsFrom;
        const friendRequestsTo = user.friends && user.friends.friendRequestsTo;
        const friends = user.friends & user.friends.friends;
        
        const receivedMessages = user.messages && sortArrayByObjectField(user.messages.receivedMessages, "createdAt").reverse();
        const newMessages = receivedMessages.filter(m => !m.readAt);
        const sentMessages = user.messages && sortArrayByObjectField(user.messages.sentMessages, "createdAt").reverse();
        
        let countGroupChats = 0;
        

        allGroups.forEach(g => {
        //    // console.log({g});
           if(!g || !g.members)return;
           const userGroup = g.members.find(m => m.username === user.username)
            g.lastLogin = (userGroup && userGroup.lastLogin) || "";
            let lastChat = "";
            const unreadChats = g.chat.filter(c => {
                lastChat = c.createdAt > lastChat ? c.createdAt : lastChat;
                return c.createdAt > g.lastLogin && c.createdBy !== user.username;
            });
            g.unread = unreadChats.length;
            g.lastChat = lastChat;
            countGroupChats += g.unread;
        });

        let countGroupsInvited = 0;
        allGroups.forEach(g => {
            if(g.members.some(gm => gm.invited && gm.username === user.username))countGroupsInvited += 1
        });

        // console.log({countGroupsInvited});
        const countFriendRequests = friendRequestsFrom.length;
        const countFriendRequestsTo = friendRequestsTo.length;
        const countFriends = friends.length;
        
        const countMessages = newMessages.length;
        const countSentMessages = sentMessages.length;
        const countAll = countFriendRequests + countMessages + countGroupChats + countGroupsInvited;
        
    // console.log({countFriendRequests, countMessages, countGroupChats, countAll, countGroupsInvited});
        
    return {sentMessages, receivedMessages, newMessages,
                countFriendRequests, countMessages, countGroupChats, countGroupsInvited,
                countAll, countFriendRequestsTo, countFriends, countSentMessages };
    };
};
export default User;