import {
  FETCH_OTHER_USER,
  UPDATE_OTHER_USER
} from "./types";
import {simpleRequest} from "../newForms2/simpleRequest"


export function fetchOtherUserFromApi(username) {

  return async function (dispatch) {
    const response = await simpleRequest({url:`users/userinfo/${username}`, method:"get"});
    const oUser=response.data && response.data.otherUser;
    // console.log({oUser})
    if(!oUser)return null;
    return dispatch(getOtherUser(oUser));
  };
};

function getOtherUser(otherUser) {
  return {
    type: FETCH_OTHER_USER,
    otherUser,
  };
};

export function updateOtherUserOnApi(username, data){
  return async function(dispatch){
    const response = await simpleRequest({url:`admin/users/${username}`, method:"patch", data});
    const otherUser = response.data?.updated;
    // console.log({otherUser});
    if(!otherUser)return;
    return dispatch(updateOtherUser(otherUser));
  };
};
function updateOtherUser(otherUser){
  return{
    type: UPDATE_OTHER_USER,
    otherUser
  };
};