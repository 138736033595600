
import {
  FETCH_DESTINATIONS, 
  ADD_DESTINATION, 
  UPDATE_DESTINATION, 
  DELETE_DESTINATION
} from "../actions/types";

function sortByDestinationName(destinations) {
  return destinations.sort((a,b) => a.enUK < b.enUK ? -1: 1);
};
export default function rootReducer(state = [], action) {
  // console.log({action});
  switch (action.type) {
    case FETCH_DESTINATIONS:
          if(!action.destinations)return state;
          return sortByDestinationName([...action.destinations]);
    case ADD_DESTINATION:
          if(!action.destination)return state;
          return sortByDestinationName([...state, action.destination]);
    case UPDATE_DESTINATION:
          if(!action.destination)return state;
          return sortByDestinationName([...state.filter(d => d.id !== action.destination.id), action.destination]);
    case DELETE_DESTINATION:
          if(!action.destination)return state;
          return sortByDestinationName([...state.filter(d => d.id !== action.destination.id)]);
    default:
          return state;
  };
};
    