import React, {useState} from "react";
import {useDispatch} from 'react-redux';
import {translate} from '../Language/Translate'
import GroupChatItem from "./GroupChatItem";
import {Form} from "react-bootstrap";
import { addChatItemOnApi } from "../../actions/groups";

const GroupChat = ({group}) => {
    const [body, setBody] = useState("");
    const dispatch = useDispatch();

    if (!group.chat) return null;
    group.chat.sort(createdAt => createdAt);
    // console.log(group.chat);

    const handleChange = e => setBody(e.target.value);

    const handleSubmit = e => {
        e.preventDefault();
        // console.log(body);
        dispatch(addChatItemOnApi(group.id,{body, createdAt:new Date()}));
    };



    return (
        <div className="my-2 p-2" style = {{border:"1px solid grey", borderRadius:"5px"}}>
            {(!group.chat || group.chat.length === 0) && <p>{translate("No items")}</p>}
            {group.chat && group.chat.length > 0 && <h6>{translate("Chat History")}</h6>}
            {group.chat.map(c => <GroupChatItem key={c.id} id = {c.id} chatItem={c} />)}
            <Form>
                <Form.Group size="lg" controlId="body">
                    <Form.Control as="textarea" rows={5}
                        name="body" 
                        type="textarea" 
                        key="body" 
                        autoFocus={true}
                        value={body}
                        required = {true}
                        onChange={e => handleChange(e)} 
                    />
                </Form.Group>
                <button className="btn btn-outline-success" onClick={e => handleSubmit(e)}>{translate("Submit")}</button>
            </Form>
        </div>
    )
};
export default GroupChat;