// 11.11.23

import {
    FETCH_AUTHOR,
    UPDATE_AUTHOR
} from "./types";
import {simpleRequest} from "../newForms2/simpleRequest";
  
export function fetchAuthorFromAPI(username) {
  return async function (dispatch) {
    const response = await simpleRequest({url:`authors/${username}`, method:"get"});
    const author = response.data && response.data.author;
    return dispatch(getCompany(author));
  };
};
function getCompany(author) {
  return {
    type: FETCH_AUTHOR,
    author,
  };
};

export function updateAuthorOnAPI({username, data}) {
  // console.log({username, data});
  return async function(dispatch){
    const response = await simpleRequest({url:`authors/${username}`, method:"patch", data});
    const author = response.data && response.data.author;
    if(!author)return;
    return dispatch(updateAuthor(author));
  };
};
function updateAuthor(author){
  return{
    type: UPDATE_AUTHOR,
    author
  };
};