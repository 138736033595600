import Stars from "../../components/Stars";

const StarSet = ({name, props}) => {
    const {
        setVariables,
        variables
    } = props;

    const handleClick = e => setVariables({...variables, [name]:+e.target.dataset.number || 1});
   
    return(
        <div onClick = {e => handleClick(e)}>
            <Stars  value={variables[name]} />
        </div>
    );
};
export default StarSet;



