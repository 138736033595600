// import React, { Component } from "react";
import {appId, redirectUri, stateParam} from './consts';
import {Translate, translate} from '../../Language/Translate';
import {simpleRequest} from '../../../newForms2/simpleRequest';

import React, { Component } from "react";
import FacebookLogin from "react-facebook-login";

export default class Facebook extends Component {
  
  state = {
    isLoggedIn: false,
    userID: "",
    name: "",
    email: "",
    picture: "",
    serverError: "",
  };
  
  
  responseFacebook = async(response) => {
    // console.log({props:this.props});
    // console.log({response});

    
    if(!response.name){
      return(<h6 className="text-danger">{translate("Something went wrong.")}</h6>);
    }
    


    this.setState({
      isLoggedIn: true,
      userID: response.userID,
      name: response.name,
      email: response.email,
      picture: response.picture.data.url,
      language: Translate.language || "enUK"
    });

    const data = {...response, language:Translate.language || "enUK", response}
    const user = (await simpleRequest({url:`auth/facebookresponse`, method:"post", data})).data?.user;
    // console.log({user});
    if(user){
      // console.log('JAAAA', {user});
      localStorage.setItem("user", JSON.stringify(user));
      setTimeout(() => window.location.href="/", 2000);
    }else{
      
    };

  };
  
  componentClicked = () => console.log("clicked");

  

  render() {
    let fbContent;
    <h2>Facebook-Login</h2>
    if (this.state.isLoggedIn) {
      fbContent = (
        <div
          style={{
            width: "400px",
            margin: "auto",
            background: "#f4f4f4",
            padding: "20px"
          }}
        >
          <img src={this.state.picture} alt={this.state.name} />
          <h2>Welcome {this.state.name}</h2>
          Email: {this.state.email}
        </div>
      );
    } else {
      fbContent = (
        <FacebookLogin
          appId={appId}
          autoLoad={true}
          fields="name, email, picture"
          returnScopes = {true}
          scope="public_profile, email, user_birthday"
          onClick={this.componentClicked}
          callback={this.responseFacebook}
        />
      );
    }

    return <div>{fbContent}</div>;
  }
}