import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { translate } from "../../../components/Language/Translate";

const RoutingFormNew2 = ({setProposals}) => {
    const [value, setValue] = useState('');
    const formatTime = (time) => {
        const arr = time.split('.');
        if(arr.length !== 2)return("");
        const newArr = arr.map(i => `0${i}`.slice(-2));
        return newArr.join(':');
    }
    
    useEffect(() => {
        if(!value)return;
        try{
            const newValue = value.replaceAll('\n\n\n\n', '\n\n').replaceAll('\n\n\n', '\n\n');
            const arr1 = newValue.split('\n\n');
            const arr2 = arr1.map((i, idx) => {
                const rows = i.split('\n').filter(i => !i.includes("Individuelle"));
                const dayString = rows[0].split(', ');
                const lastItem = rows[rows.length-1];
                const destination = i.includes('Entspannung auf See') ? "Auf See" : lastItem.split('/')[0];
                const day2 = dayString[1].split('.').reverse().join('-');
                const timesArr = lastItem.split(' ').filter(i => i.includes('.'));
                const arrival = timesArr.length===2 ? formatTime(timesArr[0]) : lastItem.includes("Ankunft") ? formatTime(timesArr[0]) : "";
                const departure = timesArr.length===2 ? formatTime(timesArr[1]) : lastItem.includes("Abfahrt") ? formatTime(timesArr[0]) : "";
                return {
                    idx,
                    fulltext:i,
                    rows,
                    dayString,
                    day2,
                    lastItem,
                    destination,
                    timesArr,
                    arrival,
                    departure,
                    dateMonthDay: day2.slice(5)
                };
            })
            setProposals([...arr2]);
        }catch(err){
            console.log({"ERROR": err})
        }
        }, [value, setProposals]);

    const handleChange = e => {setValue(e.target.value)};

    return (
        <Form>
        <Form.Group size="lg" controlId={"routing"} >
            <Form.Label  className ="pt-1 px-1" >Text</Form.Label>
            <Form.Control as="textarea" rows={5}
                name={"routing"} 
                type="textarea" 
                fieldtype="textarea" 
                key={"description"} 
                autoFocus={true}
                value={value}
                onChange={e => handleChange(e)}
                placeholder={translate("Please insert the whole routing text FROM InternetData")}
            />
        </Form.Group>
    </Form>
    )
};
export default RoutingFormNew2;