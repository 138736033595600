import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "../../components/Language/Translate";
import Spinner from "../../components/Spinner";
import FormFields from "../FormFields";
import { Form, Button } from "react-bootstrap";
import FormValidation from "../Validation/FormValidation";
import { addMedicalInstituteOnApi } from "../../actions/currentDestination";
import MedicalInstituteFormSchema from "./MedicalInstituteFormSchema";

const MedicalInstituteNew = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [fieldSettings, setFieldSettings] = useState({});
    const [variables, setVariables] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    // const [serverError, setServerError] = useState('');
    const currentDestination = useSelector (st => st.currentDestination);
    const destinationId = currentDestination.id;
    // console.log({currentDestination});
    
    useEffect (() => {
        const fetch = () => {
            const sets = new MedicalInstituteFormSchema();
            delete sets.fields.geolocation;
            (() => setFieldSettings(sets.fields))();
            const vars = {};
            Object.keys(sets.fields).forEach(k => vars[k] = sets.fields[k].value);
            (() => setVariables(vars))();
        };
        if(isLoading)fetch();
        (() => setIsLoading(false))();
    }, [isLoading]);

    if(isLoading)return <Spinner/>

  
    const handleSubmit = e => {
        e.preventDefault();
        const validation = new FormValidation({fieldSettings, variables, setFieldErrors});
        validation.formValid();
        if(Object.keys(fieldErrors).length !== 0)return;
        dispatch(addMedicalInstituteOnApi({...variables, destinationId}));
    };
    
    const props = {
        fieldSettings, 
        variables, 
        setVariables,
        fieldErrors, 
        setFieldErrors, 
        // serverError
    };
        
    return(
        <>
        <h5 className = "mt-3">{translate("Add Medical Institute")}</h5>
         <Form onSubmit = {e => handleSubmit(e)}>
            <FormFields props = {props}/>
            <Button block size="lg" type="submit" className="btn btn-info">{translate("Submit")}</Button>
        </Form>
        </>
    );
};
export default MedicalInstituteNew;