import React, {useState, useEffect} from "react";
import {useSelector,useDispatch} from 'react-redux';
import {translate} from './Language/Translate'
import CruiseAreaCardDestinationWholeDiary from "./CruiseAreaCardDestinationWholeDiary";
import {sortArrayByObjectField} from '../helpers/sort';
import { fetchDestinationsFromAPI } from "../actions/destinations";
import CruiseAreaCardDestinationsAcc2 from "./CruiseAreaCardDestinationsAcc2";
import Spinner from "./Spinner";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import AuthArea from "./Auth/AuthArea";
import Auth from "../helpers/authNew";
import { isInDevelopment } from "../config";

const CruiseAreaCardDestinations3 = () => {
    const [wholeDiary, setWholeDiary] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const currentCruise = useSelector(st => st.currentCruise);
    const destinations = useSelector(st => st.destinations);
    const currentUser = useSelector(st => st.currentUser)
    // const loggedIn = !!currentUser.username;
    const authObj = new Auth(currentUser.userStatus, currentCruise.companyHandle);
    // const {editRouting} = useSelector(st => st.globals);
    const history = useHistory();
    
    useEffect(() => {
        const fetch = () => dispatch(fetchDestinationsFromAPI());
        if(destinations.length === 0)fetch();
        (() => setIsLoading(false))();
      },[isLoading, dispatch, destinations]);
  
    if(isLoading)return <Spinner />
    
    const daySet = new Set()
    currentCruise.days && currentCruise.days.forEach(d => daySet.add(d.date));
    const dates = sortArrayByObjectField(Array.from(daySet).map(date => {
        const actions = currentCruise.days.filter(cd => cd.date === date)
                    .sort((a, b)  => a.arrival > b.arrival ? 1 : -1);
        return {date:date, actions, key:date}}), 'date'); 
    const diaryExists = currentCruise.diaries && currentCruise.diaries.length > 0;
    // const buttonText = editRouting ? "Finish edit routing" : "Edit Routing";

    // console.log(currentCruise);
    return (
        <div style={{backgroundColor:"rgba(255,255,255,0.3)"}} className="py-1">
            {isInDevelopment && <AuthArea/>}
            <h4 className="mt-3">{translate("Cruise Diary")} </h4>
            {authObj.isCoSubAdmin && <button className = "btn btn-outline-dark m-1" onClick = {() => history.push('/routing')}>
                {`${currentCruise.companyName} - Admin Area`}</button>}
            {diaryExists && <button className="btn btn-outline-dark m-1" onClick={() => setWholeDiary(!wholeDiary)}>
                       {wholeDiary? translate("Close Diary") : translate("Show whole cruise diary")}</button>}
            {wholeDiary && <CruiseAreaCardDestinationWholeDiary setWholeDiary={setWholeDiary}/>}
            <CruiseAreaCardDestinationsAcc2 dates={dates}/>
        </div>
    )
};

export default CruiseAreaCardDestinations3;