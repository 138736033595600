import React, {useState, useEffect} from 'react';
import {useParams, Link} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import { fetchOtherUserFromApi } from '../actions/otherUser';
import {ensureEditAllowed} from '../helpers/auth'
import {translate} from "./Language/Translate"
import {toggleBlockedOnApi} from '../actions/currentUser'
import {Image} from 'react-bootstrap'
import Spinner from './Spinner';
import OtherUserAccordion from './OtherUserAccordion';
import CruiseBackToCruiseButton from "./CruiseBackToCruiseButton";
import UserEditByAdmin from '../newForms2/users/UserEditByAdmin';

const OtherUser2 = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [showForm, setShowForm] = useState('none')
    const {username} = useParams();
    const dispatch = useDispatch();
    const currentUser = useSelector (st => st.currentUser);
    const otherUser = useSelector(st => st.otherUser);
 
    useEffect(() => {
        const fetch = async () => dispatch(fetchOtherUserFromApi(username));
        if(isLoading)fetch();
        (() => setIsLoading(false))();
    }, [dispatch, username, otherUser, isLoading])
    
    if(isLoading) return <Spinner/>
    
    const isBlocked = currentUser.blockedUsers.includes(username);
    const handleBlock = () => dispatch(toggleBlockedOnApi(otherUser.username));
    const editable = ensureEditAllowed (currentUser, otherUser);
    // console.log({otherUser, currentUser, isBlocked, editable});
    return (
        <>
            <div className="text-center mb-3"><CruiseBackToCruiseButton /></div>
            <div className="text-center" style={{backgroundColor:"rgba(255,255,255,.5", borderRadius:"5px"}}>
                <div className="p-3">
                    {otherUser.photoUrl && <Image src={otherUser.photoUrl} className="mb-4" alt={otherUser.nickname} style={{height:"200px", width:"auto"}}/>}
                    <h3>{otherUser.nickname}<small> (username: {otherUser.username})</small></h3>
                    <div className="my-3">
                    {!isBlocked && <Link to= {`/messages/${otherUser.username}`} className="btn btn-outline-dark ml-1 my-1">{translate("Write Message")}</Link>}
                    {!isBlocked && <Link to= {`/friends/apply/${otherUser.username}`} className="btn btn-outline-dark mx-1 my-1">{translate("Add as Friend")}</Link>}
                    {editable && <button onClick = {() => setShowForm(showForm === "edit" ? "none" : "edit")} 
                                className="btn btn-outline-dark mx-1 my1">{translate(showForm === "edit" ? "Close Formular" : "Edit Profile")}</button>}
                    {/* {editable && <Link to= {`/admin/users/${otherUser.username}`} className="btn btn-outline-dark mx-1 my1">{translate("Edit Profile")}</Link>} */}
                    {!isBlocked && <button className="btn btn-outline-danger mx-1 my-1" onClick={handleBlock}>{translate("Block")}</button>}
                    {isBlocked && <button className="btn btn-outline-danger mx-1 my-1" onClick={handleBlock}>{translate("De-Block")}</button>}
                    </div>
                </div>
                <div>
                    {showForm === "edit" && <UserEditByAdmin setShowForm={setShowForm}/>}
                </div>
                <OtherUserAccordion otherUser={otherUser} />
            </div>
        </>
    )
};

export default OtherUser2;