import React from "react";
import {Accordion, Card, Button} from 'react-bootstrap';
import CruiseAreaCardAnnouncements from "./CruiseAreaCardAnnouncements";
import CruiseAreaCardDestinations2 from "./CruiseAreaCardDestinations2";
import CruiseAreaCardEditorials from "./CruiseAreaCardEditorials";
import CruiseAreaCardMap from "./CruiseAreaCardMap";
import { BlackWhite } from "./styledComponents/colors";
import CruiseAreaCardDestinations3 from "./CruiseAreaCardDestinations3";

const CruiseAreaCard = ({eventKey, title, type, defaultKey}) =>{

  const handleClick = () => {
    (() => defaultKey.current=eventKey)();
  }
  
  const SubArea = () =>{
    switch (type) {
        case "showDays":
          return <CruiseAreaCardDestinations2/>
        case "showDays2":
          return <CruiseAreaCardDestinations3/>
        case "showMap":
          return <CruiseAreaCardMap />
        case "showAnnc":
          return <CruiseAreaCardAnnouncements table={"announcements"} />
        case "showPinboard":
          return <CruiseAreaCardAnnouncements table={"pinboard"} />
        case "showEditorials":
          return <CruiseAreaCardEditorials />
        default:
          return null;
    }
  }

    return(
        <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey={eventKey} onClick={handleClick}>
               <BlackWhite>{title}</BlackWhite>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey}>
              <Card.Body className="p-1" >
                <SubArea/>
             </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
};

export default CruiseAreaCard;