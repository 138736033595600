import React from "react";
import { Form } from "react-bootstrap";
import { translate } from "../../components/Language/Translate";
    
const TextAreaField = ({props}) => {
    const {name, setVariables, variables, maxLength, fieldErrors, label, required, rows, toolTip} = props;
    // console.log({name, setVariables, variables, maxLength, fieldErrors, label, rows});
    const style = fieldErrors[name] ? {border:"2px solid red", borderRadius:"5px"} : {};
    const ErrorSpan = () => <span className = "text-danger">{` - ${fieldErrors[name]}`}</span>;
    
    const handleChange = e => {
        const {name, value} = e.target;
        if(value.length > maxLength)return;
        setVariables({...variables, [name]:value});
    };

    return(
        <Form.Group>
        <Form.Label>{translate(label) + `${required ? ` *` : ''}`}
            {fieldErrors[name] && <ErrorSpan/>}
        </Form.Label>
        <Form.Control
            as = "textarea"
            rows = {rows || 5}
            style={style}
            name={name}
            value={variables[name]}
            type="textarea"
            onChange={e => handleChange(e)}
            required = {!!required}
        />
        {toolTip && <p><small>{toolTip}</small></p>}
        </Form.Group>
    );
};
export default TextAreaField;