import { sortArrayByObjectField } from "../../helpers/sort";
import { translate } from "../Language/Translate";

const newTextGeolocation = (geolocation, len=3) => {
    if(!geolocation)return geolocation;
    const [long, lat] = geolocation.split(', ');
    const newText = `${parseFloat(long).toFixed(len)}, ${parseFloat(lat).toFixed(len)}`;
    // console.log({newText},'###################################################################');
    return newText;
}
export const getGeolocationArrayFromDestinations = (destinations) => {
    const arr1 = destinations.filter(d => !!d.geolocation)
            .map(d => {return{name:`${d.deDE} / ${d.enUK}`, destinationId:d.id, geolocation:newTextGeolocation(d.geolocation)}});
    return sortArrayByObjectField(arr1, "name");
};
export const getGeolocationArrayFromAlbums = (albums) => {
    const arr2 = albums.filter(a => !!a.geolocation)
        .map(d => {return{name:`${d.titleDE} / ${d.titleEN}`, albumId:d.id, geolocation:newTextGeolocation(d.geolocation)}});
    return sortArrayByObjectField(arr2, "name");
};

export const getGeolocationArray = (albums, destinations) => {
    //deprecated
    const arr1 = destinations.filter(d => !!d.geolocation)
            .map(d => {return{name:`${d.deDE} / ${d.enUK}`, destinationId:d.id, geolocation:newTextGeolocation(d.geolocation)}});
    const arr2 = albums.filter(d => !!d.geolocation)
        .map(d => {return{name:`${d.titleDE} / ${d.titleEN}`, albumId:d.id, geolocation:newTextGeolocation(d.geolocation)}});
    return sortArrayByObjectField(arr1.concat(arr2), "name") || [];
};


export const getTextGeo = (variables, albums, destinations) => {
    const array = getGeolocationArray(albums, destinations);
    const {albumId, destinationId, geolocation} = variables;
    const albumGeo = (albumId && array.find(i => i.albumId === albumId)) || {};
    const destGeo = (destinationId && array.find(i => i.destinationId === destinationId)) || {};
    // console.log(!!albumId, !!destinationId, !!geolocation)
    const textGeo = geolocation ? newTextGeolocation(geolocation)
                : albumId ? `${newTextGeolocation(albumGeo.geolocation)}, (${albumGeo.name})`
                : destinationId ? `${newTextGeolocation(destGeo.geolocation)}, (${destGeo.name})`
                : "unbekannt";
    return textGeo;
};

export const getTextGeoFromSpeciesObservations = (speciesObservation) => {
    // console.log(speciesObservation)
    const {geolocation, 
        albumId, albumGeolocation, albumDE, albumEN,
        destinationId, destinationGeolocation, destinationDE, destinationEN} = speciesObservation;
    // const vessel = `${prefix ? `${prefix} ` : ""} ${vesselName}`
    const text =  albumId ? `${newTextGeolocation(albumGeolocation)} (${albumDE} / ${albumEN})`
                    : destinationId ? `${newTextGeolocation(destinationGeolocation)} (${destinationDE} / ${destinationEN})`
                    : geolocation ? `${newTextGeolocation(geolocation)}`
                    : translate("unknown");
    return text
}
export const getTextGeoFromSpeciesObservationsWithVessel = (speciesObservation) => {

    const {geolocation, 
        albumId, albumGeolocation, albumDE, albumEN,
        destinationId, destinationGeolocation, destinationDE, destinationEN, vesselName, prefix} = speciesObservation;
    const vessel = `${prefix ? `${prefix} ` : ""} ${vesselName}`
    const text =  albumId ? `${newTextGeolocation(albumGeolocation)} (${albumDE} / ${albumEN} - ${vessel})`
                    : destinationId ? `${newTextGeolocation(destinationGeolocation)} (${destinationDE} / ${destinationEN} - ${vessel})`
                    : geolocation ? `${newTextGeolocation(geolocation)} - ${vessel}`
                    : translate("unknown");
    return text
}
export const fixGeolocation = (geolocation) => {
    const arr = geolocation.split(', ');
    const long = parseFloat(arr[0]);
    const lat = parseFloat(arr[1]);
    const result = `${long.toFixed(3)}, ${lat.toFixed(3)}`
    return result;
};