import React from "react";

const FormatText = ({str=''}) => {
    // console.log('str:', str, typeof str);
    const newString = typeof str === "string" ? str : ''
    const arr = newString?.split('\n') || [];
    return(
        <p>
            {arr.map((s,idx) => <span key = {idx}>{s}<br/></span>)}
        </p>
    )
}
export default FormatText;