import React from 'react';
import CruiseListItem from './CruiseListItem';
import {Accordion, Card, Button} from 'react-bootstrap';
import {getMonthsFromCruises} from './CruiseListFunctions';
import CruiseListMonthCard from './CruiseListMonthCard';
import { BlackWhite } from './styledComponents/colors';
import { getDatesOfYear } from '../helpers/usercruise';
import { translate } from './Language/Translate';


const CruiseListYearCard = ({eventKey, year, period, cruises}) => {

    const yearCruises = cruises.filter(c => {
      return (c.startAt.slice(0,4) <= year && year <= c.endAt.slice(0,4) )
    });
    const months = getMonthsFromCruises(yearCruises).filter(m => m.longMonth.includes(year));
    const totalDays = getDatesOfYear(yearCruises, year).length;

    const handleClick = () => {console.log('Clicked')}
    return(
        <Card className="text-center">
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" className="btn btn-outline-dark ml-3"  eventKey={eventKey} onClick={handleClick} >
            <BlackWhite>{year}</BlackWhite>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={eventKey} id={eventKey} >
          <Card.Body>
            <h4>{year} ({totalDays} {translate("days")})</h4>
            {period === "year" && yearCruises.map((c, idx) => <CruiseListItem key={idx} cruise = {c}/>)}
            {period === "month" && 
                    <Accordion>
                      {months.map((m, idx) => <CruiseListMonthCard cruises={yearCruises}key={m.longMonth} month={m} eventKey={idx+1} idx={idx}/>)}
                    </Accordion>
            }
          </Card.Body>
        </Accordion.Collapse>
    </Card>
    )

};
export default CruiseListYearCard;