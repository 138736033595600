// import FormCruiseNotFoundForm from "./FormCruiseNotFound";
import {translate} from '../../components/Language/Translate'

class NewCruiseSchema {
    constructor(){
        this.fields = {

            startAt : {
                fieldType: "text",
                label:translate("Start at"), 
                placeholder: translate('e.g.')+": 2021-09-09",
                required:true, 
                initialValue:"",
                toolTip:translate("Please insert the date with the format yyyy-mm-dd."),
                validation:{validDateString:true}
            },
            startPort: {
                fieldType: "text",
                label:translate("Start Port"), 
                initialValue:"",
                required :true,
                toolTip:translate('Please insert a port or "unknown".'),
                validation: {minLength:3}
            },
            endAt : {
                fieldType: "text",
                label:translate("End at"), 
                placeholder: translate('e.g.')+": 2021-10-09",
                required:true,
                toolTip:translate("Please insert the date with the format yyyy-mm-dd."),
                initialValue:"",
                validation:{validDateString:true}
            },
            endPort: {
                fieldType: "text",
                label:translate("End Port"),
                required:true, 
                initialValue:"",
                toolTip:translate('Please insert a port or "unknown".'),
                validation: {minLength:3}
            },
            title: {
                fieldType: "text",
                label:translate("Title"), 
                initialValue:"",
                placeholder:translate("Optional, eg: Cruise through the Baltic See"),
                toolTip:translate("Optional, eg: Cruise through the Baltic See")
            },
            shorthand: {
                fieldType: "text",
                label:translate("Description"), 
                initialValue:"",
                placeholder:translate("Optional, e.g. cruise number, if known"),
                toolTip:translate("Optional, e.g. cruise number, if known")
            },
            description: {
                fieldType: "textarea",
                label:translate("Description"), 
                initialValue:"",
                toolTip:"Optional"
            },
            cruiseStatus: {
                label:"Status",
                required : true,
                fieldType : "select",
                type: "text",
                options:[{key:"guest", value:translate("guest")}, {key:"staff", value:translate("Staff")}, {key:"crew", value:translate("Crew")}],
                value : "guest",
            },
            department : {
              label : "Department",
              fieldType: "text",
              type: "text",
              value: "",
              placeholder: translate("Only for crew and staff")
            },
            position : {
                 label:"Position",
                 fieldType: "text",
                 type:"text",
                 value:"",
                 placeholder: translate("Only for crew and staff")
            },
            optionalText: {
                  label:translate("Optional text"),
                  fieldType:"textarea",
                  type:"text",
                  value:''
            }
        };
        

    };
    static sendData = ["vesselId", "vessel", "shorthand", "startAt", 
            "startPort", "endAt", "endPort", "title", "description"];
    
    writeVesselSelectList(vessels){
        const vesselSelect = vessels && vessels.map(v => {
            const value = `${v.prefix} ${v.name} - ${v.companyName}${v.note?` (${v.note})`:""}, ${translate(v.type)}`;
            return {key: v.id.toString(), value}
        });
        vesselSelect.unshift({key:"-1", value:translate('Please select a vessel')})
        return vesselSelect;
    };
    companySelectList(){
        
    }
};

export default  NewCruiseSchema;