import React, { useState } from "react";
import { Col, Image } from "react-bootstrap";
import { translate } from "../../Language/Translate";
import { useSelector } from "react-redux";
import { simpleRequest } from "../../../newForms2/simpleRequest";
import PhotoLikeButton from "../../PhotoLikes/PhotoLikeButton";

const PhotoItem = ({photo, setSelectedId, photos, refresh}) => {
    // console.log("PhotoItem", {photo, setSelectedId, photos, refresh});
    const [idx, setIdx] = useState(photos.findIndex(p => p.id === photo.id))
    const currentUser = useSelector(st => st.currentUser);
    const mayDelete = currentUser.username === photo.createdBy || ["admin", "developer"].includes(currentUser.userStatus);
    // console.log({photo, mayDelete});
    
    const handleClick = (num) => {
        if((idx + num > -1) && (idx + num < photos.length)){
            setSelectedId(photos[idx + num].id);
            setIdx(idx + num);
        };
    };
    const handleDelete = async() => {
        if(!mayDelete)return;
        await simpleRequest({url:`albumphotos/${photo.id}`, method:"delete"});
        refresh();
    };
    return(
        <div className="mt-3" >
            
            <div className="row" style={{display: 'flex', alignItems: 'center',justifyContent: 'center'}}>
                <Image src={photo.imageUrl} style={{maxHeight: "60vh", maxWidth: "80vw"}} className = "mx-1"
                    alt={photo.id} onClick={() => setSelectedId(0)}/>

            </div>
            <div className="row mt-3" style={{display: 'flex', alignItems: 'center',justifyContent: 'center'}}>
                <Col>
                    <button block="true" size="lg" className = "btn btn-outline-dark col-1" onClick={() => handleClick(-1)}>{`<`}</button>
                </Col>
                <Col>
                    <PhotoLikeButton albumPhoto={photo}/>
                </Col>
                <Col>
                    <button className = "btn btn-outline-dark col-1" onClick={() => handleClick(1)}>{`>`}</button>
                </Col>
                
                
            </div>
            <div className="mt-2 text-center">
                <p>&copy; {photo.nickname}</p>
                {mayDelete && <button className = "btn btn-outline-danger" onClick={e => handleDelete(e)}>{translate("Delete Photo")}</button>}        
            </div>
        </div>
    )
};
export default PhotoItem;