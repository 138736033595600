import React, { useEffect, useState } from "react";
import { simpleRequest } from "../../newForms2/simpleRequest";
import { translate } from "../Language/Translate";
import Spinner from "../Spinner";
import { BlackWhite } from "../styledComponents/colors";
import { useSelector } from "react-redux";
import {getDateObject} from '../../helpers/Date';

const ChatCommentList = ({post}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [comments, setComments] = useState([]);
    const [activeCommentId, setActiveCommentId] = useState(0);
    const currentUser = useSelector(st => st.currentUser);
    const activeComment = comments.find(c => c.id === activeCommentId);
    const mayDelete = currentUser && (["developer", "admin"].includes(currentUser.userStatus) || activeComment?.createdBy === currentUser.username);
 
    useEffect(() => {
        const fetch = async() => {
            const request = {url: `travelchatcomments/${post.id}`}
            const _comments = (await simpleRequest(request)).data?.travelchatComments || [];
            console.log({request, _comments});
            setComments([..._comments])
            setIsLoading(false)
        }
        fetch();
        
    }, [setIsLoading, post]);
    if(isLoading)return <Spinner/>

    const handleDelete = async(comment) => {
        console.log('DELETE', {comment});
    }

    console.log({activeComment, currentUser, comments, activeCommentId});
    return(
        <div className="my-3">
            <h6>{translate("Comments")}:</h6>
            <ul>
                {comments.map(c => {
                    // console.log({c});
                    return(
                        <li key={c.id}>
                            <BlackWhite onClick={() => setActiveCommentId(activeCommentId === c.id ? 0 : c.id)}>
                                <b>
                                    {translate(`posted by`)}
                                    {` ${c.nickname}`}
                                    , {getDateObject(c.createdAt).dateTime}</b><br/>
                                {c.comment}
                            </BlackWhite>
                            {mayDelete && activeCommentId === c.id && 
                                <p><button className="btn btn-outline-danger mt-3" onClick={() => handleDelete(c)}>
                                    {translate("Delete Comment")}
                                </button></p>}
                        </li>
                    )
                })}
            </ul>
        </div>
    );
};
export default ChatCommentList;