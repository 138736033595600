import styled from 'styled-components';

export const SmallButton = styled.button`
    height:40px;
    color:black;
    background-color:rgba(255,255,255,.4);
    padding:5px
    border: 1px solid black;
    border-radius:5px;
    :hover{
        background-color:black;
        color:white
    }
`

export const LargeButton = styled.button`
    width:100%;
    height:40px;
    color:black;
    background-color:rgba(255,255,255,.4);
    padding:5px
    border: 1px solid black;
    border-radius:5px;
    :hover{
        background-color:black;
        color:white
    }
`

export const BlackWhite = styled.span`
    color:black;
    background-color: transparent;
    padding:5px;
    :hover{
        background-color:black;
        color:white
    }
`
export const EeeLink = styled.span`
    color:#111111;
    background-color: transparent;
    padding:5px;
    :hover{
        background-color:#111111;
        color:#eeeeee
    }
`
export const ScoreResults = styled.div`
    border:"1px solid grey";
    border-radius:"5px";
    background-color: rgba(255,255,255,.5);
    padding:"50px";
`