
class Groups{
    constructor(groups, cruise, user){
        // console.log({groups, cruise, user});
        this.groups = groups;
        this.cruise = cruise;
        this.user = user;
        this.userGroups = [];
        this.cruiseGroups = [];
        this.availableUserGroups = [];
        this.availableCruiseGroups = [];
        this.init();
        this.countInvited = this.updateCountInvited();
    };
    init(){
        if(!this.groups || !this.groups[0])return;
        if(this.user && this.user.username){
            this.userGroups = this.getUserGroups();
            this.availableUserGroups = this.getAvailableUserGroups();
        };
        if(this.cruise && this.cruise.id){
            this.cruiseGroups = this.getCruiseGroups();
            if(this.user && this.user.username){
                this.availableCruiseGroups = this.getAvailableCruiseGroups();
            }else{
                this.availableCruiseGroups = this.groups.filter(g => g.cruiseId === this.cruise.id
                    && g.type.includes("public"));
            
            };
        };
    }
    getUserGroups(){
        return this.groups && this.groups.filter(g => g.members && g.members.some(m => m.username === this.user.username));
        
    };
    getCruiseGroups(){
        return this.groups && this.groups.filter(g => {
            return (
                (
                    (g.level === "cruise" && g.cruiseId === this.cruise.id)
                || (g.level === "vessel" && g.vesselId === this.cruise.vesselId)
                || (g.level === "company" && g.companyHandle === this.cruise.companyHandle)
                )
                && g.members && g.members.some(m => m.username === (this.user && this.user.username)))
        }
            )
    };
    updateCountInvited(){
        const invitedGroups = this.groups?.filter(g => g.invited === true) || [];
        return invitedGroups.length;

    }
                     
        
    
    getAvailableUserGroups(){
       return this.groups.filter(g => g.cruiseId === 0 && g.type.includes("public")
                && g.members.every(m => m.username !== this.user.username));
    };
    getAvailableCruiseGroups(){
       return (this.groups.filter(g => {
        return (
            (
                (g.level === "cruise" && g.cruiseId === this.cruise.id)
                || (g.level === "vessel" && g.vesselId === this.cruise.vesselId)
                || (g.level === "company" && g.companyHandle === this.cruise.companyHandle)
            )
            && g.type.includes("public")
            && g.members.every(m => m.username !== this.user.username)
        )}
       ))};
    static getMember(groups, groupId, username){
        if (!groupId || !username) return null;
        const group = groups.find(gs => gs.id === groupId);
        const member = (group && group.members.find(m => m.username === username)) || [];
        // console.log({member});
        return member;
    };
};

    

export default Groups;