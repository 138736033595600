import React from 'react';
import NewDestinationInUseListItem from './NewDestinationInUseListItem';
import { translate } from '../../Language/Translate';
import { simpleRequest } from '../../../newForms2/simpleRequest';

const NewDestinationInUseList = ({newDestinations, setNewDestinations}) => {
    // console.log({newDestinations, setNewDestinations});
const updateGeolocations = async() => {
    const res = await simpleRequest({url: "destinations/geolocations", method:"patch"});
    // console.log({res});
}    

    return(
        <div>
            <h5>New Destinations in Use</h5>
            <button className="btn btn-outline-dark" onClick={updateGeolocations}>{translate("Update Geolocations")}</button>
            {newDestinations && newDestinations.map(d => <NewDestinationInUseListItem setNewDestinations = {setNewDestinations} newDestinations = {newDestinations} destination={d} key={d.id}/>)}
        </div>
    );
};
export default NewDestinationInUseList;