import React, { useState } from "react";
import {translate} from '../Language/Translate';
import { BlackWhite } from "../styledComponents/colors";
import MedicalInstituteEdit from "../../newForms2/destinations/MedicalInstituteEdit";
import Symbols from "../Symbols";
import MedicalCommentItem from "./MedicalCommentItem";
import MedicalCommentNew from "../../newForms2/destinations/MedicalCommentNew";
import { useSelector } from "react-redux";

const MedicalInstituteListItem = ({item}) => {
    // console.log({item});
    const [showDetails, setShowDetails] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    // const [showComments, setShowComments] = useState(false);
    const [showCommentNewForm, setShowCommentNewForm] = useState(false);
    const [copied, setCopied] = useState('none');
    const copyArr = ["name", "website", "departments", "address", "phone", "email", "geolocation"];
    const comments = item.comments?.sort((a,b) => a.createdAt < b.createdAt ? 1 : -1) || [];
    const currentUser = useSelector(st => st.currentUser);
    const isDeveloperOrAdmin = ["developer", "admin"].includes(currentUser?.userStatus);
    const isAdmin = currentUser?.userStatus && currentUser.userStatus.toLowerCase().includes("admin");
    const mayEditAndSeeComments = isAdmin || isDeveloperOrAdmin;
    const mayEditInstitute = isDeveloperOrAdmin || currentUser?.username === item.createdBy;
    
    const address = () => {
        return Object.entries(item).filter(kv => (copyArr.includes(kv[0]) && kv[1]))
            .map(kv => `${kv[0]}: ${kv[1]}`)
            .join('\n').replaceAll('#', " - ");
    };
    const copyGeolocation = async() => {
        navigator.clipboard.writeText(item.geolocation)
            .then(() => console.log("Copied to Clipboard", item.geolocation))
            .catch(err => console.log(err));
        setCopied('geolocation');
        setTimeout(() => setCopied("none"), 2000);
    };
    const copyAddress = async() => {
        const adr = address();
        navigator.clipboard.writeText(adr)
            .then(() => console.log('Copied to Clipboard', adr))
            .catch(err => console.log(err));
        setCopied('address');
        setTimeout(() => setCopied("none"), 2000);
    }
    const hideDetails = () => {
        // setShowComments(false);
        setShowEditForm(false);
        setShowDetails(false);
        setShowCommentNewForm(false);
    }
    const ranking = (comments && comments.length > 0 && (comments.reduce((accum, c) => accum += c.points, 0) 
                        / comments.length).toPrecision(3)) || 0;
    // console.log({ranking});
    
    const Details = () => {
        return (
            <>
             
            {item.website && <p><a href={`/${item.website}`}  target="_blank" rel="noopener noreferrer" >
                <BlackWhite>{translate("Website") + ": " + item.website}</BlackWhite></a></p>}
            {item.address && <p>{translate("Address") + ": " + item.address}</p>}
            {item.phone && <p>{translate("Phone") + ": " + item.phone}</p>}
            {item.email && <p>{translate("Email") + ": " + item.email}</p>}
            {item.description && <p>{translate("Description") + ": " + item.description}</p>}
            {item.geolocation && <p onClick={copyGeolocation}>{translate("Geolocation") + ": " 
                + item.geolocation} <Symbols typeArray={["Copy"]}/>
                {copied === "geolocation" && <span className="ml-1">{translate("Copied")}!</span>}
                </p>}

            </>
        )
    }

    const CommentButtonsArea = () => {
        return (
            <>
                   {showDetails && <button className="btn btn-outline-dark mx-1" onClick={() => setShowCommentNewForm(!showCommentNewForm)}>
                        {translate("Add Comment")}</button>}
                    {showCommentNewForm && <MedicalCommentNew medicalInstituteId={item.id}/>}
                    {showDetails && comments?.map(c => <MedicalCommentItem key={c.id} comment={c}/>)}
                    {showEditForm && <MedicalInstituteEdit item={item}/>}
            </>
        )
    }

    return(
            <div className = "mt-1 p-2 text-center" 
                style = {{border:'1px solid black', borderRadius:"5px", backgroundColor:"rgba(255,255,255,.6)"}}>
                    <h5 onClick={copyAddress}><BlackWhite>{item.name}</BlackWhite> 
                        <Symbols typeArray={["Copy"]}/>
                        {copied === "address" && <small className="mx-1">{translate("Copied")}!</small>}
                        </h5>
                        {mayEditAndSeeComments && (ranking ? <p>{`${ranking} ${translate("of 5 points")}, ${item.comments.length} ${translate("comments")}`}</p> 
                            : <p>{translate('No Comments available')}</p>)}
                        
                    <p>{item.departments?.replaceAll('#', ' - ')}</p>
                    {showDetails && <Details/>}
                    <button className="btn btn-outline-dark mx-1" onClick={() => showDetails ? hideDetails() : setShowDetails(true)}>
                        {showDetails ? translate("Show less"): translate("Show more")}
                    </button>                    
                    {mayEditInstitute && showDetails && 
                        <button className = "btn btn-outline-dark mx-1 " onClick={() => setShowEditForm(!showEditForm)}>
                            {showEditForm ? translate("Close Formular") : translate("Edit")}
                        </button>}
                    {mayEditAndSeeComments && <CommentButtonsArea/>}
            </div>
    );
};
export default MedicalInstituteListItem;