import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import {translate} from "./Language/Translate";
import {Image } from 'react-bootstrap';
import UploadAreaSingle from "../newForms2/upload/UploadAreaSingle";
import {updateCurrentUserOnAPI} from '../actions/currentUser';
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router-dom";


const UserAreaCardProfile = () =>{
    // console.log({props})
    const [uploadArea, setUploadArea] = useState(null);
    const [deleteImageConfirmation, setDeleteImageConfirmation] = useState(false);
    const [uploadData, setUploadData] = useState(false);
    const currentUser=useSelector(st => st.currentUser)
    const dispatch = useDispatch();
    const history = useHistory();
    // const frontendURL = process.env.NODE_ENV === "production" ? "https://cruisefriends.net" : "http://127.0.0.1:3000";
    // console.log({frontendURL}, process.env)

    useEffect(() => {
        const updateUrl = async () =>{
            let data = {...uploadData.data};
            // console.log({data}, 'lllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllllll');
            await dispatch(updateCurrentUserOnAPI(currentUser.username, data));
            (() => setUploadData(null))();
            (() => setUploadArea(false))();
        };
        if(uploadData)updateUrl();

    }, [uploadData, currentUser, dispatch, setUploadData])



    const ConfirmationImage = () => {
        return(
            <div >
                <h4>{translate("Are yo sure?")}</h4>
                
                <div className="row">
                    <div className="col-6 p-1">
                        <button className= "btn btn-outline-danger col-12" onClick={handleRemoveProfilePhoto}>{translate("Delete Photo")}</button>
                    </div>
                    <div className="col-6 p-1">
                        <button className= "btn btn-outline-secondary col-12" onClick={() => setDeleteImageConfirmation(false)}>{translate("Cancel")}</button>
                    </div>
                </div>
            </div>
        );
    };



    // console.log('inside UserAreaCardProfile', {props}, props.props)
    const profile = currentUser;
    // console.log({profile});

    const handleDelete = () =>{
        // console.log('DELETE');
        history.push('/deleteform');
    };
    const handleAddChangeProfilePhoto = async() => {
        // console.log('inside handleAddChangeProfilePhoto');
        if(deleteImageConfirmation)(()=>setDeleteImageConfirmation(false))();
        (() => setUploadArea(!uploadArea))();
    };
    const handleRemoveProfilePhoto = async() => {
        // console.log('inside handleRemoveProfilePhoto');
        if(uploadArea)(() => setUploadArea(false))();
        (() => setDeleteImageConfirmation(!deleteImageConfirmation))();
    };
    const developer = currentUser.userStatus === "developer";
    // console.log({developer, currentUser})
    const displayed = ['username', 'nickname', 'firstName', 'lastName', 'email', 'userStatus', 'language']
    const replacements = {
        username: translate("Username"),
        nickname: translate("Displayed Name"),
        firstName: translate("First Name"),
        lastName: translate("Last Name"),
        email: translate("Email"),
        userStatus: translate("Userstatus"),
        language: translate("Language")
    }
     
    return(
            <div style={{backgroundColor:"rgba(255,255,255,.5)"}} className="p-3">
                {uploadArea && <UploadAreaSingle title="photoUrl" setUploadArea={setUploadArea} methods={["file", "url", "link"]} 
                            uploadData={uploadData} setUploadData={setUploadData} subFolder={`users/${profile.username}`}/>}
                

                {(!uploadArea && !deleteImageConfirmation) && 
                <>                                
                
                {(developer && uploadData) && <p>New Photo URL: {uploadData.data.photoUrl}</p>}
                {developer && <p>Recent Photo URL: {currentUser.photoUrl}</p>}
                <table className="table">
                        <tbody>
                        {profile?(displayed.map(val =>(<tr key={val}><td>{replacements[val]}:</td><td>{profile[val]}</td></tr>))) : null}
                    </tbody>
                </table>
                
                <div className="text-center">
                    {(profile.photoUrl) && <Image src={profile.photoUrl} alt={profile.nickname} className="img-fluid img-thumbnail" style={{height:"50vh", width:"auto"}} />}
                    {(profile.photoUrl && !profile.photoConfirmed) && <h6 className='my-3'>{translate("Your photo is being reviewed")}</h6>}
                </div>
                
                

                <div className="row mt-3">
                    
                    {!profile.photoUrl && <div className="p-1 col-md-4 mb-2"><button className = "btn btn-outline-dark col-12" onClick={handleAddChangeProfilePhoto}>{translate("Add Profile Photo")}</button></div>}
                    {profile.photoUrl && <div className="p-1 col-md-4 mb-2"><button className = "btn btn-outline-dark col-12" onClick={handleAddChangeProfilePhoto}>{translate("Change/Delete Profile Photo")}</button></div>}


                    <div className="col-md-4 p-1">
                        <Link to="/users/currentUser/edit" className='btn btn-outline-dark col-12'>{translate('Edit Profile')}</Link>
                    </div>
                    <div className="col-md-4 p-1">
                        {/* <a href={`${frontendURL}?next=deleteForm`} className="btn btn-outline-danger col-12">{translate('Delete Profile')}</a> */}
                        <button className="btn btn-outline-danger col-12" onClick={handleDelete}>{translate('Delete Profile')}</button>
                    </div>
                    
                </div>
                </>
                }
                {deleteImageConfirmation && <ConfirmationImage/>}
            </div>
    )
};

export default UserAreaCardProfile;