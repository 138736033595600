import React, { useEffect, useState } from "react";
import "./CompanyList.css";
import { useSelector, useDispatch } from 'react-redux';
import { fetchCompaniesFromAPI } from '../actions/companies';
import { Link } from 'react-router-dom';
import {ensureAuthorized} from '../helpers/auth'
import CompanyListCard from "./CompanyListCard";
import {translate} from "./Language/Translate"
import Spinner from './Spinner';
import CruiseBackToCruiseButton from "./CruiseBackToCruiseButton";

function CompanyList() {
  const companies = useSelector(st => st.companies);
  const currentUser = useSelector(st => st.currentUser);
  // const currentCruise = useSelector(st => st.currentCruise);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(function() {
      function fetchCompany() {
      dispatch(fetchCompaniesFromAPI());
      setIsLoading(false);
    }
    if (isLoading) {
      fetchCompany();
  }
  }, [dispatch, isLoading]);
  // console.log({companies, currentUser})
  if (isLoading) return <Spinner/>
  // console.log({isLoading})

  const mayAddCompany = currentUser && currentUser.username && ensureAuthorized(currentUser.userStatus,'adCo');
  const companyList = companies.filter(c => c.handle !== "unknown").map(company => <CompanyListCard company={company} key={company.handle}/>);
 
  return (
   <div>
   {mayAddCompany && <Link to="/companies/new" className="btn btn-outline-dark my-4">{translate("Add Company")}</Link>}
    <CruiseBackToCruiseButton/>
   <h4>{translate('Companies')} ({companyList.length}):</h4>
   {/* <h4>Dietmar</h4> */}
    <div className="row row-eq-height" >
        {companyList}
    </div>
  </div>
  );
};

export default CompanyList;