import React, {useState, useEffect} from "react";
import {useSelector,useDispatch} from 'react-redux';
import {translate} from './Language/Translate'
import CruiseAreaCardDestinationWholeDiary from "./CruiseAreaCardDestinationWholeDiary";
import {sortArrayByObjectField} from '../helpers/sort';
import { fetchDestinationsFromAPI } from "../actions/destinations";
import CruiseAreaCardDestinationsAcc from "./CruiseAreaCardDestinationsAcc";
import Spinner from "./Spinner";

const CruiseAreaCardDestinations2 = () => {
    const [wholeDiary, setWholeDiary] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const currentCruise = useSelector(st => st.currentCruise);
    const destinations = useSelector(st => st.destinations);
    
    useEffect(() => {
        const fetch = () => dispatch(fetchDestinationsFromAPI());
        if(destinations.length === 0)fetch();
        (() => setIsLoading(false))();
      },[isLoading, dispatch, destinations]);
  
    if(isLoading)return <Spinner />
    
    const daySet = new Set()
    currentCruise.days && currentCruise.days.forEach(d => daySet.add(d.date));
    const dates = sortArrayByObjectField(Array.from(daySet).map(date => {
        return {date:date, actions: currentCruise.days.filter(cd => cd.date === date), key:date}}), 'date'); 
    const diaryExists = currentCruise.diaries && currentCruise.diaries.length > 0;
    
    return (
        <div style={{backgroundColor:"rgba(255,255,255,0.6)"}} className="p-1">
            <h4 className="mt-3">{translate("Cruise Diary")} </h4>
            {diaryExists && <button className="btn btn-outline-dark m-1" onClick={() => setWholeDiary(!wholeDiary)}>
                       {wholeDiary? translate("Close Diary") : translate("Show whole cruise diary")}</button>}
            {wholeDiary && <CruiseAreaCardDestinationWholeDiary setWholeDiary={setWholeDiary}/>}
            <CruiseAreaCardDestinationsAcc dates={dates}/>
        </div>
    )
};

export default CruiseAreaCardDestinations2;