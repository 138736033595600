import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import Spinner from './Spinner';
import { useSelector } from 'react-redux';
import MapMain from './Leaflet/MapMain';

const CruiseAreaCardMap = () => {
    const currentCruise = useSelector(st => st.currentCruise);
    const days = currentCruise.days;
    const daysInMap = days?.filter(d => {
        // console.log(d.geolocation, !!d.geolocation);
        return !!d.geolocation
    }) || [];

    const daysNotInMap = days?.filter(d => !d.geolocation && d.destinationId > 1) || [];
    // console.log({daysInMap, daysNotInMap});
    // console.log(daysInMap.map(d => {return{geo:d.geolocation, de:d.deDE}}));

    if(!(MapContainer && TileLayer && Marker && Popup))return <Spinner/>

    return(<MapMain daysInMap = {daysInMap} daysNotInMap={daysNotInMap} />)
};
export default CruiseAreaCardMap;