export const getObjWithLatLongCenterZoom = (arr) => {
    const newArr = arr.map(d => {
        d.geolocation = d.geolocation || d.albumGeolocation || d.destinationGeolocation;
        if(!d.geolocation){
            return({...d, lat:0, long:0});
        }else{
            const [la, lo] = d.geolocation.split(',');
            const lat = parseFloat(la);
            const long = parseFloat(lo);

            return({...d, lat, long});
        };
    });
            
    const south = newArr.reduce((acc, val) => acc < val.lat ? acc : val.lat, 90);
    const north = newArr.reduce((acc, val) => acc > val.lat ? acc : val.lat, -90);
    const west = newArr.reduce((acc, val) => acc < val.long ? acc : val.long, 180);
    const east = newArr.reduce((acc, val) => acc > val.long ? acc : val.long, -180);
    const passedDateChange = newArr.some(d => d.long > 90) && newArr.some(d => d.long < -90);
    
    const centerLat = (south + north) / 2;
    const firstCenterLong = (east + west) / 2;
    let centerLong = firstCenterLong;
    
    if(passedDateChange){
        centerLong = firstCenterLong < 0 ? firstCenterLong + 180 : firstCenterLong - 180
        if(centerLong < 0){
            newArr.forEach(d => {if(d.long > 0)d.long = d.long - 360});
        }else{
            newArr.forEach(d => {if(d.long < 0) d.long = d.long + 360});
        };
    };
    const diffEastWest = Math.abs(east - west);
    const diffNorthSouth = Math.abs(centerLat - north);
    let maxTemp = (diffEastWest > diffNorthSouth ? diffEastWest : diffNorthSouth);

    const newDiffEastWest = Math.abs(east - west);
    if(passedDateChange)maxTemp = (newDiffEastWest > diffNorthSouth ? newDiffEastWest : diffNorthSouth);

    let zoomTemp;
    if(maxTemp > 80)zoomTemp = 3;
    if(maxTemp < 80)zoomTemp = 4;
    if(maxTemp < 40)zoomTemp = 5;
    if(maxTemp < 20)zoomTemp = 6;
    if(maxTemp < 10)zoomTemp = 7;
    if(maxTemp < 6)zoomTemp = 8;
    if(maxTemp < 3)zoomTemp = 9;
    if(maxTemp <= 1)zoomTemp = 10;

    return {zoom:zoomTemp, newArr, arr, center:[centerLat, centerLong]}
};




export const getLocationObj = (observations, language) => {
    const _getGeolocationString = (lat, lang) => `${lat}, ${lang}`;
    const _getHeadline = (obj) => {
        const {albumDE, albumEN, destinationDE, destinationEN, lat, long} = obj;
        const album = language === "deDE" ? albumDE : albumEN;
        const destination = language === "deDE" ? destinationDE : destinationEN;
        const _title1 = `${album ? album : destination ? destination : ''}`;
        const latLong = `${lat.toFixed(3)}, ${long.toFixed(3)}`
        return _title1.length ? `${_title1} (${latLong})`: `Location: ${latLong}`;
    };
    const newObj1 = getObjWithLatLongCenterZoom(observations);
    const _geolocationArray = Array.from(new Set(newObj1.newArr.map(no => _getGeolocationString(no.lat, no.long)))).sort();
    
    const obj = _geolocationArray.map(geolocation => {
        const entries = newObj1.newArr.filter(entry => entry.geolocation === geolocation);
        const animals = Array.from(new Set(entries.map(entry => `${entry.deDE} / ${entry.enUK}`))).sort();
        const headline = entries.reduce((accum, entry) => {
            const hl = _getHeadline(entry);
            return hl.length > accum.length ? hl : accum
        }, '');

        return {
            geolocation,
            entries,
            animals,
            headline
        }
    })
    return obj;
};