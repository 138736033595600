import Form from "react-bootstrap/Form";
import { translate } from "../../components/Language/Translate";

const GroupMemberNewItem = ({other, addArray, setAddArray}) => {

    const value = addArray.map(nm => nm.username).includes(other.username)

    const handleCheck = e => { 
        // console.log(e.target.checked, e.target.name);
        if(e.target.checked){
            (() => setAddArray([...addArray, other]))();
        } else {
            (() => setAddArray([...addArray.filter(nm => nm.username !== other.username)]))();
        }
    };

    return (

        <Form.Group size="lg" controlId={other.username}>
        <Form.Check type="checkbox" 
                    label={`${other.nickname} (${translate("username")}: ${other.username})`}
                    key={other.username}
                    checked={value}
                    name={other.username}
                    onChange={(e) => handleCheck(e)}
        />
        </Form.Group>
    );
};

export default GroupMemberNewItem;

