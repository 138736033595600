import React from "react";
import {useSelector} from "react-redux";
import MedicalInstituteListItem from "./MedicalInstitutListItem";

const MedicalInstituteList = () => {
    const currentDestination = useSelector(st => st.currentDestination);
    const allItems = currentDestination.medicalInstitutes?.sort((a,b) => a.name > b.name ? 1 : -1) || [];
    
    return(
        <div>
          {allItems.map(mi => <MedicalInstituteListItem item={mi} key={mi.id}/>)}
        </div>
    );
};
export default MedicalInstituteList;