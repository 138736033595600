import {monthsDE} from '../helpers/Date';
// import {getYearsFromCruises, getMonthsFromCruises} from './CruiseListFunctions';
import {sortArrayByObjectField} from '../helpers/sort';

const getYearsFromCruises = (cruises) => {
    // console.log('getYearsAndMonthsFromCruises', cruises)
    const years = new Set();
    cruises.forEach(c => {
        years.add(c.startAt.slice(0, 4))
        years.add(c.endAt.slice(0, 4))
    });
    // console.log({years})
    return Array.from(years).sort();
}
const getMonthsFromCruises = (cruises) =>{
    const longMonths = new Set();
    cruises.forEach(c => longMonths.add(c.startAt.slice(0,7)))
    cruises.forEach(c => longMonths.add(c.endAt.slice(0,7)))
    const months = Array.from(longMonths).map(l => {
        return {longMonth:l, monthString:`${monthsDE[parseInt(l.slice(5,7))-1]} ${l.slice(0,4)}` }
    });
    // console.log({months})
    return sortArrayByObjectField(months, 'longMonth');
}
export {getYearsFromCruises, getMonthsFromCruises};