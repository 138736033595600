
import {
    FETCH_AUTHOR,
    UPDATE_AUTHOR
} from "../actions/types";

export default function rootReducer(state = {}, action) {
    switch (action.type) {

    case FETCH_AUTHOR:
      if(!action.author)return state;
      return ({...action.author});
    case UPDATE_AUTHOR:
      // console.log(action);
      if(!action.author)return state;
      
      return ({...state, ...action.author});
    default:
      return state;
  };
};