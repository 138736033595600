import React from "react";
import {Form} from "react-bootstrap";
import {translate} from '../../components/Language/Translate';


const RadioField = ({name, props}) => {

  const {fieldSettings, variables, fieldErrors, setVariables} = props;
  const field = fieldSettings[name];
  const arr = field && field.options;
  const value = variables[name];
  // console.log({field, arr, value, variables})
  if(!arr)return null;
  
  const handleChangeLocal = (e) => {
    (() => setVariables({...variables, [name]:e.target.dataset.value}))();

  }

    return(
      <div className = "row">
        <div className = "col-md-4 col-sm-2" style={{height:"5px"}}></div>
        <div className = "col-md-6">
          <div style ={{maxWidth:"200px"}} className="text-left">
          <h6>{translate("Please select")}</h6><br/>
          <Form.Group size="lg" controlId={name}>
          <Form.Label>{field.label}{field.required? ' * ':' '}</Form.Label>
        { fieldErrors[name]?<small className="text-danger" > - {fieldErrors[name]}</small>:null}
          {arr.map(type => (
              <Form.Check
                key={`inline-radio-${type.key}`}
                checked = {type.key === value}
                onChange = {e => handleChangeLocal(e)}
                label={type.value}
                name={name}
                type="radio"
                id={type.value}
                data-value = {type.key}
              />
          ))}
          </Form.Group>
          </div>
        </div>
        <div className = "col-md-2" style={{height:"5px"}}></div>
      </div>

        
    )
}
export default RadioField;