import React, { useState, useEffect } from "react";
import { Translate, translate } from "../Language/Translate";
import {simpleRequest} from '../../newForms2/simpleRequest';
import { getDateObject } from "../../helpers/Date";
import { Col, Image } from "react-bootstrap";
import PhotoLikeButton from "../PhotoLikes/PhotoLikeButton";

const PhotoMonthMain = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [photosMonth, setPhotosMonth] = useState([]);
    const [actualPhotoId, setActualPhotoId] = useState(0);
    const [actualPhoto, setActualPhoto] = useState({});
    const [photosMonthIdx, setPhotosMonthIdx] = useState(0);
    const {titleDE, titleEN} = actualPhoto;
    const title = Translate.language === "deDE" ? titleDE : titleEN;

    useEffect(() => {
        const fetch = async() => {
            
            const _photosMonth = (await simpleRequest({url:'photosmonth'})).data?.photosMonth;
            // console.log({_photosMonth});
            setPhotosMonth(_photosMonth);
            setActualPhotoId(_photosMonth.length ? _photosMonth[0].albumPhotoId : 0);
            setIsLoading(false);
        }
        if(isLoading)fetch()
    });
    useEffect(() => {
        const fetch = async() => {
            if(!actualPhotoId)return;
            // const _actualPhoto = (await simpleRequest({url:`albumphotos/${actualPhotoId}`})).data;
            const _actualPhoto = (await simpleRequest({url:`albumphotos/${actualPhotoId}`})).data?.albumPhoto;
            // console.log({_actualPhoto, actualPhotoId})
            setActualPhoto(_actualPhoto)
        };
        fetch();
    }, [actualPhotoId]);

    const monthYear = getDateObject(photosMonth[photosMonthIdx]?.createdAt, Translate.language).monthYear;

    // console.log({photosMonth, actualPhoto, actualPhotoId, isLoading, title});
    if(!photosMonth.length)return<h5>{translate("No items")}</h5>


    const handleClick = (num) => {
        const newPhotosMonthIdx = photosMonthIdx + num;
        // console.log({num, photosMonthIdx, newPhotosMonthIdx})
        if((newPhotosMonthIdx > -1) && (newPhotosMonthIdx < photosMonth.length)){
            const newPhotoId = photosMonth[newPhotosMonthIdx].albumPhotoId;
            // console.log({num, photosMonth, photosMonthIdx, newPhotosMonthIdx, newPhotoId})
            setPhotosMonthIdx(newPhotosMonthIdx);
            setActualPhotoId(newPhotoId);
        };
    };

    return(
        <div className="mt-3" >
            <h2 className = "py-4">{`${translate("Photo of the Month")}:`} <i>{monthYear}</i></h2>
            
            <div className="row" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Image src={actualPhoto.imageUrl} style={{maxHeight: "80vh", maxWidth: "95vw"}} className = "mx-1"
                    alt={actualPhoto.id} />

            </div>
            <div className="row mt-3" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Col className="text-left">
                    {photosMonthIdx < photosMonth.length -1 
                        && <button block="true" size="lg" className = "btn btn-outline-dark col-1" onClick={() => handleClick(1)}>{`<`}</button>}
                </Col>
                <Col className="text-center">
                
                    <PhotoLikeButton albumPhoto={actualPhoto}/>
                </Col>
                <Col className="text-right">
                    {photosMonthIdx > 0 && <button className = "btn btn-outline-dark col-1" onClick={() => handleClick(-1)}>{`>`}</button>}
                </Col>
                
                
            </div>
            <div className="mt-2 text-center">
                <h5>{title}</h5>
                <p>&copy; {actualPhoto.nickname}</p>
             </div>
        </div>
    )









};
export default PhotoMonthMain;