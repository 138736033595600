import React from "react";
import PreviewItem from "./PreviewItem";


const PreviewArea = ({data}) => {
    const files = data.selectedFiles || [];
    if(files.length < 1)return null;
  
    return(
    <>
        {data.selectedFiles?.map((f, idx) => <PreviewItem file={f} key={idx}/>)}
    </>
    )
};
export default PreviewArea;