import { useEffect, useState } from "react";
import { simpleRequest } from "../../newForms2/simpleRequest";
import ArticleList from "./ArticleList";
import Spinner from '../Spinner';



const ArticleArea = () => {
    const [articles, setArticles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetch = async() => {
            const res = await simpleRequest({url:"articles", method:"get"});
            const artic = res.data && res.data.articles
            if(artic)(() => setArticles(artic))();
            setIsLoading(false);
        }
        if(isLoading)fetch();
    }, [isLoading, articles]);
    // console.log({articles});
    if(isLoading) return <Spinner/>
    return <ArticleList list={articles} placeholder="articles"/>
}
export default ArticleArea;