import React, { useState, useEffect } from "react";
import "./MainBoard.css" 
import { sortArrayByObjectField } from "../../../helpers/sort";
import { readTimeStamp } from '../../../helpers/Date';
import Spinner from '../../Spinner';
import {filterDuration} from './functions';


const CompanyScores = ({duration, companyHandle, allScores}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [scores, setScores] = useState([]);
    useEffect(() => {
        const fetch = async() => {
            const mScores2 = allScores.filter(s => s.companyHandle === companyHandle);
            (() => setScores(s => filterDuration(mScores2, duration)))();
            (() => setIsLoading(false))();
        }
        if(isLoading)fetch()
    });
    if (isLoading)return <Spinner/>
    if (!companyHandle) return null;
    // console.log({scores});
    if (scores.length === 0) return null;
    const best = sortArrayByObjectField(scores, "counter")[0];
    const fastest = sortArrayByObjectField(scores, "seconds", "float")[0];
    
    const cruiseString1 = best && best.name ? `, ${best.prefix} ${best.name}`: "";
    const cruiseString2 = fastest && fastest.name ? `, ${fastest.prefix} ${fastest.name}`: "";

    return(
        <div className="my-3 p-3" style = {{border:"1px solid grey", borderRadius:"5px",backgroundColor: "rgba(255,255,255,.3)"}}>
            {(best || fastest) && <h5> Best Results of {scores[0].companyName} (last {duration} days):</h5>}
            <p>{best && <i>Best: <b>Counter: {best.counter},</b> Seconds: {best.seconds} ({readTimeStamp(best.createdAt)}, {best.nickname}<b>{cruiseString1}</b>)<br/></i>}
            {fastest && <i>Fastest: Counter: {fastest.counter}, <b>Seconds: {fastest.seconds}</b> ({readTimeStamp(fastest.createdAt)}, {fastest.nickname}<b>{cruiseString2}</b>)</i>}</p>
        </div>
    )
};
export default CompanyScores;