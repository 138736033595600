import {translate} from '../../components/Language/Translate'


class AlbumPhotoSchema{

        constructor(){
            this.fields = {
                checkVessel: {
                    label:translate("Vessel photo"),
                    initialValue:false,
                    type:"boolean",
                    fieldType:"checkBox"
                },                
                subTitle: {
                    label:translate("Subtitle"),
                    initialValue:"",
                    validation:{maxLength:60},
                    type:"text",
                    fieldType:"text",
                },  

                description: {
                    label:translate("Description"),
                    initialValue:"",
                    type:"text",
                    fieldType:"textarea"
                },

                

             
        }
    }

};

export default AlbumPhotoSchema;

