import React from "react";
// import AdvertisementLists from "./Advertisements/AdvertisementLists";
// import Variables from './Debug/Variables'



const DebugArea = () =>{
    return(
        <>
            {/* <Variables /> */}
            {/* <AdvertisementLists /> */}
        </>
    )
}
export default DebugArea;