import {ADD_REQUEST, RENEW_REQUESTS, DELETE_REQUEST} from "./types";

export function addOpenRequest(request){
    return function(dispatch){
        return dispatch(addRequest(request));
    };
};
function addRequest(request){
    return{
        type: ADD_REQUEST,
        request
    };
};

export function renewOpenRequests(requests){
    return function(dispatch){
        return dispatch(renewRequests(requests));
    };
};
function renewRequests(requests){
    return{
        type:RENEW_REQUESTS,
        requests
    };
};

export function deleteOpenRequest(request){
    return function(dispatch){
        return dispatch(deleteRequest(request));
    };
};
function deleteRequest(request){
    return{
        type:DELETE_REQUEST,
        request
    };
};