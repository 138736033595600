import React from "react";
import { Image } from "react-bootstrap";

const PhotoList = ({photos, setSelectedId}) => {
    return(
        <div className="mt-3">
        {photos.map(p => <span className="m-1" key={p.id}
                    onClick={()=>setSelectedId(p.id)}>
                        <Image src={p.imageUrl} style={{width: "70px", height:"auto"}} alt="p"/>
                    </span>)}
        </div>
    )
};
export default PhotoList;