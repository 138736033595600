import React, { useState } from 'react';
import DestinationEditByAdmin from '../../../newForms2/dayDestinations/DestinationEditByAdmin';


const NewDestinationListItem = ({day, newDays, setNewDays}) => {
    const [editForm, setEditForm] = useState(false);

    return(
        <div>
            <div key={day.id}  className = "">
                <button className="btn btn-outline-dark col-12 my-1" onClick={() => setEditForm(!editForm)} id={day.id}>
                    {day.destination}, ({day.updatedBy})
                </button>
            </div>
            {editForm && <DestinationEditByAdmin day={day} newDays={newDays} setNewDays={setNewDays} setEditForm={setEditForm}/>}
        </div>
    )
};

export default NewDestinationListItem;