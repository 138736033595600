import {dateTimeStringToDate} from '../../../helpers/Date';
import { sortArrayByObjectField } from "../../../helpers/sort";


const buildMasterArray = (numPictures) => {
    const arr = [];
    for (let i=0; i < numPictures; i++){
        for (let j=0; j<2; j++){
            arr.push({url:`img${i+1}`, random:Math.random()})
        }
    };
    return sortArrayByObjectField(arr, "random").map((v,id) => {
        return {id:id+1, url:v.url, className:"validCard"};
    });
};

const filterDuration = (arr, duration) => {
    if (!duration) return [...arr];
    const result = arr.filter(s => {
        const createdAt = dateTimeStringToDate(s.createdAt).getTime();
        const interval = (duration * 24 * 60 * 60 * 1000);
        const validUntil = createdAt + interval;
        const now = (new Date()).getTime();
        // console.log(duration, new Date(createdAt), new Date(validUntil), new Date(now), validUntil > now, {arr, result});
        return validUntil > now;
    });
    
    // console.log({arr, result});
    return result;
};

const getUserScores = ({username, duration, allScores}) => {
    // console.log({allScores});
    const memoryScores = allScores && allScores.filter(s => s.username === username);
    if (!memoryScores || memoryScores.length === 0) return {best:null, fastest:null};
    const scores = filterDuration(memoryScores, duration);
    const best = sortArrayByObjectField(scores, "counter")[0];
    const fastest = sortArrayByObjectField(scores, "seconds", "float")[0];
    return({best, fastest});
};

export {
    buildMasterArray,
    filterDuration,
    getUserScores
};