import chatCategories from "../../components/TravelChat/chatCategories";
import { translate, Translate } from "../../components/Language/Translate";

class ChatPostSchema{
    constructor(themeId){
        this.fields = {
            title:{
                type:"text",
                fieldType: "text",
                initialValue:"",
                validation: {
                    maxLength: 60,
                    minLength: 3,
                },
                label: translate("Title"),
                required:true,
            },
            details:{
                type:"text",
                fieldType: "textarea",
                initialValue:"",
                label: translate("Details")
            },

        }
    }
};
export default ChatPostSchema;