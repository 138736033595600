import React, {useEffect, useState} from 'react';
import Form from "react-bootstrap/Form";
import {validateField} from '../ReactFormFunctions';
import {useSelector, useDispatch} from 'react-redux';
import { fetchDestinationsFromAPI } from '../../actions/destinations';
// import {Link} from 'react-router-dom'
import Spinner from '../../components/Spinner';

const TextFieldWithValueAreaEvent = ({name, variables, constants}) => {

    //  // console.log('inside textfield, ', {props});
    
    const {errors} = variables;
    const fieldSettings = constants.fields;
    const field = fieldSettings[name]
    const value = variables.fields[name] || "";
    const [destinationList, setDestinationList] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const destinations = useSelector(st => st.destinations);
    const dispatch = useDispatch();

    // console.log('inside TEXTFIELD WITH VALUE AREA EVENT', {name, variables, constants, errors, fieldSettings, field, value, destinationList, destinations, isLoading})
    
    const handleChangeWithList=async(e, constants, variables)=>{
        const {name, value}=e.target;
        // console.log({name, value})
      
        const fieldsNew = {...variables.fields,[name]: value}
        const vars = {...variables,fields: fieldsNew}
        // console.log('inside handleChangeWithList', {fieldsNew, vars})
        constants.events.setVariables(vars)
        // console.log({variables})
        if(value.length < 3)return;
        // console.log("VOR FILTER", {fieldsNew, variables, fieldSettings, name, value})      
        const destList = destinations.filter(d=>{
           return d.enUK.toLowerCase().includes(value.toLowerCase());
        });
        // console.log('NACH FILTER')
        // console.log(destList && destList.length)
        destList && destList.length > 0 && setDestinationList(destList);
        

    }
    useEffect(function() {
        async function fetchDestinations() {
          // console.log('inside useEffect, fetchDestinations', {isLoading})
          await dispatch(fetchDestinationsFromAPI());
          // console.log('nach dispatch+++++.', destinations)
          setIsLoading(false);
        }
          if (isLoading) {
          fetchDestinations();
        }
    }, [dispatch, isLoading]);


    
    
        // const handleClick = useCallback(async e => {
        const handleClick = async e => {
          // console.log('Click', e.target, e.target.id, e.target.innerText, e.target.classList);
          if(!e.target.classList.contains('destination'))return;
          constants.fields.destinationId.value=+e.target.id;
          const id = parseInt(e.target.id) || "0";
          constants.fields.destinationId.value = id
          const newFields = {...variables.fields}
          newFields.destinationId=e.target.id;
          newFields.destination=e.target.innerText
          const vars = {...variables, fields:newFields}
          // console.log('inside handleClick before setVariables', {newFields, vars})
          constants.events.setVariables(vars);
        };
        // );
      
        useEffect(() => {
          window.addEventListener('click', handleClick);
          // console.log("Installing the EVENTLISTENER")
      
          // cleanup this component
          return () => {
            // console.log('REMOVING Eventlistener')
            window.removeEventListener('click', handleClick);
        };
        });
      





    // console.log({destinations, isLoading})
    if (!destinations) return <Spinner/>
    // console.log({destinations})

  

    const ValueArea = (props) => {
        // console.log("VOR RENDER destinationList")
        const destinationOutput = destinationList && destinationList.map(function(d){
            return(
                <li key={d.id} id={d.id} className='destination' >
                    {d.enUK}
                    {/* <Link to={`/destination/edit/${d.id}`} className="ml-3">Edit</Link> */}
                </li>)
        });
        // console.log("NACH RENDER destinationList")
        // console.log(destinationList && destinationList.length)
        const ul=(<ul id="destinationList">{destinationOutput}</ul>)
        return ul;
    }
    
    return (
    <>
         <Form.Group size="lg" controlId={name}>
           <Form.Label>{field.label}{field.required? ' * ':' '}</Form.Label>
              { errors[name]?<small className="text-danger" > - {errors[name]}</small>:null}
              <Form.Control 
                 type={field.type || "text"}
                 key={name}
                 required={field.required}
                 autoFocus={field.autofocus}
                 disabled={field.disabled}
                 value={value || ""}
                 placeholder={field.placeholder?`${field.placeholder}`:null}
                 name={name}
                 onChange={(e) => handleChangeWithList(e, constants, variables)}
                 onBlur={(e) => validateField(e, constants, variables)}
                    //              onTouchEnd={(e) => // console.log('onTouchEnd')}
           />
           
           {field.toolTip?<p><small className="text-black" >{field.toolTip}</small></p>:null}
        </Form.Group>
        <ValueArea/>
    </>
    )
}

export default TextFieldWithValueAreaEvent;