import React, { useState, useEffect } from "react";
import {Form} from 'react-bootstrap';
import { actualLocation } from "../../helpers/location";
import ObservationSchema from "./ObservationSchema";
import DateTime from "../Fields/DateTime";
import Spinner from "../../components/Spinner";
import { translate } from "../../components/Language/Translate";
import { simpleRequest } from "../simpleRequest";
import TextFieldWithValues from "./TextFieldWithValues";
import Geolocations from './geolocations/Geolocations';
import TextArea from '../Fields/TextArea';
import { useSelector } from "react-redux";

const ObservationNew = ({mainProps, initialValues}) =>{
    // console.log({mainProps, initialValues})
    const {setMainLoading, setOpenForm} = mainProps;
    const [isLoading, setIsLoading] = useState(true);
    const [geolocation, setGeolocation] = useState(null);
    const [fieldSettings, setFieldSettings] = useState({});
    const [variables, setVariables] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    const [serverError, setServerError] = useState('');
    const [localObservations, setLocalObservations] = useState([]);
    const cruiseId = useSelector(st => st.currentCruise).id;
    const {species, speciesObservations, setSpeciesObservations} = mainProps;
    
    const validateField = () =>  console.log("MISSING");

    useEffect(() => {
        const fetch = async () => {
            actualLocation(setGeolocation);
            const fields = (new ObservationSchema()).fields;
            setFieldSettings({...fields});
            setVariables({...initialValues});
            setIsLoading(false);
        }
        if(isLoading)fetch()
    }, [isLoading, setIsLoading, setGeolocation, initialValues]);

    if(isLoading)return <Spinner/>
    const actualSpecies = (species && species.find(s => s.id === variables.speciesId)) || null;
    const fullname = (actualSpecies && `${actualSpecies.deDE} / ${actualSpecies.enUK}`) || "" ;
 
    const handleChange = e => setVariables({...variables, [e.target.name]:e.target.value});
    
    const valid = () => {
        const errors = {};
        const {albumId, geolocation, destinationId, speciesId, dateTime} = variables;
        if(!(albumId || destinationId || geolocation))errors.geolocation = translate("Required");
        if(!speciesId)errors.speciesId = translate("Required");
        if(!dateTime)errors.dateTime = translate("Required");
        setFieldErrors({...errors});
        return Object.values(errors).length === 0;
    }
    
    const handleSubmit = async(e) => {
        e.preventDefault();
        // console.log(variables, fieldErrors, valid());
        if(!valid()){
            // console.log("NICHT MÖGLICH")
            return
        };

        const request = {url:'speciesobservations', method:"post", data:{...variables, cruiseId}};
        // console.log("ES GEHT WEITER", {request});
        const speciesObservation = (await simpleRequest(request)).data?.speciesObservation;
        // console.log({speciesObservation});
        setSpeciesObservations([...speciesObservations, speciesObservation]);   
        setLocalObservations([...localObservations, speciesObservation]);
        setVariables({...variables, speciesId:0 });
    };
    const handleCancel = () => {
        setOpenForm('list');
        setMainLoading(true);
    }
    
    
    const props = {
        fieldSettings, 
        variables, 
        setVariables,
        fieldErrors, 
        setFieldErrors, 
        serverError,
        setServerError, 
        handleChange,
        validateField
      };

    // console.log({props, geolocation, fullname, speciesId:variables.speciesId, actualSpecies});
    // console.log({variables, initialValues, localObservations, speciesObservations});

    const FullName = () => {
        // console.log('inside Fullname');
        return(
            <div >
                <button className="btn btn-outline-danger mr-3" onClick={() => setVariables({...variables, speciesId:0})}>X</button>
                <b>{fullname}</b>
            
            </div>
        )
    };

    return(
        <div className="my-3 p-3" style={{border:"1px solid grey", borderRadius:"5px"}}>
            <h5>{translate("New Observation")}</h5>
            <Form>
                <Geolocations props={props}/>
                <DateTime name="dateTime" props={props}/>
                {fullname && <FullName/>}
                {!fullname && <TextFieldWithValues name="speciesInput" props={props} array = {species}/>}
                <TextArea name="description" props={props}></TextArea>
                <button size="lg" className = "btn btn-outline-dark" onClick={handleSubmit}>{translate("Save")}</button>
                <button size="lg" className = "btn btn-outline-secondary ml-2" onClick={handleCancel}>{translate("Close")}</button>
                <ul className = "mt-3">{localObservations.map(o => <li key = {o.id} ><b>{`${o.deDE} / ${o.enUK}`}</b></li>)}</ul>
            </Form>
        </div>
    )
}

export default ObservationNew;