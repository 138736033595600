// import { simpleRequest } from "../simpleRequest";
import {translate} from "../../components/Language/Translate"

import {
    simpleRequest
} from "../simpleRequest";

class AuthorFormData {

    static async authorNew() {
        // console.log('inside AuthorFormData.authorNew')
        const consts = new AuthorFormSchema();
        let fields;
        Object.entries(consts.fields).forEach(e => fields = {
            ...fields,
            [e[0]]: e[1].value
        })
        consts.formType = 'authorNew';
        consts.request = {
            url: `authors/`,
            method: "post"
        };
        consts.nextPage = (`/editorials`);
        consts.sendData = ["username", "firstName", "lastName","suffix", "bio", "subjects"]

        const vars = {
            fields
        };

        return {
            consts,
            vars
        };
    }

    static async authorEdit(data) {

        // console.log('inside CruiseFormData. cruiseEdit', {data})
        const username = data.params.username
        const author = (await AuthorFunctions._getByUsername(username)).author;

        // console.log({author})
        const consts = new AuthorFormSchema()
        consts.fields.username={label:"Username", value:author.username, fieldType:"info"}

        consts.sendData = ["firstName", "lastName","suffix", "photoUrl", "bio", "subjects"]
        let fields;
        consts.sendData.forEach(k => fields = {...fields,k: author[k]})
        consts.formType = 'authorEdit';
        consts.formLabels = {
            title: "Edit Author",
            btnLabel: "Update "
        }
        consts.request = {
            url: `authors/${username}`,
            method: "patch"
        };
        consts.nextPage = (`/editorials`);
        consts.oldValues = author;
        const vars = {fields: author}

        return {consts,vars};
    };

};

class AuthorFormSchema {
    constructor() {
        this.formType = "authorNew";
        this.nextPage = ""
        this.request = {
            url: "authors",
            method: "post",
        };
        this.formLabels = {
            title: translate("Add Author"),
            btnLabel: translate("Add")
        };


        this.fields = {

            username: {
                label: translate("Username"),
                value: "",
                validation: {
                    minLength: 8,
                    maxLength: 40
                },
                fieldType: "text",
                type: "text",
                required: true,
                toolTip: translate("Must be equal to an existing username.")
            },

            firstName: {
                label: translate("First Name"),
                value: "",
                validation: {
                    minLength: 2,
                    maxLength: 40
                },
                fieldType: "text",
                type: "text",
            },
            lastName: {
                label: translate("Last Name"),
                value: "",
                validation: {
                    minLength: 2,
                    maxLength: 40
                },
                fieldType: "text",
                type: "text",
            },
            suffix: {
                label: "Suffix",
                value: "",
                fieldType: "text",
                type: "text",
            },
            bio: {
                label: translate("Biography"),
                value: "",
                validation: {},
                fieldType: 'textarea',
                type: "text"
            },
            subjects: {
                label: translate("Subjects"),
                value: "",
                validation: {},
                fieldType: 'textarea',
                type: "text",
                placeholder: `${translate("e.g.")} Greece, MS Albatros`,
                toolTip: translate('e.g. "Greece, MS Albatros", divided by comma. This list is only an information for the visitors.')
            },
        }
    }
};

class AuthorFunctions {
    static async _getByUsername(username) {
        const response = await simpleRequest({
            url: `authors/${username}`,
            method: "get"
        });
        // console.log({response})
        const cc = response && response.data 
        return cc;
    }
}


export default AuthorFormData;