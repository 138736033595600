import React, {useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {translate, Translate} from '../Language/Translate'
import GroupMembersNew from '../../newForms2/groups/GroupMembersNew';
import GroupChat from "./GroupChat";
import NewsBadge from "../NewsBadge";
import { getDateObject } from "../../helpers/Date";
import { addDeleteMultipleGroupMembersOnApi, updateGroupMemberOnApi } from "../../actions/groups";
import Group from "../../helpers/group";
import { setGlobalVarToState } from "../../actions/globals";
import Groups from "../../helpers/groups";
import GroupsEditForm from "../../newForms2/groups/GroupsEditForm";

const GroupListItem = ({group, groups}) => {
    const dispatch = useDispatch();
    const [showForm, setShowForm] = useState('none');
    // const [showFormEdit, setShowFormEdit] = useState(false);
    const currentUser = useSelector(st => st.currentUser);
    const language = useSelector(st => st.currentUser)?.language || Translate.language;
    const currentCruise = useSelector(st => st.currentCruise);
    const globals = useSelector(st => st.globals);
    // const {groupLocation} = globals;
    const {endAt, endPort, startAt, startPort, 
        prefix, vesselName, unread, type, groupMemberId,
       level, companyName} = group;
    const gr = new Group(group, currentCruise, currentUser);
    // console.log({gr});
    const {adminNickname, isAdmin, lastChat, lastLogin, member, assignedToCruise, 
        isLoggedIn, mayAddMembers, joinable, isMember, mustConfirmInvite, mayOpenChat, noAdmin} = gr;
    const backgroundColor = currentCruise && currentCruise.id === group.cruiseId ? "#eddddd" : "#eeeeee";
    const start = getDateObject(startAt,language).short;
    const end = getDateObject(endAt,language).short;

    const handleJoin = () => {
        isLoggedIn && dispatch(addDeleteMultipleGroupMembersOnApi(group, {addMembers:[currentUser.username]}));
    };
    const handleConfirm = () => {
        // console.log('handleConfirm', groupMemberId);
        dispatch(updateGroupMemberOnApi(groupMemberId, {invited:false}));
    };
   
    const {groupOpenGroup} = globals;

    const handleReject = () => {
        const groupMemberId = gr.member.id;
        const username = currentUser.username;
        const deleteObj = {id: groupMemberId, username}
        // console.log({deleteObj});
        dispatch(addDeleteMultipleGroupMembersOnApi(group, {deleteMembers:[deleteObj]}));
    };

    const toggleShowDetails = () => {
         if(!isLoggedIn || !isMember || mustConfirmInvite)return;
         if(groupOpenGroup === 0 || !groupOpenGroup){
            dispatch(setGlobalVarToState({groupOpenGroup:group.id}))
         }else if(groupOpenGroup === group.id){
            updateGroupLogin(member);
            dispatch(setGlobalVarToState({groupOpenGroup:0}))
        }else{
            const gMember = Groups.getMember(groups, groupOpenGroup, currentUser.username);
            updateGroupLogin(gMember);
            dispatch(setGlobalVarToState({groupOpenGroup:group.id}));
        };
    };

    const updateGroupLogin = (grMember) => {
                if(!grMember.id)return
                dispatch(updateGroupMemberOnApi(grMember.id, {lastLogin: new Date()}));
            };

    const GroupLevel = () => {
        // console.log({level});
        return(
            <>
            {level === "cruise" && <p><small>{`${prefix} ${vesselName}, ${start} (${startPort})- ${end} (${endPort})`}</small></p>}
            {level === "vessel" && <p><small>{`${prefix} ${vesselName}`}</small></p>}
            {level === "company" && <p><small>{companyName}</small></p>}
            </>
        )
    }
   
    const CruiseInfo = () => {
        
        if (!assignedToCruise)return null;
        return(
            <p><small>{prefix} {vesselName} {start} ({startPort}) - {end} ({endPort})</small></p>
        );
    };

    const Details = () => {
        return(
            <div>
                {isAdmin && !noAdmin && <p>{translate("You are admin of this group.")}</p>}
                <div>
                    {mayAddMembers && <button className="btn btn-outline-success m-1" 
                        // onClick={() => dispatch(setGlobalVarToState({groupShowFormMemberNew:!groupShowFormMemberNew}))}>
                        onClick={() => setShowForm(showForm === "members" ? "none" : "members")}>
                            {translate("Group Members")}</button>}
                    {isAdmin && 
                                <button className="btn btn-outline-info m-1" 
                                    onClick={() => setShowForm(showForm === "edit" ? "none": "edit")}>
                                    {translate("Edit Group")}
                                </button>}
                </div>
                {showForm === "edit" && <div><GroupsEditForm group={group} setShowForm={setShowForm}/></div>}
                <div>
                    {showForm === "members" && <GroupMembersNew group = {group} setShowForm={setShowForm}/>}
                    {groupOpenGroup === group.id && <GroupChat group={group}/>}
                </div>
            </div>
        );
    };

    return(
        <div  className="my-1 col-md-6" >
            <div className = "p-2" style={{border:"1px solid grey", borderRadius:"5px", backgroundColor:backgroundColor}}>
                <div onClick = {toggleShowDetails}>
                    <h6 key={group.groupMemberId}>{group.name} <small><i>({translate(`${type} group`)}{isLoggedIn && !noAdmin && `, Admin: ${adminNickname}`}{}) </i> <NewsBadge count={unread}/></small></h6>
                    {joinable && <CruiseInfo group = {group}/>}
                    <GroupLevel />
                    {lastChat && <p>
                        <small>
                            {translate("Last entry")}: {lastChat}{", "}
                            {translate("Last login")}: {lastLogin}
                        </small></p>}
                        
                        {mustConfirmInvite && <>
                                <p>{translate("You are invited to this group")}.</p>
                                <button className="btn btn-outline-success mx-1" onClick={handleConfirm}>{translate("Accept Invitation")}</button>
                                <button className="btn btn-outline-danger mx-1" onClick={handleReject}>{translate("Reject Invitation")}</button>
                                </>}
                        {joinable && <button className="btn btn-outline-success mx-1" onClick={handleJoin}>{translate("Join Group")}</button>}
                </div>
            {groupOpenGroup === group.id && mayOpenChat && <Details/>}
            <div>
            </div>
            </div>
        </div>
    )
};
export default GroupListItem;