import React, { useEffect, useState } from "react";
import {useParams, useHistory} from "react-router-dom"
import ConfirmationArea from "../ConfirmationArea";
import { useSelector, useDispatch } from "react-redux";
import { fetchDestinationFromAPI } from "../../actions/currentDestination";
import Spinner from '../Spinner';
import {translate} from '../Language/Translate';
import CruiseBackToCruiseButton from "../CruiseBackToCruiseButton";
import DestinationEdit from "../../newForms2/destinations/DestinationEdit";
import { deleteDestinationFromApi } from "../../actions/destinations";
import DestinationAccordion from "./DestinationAccordion";
import PhotoShowDestination from "../PhotoShows/Destination/PhotoShowDestination";


const DestinationDetails = ({destination_id}) => {
    const [showFormEdit, setShowFormEdit] = useState(false);
    const id = useParams().id || destination_id;
    

    const [isLoading, setIsLoading] = useState(true);
    const [confirmation, setConfirmation] = useState(false)
    
    const history = useHistory();
    const currentUser = useSelector(st => st.currentUser);
    const currentDestination = useSelector(st => st.currentDestination);
    const dispatch = useDispatch();

    // console.log({id, currentDestination});
    useEffect(() => {
        const fetch = async() => {
            dispatch(fetchDestinationFromAPI(id));
            (() => setIsLoading(false))();
        }
        if(isLoading)fetch();
    },[isLoading, id, dispatch])

    if(isLoading || !currentDestination?.id) return <Spinner/>;

    // console.log({currentDestination});

    const userStatus = currentUser?.userStatus?.toLowerCase() || 'guest';
    const admin =  userStatus.includes("admin") || userStatus.includes("developer") || false;
    const correctUser = currentUser?.username === currentDestination.createdBy;
    
    const handleDelete = e => setConfirmation(true);
    const deleteDestination = async() =>{
        dispatch(deleteDestinationFromApi(currentDestination.id));
        history.push('/destinations')
    };

    const MainArea = () => {
        return(
        <>
            <PhotoShowDestination/>
            <h4 className="mt-3">{currentDestination.deDE} / {currentDestination.enUK}</h4>
            {(admin || correctUser) && 
                <button onClick= {() => setShowFormEdit(!showFormEdit)} className="btn btn-outline-dark"> 
                    {showFormEdit ? translate("Close Formular") : translate("Edit Destination")}
                </button>}
            {(admin || correctUser) && <button onClick={e => handleDelete(e)} className="btn btn-outline-danger m-1">{translate("Delete Destination")}</button>}
            <CruiseBackToCruiseButton/>
            {showFormEdit && <DestinationEdit setShowFormEdit={setShowFormEdit} destination={currentDestination}/>}
            
            <DestinationAccordion/>
        </>
    );
    }
    
    return(
        <>
            {!confirmation && <MainArea />}
            {confirmation && <ConfirmationArea question="Are you sure to delete this destination?" 
                        handleDelete={deleteDestination} handleCancel={() => setConfirmation(false)}/>}
        </>
    );
};
export default DestinationDetails;