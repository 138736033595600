import React, { useEffect, useState } from "react";
import { StyledDivWithBorder } from "../styledComponents/divs";
import { translate } from "../Language/Translate";
import TourReportNew from  '../../newForms3/tourReports/TourReportNew'
import { useSelector } from "react-redux";
import { simpleRequest } from "../../newForms2/simpleRequest";
import { sortArrayByObjectField } from "../../helpers/sort";
import TourReportList from "./TourReportList";

const TourReportMain = () => {
    const [view, setView] = useState('main'); //"formNew"
    const [tours, setTours] = useState([]);
    const [tourReports, setTourReports] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const currentCruise = useSelector(st => st.currentCruise);
    const currentUser = useSelector(st => st.currentUser);

    useEffect(() => {
        const fetch = async() => {
            const _tours = (await simpleRequest({url:`tours/cruise/${currentCruise.id}`})).data?.tours || [];
            const _tourReports = (await simpleRequest({url: `tourreports/user/${currentUser.username}`}))
                        .data?.tourReports || [];
            setTours(sortArrayByObjectField(_tours,"date"));
            setTourReports(sortArrayByObjectField(_tourReports, "createdAt").reverse());
            setIsLoading(false);
        };
        if(isLoading)fetch();
    }, [isLoading, currentCruise, currentUser])
    const props = {tours, setTours, tourReports, setTourReports}
    return(
        <StyledDivWithBorder>
            <h4>{translate("Tour Reports")}</h4>
            {view==="main" && 
                <button className = "btn btn-outline-dark" onClick={() => setView("formNew")}>
                    {translate("New Tour Report")}
                </button>}
            {view === "main" && <TourReportList props = {props}/>}
            {view === "formNew" && <TourReportNew props = {{...props, backFunc: () => setView("main")}}/>}
        </StyledDivWithBorder>
    )
};
export default TourReportMain