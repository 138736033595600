import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import UserFormData from './UserFormData';
import CompanyFormData from '../companies/CompanyFormData';
import VesselFormData from '../vessels/VesselFormData';
import CruiseFormData from '../cruises/CruiseFormData';
import UserFormConnectCruiseData from '../userCruises/UserFormConnectCruiseData'
import MessageFormData from '../messages/MessageFormData'
import FriendFormData from '../friends/FriendFormData'
import FormBuilder from '../FormBuilder';
import {useParams, useHistory} from 'react-router-dom';
import CompanyCrewFormData from "../companyCrew/CompanyCrewFormData";
import CruiseCrewFormData from "../cruiseCrew/CruiseCrewFormData";
import DestinationFormData from "../destinations/DestinationFormData";
import DayFormData from '../days/DayFormData'
import AnnouncementFormData from "../announcements/AnnouncementFormData";
import PinboardFormData from "../pinBoard/PinBoardFormData";
import AuthorFormData from "../authors/AuthorFormData";
import ArticleFormData from "../articles/ArticleFormData";
import AdvertisementFormData from "../advertisements/AdvertisementFormData";
import CodeFormData from "../code/CodeFormData";
import Spinner from '../../components/Spinner';
import CruiseNotFoundData from "../firstSteps/CruiseNotFoundData";


const UserForm = ({formType}) =>{
    // console.log('inside USERFORM', formType)
    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState();
    const [constants, setConstants] = useState();

    const history=useHistory();
    const currentUser = useSelector(st => st.currentUser);
    const currentCruise = useSelector(st => st.currentCruise);
    const vessels = useSelector(st => st.vessels)
    const params = useParams();
   

    useEffect(()=>{
        const _getFormData = async() =>{

            // const {formType} = props
            // console.log('inside _getFormData', {formType, currentUser, params})
            if(formType.includes("user")) return await UserFormData[formType]({currentUser, params});
            if(formType.includes("company")) return await CompanyFormData[formType]({currentUser, params});
            if(formType.includes("vessel")) return await VesselFormData[formType]({currentUser, params});
            if(formType.includes("cruise")) return await CruiseFormData[formType]({currentUser, params});
            if(formType.includes("UserCruise")) return await UserFormConnectCruiseData[formType]({currentUser, currentCruise});
            if(formType.includes("message")) return await MessageFormData[formType]({currentUser, params});
            if(formType.includes("friend")) return await FriendFormData[formType]({currentUser, params});
            if(formType.includes("compCrew")) return await CompanyCrewFormData[formType]({currentUser, params});
            if(formType.includes("cruiCrew")) return await CruiseCrewFormData[formType]({currentUser, params});
            if(formType.includes("destination")) return await DestinationFormData[formType]({currentUser, params});
            if(formType.includes("day")) return await DayFormData[formType]({currentUser, params});
            if(formType.includes("announcement")) return await AnnouncementFormData[formType]({currentUser, params});
            if(formType.includes("pinboard")) return await PinboardFormData[formType]({currentUser, params});
            if(formType.includes("author")) return await AuthorFormData[formType]({currentUser, params});
            if(formType.includes("article")) return await ArticleFormData[formType]({currentUser, params});
            if(formType.includes("advertisement")) return await AdvertisementFormData[formType]({currentUser, params});
            if(formType.includes("codeInput")) return await CodeFormData[formType]({currentUser, params});
            if(formType.includes('notFoundNew')) return await CruiseNotFoundData[formType]({vessels});
        }      
    
    
        // console.log('useEffect in UserForm')
        const fetchData = async () => {
            const data = await _getFormData();
            if(!data || !data.consts || !data.vars)return <p>Not possible</p>;
            const {consts, vars} = data;
            if (consts){
                consts.events={setVariables, setConstants}
                consts.history = history;
            }
            if (isLoading){
                setVariables(vars);
                setConstants(consts);
                setIsLoading(false);
            }
        };
        fetchData()
    }, [isLoading, history, currentUser, currentCruise, params, formType, vessels]);
    if (isLoading)return <Spinner/>;
    // console.log({constants, variables})
    // console.log({variables})
    return(
        <FormBuilder constants={constants} variables ={variables}/>
    )
};
export default UserForm;