import { simpleRequest } from "../simpleRequest";
import {translate} from "../../components/Language/Translate"
class AnnouncementFormData {

    static async announcementNew(data) {
        
        // console.log('inside AnnouncementFormData.announcementNew', data, data.params, +data.params.cruise_id)
        const consts = new AnnouncementFormSchema();
        consts.fields.cruiseId.value = +data.params.cruise_id;
        consts.fields.by.value=data.currentUser.nickname;
        let fields;
        Object.entries(consts.fields).forEach(e => fields = {...fields, [e[0]]: e[1].value})
        consts.formType = 'announcementNew';
        consts.request = {
            url:`announcements/`,
            method:"post"
        };
        consts.nextPage = (`/cruises/${data.params.cruise_id}`);
        consts.sendData = ["cruiseId", "by", "subject", "description", "visibleCrew", "visibleStaff", "visibleGuests"]
        const vars={fields};
        // console.log({consts, vars})
        return {consts, vars};
    }
    
    static async announcementEdit(data, table="announcements"){
        
        // console.log('inside AnnouncementFormData. announcementEdit', {data}, table)
        const announcementId=+data.params.id
        // console.log(announcementId)
        const announcement = await AnnouncementFunctions._getById(announcementId, table)

        // console.log({announcement})
        const consts = new AnnouncementFormSchema()
        consts.fields.cruiseId.value=announcement.cruiseId;
        consts.sendData = ["by", "subject", "description", "visibleCrew", "visibleStaff", "visibleGuests"]
        let fields;
        consts.sendData.forEach(k => fields = {...fields, k: announcement[k]})
        consts.formType = 'announcementEdit';
        consts.formLabels = {
            title:"Edit Announcement",
            btnLabel:"Update"
        }
        consts.request = {
            url:`announcements/${announcementId}`,
            method:"patch"
        };        
        consts.nextPage = (`/cruises/${announcement.cruiseId}`);
        consts.oldValues=announcement;
        const vars={fields:announcement}
        
        return {consts, vars};
    };
        
};
       




class AnnouncementFormSchema{
    constructor(){
        this.formType="announcementNew";
        this.nextPage=""
        this.request = {
            url:"announcements",
            method:"post",
        };
        this.formLabels = {title: "Add Announcement", btnLabel:"Add"};
        // "cruiseId", "by", "subject", "description", "url", "visibleCrew", "visibleStaff", "visibleGuests"

        this.fields = {

            cruiseId : {
                name:"cruiseId",
                label : "Cruise ID",
                fieldType: "info_stay",
                type:"number",
                value :0,
                required:true
            },
      
            by : {
                name:"by",
                label : translate("By"),
                type:"text",
                fieldType: "text",
                disabled:false,
                value :"",
                required:true,
                validation:{maxLength:40}
            },
            subject : {
                name:"subject",
                label : translate("Subject"),
                type:"text",
                fieldType: "text",
                disabled:false,
                value :"",
                required:true,
                validation:{maxLength:40}
            },
            description: {
                name:"description",
                label : translate("Description"),
                type:"text",
                fieldType: "textarea",
                disabled:false,
                value :"",
            },
            // url: {
            //     name:"url",
            //     label : "URL",
            //     type:"url",
            //     fieldType: "text",
            //     disabled:false,
            //     value :"",
            // },
            // photoUrl: {
            //     name:"photoUrl",
            //     label : "Photo URL",
            //     type:"url",
            //     fieldType: "text",
            //     disabled:false,
            //     value :"",
            // },
            visibleCrew: {
                name:"visibleCrew",
                label : translate("Visible for Crew"),
                type:"checkBox",
                fieldType: "checkBox",
                disabled:false,
                value :true,
            },
            visibleStaff: {
                name:"visibleStaff",
                label : translate("Visible for Staff"),
                type:"checkBox",
                fieldType: "checkBox",
                disabled:false,
                value :true,
            },
            visibleGuests: {
                name:"visibleGuests",
                label : translate("Visible for Guests"),
                type:"checkBox",
                fieldType: "checkBox",
                disabled:false,
                value :false,
            },

        }
    }
};

class AnnouncementFunctions{
    static async _getById(id, table){
        const response = await simpleRequest({url:`${table}/${id}`, method:"get"});
        const cc = response && response.data
        return cc;
    }
}


export default AnnouncementFormData;