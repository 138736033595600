
import {translate} from "../../components/Language/Translate"

class UserCruiseSchema{
    constructor(){
        this.fields = {

            cruiseStatus: {
                label:"Status",
                required : true,
                fieldType : "select",
                type: "text",
                options:[
                  {key:"guest", value:translate("Passagier")}, 
                  {key:"staff", value:translate("Staff (including Commissioners, Lectures, Experts, Guest Artist etc. with the status as passenger)")}, 
                  {key:"crew", value:translate("Crew")}
                ],
                value : "guest",
            },
            department : {
              label : "Department",
              fieldType: "text",
              type: "text",
              value: "",
              placeholder: translate("Only for crew and staff"),
              validation: {
                maxLength: 40
              }
            },
            position : {
                 label:"Position",
                 fieldType: "text",
                 type:"text",
                 value:"",
                 placeholder: translate("Only for crew and staff"),
                 validation: {
                    maxLength: 50
                  }
            },

        }
    }
};
export default UserCruiseSchema;