import React, {useState, useEffect} from "react";
import {simpleRequest} from '../../newForms2/simpleRequest'
import { Translate, translate } from "../Language/Translate";
import { useDispatch, useSelector } from "react-redux";
import { fetchCruisesFromAPI } from "../../actions/cruises";
import CruiseListItems from "../CruiseListItems";


const PhotoDetails = ({actualPhoto, setView}) => {
    const [album, setAlbum] = useState(null);
    const {nickname, imageUrl, titleDE, titleEN, albumId} = actualPhoto;
    const [albumDestinations, setAlbumDestinations] = useState([]);
    const [selectedCruises, setSelectedCruises] = useState([]);
    const [showCruises, setShowCruises] = useState(false);
    const language = Translate.language || "enUK"
    const dispatch = useDispatch();
    const title = language === "deDE" ? titleDE : titleEN;
    const cruises = useSelector(st => st.cruises);
    // console.log({actualPhoto, album, albumDestinations, selectedCruises, showCruises});

    useEffect(() => {
        // console.log('first useEffect');
        if(cruises.length < 1){
            // console.log('dispatch cruises');
            dispatch(fetchCruisesFromAPI());
        };
    }, [cruises, dispatch]);

    useEffect(() => {
        const fetch = async () => {
            const album = (await simpleRequest({url:`albums/${albumId}`})).data?.album || {};
            const ad = album?.destinations || [];
            (() => setAlbumDestinations(album?.destinations || []))();
            (() => setAlbum(album))();
            // console.log({album, ad});
            let days = []
            for(let i=0; i < ad.length; i++){
                const d = (await simpleRequest({url:`days/destination/${ad[i].destinationId}`})).data?.days;
                days = [...days, ...d];
            };
            // console.log({album, days});
            const selectedCrArr = Array.from(new Set(days.map(d => d.cruiseId)));
            // console.log({selectedCrArr});
            (() => setSelectedCruises(cruises.filter(c => selectedCrArr.includes(c.id))
                                        .map(c => {return{...c, showVessel:true}})))();
        }
        if(!album)fetch();
    },[album, albumId, cruises]);

    // console.log({album, albumDestinations, selectedCruises, actualPhoto});

    return(
        <div>
            <div>
                <img src={imageUrl} onClick = {() => setView('diaShow')}
                    style = {{height:"50vh", width : "auto", objectFit: "contain"}} 
                    alt='Photo_' className="img-thumbnail" >
                </img>
            </div>
            <div className = "mt-2">
                <p>&copy; {nickname}</p>
                <p>{title}</p>
            </div>
            <div className="mt-2">
                <button className="btn btn-outline-dark" onClick={() => setView("diaShow")}>{translate("Back to Dia Show")}</button>
                <button className="btn btn-outline-dark ml-1" onClick={() => setView("album")}>{`${translate("Photo Album")}: ${title || titleDE}` || '.'}</button>
            </div>
            <div className="mt-3 p-2" style={{border:"1px solid grey", borderRadius:"5px"}}>
                <h6 className="mt-3">{`${translate(`Destinations near`)}  "${title}":`}</h6>
                {!albumDestinations.length && <p>{translate("No destinations available")}</p>}
                {!selectedCruises.length && <p>{translate("No cruises available")}</p>}
                {!!albumDestinations.length && albumDestinations.map(ad => {
                    return(<p key={ad.id}>{`${ad.deDE} / ${ad.enUK}`}</p>)
                })}
                {!!albumDestinations.length && <button className="btn btn-outline-dark mx-2" onClick={() => setShowCruises(!showCruises)}>
                    {!showCruises ? translate("Show cruises with these destinations") : translate('Hide cruises')}
                </button>}
                {showCruises && !!selectedCruises.length && <CruiseListItems headLine="" cruises = {selectedCruises}/>}
            </div>
            
        </div>
    );
};
export default PhotoDetails;