import React from "react";
import { Translate, translate } from "../../../components/Language/Translate";
import { sortArrayByObjectField } from "../../../helpers/sort";
import { categories } from "../../../components/species/categories";
import { BlackWhite } from "../../../components/styledComponents/colors";

const ExistingGroups = ({props}) => {
    // console.log({props});
    const {speciesGroups, setSpeciesGroupId, setOpenForm}= props;
    const handleClick = e => {
        // console.log(setAcutalId, +e.target.parentElement.id)
        setSpeciesGroupId(+e.target.parentElement.id);
        setOpenForm("showSpeciesGroup");
    }

    return(
        <div className="mt-4">
            <h5>{translate("Existing Groups")}</h5>
            {Object.entries(categories).map((c, idx) => {
                return (
                    <div key = {idx} className="px-5" >
                        <h5>{c[1][Translate.language]}</h5>
                        <ul style={{textDecoration :"none"}}>
                            {sortArrayByObjectField(speciesGroups.filter(sg => sg.category === c[0]), "deDE")
                                                                .map(sg => <li key={sg.id} id={sg.id} onClick={e => handleClick(e)}>
                                                                                <BlackWhite >
                                                                                    {`${sg.deDE || ""} / ${sg.enUK || ""} / ${sg.latin || ""}`}
                                                                                </BlackWhite>
                                                                            </li>)}
                        </ul>                                    
                    </div>
                )
            })}
        </div>
    )
};
export default ExistingGroups;