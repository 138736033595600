import React, { useState } from "react";
import ArticleLinkFormEdit from "../../newForms2/articles/ArticleLinkFormEdit";
import { simpleRequest } from "../../newForms2/simpleRequest";
import { Image } from "react-bootstrap";
import { translate } from "../Language/Translate";
import { useSelector } from "react-redux";

const ArticleLinkListItem = ({link, setArticle, article}) => {
    // console.log({link, setArticle, setShowNewForm});
    const [showEditForm, setShowEditForm] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const currentUser = useSelector(st => st.currentUser);
    const isAuthor = currentUser?.username === link.createdBy;
    // console.log({isAuthor, setShowEditForm});

    const rightColClass = link.photoUrl? "col-md-9 text-left" : "text-center";

    const copyRightText = link.copyright 
        ? ` ${link.copyright}` 
        : ` Weblink (${translate("Open link for further informations")}.), ${translate("Photo address")}: ${link.photoUrl}`;
    
    const handleDelete = async() => {
        const response = await simpleRequest({url:`articlelinks/${link.id}`, method:'delete'});
        const deleted = response.data?.deleted;
        if(!deleted)return;
        await (() => setArticle({...article, links:[...article.links.filter(l => l.id !== deleted.id)]}))();
        (() => setShowDelete(false))();
    };

    const AuthorButtons = () => (
        <span>
            <button className="btn btn-outline-dark" onClick={() => setShowEditForm(true)}>
                        {translate("Edit Link")}
            </button>
            <button className="btn btn-outline-danger mx-1" onClick={() => setShowDelete(true)}>
                        {translate("Delete Link")}
            </button>

        </span>
    )
    
    const Main = () => (
        <div className="mt-1" style={{border:"1px solid grey", borderRadius:"5px"}}>
            <div className={link.photoUrl ? "row mt-1 p-3" : "text-center mt-1 p-3"} 
                        >
                {link.photoUrl && 
                    <div className = "col-md-3">
                        {link.photoUrl && <Image src={link.photoUrl} style={{width:"100%", height:"auto"}}></Image>}
                  
                    </div>}
                <div className = {`${rightColClass}`} >
                    <h6>{link.subject}</h6>
                    <p>{link.description}</p>


                    {isAuthor && <AuthorButtons/>}
                    <a className = "btn btn-outline-dark" href={link.url} target="_blank" rel="noopener noreferrer">{translate("Open Link")}</a>
                </div>
                </div>
            {link.photoUrl && 
                    <p className="text-left ml-3">&copy; {copyRightText}
                    </p>}
            </div>
    );
    // const Main = () => (
    //     <div className="">
    //         <div className={link.photoUrl ? "row mt-1 p-3" : "text-center mt-1 p-3"} 
    //                     style={{border:"1px solid grey", borderRadius:"5px"}}>
    //             {link.photoUrl && 
    //                 <div className = "col-md-3">
    //                     {link.photoUrl && <Image src={link.photoUrl} style={{width:"100%", height:"auto"}}></Image>}
                  
    //                 </div>}
    //             <div className = {`${rightColClass}`} >
    //                 <h6>{link.subject}</h6>
    //                 <p>{link.description}</p>
    //                 <p>&copy; {link.copyright 
    //                         ? ` ${link.copyright}` 
    //                         : ` Weblink (${translate("Open link for further informations")}.), ${translate("Photo address")}: ${link.photoUrl}`}
    //                 </p>

    //                 {isAuthor && <AuthorButtons/>}
    //                 <a className = "btn btn-outline-dark" href={link.url} target="_blank" rel="noopener noreferrer">{translate("Open Link")}</a>
    //             </div>
    //         </div>
    //     </div>
    // );
    
    const ConfirmationArea = () => {
       return(
            <div>
                <h5>{translate("Are you sure to delete this link?")}</h5>
                <button className="btn btn-outline-danger" onClick={handleDelete}>{translate("Delete")}</button>
                <button className="btn btn-outline-secondary" onClick={() => setShowDelete(false)}>{translate("Cancel")}</button>
            </div>
       ); 
    }

    return(
        <>
            {!showEditForm && !showDelete&& <Main/>}
            {showEditForm && <ArticleLinkFormEdit id={link.id} article={article} setArticle={setArticle} setShowEditForm={setShowEditForm}/>}
            {showDelete && <ConfirmationArea />}     
        </>
    )
};
export default ArticleLinkListItem;