import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import { translate } from "../../components/Language/Translate";
// import {validateField} from '../ReactFormFunctions';

const PasswordToggle = ({props}) => {
    const [checked, setChecked] = useState(false);
    const name = "passwordToggle";
    const {fieldSettings, setFieldSettings} = props;
    // console.log({fieldSettings, setFieldSettings, props});

    const handleCheck = e => {
        const selected = e.target.checked;
        
        (()=>setChecked(!checked))();
        const {password, confirmPassword} = fieldSettings; 
        // console.log({selected, password, confirmPassword});
        password && (password.type = checked ? "password" : "text");
        confirmPassword && (confirmPassword.type = checked ? "password" : "text");
        (() => setFieldSettings({...fieldSettings, password, confirmPassword}))();
    }

    return(
        <Form.Group size="lg" controlId="passwordToggle">
        {/* <Form.Label>{translate("Show password")}</Form.Label> */}
        <Form.Check type="checkbox" 
                    label = {translate("Display password")}
                    key={name}
                    checked={checked}
                    value={checked}
                    name={name}
                    onChange={(e) => handleCheck(e)}
        />
        </Form.Group>
    );

};
export default PasswordToggle;