import FieldValidation from './Fields/FieldValidation';
import FormValidation from './Fields/FormValidation';
import {simpleRequest} from '../newForms2/simpleRequest';
import UserFormEvaluation from './users/UserFormEvaluation';
// import CompanyFormEvaluation from './companies/xxCompanyFormEvaluation';
import VesselFormEvaluation from './vessels/VesselFormEvaluation';
import FirstStepsEvaluation from './firstSteps/FirstStepsEvaluation'
import FormSpecialValidation from './FormSpecialValidation';



const handleChange = (e, constants, variables) => {
    // console.log(e.target, e.target.name, e.target.value)

    const {name,value} = e.target;
    const fields = {...variables.fields,[name]: value}
    const vars = {...variables,fields: fields}
    constants.events.setVariables(vars)
}

function _changedData(data, oldValues) {
    const changed = Object.keys(data).some(k => {
        // console.log(k, oldValues[k]);
        return data[k] !== oldValues[k]
    });
    // console.log({data, oldValues, changed})
    return changed;
};



async function prepareRequest(constants, variables) {
    // console.log('inside prepare request', {constants, variables})
    if (!validateForm(constants, variables)) {
        // console.log("Validation failed")
        return null;
    }
    
    const {formType,oldValues} = constants;
    let data = {...variables.fields};
    if (constants.handleBeforeRequest) data = await FormSpecialValidation[formType](constants, variables, data);
    if(!data)return null;
    // constants.events.setServerError("Hallo")
    if (oldValues && !_changedData(data, oldValues)) {
        constants.events.setServerError('No changed data');
        return null;
    }
    let newData={}
    if (constants.sendData){
        constants.sendData.forEach(k=>newData = {...newData, [k]: data[k]})
    }else{
        newData={...data}
    }
    if(constants.oldValues){
        Object.keys(newData).forEach(k => {
            if(newData[k] === constants.oldValues[k])delete newData[k]
        });
    };
    Object.keys(constants.fields).forEach(k => {
        if (constants.fields[k].fieldType === "info") delete newData[k];
        if (constants.fields[k].toNumber && newData[k]) newData[k] = parseInt(newData[k])
        if (constants.fields[k].type === "text" && newData[k]===null) {newData[k]=""};
    })

    const axiosObj = {...constants.request,data:newData};
    // console.log({axiosObj})
    return axiosObj;
}


async function handleSubmit(e, constants, variables) {
    e.preventDefault();
    const axiosObj = await prepareRequest(constants, variables);
    // console.log({axiosObj})
    if (!axiosObj) return;
    const response = await simpleRequest(axiosObj);
    // console.log(response);
    // console.log(response.status)
    // console.log(response.data)
    // console.log(response.data.error)
    // console.log(response.errorMessage)
    const errorMessage = response.response && response.response.data.error && response.response.data.error.message;
    // console.log({errorMessage})
    // console.log('vor ERROR', errorMessage)
    if (errorMessage) {
        // console.log('ERROR', errorMessage)
        // console.log(response.response.data.errorMessage)
        constants.events.setServerError(errorMessage);
        // console.log(variables.serverError)
        return;
    }
    // console.log('keinError')
    // console.log(constants.evaluation, constants.nextPage,'OOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO')
    if (constants.evaluation) {
        if (constants.formType.includes("user")) UserFormEvaluation[constants.formType](constants, variables, response.data);
        // if (constants.formType.includes("company")) CompanyFormEvaluation[constants.formType](constants, variables, response.data);
        if (constants.formType.includes("vessel")) VesselFormEvaluation[constants.formType](constants, variables, response.data);
        if (constants.formType.includes("notFoundNew")) FirstStepsEvaluation[constants.formType](constants, variables, response.data);
    } else {
        // console.log('HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH', constants, constants.nextPage)
        if(constants.nextPage)constants.events.history.push(constants.nextPage);
    }
}

const validateField = (e, constants, variables) => {
    // console.log(e.target.name, e.target.value)
    const {errors} = variables;
    const val = new FieldValidation(e.target.name, e.target.value, constants, variables)
    const {errMsg} = val.isValid();
    let tempErrors = {...errors,[e.target.name]: errMsg};
    constants.events.setErrors({...tempErrors})
    // console.log('inside validateField', e.target.name,{variables, constants})
};

const validateForm = (constants, variables) => {

    const valForm = new FormValidation(constants, variables)
    const result = valForm.formValid()
    // console.log('Result Validation:', {result})
    if (!result.valid) {
        constants.events.setErrors(result.errObj)
        // console.log('validateForm ist nicht ok und gibt den Wert false zurück!', result.errObj)
    }
    return result.valid
}

export {
    handleChange,
    handleSubmit,
    validateField,
    validateForm
};