import React, { useEffect, useState} from "react";
import Spinner from '../../components/Spinner';
import { useDispatch, useSelector } from "react-redux";
import UserCruiseSchema from "./UserCruiseSchema";
import FormFields from "../FormFields";
import { translate } from "../../components/Language/Translate";
import { simpleRequest } from "../simpleRequest";
import { fetchCruiseFromApi } from "../../actions/currentCruise";
import { fetchCurrentUserFromApi } from "../../actions/currentUser";

const UserCruiseNew = () => {
        const [isLoading, setIsLoading] = useState(true);
        const [fieldSettings, setFieldSettings] = useState({});
        const [variables, setVariables] = useState({});
        const [fieldErrors, setFieldErrors] = useState({});
        const [serverError, setServerError] = useState('');

    const currentUser = useSelector(st => st.currentUser);
    const currentCruise = useSelector(st => st.currentCruise);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetch = () => {
            const sets = new UserCruiseSchema();
            const vars = {};
            Object.entries(sets.fields).forEach(e => vars[e[0]] = e[1].value);
            (() => setVariables(vars))();
            (() => setFieldSettings(sets.fields))();
            (() => setIsLoading(false))();
        }
        if(isLoading)fetch();
    },[isLoading]);

    

    useEffect(() => {
        const {position, department} = fieldSettings;
        let changed = false;
        if(!position || !department)return;

        if(variables.cruiseStatus === "guest" && (position.visible || department.visible)){
            position.visible = false;
            department.visible = false;
            changed = true;
        }else if(variables.cruiseStatus !== "guest" && (!position.visible || !department.visible)){
            position.visible = true;
            department.visible = true;
            changed = true;
        };
        // console.log({department, position});
        if(changed)(() => setFieldSettings({...fieldSettings, position, department}))();
    }, [variables.cruiseStatus, fieldSettings]);
    
    if(isLoading) return <Spinner/>
    const props = {
        fieldSettings, 
        variables, setVariables,
        fieldErrors, setFieldErrors, 
        serverError, setServerError};

    const handleSubmit = async() => {
        const data = {...variables, username:currentUser.username};
        await simpleRequest({url:`usercruises/${currentCruise.id}`, method:"post", data});
        dispatch(fetchCruiseFromApi(currentCruise.id));
        dispatch(fetchCurrentUserFromApi(currentUser.username));
    }

    return(
        <div className="m-1 my-5">
                <FormFields props = {props}/>
                <button onClick={handleSubmit} className="btn btn-info col-12">{translate("Submit")}</button>
        </div>
    )


};
export default UserCruiseNew;

