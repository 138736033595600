
import {
  FETCH_VESSEL,
  UPDATE_CURRENT_VESSEL
} from "./types";
import {simpleRequest} from "../newForms2/simpleRequest"

function fetchVesselFromAPI(vesselId) {
  return async function (dispatch) {
    const response = await simpleRequest({url:`vessels/${vesselId}`, method:"get"});
    const cVessel=response.data?.vessel;
    // console.log({cVessel});
    return dispatch(getVessel(cVessel));
  };
};
function getVessel(currentVessel) {
  // console.log('inside getVessel', currentVessel)
  return {
    type: FETCH_VESSEL,
    currentVessel,
  };
};
function updateCurrentVesselOnAPI({vesselId, data}){
  // console.log({vesselId, data})
  return async function (dispatch){
    const response = await simpleRequest({url:`vessels/${vesselId}`, method:"patch", data});
    const vessel = response.data && response.data.vessel;
    // console.log( response.data, {vessel})
    if(!vessel)return;
    return dispatch(updateCurrentVessel(vessel));
  };
};
function updateCurrentVessel(vessel){
  return{
    type:UPDATE_CURRENT_VESSEL,
    vessel
  }
}

export {
  fetchVesselFromAPI,
  updateCurrentVesselOnAPI
};
