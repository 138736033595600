import React,{useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux"
import { translate } from "../../Language/Translate";
import UserMapCheckList from "./UserMapCheckList";
import { fetchUserDaysFromApi } from "../../../actions/currentUser";
import MapMain from "../../Leaflet/MapMain";
import {getDaysOnSea} from '../../../helpers/usercruise';

const UserMap = () => {
    
    const [list, setList] = useState([]);
    const [show, setShow] = useState('selection');
    const currentUser = useSelector(st => st.currentUser);
    const dispatch = useDispatch();
    const userCruises = currentUser?.cruises || [];
    
    useEffect (() => (() => setList(currentUser.cruises?.map(uc => {
                                        return {...uc, checked:true}})) || []
                                        .sort((a,b) => a.startAt > b.startAt ? 1 : -1))()
        , [setList, currentUser]);
    // console.log({currentUser});

    // const daysOnSea = 

    if(!currentUser?.days?.length){
        return(
        <div className = "text-center">
            <h4>{translate("User Map")}</h4>
            {/* <h6>{translate("No items")}</h6> */}
            <button className="btn btn-outline-dark" onClick = {() => dispatch(fetchUserDaysFromApi())}>{translate("Load Map")}</button>
        </div>
    )};
    const shortList = list.filter(uc => uc.checked === true).map(uc => uc.cruiseId);

    const daysOnSea = getDaysOnSea(userCruises.filter(uc => shortList.includes(uc.cruiseId)));
    const dayList = currentUser.days.filter(d => {
        return shortList.includes(d.cruiseId)
    });
    const daysInMap = dayList?.filter(d => d.geolocation !== null) || [];
    const daysNotInMap = dayList?.filter(d => !d.geolocation && d.destinationId > 1) || [];


    // console.log({shortList, dayList, daysInMap, daysNotInMap, currentUser});

    return (
        <>
            <p>{translate("Total days")}: {daysOnSea}</p>
            {show === "selection" &&  <UserMapCheckList list = {list} setList={setList} setShow={setShow}/>}
            {show === "map" &&  
                <>
                    <button className="btn btn-outline-dark" onClick={() => setShow('selection')}>
                        {translate("Change Selection")}
                    </button>
                    <MapMain daysInMap = {daysInMap} daysNotInMap={daysNotInMap}/>
                </>}
                
        </>
       
    )
};
export default UserMap;