import React from "react";
import {translate} from '../Language/Translate';

export const HelpText1 = ({setShowHelp}) => (
    <div style={{ backgroundColor:"rgba(255,255,255,.5"}} className="p-5">
      <h4>{translate("What is Cruisefriends.net")} ?</h4>
    <span>{translate("Cruisefriends.net is an innovative network for friends of cruising.")}</span><br/><br/>
    <span>{translate("It is completely free for users.")}</span><br/>
    <span>{translate("Its purpose is to meet again friends you met on your cruise and to be able to contact them.")}</span><br/>
    <span>{translate("In case of a future cruise, you will also be able to see who of your acquaintances will be with you again.")}</span><br/><br/>
    <span>{translate("On the cruise itself, through this platform you can")}</span><br/>
    <ul>
      <li>{translate("exchange messages with your friends")}</li>
      <li>{translate("form chat groups with your fellow travelers")}</li>
      <li>{translate("join existing chat groups")}</li>
      <li>{translate("share pictures and interesting articles with your fellow travelers")}</li>
      <li>{translate("write your personal travel diary")}</li>
      <li>{translate("see recommendations for restaurants or insider tips in the ports")}</li>
      <li>{translate("write recommendations yourself")}</li>
    </ul>
    <span>{translate("There are also some useful tools (such as a currency converter) at your disposal.")}</span><br/>
    <button className ="btn btn-outline-dark mt-3" onClick={()=> setShowHelp(0)}>{translate("Back")}</button>
    </div> 
  );

  export const HelpText2 = ({setShowHelp}) => (
    <div style={{ backgroundColor:"rgba(255,255,255,.5"}} className="p-5">
      <h4>{translate("How it works")}</h4>
      <span>{translate("You start by choosing the ship on which you will take the cruise.")}</span><br/>
      <span>{translate("If the ship is not yet in the list, you can create it yourself (this is only possible for logged-in users).")}</span><br/>
      <span>{translate("Then select the cruise on the list.")}</span><br/>
      <span>{translate("If the cruise is not yet in the list, you can create it yourself (this is also possible only for logged-in users).")}</span><br/>
      <span>{translate("You can then see details of the trip if they have already been entered.")}</span><br/>
      <span>{translate("As a logged in user, you can now see passengers and crew members who have also registered on this trip.")}</span><br/>
      <span>{translate("If you then click on your fellow travelers, you can write them a message directly.")}</span><br/>
      <button className ="btn btn-outline-dark mt-3" onClick={()=> setShowHelp(0)}>{translate("Back")}</button>
    </div>
  );