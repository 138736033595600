import React from "react";
import Form from "react-bootstrap/Form";
import {validateField, handleChange} from '../ReactFormFunctions';

const TextField = (props) => {
     // console.log('inside textfield, ', {props});
    const {name, variables, constants} = props;
    const {errors} = variables;
    const {fields} = constants;
    const field = fields[name]
    const value = variables.fields[name] || "";
   //  // console.log(constants)
   //  // console.log('inside TEXTFIELD', {name, variables, constants, errors, fields, field, value})
   //  // console.log({name, value})
   const maxLength = constants.fields[name]?.validation?.maxLength || 0;
   // console.log({maxLength});
   const handleChangeLocal = (e, constants, variables) => {
      const len = e.target?.value?.length || 0;
      // console.log({len, maxLength});
      if(!maxLength || len <= maxLength)handleChange(e, constants, variables);
   }
    return (
    
         <Form.Group size="lg" controlId={name}>
           <Form.Label>{field.label}{field.required? ' * ':' '}</Form.Label>
              { errors[name]?<small className="text-danger" > - {errors[name]}</small>:null}
              <Form.Control 
                 type={field.type || "text"}
                 key={name}
                 required={field.required}
                 autoFocus={field.autofocus}
                 disabled={field.disabled}
                 value={value || ""}
                 placeholder={field.placeholder?`${field.placeholder}`:null}
                 name={name}
                 onChange={(e) => handleChangeLocal(e, constants, variables)}
                 onBlur={(e) => validateField(e, constants, variables)}
                    //              onTouchEnd={(e) => // console.log('onTouchEnd')}
           />
           
           {field.toolTip?<p><small className="text-black p-1" style={{backgroundColor:"rgba(255,255,255,.5"}}>{field.toolTip}</small></p>:null}
        </Form.Group>
    )
}

export default TextField;