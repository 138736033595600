import React, {useState, useEffect} from "react";
import { Translate, translate} from '../../components/Language/Translate';
import { useSelector} from "react-redux";
import AlbumPhotoSchema from "./AlbumPhotoSchema";
import FormFields from "../FormFields";
import FileInput from "../uploadNew/FileInput";
import AlbumObj from "../../helpers/albums/albumObj";
import FormValidation from "../Validation/FormValidation";
import ServerError from '../../components/ServerError';
import { resizeAndUpload } from "../../helpers/upload/functions";
import { simpleRequest } from "../simpleRequest";

// import { fetchDestinationFromAPI } from "../../actions/currentDestination";
import { getActualAccount } from "./accounts";

const AlbumPhotosNew = ({setView, album, refresh, albumId=0}) => {
    const maxCount = 5;
    const [variables, setVariables] = useState({});
    const [fieldSettings, setFieldSettings] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    const [serverError, setServerError] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [progress, setProgress] = useState({});
    const [data, setData] = useState({});
    const currentCruise = useSelector(st => st.currentCruise);
    // const dispatch = useDispatch();
    const defTitle = AlbumObj.analyze(album, Translate.language);
    const defaultTitle = currentCruise?.vesselId && variables.checkVessel
        ? `${currentCruise.prefix} ${currentCruise.name} / ${defTitle}` 
        : defTitle;
    
    // console.log({album, refresh, albumId, variables});
    
    useEffect(() => {
        const fetch = () => {
            const key=getActualAccount(process.env.NODE_ENV).imgBB;
            // console.log({key});
            const fieldsets = new AlbumPhotoSchema().fields;
            const vars = {
                checkVessel:false,
                albumId: albumId || album.id,
                cruiseId:currentCruise?.id || 0,
                vesselId:0,
                imageUrl:"",
                subTitle:"",
                description:"",
                account:key.slice(0,15),
            }
            if(!currentCruise.id)delete vars.checkVessel;
            setFieldSettings(fieldsets);
            setVariables(vars);
            setIsLoading(false);
        }
        if(isLoading)fetch();
    }, [isLoading, album, currentCruise, albumId]);

    useEffect (() => {
            setVariables(variables => {return{...variables, vesselId: variables.checkVessel ? currentCruise?.vesselId : 0}})
    }, [variables.checkVessel, currentCruise]);


    const validateFiles = (files) => {
        const newArr = files.filter(f => {
            const xArr = f.name.split('.');
            const type = xArr[xArr.length-1].toLowerCase();
            // console.log({type});
            return ["jpg", "png", "heic"].includes(type);
        });
        const countErrors = files.length - newArr.length;
        if(countErrors)setServerError(`${countErrors} files not valid as images`);
        return newArr;
    }
    const handleSubmit = async(e) => {
        e.preventDefault();
        if(!new FormValidation({fieldSettings, variables, setFieldErrors}).valid)return;
        const newVars = {...variables};
        delete newVars.checkVessel;
        const {selectedFiles} = data;
        const validFiles = validateFiles(selectedFiles);
        // console.log({validFiles});
        for (let i = 0; i < validFiles.length; i ++){

            try{
                // console.log(validFiles[i]);
                (() => setProgress(p => {return {...p, [`nr_${i}`]: `${validFiles[i].name}...`}}))();
                // console.log({progress});
                const resData= await resizeAndUpload(validFiles[i]);
                // console.log({resData, newVars}); 
                const data = (await simpleRequest({url:'albumphotos', method:"post", data:{...newVars, ...resData}})).data;
                // console.log({data});
                (() => setProgress(p => {return {...p, [`nr_${i}`]: `${validFiles[i].name}... ok`}}))();
            }catch(err){
                (() => setProgress(p => {return {...p, [`nr_${i}`]: `${validFiles[i].name}... failed`}}))();
                // console.log("ERROR", err);
            }
        };
        setTimeout(async () => {
            if(refresh) await refresh();
            (() => setView("main"))();
        }, 2000);
        
        
    }
    const props = {fieldSettings, variables, setVariables, fieldErrors, setFieldErrors, serverError, setServerError};
    return(
        <div>
            <h6>{translate("New Album Photos")}</h6>
            
            <FileInput data={data} setData={setData} maxCount={maxCount}/><br/>
            <p>{translate("Title")}: <b>{defaultTitle}</b></p>
            <FormFields props={props}/>
            {variables.vesselId ? <p>{`${currentCruise.prefix} ${currentCruise.name}`}</p> : ''}
            {serverError && <ServerError/>}
            {progress && Object.values(progress).map((p, idx) => <p key={idx}>{p}</p>)}
            <button className="btn btn-outline-dark mr-1 mt-2" onClick={e => handleSubmit(e)}>{translate("Submit")}</button>
            <button className="btn btn-outline-secondary mt-2" onClick={() => setView("main")}>{translate("Cancel")}</button>
        </div>
    )
};
export default AlbumPhotosNew;