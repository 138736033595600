import React, {useEffect, useState} from "react";
import { Form } from "react-bootstrap";
import PrivateFormNew from "./PrivateFormNew";
import SecField from "./SecField";



const PrivateMain = () => {
    const [sec, setSec] = useState('');
    const [variables, setVariables] = useState({sec:""});
    const [keyList, setKeyList] = useState(['nix', 'nix']);
    const [formNew, setFormNew] = useState(false);
    // const [sec, setSec] = useState('')
 
    const handleChange = e => {
        setVariables({...variables, [e.target.name]: e.target.value })

    };
    // const submitSec = e => {
    //     e.preventDefault();
    //     setSec(variables.sec)
    // }



    useEffect(() => {
        const fetch = async() => {
            // console.log(sec);
            if (sec === "fff")setKeyList(['Comdirect', "Diba"]);
        };
        fetch();

    }, [sec])



    // console.log({variables, sec, keyList});









    return(
        <>
        <SecField sec = {sec} setSec={setSec}/>
        <button className="btn btn-outline-dark" onClick={() => setFormNew(!formNew)}>New Entry</button>
        {formNew && <PrivateFormNew/>}
        {/* <Form>
            <Form.Group>
                <Form.Label>Sec</Form.Label>
                <Form.Control
                    name = "sec"
                    type="password"
                    value={variables.sec}
                    onChange={e => handleChange(e)}
                    >

            </Form.Control>
        </Form.Group>
        <button className="btn btn-outline-dark" onClick={e => submitSec(e)}>Ok</button>
        
    </Form> */}
    </>
    )








}
export default PrivateMain;