import React from "react";
import {simpleRequest} from  '../newForms2/simpleRequest';
import {Card, Image} from 'react-bootstrap';

const ProfilePhotoListItem = ({photo, photos, setPhotos}) => {

    // console.log({photo});
    const handleConfirmReject = async(accepted) => {
        // setphotoConfirmed to true
        // console.log("confirm");
        const response = await simpleRequest({url:`admin/profile/${photo.username}`, method:"patch", data:{accepted}});
        // console.log(response.data);
        if(response.data && response.data.error){
            // console.log("ERROR", response.data.error);
            return
        };
        (() => setPhotos(photos.filter(p => p.username !== photo.username)))();
    };
    // const handleReject = async() => {
    //     // delete url in table, set photoConfirmed to true
    //     // console.log('reject', photo.username);
    //     const response = await simpleRequest({url:`admin/profile/${photo.username}`, method:"patch", data:{accepted}});
    //     // console.log(response.data);
    //     if(response.data && response.data.error){
    //         // console.log("ERROR", response.data.error);
    //         return;
    //     };
    //     (()=> setPhotos(photos.filter(p => p.username !== photo.username)))();
    // };

    return(
        <div className="col-lg-3 col-md-4 col-sm-6 p-1 text-center" style={{height:"100%"}}>
        <Card style={{height:"100%"}}>
                <Card.Header>
                    <h4>{photo.username}</h4>
                </Card.Header>
                <Card.Body>
                    {photo.photoUrl && <Image src={photo.photoUrl} alt="" className="img-fluid img-thumbnail" style={{minWidth:"100%", height:"auto"}} />}
                </Card.Body>
                <Card.Footer>
                    <div className="row">
                        <div className="col-md-6 p-1"><button className="btn btn-outline-dark col-12" onClick={() => handleConfirmReject(true)}>Ok</button></div>
                        <div className="col-md-6 p-1"><button className="btn btn-outline-danger col-12" onClick={() => handleConfirmReject(false)}>Reject</button></div>
                    </div>
                </Card.Footer>
        </Card>
        </div>
    );
};
export default ProfilePhotoListItem;